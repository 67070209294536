import React from 'react'
import Grid from "@material-ui/core/Grid";
import ProductSecuredDetails from "./ProductSecuredDetails";
import ProductSecured from "./ProductSecured";

const ProductSecuredDetailsPanel = () => {
    return (
        <Grid container>
            <Grid item sm>
                <ProductSecuredDetails/>
            </Grid>
            <Grid item md>
                <ProductSecured/>
            </Grid>
        </Grid>
    )
}

export default ProductSecuredDetailsPanel