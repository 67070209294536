import React, {useContext} from 'react'
import FeedbackContext from "../../../Contexts/Feedback/feedbackContext";
import Grid from "@material-ui/core/Grid";
import SubjectField from "../../Selections/SubjectField";
import MessageField from "../../Selections/MessageField";
import SelectionIconWidget from "../../Selections/SelectionIconWidget";

const FeedbackSelection = () => {
    const feedbackContext = useContext(FeedbackContext)
    const {category, subject, message, setCategory, setSubject, setMessage} = feedbackContext

    return (
        <Grid container direction="column" spacing={5}>
            <Grid item>
                <SelectionIconWidget value={category} handleSelection={setCategory}/>
            </Grid>
            <Grid item>
                <SubjectField value={subject} handleInput={setSubject}/>
            </Grid>
            <Grid item>
                <MessageField value={message} handleInput={setMessage}/>
            </Grid>
        </Grid>
    )
}

export default FeedbackSelection