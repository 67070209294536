import React from 'react'
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";

const SearchMenu = (props) => {
    const {search, handleSelection} = props
    const [open, setOpen] = React.useState(false)
    const loading = open && search.options.length === 0

    return (
        <Autocomplete
            value={search.value}
            options={search.options}
            loading={loading}
            onChange={handleSelection}
            open={open}
            onOpen={() => {setOpen(true)}}
            onClose={() => {setOpen(false)}}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={search.label}
                    placeholder={search.placeholder}
                    style={{width:"240px"}}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />}
        />
    )
}

export default SearchMenu