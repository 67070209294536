import React from 'react'
import {SET_OPEN} from "../types";

export default (state, action) => {
    switch(action.type) {
        case SET_OPEN:
            return {
                ...state,
                open: action.open
            }
        default:
            return state
    }
}