export const evColumns = ["fileDate","flightId","feRecordTime","eventId","evtype","objclass","altitude",
    "latitude","longitude","oldname","newname","course","speed","directdist","flightdist","flighttime",
    "evnuminfo","evcharinfo","directtime","rev","eventId","fileDate"]

export const evColumnNames = [
    {name: "File Date"},
    {name: "Flight ID"},
    {name: "Fe Record Time"},
    {name: "Event ID"},
    {name: "EV Type"},
    {name: "Obj Class"},
    {name: "Altitude"},
    {name: "Latitude"},
    {name: "Longitude"},
    {name: "Old Name"},
    {name: "New Name"},
    {name: "Course"},
    {name: "Speed"},
    {name: "Direct Dist"},
    {name: "Flight Dist"},
    {name: "Flight Time"},
    {name: "EV Num Info"},
    {name: "EV Char Info"},
    {name: "Direct Time"},
    {name: "Rev"},

    {name: "Event ID", options: {display: "false"}},
    {name: "File Date", options: {display: "false"}},
]

export const rdColumns = ["flightId","starttime","stoptime","cid","acid","actype","orig","route","perfcat",
    "opstype","equiplist","weightclass","beacon","airline","ckey","carriertype","calcorig","calcdest","deprwy",
    "arrrwy","tdep","tarr","estarr","estdep","facname","systems","firs","centers","tracons","sectors","suas",
    "splairsp","lclairsp","clsairsp","navobjs","pcdef","pcrj","wcdef","ctdef","ctrj","ptorig","ptdest",
    "airportorig","airportdest","icaoregionorigf10","icaoregiondestf10","duration","flightrulecategory",
    "fileDate","tin","toagarr","toagdep","toff","ton","tout","ttaxi"]

export const rdColumnNames = [
    {name: "Flight ID"},
    {name: "Start Time"},
    {name: "Stop Time"},
    {name: "CID"},
    {name: "AcID"},
    {name: "Ac Type"},
    {name: "Orig"},
    {name: "Route"},
    {name: "Perf Cat"},
    {name: "Ops Type"},
    {name: "Equip List"},
    {name: "Weight Class"},
    {name: "Beacon"},
    {name: "Airline"},
    {name: "Ckey"},
    {name: "Carrier Type"},
    {name: "Calc Orig"},
    {name: "Calc Dest"},
    {name: "Dep Rwy"},
    {name: "Arr Rwy"},
    {name: "Tdep"},
    {name: "Tarr"},
    {name: "Est Arr"},
    {name: "Est Dep"},
    {name: "Fac Name"},
    {name: "Systems"},
    {name: "Firs"},
    {name: "Centers"},
    {name: "Tracons"},
    {name: "Sectors"},
    {name: "Suas"},
    {name: "Splairsp"},
    {name: "Lclairsp"},
    {name: "Clsairsp"},
    {name: "Navobjs"},
    {name: "Pcdef"},
    {name: "Pcrj"},
    {name: "Wcdef"},
    {name: "Ctdef"},
    {name: "Ctrj"},
    {name: "Ptorig"},
    {name: "Ptdest"},
    {name: "Airport Orig"},
    {name: "Airport Dest"},
    {name: "ICAO Region Orig F10"},
    {name: "ICAO Region Dest F10"},
    {name: "Duration"},
    {name: "Flight Rule Category"},

    {name: "File Date", options: {display: "false"}},
    {name: "Tin", options: {display: "false"}},
    {name: "Toag Arr", options: {display: "false"}},
    {name: "Toag Dep", options: {display: "false"}},
    {name: "Toff", options: {display: "false"}},
    {name: "Ton", options: {display: "false"}},
    {name: "Tout", options: {display: "false"}},
    {name: "Ttaxi", options: {display: "false"}},
]

export const iffColumns = ["flightId","fileDate","sysname","trackPointId","tpRecordTime","","","","","",
    "","altitude","","","","","","","","","","","","","","","","","","","","","","tracknumber","modescode",
    "",""]

export const iffColumnNames = [
    {name: "Flight ID"},
    {name: "File Date"},
    {name: "Sysname"},
    {name: "Flight Plan Id"},
    {name: "Fp Record Time"},
    {name: "Bcncode"},
    {name: "Cid"},
    {name: "Source"},
    {name: "Acid"},
    {name: "Actype"},
    {name: "Orig"},
    {name: "Alt"},
    {name: "Maxalt"},
    {name: "Route"},
    {name: "Esttime"},
    {name: "Fltcat"},
    {name: "Perfcat"},
    {name: "Opstype"},
    {name: "Equiplist"},
    {name: "Coordinationtime"},
    {name: "Coordinationtimetype"},
    {name: "Leaderdir"},
    {name: "Scratchpad1"},
    {name: "Msgtype"},
    {name: "Assignedaltstring"},
    {name: "Requestedaltstr"},
    {name: "Prefdeparrroute"},
    {name: "Prefdeproute"},
    {name: "Prefarrroute"},
    {name: "Scratchpad2"},
    {name: "Fixpairscratchpad"},
    {name: "Coordinationpoint"},
    {name: "Coordinationpointtype"},
    {name: "Tracknumber"},
    {name: "Modescode"},


    {name: "Altcode", options: {display: "false"}},
    {name: "Dest", options: {display: "false"}},
]

export const dailySummaryColumns = ["dateLTZ","airportID","windDRCTN","windGustKtMax","windSpeedKtMax","windSpeedKtAvg",
    "skyLowestLayerAltFtMin","visibilitySmMin","HAS_FZ","HAS_FG","HAS_GR","HAS_RA","HAS_SN","HAS_TS","HAS_FC","HAS_TW",
    "HAS_ACFT_MSHP","HAS_ACC","HAS_ACSL","HAS_CCSL","HAS_CB","HAS_CBAM","HAS_CU","HAS_DZ","HAS_DS","WSHFT_HAS_FROPA",
    "HAS_HZ","HAS_IC","HAS_PL","HAS_LTG","HAS_LLWS","HAS_BR","HAS_PIREP","HAS_ROTOR","HAS_SA","HAS_SS","HAS_SH","HAS_GS",
    "HAS_FU","HAS_SG","HAS_PY","HAS_SQ","HAS_SCSL","HAS_TCU","HAS_UP","HAS_VIRGA","HAS_VA","HAS_VOLCANIC_ERPTNS","HAS_PO",
    "HAS_DU","HAS_WSHFT"]

export const dailySummaryColumnNames = [
    {name: "Date (Local TZ)"},
    {name: "Airport"},
    {name: "Wind DRCTN"},
    {name: "Highest Wind Gust (kn)"},
    {name: "Highest Wind Speed (kn)"},
    {name: "Average Wind Speed (kn)"},
    {name: "Lowest Ceiling Height AGL (ft)"},
    {name: "Lowest Visibility (smi)"},
    {name: "Has Freezing PCPN/OBSC"},
    {name: "Has Fog"},
    {name: "Has Hail"},
    {name: "Has Rain"},
    {name: "Has Snow"},
    {name: "Has Thunderstorm"},
    {name: "Has Funndel Cloud"},
    {name: "Has Tornado/Waterspout"},

    {name: "Has Aircraft Mishap Reported", options: {display: "false"}},
    {name: "Has Altocumulus Castellanus Cloud", options: {display: "false"}},
    {name: "Has Altocumulus Cloud", options: {display: "false"}},
    {name: "Has Cirrocumulus Cloud", options: {display: "false"}},
    {name: "Has Cumulonimbus Cloud", options: {display: "false"}},
    {name: "Has Cumulonimbus Mammatus Cloud", options: {display: "false"}},
    {name: "Has Cumulus Cloud", options: {display: "false"}},
    {name: "Has Drizzle", options: {display: "false"}},
    {name: "Has Duststorm", options: {display: "false"}},
    {name: "Has Frontal Passage", options: {display: "false"}},
    {name: "Has Haze", options: {display: "false"}},
    {name: "Has Ice Crystals", options: {display: "false"}},
    {name: "Has Ice Pellets", options: {display: "false"}},
    {name: "Has Lightning", options: {display: "false"}},
    {name: "Has Low Level Wind Shear", options: {display: "false"}},
    {name: "Has Mist", options: {display: "false"}},
    {name: "Has Pilot Report in Remarks", options: {display: "false"}},
    {name: "Has Rotor Cloud", options: {display: "false"}},
    {name: "Has Sand", options: {display: "false"}},
    {name: "Has Sandstorm", options: {display: "false"}},
    {name: "Has Shower(s)", options: {display: "false"}},
    {name: "Has Small Hail and/or Snow Pellets", options: {display: "false"}},
    {name: "Has Smoke", options: {display: "false"}},
    {name: "Has Snow Grains", options: {display: "false"}},
    {name: "Has Spray", options: {display: "false"}},
    {name: "Has Squalls", options: {display: "false"}},
    {name: "Has Stratocumulus Cloud", options: {display: "false"}},
    {name: "Has Towering Cumulus Cloud", options: {display: "false"}},
    {name: "Has Unknown PCPN", options: {display: "false"}},
    {name: "Has Virga", options: {display: "false"}},
    {name: "Has Volcanic Ash", options: {display: "false"}},
    {name: "Has Volcanic Eruptions", options: {display: "false"}},
    {name: "Has Well-Developed Dust/Sand Whirls", options: {display: "false"}},
    {name: "Has Widespread Dust", options: {display: "false"}},
    {name: "Has Wind Shift", options: {display: "false"}},
]
//airportID: "KATL"
// altimeterHG: null
// dewPointC: 13
// flightRulesCategory: "VFR"
// reportDateTimeLTZ: "2016-10-20 14:52:00.0"
// reportDateTimeUTC: "2016-10-20 18:52:00.0"
// skyLowestLayerAltFt: 6000
// temperatureC: 29
// visibilitySM: 10
// vrbwindDRCTNMax: null
// vrbwindDRCTNMin: null
// windDRCTN: 190
// windDRCTNCardinal: null
// windDRCTNIsVRB: "N"
// windGustKT: null
// windSpeedKT: 7
export const observationDetailColumns = ["airportID","reportDateTimeUTC","reportDateTimeLTZ","flightRulesCategory",
    "windDRCTN","windDRCTNCardinal","windSpeedKT","windGustKT","visibilitySM","skyLowestLayerAltFt","temperatureC",
    "dewPointC","altimeterHG"]

export const observationDetailColumnNames = [
    {name: "Airport ID"},
    {name: "Report Date Time (UTC)"},
    {name: "Report Date Time (Local TZ)"},
    {name: "Flight Rules Category"},
    {name: "Wind Direction (deg)"},
    {name: "Wind Direction (Cardinal)"},
    {name: "Wind Speed (kn)"},
    {name: "Wind Gust (kn)"},
    {name: "Visibility (smi)"},
    {name: "Ceiling Height AGL (ft)"},
    {name: "Temp (C)"},
    {name: "Dew Point (C)"},
    {name: "Altimeter (Hg)"}
]

export const trackAnalysisColumns = ["trackSourceFacility","trackDateUTC","trackHourUTC","aircraftCount","trackCount",
    "trackMissingMinuteCount","belowThresholdMinuteCount"]

export const trackAnalysisColumnNames = [
    {name: "Track Source Facility"},
    {name: "Date UTC"},
    {name: "Hour UTC"},
    {name: "Aircraft Count"},
    {name: "Track Count"},
    {name: "Track Missing Minute Count"},
    {name: "Below Minimum Threshold Minute Count"},
]

export const hoursWithTrackColumns = ["trackSourceFacility","trackDateUTC","belowThresholdHourCount",
    "belowThresholdHours"]

export const hoursWithTrackColumnNames = [
    {name: "Source Facility"},
    {name: "Date UTC"},
    {name: "Below Threshold Hour Count"},
    {name: "Below Threshold Hours UTC"},
]

export const hoursWithoutTracksColumns = ["trackDateUTC","noTrackHourCount","noTrackHours"]

export const hoursWithoutTracksColumnNames = [
    {name: "Date UTC"},
    {name: "No Track Hour Count"},
    {name: "No Track Hours UTC"},
]

export const dailyWITIColumns = ["dateUTC", "totalWiti", "totalDelayMinutes", "total", "normWiti",
    "ZAB","ZAU","ZBW","ZDC","ZDV","ZFW","ZHU","ZID","ZJX","ZKC","ZLA","ZLC","ZMA","ZME","ZMP","ZNY","ZOA",
    "ZOB","ZSE","ZTL"]

export const dailyWITIColumnNames = [
    {name: "Date (UTC)"},
    {name: "Daily WITI Total"},
    {name: "National Total Delay (min)"},
    {name: "Total Aircraft"},
    {name: "Unique Aircraft WITI Count"},
    {name: "ZAB"},
    {name: "ZAU"},
    {name: "ZBW"},
    {name: "ZDC"},
    {name: "ZDV"},
    {name: "ZFW"},
    {name: "ZHU"},
    {name: "ZID"},
    {name: "ZJX"},
    {name: "ZKC"},
    {name: "ZLA"},
    {name: "ZLC"},
    {name: "ZMA"},
    {name: "ZME"},
    {name: "ZMP"},
    {name: "ZNY"},
    {name: "ZOA"},
    {name: "ZOB"},
    {name: "ZSE"},
    {name: "ZTL"},
]

export const auditUserSummaryColumns = ["userId", "timestamp", "location", "firstName", "lastName",
    "email", "count"]

export const auditUserSummaryColumnNames = [
    {name: "Location"},
    {name: "First Name"},
    {name: "Last Name"},
    {name: "Email"},
    {name: "Visits"}
]

export const auditUserActivitySummaryColumns = ["userId", "timestamp", "action", "product", "option",
    "startDate", "endDate", "actionDuration", "feed"]

export const auditUserActivitySummaryColumnNames = [
    {name: "Action"},
    {name: "Product"},
    {name: "Option"},
    {name: "Start Date"},
    {name: "End Date"},
    {name: "Action Duration"},
    {name: "Feed"}
]

// sample columns
export const sampleColumns = ["created_at", "updated_at", "width", "height", "color"]
export const sampleColumnNames = [
    {name: "Created At"},
    {name: "Updated At"},
    {name: "Width"},
    {name: "Height"},
    {name: "Color"},
]