import React, {useContext} from 'react'
import AuditUserActivityContext from "../../../Contexts/AuditUserActivity/auditUserActivityContext";
import AuditUserActivitySummaryContext
    from "../../../Contexts/AuditUserActivitySummary/auditUserActivitySummaryContext";
import LineChart from "../../Charts/LineChart";
import LinearProgress from "@material-ui/core/LinearProgress";
import {handleActivity} from "../../Utils/AuditUtils";

const UserActivityPanel = () => {
    const auditUserActivityContext = useContext(AuditUserActivityContext)
    const {chartLoading, axes, chartFiles} = auditUserActivityContext
    const {chartTitle,chartHAxisTitle,chartVAxisTitle} = auditUserActivityContext
    const setUserActivityChartLoading = auditUserActivityContext.setChartLoading
    const setUserActivityChartFiles = auditUserActivityContext.setChartFiles

    const auditUserActivitySummaryContext = useContext(AuditUserActivitySummaryContext)
    const {files} = auditUserActivitySummaryContext

    console.log("User Activity", files.length)

    const handleUserActivity = () => {
        handleActivity(files, setUserActivityChartFiles)
    }

    React.useEffect(() => {
        console.log("Chart loading", chartLoading)
        setUserActivityChartLoading(true)
        handleUserActivity()
    }, [files])

    React.useEffect(() => {
        setUserActivityChartLoading(false)
    }, [chartFiles])

    return (
        chartLoading ? <LinearProgress/> : <LineChart chartFiles={chartFiles} columns={axes} title={chartTitle} haxis={chartHAxisTitle} vaxis={chartVAxisTitle}/>
    )

}

export default UserActivityPanel