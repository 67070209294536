import React, {useContext} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import LeftNavGeneric from '../Buttons/LeftNavGeneric'
import SideNavConext from '../../Contexts/SideNav/sideNavContext'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 245,
    background: '#eceff1',
    //This is the padding between the end date and the menu.
    paddingTop: theme.spacing(2),
    overflow: 'auto',
  },
}));

export default function LeftNavCharts() {
  const classes = useStyles();
  const sideNavConext = useContext(SideNavConext)
  const atacFlightFont = sideNavConext.atacFlightFont

  const highlightAtacFlight = sideNavConext.highlightAtacFlight
  const hoverHighlightAtacFlight = sideNavConext.hoverHighlightAtacFlight
  const unhighlightAtacFlight = sideNavConext.unhighlightAtacFlight

  const fontStyleAtacFlight = { variant: atacFlightFont, color: 'primary'}

  let atacFlightHover

  React.useEffect(() => {
    if(window.location.pathname.includes('ATAC'))
      (async () => await highlightAtacFlight())();
  }, []);

  atacFlightHover = window.location.pathname.includes('ATAC') ? hoverHighlightAtacFlight : unhighlightAtacFlight

  return (
    <List
      component="nav"
      dense
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <LeftNavGeneric link='/ChartsATAC' 
                      label='ATAC Flight Data'
                      onMouseOver={hoverHighlightAtacFlight}
                      onMouseLeave={atacFlightHover}
                      onClick = {highlightAtacFlight}
                      primaryTypographyProps={fontStyleAtacFlight}/>
    </List>
  );
}
