import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import {basename, hostname} from "../Data/api";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    modal: {
        position: 'absolute',
        height: 200,
        width: 350,
        backgroundColor: '#039be5',
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        textAlign: 'center'
    },
    background: {
        width: 350,
        textAlign: 'center',
        padding: 10
    },
    horizontalLine: {
        borderColor: '#039be5',
        marginTop: -10
    },
    button: {
        color: '#039be5',
        fontWeight: 700
    },
    closeButton: {
        float: "right"
    }
}));

const SupportDialog = (props) => {
    const classes = useStyles();
    const {open} = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    const feedback = () => {
        window.location.href = hostname.slice(0,-1) + basename + "/Feedback"
    }

    return (
        <Popover
            style={{marginTop: 30}}
            open={open}
            anchorEl={anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Grid container className={classes.background} alignItems="center" direction="column" spacing={2}>
                <Grid item>
                    <h3>Have a Question?</h3>
                    <hr className={classes.horizontalLine}/>
                </Grid>
                <Grid item>
                    <Typography>Not finding the data you are looking for</Typography>
                </Grid>
                <Grid item>
                    <Button className={classes.button} variant="outlined" onClick={feedback}>Send us Feedback</Button>
                </Grid>
            </Grid>
        </Popover>
    )
}

export default SupportDialog