import React, {useContext} from 'react'
import ProfileContext from "../../Contexts/Profile/profileContext";
import SearchMenu from "../Selections/SearchMenu";
import StartDateContext from "../../Contexts/StartDate/startDateContext";
import EndDateContext from "../../Contexts/EndDate/endDateContext";
import axios from "axios";
import {auditUrl, hostname} from "../Data/api";

const ProfileSelection = () => {
    const profileContext = useContext(ProfileContext)
    const {profile} = profileContext
    const setOptions = profileContext.setOptions
    const setProfile = profileContext.setProfile
    const resetProfileSelection = profileContext.resetProfileSelection
    const setFiles = profileContext.setFiles

    const startDateContext = useContext(StartDateContext)
    const {startDate, startTime} = startDateContext
    const endDateContext = useContext(EndDateContext)
    const {endDate, endTime} = endDateContext

    const handleProfileQuery = () => {
        const start = startDate.toString().replace(/\//g, '-')
        const end = endDate.toString().replace(/\//g, '-')
        console.log("Handle Profile Query", start, end)

        axios.get(hostname + auditUrl + "audit/summary?starttime="+start+" "+startTime+"&stoptime="+end+" "+endTime)
            .then(response => {
                setFiles(response.data)
                setOptions(handleGuestProfiles(response.data))
                response.data.length === 0 && resetProfileSelection()
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleGuestProfiles = (response) => {
        return response.length > 0 ? [...new Set(response.map(item => { return [item["firstName"], item["lastName"], "("+item["userId"]+")"].join(" ")}))]: []
    }

    React.useEffect(() => {
        handleProfileQuery()
    }, [startDate, startTime, endDate, endTime])

    return (
        <SearchMenu search={profile} handleSelection={setProfile}/>
    )
}

export default ProfileSelection