import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar';
import UserContext from '../../Contexts/User/userContext';
import {deepOrange} from '@material-ui/core/colors';
import HeaderButton from '../Buttons/HeaderButton'
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {basename, hostname} from '../Data/api'

const useStyles = makeStyles((theme) => ({
  container:{
    display: 'inline-flex',
  },
  avatar: {
    cursor: 'pointer',
    fontSize: 16,
    width: '25px',
    height: '25px',
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    marginLeft: 10
  }
}));

function HeaderAvatar(props) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {user, updateUser} = useContext(UserContext)
  const tooltip ={
    title:"Logged in as " + user.name,
    enterDelay:0
  }

  const handleClickAvatar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null)
  };

  const login = () => {
    // Do a refresh and let the server take care of authentication.
    window.location.reload();
    /*
    const url = hostname + "Shibboleth.sso/SAML2/POST"
    axios.post(url)
    .then(response => {
      updateUser()
    })
    */
  }
  const profile = () => {
    window.location.href = hostname.slice(0,-1) + basename + "/Profile"
  }
  const audit = () => {
    window.location.href = hostname.slice(0,-1) + basename + "/Audit"
  }
  const logout = () => {
    window.location.href = hostname + "Shibboleth.sso/Logout"
  }

  return (
    <div className={classes.container}>
      {user.auid===undefined || user.auid == null ?
        <Link onClick={login} className={props.linkClassName}>
          <HeaderButton label='Login' onClick={login}/>
        </Link> 
        :
        <Tooltip title={tooltip.title} enterDelay={tooltip.enterDelay}>
          <Avatar className={classes.avatar} onClick={handleClickAvatar}>
            {user.auid.charAt(0).toUpperCase()}
          </Avatar>
        </Tooltip>
      }
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}     
        getContentAnchorEl={null}      
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }} 
        onClose={handleCloseMenu}>
        {user.roles.includes("admin") &&
          <MenuItem onClick={profile}>Profile</MenuItem>
        }
        {user.roles.includes("admin") &&
          <MenuItem onClick={audit}>Audit</MenuItem>
        }
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  )
}

export default HeaderAvatar