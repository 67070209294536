import React from 'react';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.secondary.main
    }
}));

const SendButton = (props) => {
    const classes = useStyles()
    const {loading, sent, handleSend} = props

    const handleClick = () => {
        handleSend(true)
    }

    return (
        <Button variant="contained" color="primary" onClick={handleClick}>
            {loading ? <CircularProgress className={classes.icon}/> : "Send"}
        </Button>
    )
}

export default SendButton