import React, {useReducer} from 'react'
import AuditProductSummaryReducer from "./auditUserActivitySummaryReducer";
import AuditProductSummaryContext from "./auditUserActivitySummaryContext";
import {SET_FILES, SET_TABLE_LOADING} from "../types";

const AuditUserActivitySummaryState = props => {
    const initialState = {
        tableLoading: false,
        files: []
    }

    const [state, dispatch] = useReducer(AuditProductSummaryReducer, initialState);

    const setTableLoading = (tableLoading) => {
        dispatch({
            type: SET_TABLE_LOADING,
            tableLoading: tableLoading
        })
    }

    const setFiles = (files) => {
        dispatch({
            type: SET_FILES,
            files: files
        })
    }

    return <AuditProductSummaryContext.Provider
        value = {{
            tableLoading: state.tableLoading,
            files: state.files,
            setTableLoading,
            setFiles
        }}>
        {props.children}
    </AuditProductSummaryContext.Provider>
}

export default AuditUserActivitySummaryState