import React from 'react'
import {SET_FILES, SET_TABLE_LOADING} from "../types";

export default (state, action) => {
    console.log("Audit User Summary Reducer ", state, action.files)
    switch (action.type) {
        case SET_TABLE_LOADING:
            return {
                ...state,
                tableLoading: action.tableLoading
            }
        case SET_FILES:
            return {
                ...state,
                files: action.files
            }
        default:
            return state
    }
}