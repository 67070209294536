import React from 'react'
import {RESET_TOOLTIP, SET_CURRENT_DATE, SET_DATE_TIME, SET_MIN_DATE, SET_TOOLTIP, SET_TOOLTIP_MESSAGE} from "../types";

export default (state, action) => {
    // console.log("Start Date Reducer ", state, action.startDate, action.startTime)
    switch(action.type) {
        case SET_DATE_TIME:
            return {
                ...state,
                startDate: action.startDate,
                startTime: action.startTime
            }
        case SET_CURRENT_DATE:
            return {
                ...state,
                currentDate: action.currentDate
            }
        case SET_MIN_DATE:
            return {
                ...state,
                minDate: action.minDate
            }
        case SET_TOOLTIP:
            return {
                ...state,
                tooltip: true
            }
        case RESET_TOOLTIP:
            return {
                ...state,
                tooltip: false
            }
        case SET_TOOLTIP_MESSAGE:
            return {
                ...state,
                tooltipMessage: action.tooltipMessage
            }
        default: return state
    }
}