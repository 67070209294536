import React, {useContext} from 'react'
import StartDateContext from "../../Contexts/StartDate/startDateContext";
import EndDateContext from "../../Contexts/EndDate/endDateContext";
import axios from "axios";
import {auditUrl, hostname} from "../Data/api";
import AuditLogButton from "../Buttons/AuditLogButton";
import AuditContext from "../../Contexts/Audit/auditContext";
import AuditUserSummaryContext from "../../Contexts/AuditUserSummary/auditUserSummaryContext";
import AuditUserActivitySummaryContext from "../../Contexts/AuditUserActivitySummary/auditUserActivitySummaryContext";
import {
    handleAverageTime,
    handleFilterFiles,
    handleMaximumTime,
    handleTotalProducts,
    handleUniqueValues
} from "../Utils/AuditUtils";

const AuditSearch = () => {
    const auditContext = useContext(AuditContext)
    const {userTypes} = auditContext
    const setClicked = auditContext.setClicked
    const setTotalVisitors = auditContext.setTotalVisitors
    const setTotalProductInterest = auditContext.setTotalProductInterest
    const setTotalProductSecured = auditContext.setTotalProductSecured
    const setAvgSearchTime = auditContext.setAvgSearchTime
    const setMaxActiveTime = auditContext.setMaxActiveTime

    const auditUserSummaryContext = useContext(AuditUserSummaryContext)
    const userSummaryFiles = auditUserSummaryContext.files
    const setUserSummaryTableLoading = auditUserSummaryContext.setTableLoading
    const setUserSummaryFiles = auditUserSummaryContext.setFiles

    const auditUserActivitySummaryContext = useContext(AuditUserActivitySummaryContext)
    const userActivitySummaryFiles = auditUserActivitySummaryContext.files
    const setUserActivitySummaryTableLoading = auditUserActivitySummaryContext.setTableLoading
    const setUserActivitySummaryFiles = auditUserActivitySummaryContext.setFiles

    const startDateContext = useContext(StartDateContext)
    const {startDate, startTime} = startDateContext
    const endDateContext = useContext(EndDateContext)
    const {endDate, endTime} = endDateContext
    const start = startDate.toString().replace(/\//g, '-')
    const end = endDate.toString().replace(/\//g, '-')

    const handleSelectedTypes = (types) => {
        let checkedTypes = []

        types.value[0].checked ? checkedTypes = ["0"] :
            Object.entries(types.value).forEach(([key, value]) => {
                value.checked && (checkedTypes.push(value.value))
            })

        return checkedTypes.join(",")
    }

    const handleUserSelection = () => {
        setClicked(true)
        handleUserSummary()
        handleProductSummary()
    }

    const handleUserSummary = () => {
        setUserSummaryTableLoading(true)

        let queryUserType = handleSelectedTypes(userTypes)

        console.log("Query", hostname + auditUrl + "audit/summary?starttime="+start+" "+startTime+"&stoptime="+end+" "+endTime+"&location="+queryUserType)

        //http://localhost:9021/api/audit/summary
        axios.get(hostname + auditUrl + "audit/summary?starttime="+start+" "+startTime+"&stoptime="+end+" "+endTime+"&location="+queryUserType)
            .then(response => {
                console.log("Real API ", response.data)
                console.log("Response", response.data)

                setUserSummaryFiles(response.data)
                setUserSummaryTableLoading(false)
            })
            .catch(err => {
                console.log(err)
                setUserSummaryTableLoading(false)
            })
    }

    const handleProductSummary = () => {
        setUserActivitySummaryTableLoading(true)
        axios.get(hostname + auditUrl + "audit/products?starttime=" + start + " " + startTime + "&stoptime=" + end + " " + endTime)
            .then(response => {
                console.log("Real API ", response.data)

                setUserActivitySummaryFiles(response.data)
                setUserActivitySummaryTableLoading(false)
            })
            .catch(err => {
                console.log(err)
                setUserActivitySummaryTableLoading(false)
            })
    }

    const handleUserActivityQuickInfo = () => {
        let userSearchData = handleFilterFiles(userActivitySummaryFiles, "search")
        handleAverageTime(userSearchData, setAvgSearchTime)
        handleMaximumTime(userSearchData, setMaxActiveTime)
        handleTotalProducts(userActivitySummaryFiles, "search", "product", setTotalProductInterest)
        handleTotalProducts(userActivitySummaryFiles, "download", "product", setTotalProductSecured)
    }

    React.useEffect(() => {
        handleUserSelection()
    }, [])

    React.useEffect(() => {
        setTotalVisitors(handleUniqueValues(userSummaryFiles, "userId").length)
    }, [userSummaryFiles])

    React.useEffect(() => {
        handleUserActivityQuickInfo()
    }, [userActivitySummaryFiles])

    return (
        <AuditLogButton handleUserSelection={handleUserSelection}/>
    )
}

export default AuditSearch