import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { NavLink } from 'react-router-dom';
import SideNavContext from '../../Contexts/SideNav/sideNavContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 245,
    background: 'linear-gradient(90deg, #cfd8dc 60%, #78909c 120%)',
    //This is the padding between the end date and the menu.
    paddingTop: theme.spacing(1),
  },
  nested1: {
    paddingTop: 0,
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '8px',
    height: '30px',
    // width: '290px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  nested2: {
    paddingTop: 0,
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '5px',
    height: '30px',
    // width: '290px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  removeUnderline: {
    textDecoration: 'none',
    paddingBottom: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  removePadding: {
    marginBottom: theme.spacing(-1),
    disableGutters: true,
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  underline: {
    borderBottomStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#01579b',
    marginLeft: '15px',
    marginRight: '23px',
    paddingLeft: '0px',
  },
  active: {
    variant: 'body2',
  }
}));

function LeftNavDownloadATACFlight() {
  const classes = useStyles();
  const sideNavContext = useContext(SideNavContext)
  const toggleAtacFlightMenu = sideNavContext.toggleAtacFlightMenu
  const openAtacFlightMenu = sideNavContext.openAtacFlightMenu
  const atacFlightMenuState = sideNavContext.atacFlightMenuState
  const traconFont = sideNavContext.traconFont
  const centerFont = sideNavContext.centerFont
  const surfaceFont = sideNavContext.surfaceFont
  const usaFont = sideNavContext.usaFont
  const highlightAtacMenu = sideNavContext.highlightAtacMenu
  const highlightUSA = sideNavContext.highlightUSA
  const highlightSurface = sideNavContext.highlightSurface
  const highlightCenter = sideNavContext.highlightCenter
  const highlightTracon = sideNavContext.highlightTracon
  const unhighlightAtacMenu = sideNavContext.unhighlightAtacMenu
  const unhighlightUSA = sideNavContext.unhighlightUSA
  const unhighlightSurface = sideNavContext.unhighlightSurface
  const unhighlightCenter = sideNavContext.unhighlightCenter
  const unhighlightTracon = sideNavContext.unhighlightTracon
  const hoverHighlightUSA = sideNavContext.hoverHighlightUSA
  const hoverHighlightSurface = sideNavContext.hoverHighlightSurface
  const hoverHighlightCenter = sideNavContext.hoverHighlightCenter
  const hoverHighlightTracon = sideNavContext.hoverHighlightTracon
  const atacMenuFont = sideNavContext.atacMenuFont
  const fontStyle = {variant: atacMenuFont, color: 'primary'};
  const fontStyleSurface = {variant: surfaceFont, color: 'primary', };
  const fontStyleCenter = {variant: centerFont, color: 'primary', };
  const fontStyleTracon = {variant: traconFont, color: 'primary', };
  const fontStyleUSA = {variant: usaFont, color: 'primary', };
  let atacClickMethod
  let usaHover
  let surfaceHover
  let traconHover
  let centerHover

  if( (usaFont === 'overline' ||
      surfaceFont === 'overline'||
      centerFont === 'overline'||
      traconFont === 'overline') &&
      atacMenuFont === 'body1' ) {
        atacClickMethod = async function () {
        await Promise.all([highlightAtacMenu(), toggleAtacFlightMenu()]);
        console.log('fontStyle: ',fontStyle)
      }
  } else if ((usaFont === 'overline' ||
              surfaceFont === 'overline'||
              centerFont === 'overline'||
              traconFont === 'overline') &&
              atacMenuFont === 'body2' ) {
                atacClickMethod = async function () {
                await Promise.all([unhighlightAtacMenu(), toggleAtacFlightMenu()]);
                console.log('fontStyle: ',fontStyle)}
  } else {
    atacClickMethod = toggleAtacFlightMenu
  }

  console.log("ATAC Menu state", atacFlightMenuState)
  React.useEffect(() => {
    if(window.location.pathname.includes('DownloadHome'))
      (async () => await Promise.all[openAtacFlightMenu()])();
    if(window.location.pathname.includes('USA'))
      (async () => await Promise.all[highlightUSA(), openAtacFlightMenu()])();
    if(window.location.pathname.includes('Surf'))
      (async () => await Promise.all[highlightSurface(), openAtacFlightMenu()])();
    if(window.location.pathname.includes('Tracon'))
      (async () => await Promise.all[highlightTracon(), openAtacFlightMenu()])();
    if(window.location.pathname.includes('Center'))
      (async () => await Promise.all[highlightCenter(), openAtacFlightMenu()])();
  }, []);


  if(window.location.pathname.includes('DownloadUSA')) {
    usaHover = hoverHighlightUSA
  } else {
    usaHover = unhighlightUSA
  }

  if(window.location.pathname.includes('DownloadATACSurf')) {
    surfaceHover = hoverHighlightSurface
  } else {
    surfaceHover = unhighlightSurface
  }

  if(window.location.pathname.includes('DownloadATACTracon')) {
    traconHover = hoverHighlightTracon
  } else {
    traconHover = unhighlightTracon
  }

  if(window.location.pathname.includes('DownloadATACCenter')) {
    centerHover = hoverHighlightCenter
  } else {
    centerHover = unhighlightCenter
  }
  
  return (
    <React.Fragment>
      <div className={classes.underline}>
        <ListItem button 
                  disableRipple
                  onMouseOver={highlightAtacMenu}
                  onMouseLeave={unhighlightAtacMenu}
                  onClick={atacClickMethod} 
                  disableGutters 
                  className={classes.removePadding}>
          <ListItemText primary='Analysis Ready Flight Data'
                        primaryTypographyProps={fontStyle}/>
          {atacFlightMenuState ? <ExpandLess color='primary'/> : <ExpandMore color='primary'/>}
        </ListItem>
      </div>
      <Collapse in={atacFlightMenuState} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to='/DownloadUSA' 
                    onClick={highlightUSA} 
                    className={classes.removeUnderline}>
            <ListItem button
                      disableRipple
                      onMouseOver={hoverHighlightUSA}
                      onMouseDown={highlightUSA}
                      onMouseLeave={usaHover}
                      className={classes.nested1} >
              <ListItemText primary="Integrated Facilities" 
                            secondary="(USA)" 
                            primaryTypographyProps={fontStyleUSA} 
                            secondaryTypographyProps={fontStyleUSA}/>
            </ListItem>
           </NavLink>          
            <NavLink to='/DownloadATACSurf' 
                      onClick={highlightSurface} 
                      className={classes.removeUnderline}>
              <ListItem button
                        disableRipple
                        onMouseOver={hoverHighlightSurface}
                        onMouseDown ={highlightSurface}
                        onMouseLeave={surfaceHover}
                        className={classes.nested2}>
                <ListItemText primary="Individual Facilities"
                              secondary="(Surface)"                              
                              primaryTypographyProps={fontStyleSurface} 
                              secondaryTypographyProps={fontStyleSurface}/>
              </ListItem>
            </NavLink>          
            <NavLink to='/DownloadATACTracon' onClick={highlightTracon} className={classes.removeUnderline}>
              <ListItem button
                        disableRipple
                        onMouseOver={hoverHighlightTracon}
                        onMouseDown ={highlightTracon}
                        onMouseLeave={traconHover}
                        className={classes.nested2}>
                <ListItemText primary="Individual Facilities" 
                              secondary="(Tracons)"
                              primaryTypographyProps={fontStyleTracon} 
                              secondaryTypographyProps={fontStyleTracon}/>
              </ListItem>
            </NavLink>
            <NavLink to='/DownloadATACCenter' 
                      onClick={highlightCenter} 
                      className={classes.removeUnderline}>
            <ListItem button 
                      disableRipple
                      onMouseOver={hoverHighlightCenter}
                      onMouseDown ={highlightCenter}
                      onMouseLeave={centerHover}
                      className={classes.nested2}>
              <ListItemText primary="Individual Facilities" 
                            secondary=" (Centers)" 
                            primaryTypographyProps={fontStyleCenter} 
                            secondaryTypographyProps={fontStyleCenter}/>
              </ListItem>
            </NavLink>
        </List>
      </Collapse>
    </React.Fragment>
  )
}

export default LeftNavDownloadATACFlight;