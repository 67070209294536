import {userTypes} from "./facilityData";

const userTypeData = userTypes.map((name, id) => {return (
    name === "All" ?
        {
            id: id,
            name: name,
            checked: true,
            value: "0"
        }
        :
        {
            id: id,
            name: name,
            checked: false,
            value: name
        }
)})

export default userTypeData