import React from 'react'

export const handleUniqueValues = (files, key) => {
    return key === "timestamp" ?
        [...new Set(files.map(item => item[key].split(' ')[0]))].sort()
        :
        [...new Set(files.map(item => item[key]))]
}

export const handleFilterFiles = (files, action) => {
    return files.filter(item => item['action'] === action.toUpperCase())
}

export const handleFilterProfileFiles = (files, userId) => {
    return files.filter(item => item['userId'] === userId)
}

export const handleFilterTimeFiles = (files, time) => {
    return files.filter(item => item['timestamp'].split(' ')[0] === time)
}

export const handleTotalProducts = (files, action, key, setTotalProducts) => {
    let filteredFiles = handleFilterFiles(files, action)
    setTotalProducts(handleUniqueValues(filteredFiles, "product").length)
}

export const handleAverageTime = (files, setAverageTime) => {
    files.length > 0 ?
        setAverageTime((files.reduce((k, v) => k + v.actionDuration, 0) / files.length).toFixed(0))
        :
        setAverageTime(0)
}

export const handleMaximumTime = (files, setMaximumTime) => {
    files.length > 0 ?
        setMaximumTime(files.reduce((k, v) => { return (k.actionDuration > v.actionDuration) ? k : v }).actionDuration)
        :
        setMaximumTime(0)
}

export const handleValueSum = (files, key) => {
    return key === "timestamp" ?
        files.reduce((k,v) => {k[v[key].split(' ')[0]] = (k[v[key].split(' ')[0]] || 0) + 1;
            return k}, {})
        :
        files.reduce((k,v) => {k[v[key]] = (k[v[key]] || 0) + 1;
            return k}, {})
}

export const handleCountSum = (files, key) => {
    return key === "timestamp" ?
        files.reduce((k,v) => {k[v[key].split(' ')[0]] = (k[v[key].split(' ')[0]] || 0) + v.count;
            return k}, {})
        :
        files.reduce((k,v) => {k[v[key]] = (k[v[key]] || 0) + v.count;
            return k}, {})
}

const handleUsername = (files, userId) => {
    let user = files.find(item => item.userId === userId)
    return user["firstName"] === null ? user["userId"] : [user["firstName"], user["lastName"], "("+user["userId"]+")"].join(" ")
}

export const handleProfileDetails = (files, setFiles) => {
    let users = handleUniqueValues(files, "userId")
    let usersReduced = handleCountSum(files, "userId")
    const data = users.map(userId => { return {userId: handleUsername(files, userId), sum: usersReduced[userId]}})
    setFiles(data)
}

export const handleDetails = (files, action, key, setFiles) => {
    let filteredFiles = handleFilterFiles(files, action)
    let uniqueValues = handleUniqueValues(filteredFiles, key)
    let sumReduced = handleValueSum(filteredFiles, key)
    const data = uniqueValues.map(value => { return {[key]: value, sum: sumReduced[value]}})
    setFiles(data)
}

export const handleTraffic = (files, setFiles) => {
    let dates = handleUniqueValues(files, "timestamp")
    let sumReduced = handleCountSum(files, "timestamp")
    const data = dates.map(date => { return {date: date, sum: sumReduced[date]}})
    setFiles(data)
}

const handleValue = (files, time, key, uniqueValues) => {
    let responseData = handleFilterTimeFiles(files, time)
    let sumReduced = handleValueSum(responseData, key)
    let valueSearch = {date: time}
    uniqueValues.forEach(value => { valueSearch[value] = sumReduced[value]})
    return valueSearch
}

export const handleProductAction = (files, action, key, setColumns, setFiles) => {
    let filteredFiles = handleFilterFiles(files, action)
    let dates = handleUniqueValues(filteredFiles, "timestamp")
    let uniqueValues = handleUniqueValues(filteredFiles, key)
    setColumns(["date", ...uniqueValues])
    const data = dates.map(date => { return handleValue(filteredFiles, date, key, uniqueValues)})
    setFiles(data)
}

export const handleActivity = (files, setFiles) => {
    let interestData = handleFilterFiles(files, "search")
    let securedData = handleFilterFiles(files, "download")
    let dates = handleUniqueValues([...interestData, ...securedData], "timestamp")
    let interestSumReduced = handleValueSum(interestData, "timestamp")
    let securedSumReduced = handleValueSum(securedData, "timestamp")
    const data = dates.map(date => { return {date: date, search: interestSumReduced[date], download: securedSumReduced[date]}})
    setFiles(data)
}