import React from 'react'
import UserContext from "./userContext";
import axios from 'axios';
import {auditUrl, fileDowloadUrl, hostname} from '../../components/Data/api'

class UserState extends React.Component {

    constructor(props) {
        super(props)
        const updateUser = () => {
            const auidUrl = hostname + fileDowloadUrl + "api/user/details"
            const authorizationUrl = hostname + auditUrl + "user/role?userid="
            this.setState({loading:true})
            axios.get(auidUrl)
                .then(responseAuid => {
                    axios.get(authorizationUrl+responseAuid.data.AUID)
                        .then(responseAuth => {
                            const roles = responseAuth.data.map(item =>{ return item["role"]})

                            const user = roles.length ?
                                {
                                    auid:responseAuid.data.AUID,
                                    name:responseAuid.data.FIRSTNAME + " " + responseAuid.data.LASTNAME,
                                    email:responseAuid.data.EMAIL,
                                    roles: roles
                                }
                                :
                                {
                                    auid:responseAuid.data.AUID,
                                    name:responseAuid.data.FIRSTNAME + " " + responseAuid.data.LASTNAME,
                                    email:responseAuid.data.EMAIL,
                                    roles: ["guest"]
                                }
                            this.setState({user:user})
                            this.setState({loading:false})
                        })
                        .catch(err => {
                            const user =
                                {
                                    auid: responseAuid.data.AUID,
                                    name: responseAuid.data.FIRSTNAME + " " + responseAuid.data.LASTNAME,
                                    email: responseAuid.data.EMAIL,
                                    roles: ["guest"]
                                }
                            this.setState({user:user})
                            this.setState({loading:false})
                        })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({loading:false})
                })
        }
        
        this.state = {
            user: {
                auid:undefined,
                name: undefined,
                email:undefined,
                roles: ["guest"]
            },
            loading: false,
            updateUser: updateUser,
        }
    }

    componentDidMount() {
        this.state.updateUser();
    }

    render() {
        return <UserContext.Provider
            value = {{
                user:this.state.user,
                loading:this.state.loading,
                updateUser:this.updateUser,
            }}>
            {this.props.children}
        </UserContext.Provider>
    }
}

export default UserState