import React from 'react'
import Grid from "@material-ui/core/Grid";
import GuestProductInterestDetails from "./GuestProductInterestDetails";
import GuestProductInterest from "./GuestProductInterest";

const GuestProductInterestDetailsPanel = () => {
    return (
        <Grid container>
            <Grid item sm>
                <GuestProductInterestDetails/>
            </Grid>
            <Grid item md>
                <GuestProductInterest/>
            </Grid>
        </Grid>
    )
}

export default GuestProductInterestDetailsPanel