import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    horizontalLine: {
        marginTop: 25,
        marginBottom: 10,
        backgroundColor: theme.palette.primary.main,
        padding: 1
    }
}));

const HorizontalLine = () => {
    const classes = useStyles();

    return (
        <Divider className={classes.horizontalLine}/>
    )
}

export default HorizontalLine