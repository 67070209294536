import {centerFacilities} from './facilityData'


const centerData = centerFacilities.map((name, id) => {return (
    {
        id: id,
        name: name,
        checked: false,
        value: name.toLowerCase() + ","
    }
)})

export default centerData