import React, {useContext} from 'react'
import WeatherContext from "../../../Contexts/Weather/weatherContext";
import StartDateContext from "../../../Contexts/StartDate/startDateContext";
import EndDateContext from "../../../Contexts/EndDate/endDateContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import LoadDialog from "../../Modals/LoadDialog";
import Grid from "@material-ui/core/Grid";
import {handleDownload} from "../../Utils/FileDownloadUtils";
import FDTable from "../../Tables/FDTable";

const WeatherTable = () => {
    const weatherContext = useContext(WeatherContext)
    const {clicked, tableLoading, files, progress, cancelTokenSource} = weatherContext
    const setProgress = weatherContext.setProgress
    const [loading, setLoading] = React.useState(false)
    const [cancel, setCancel] = React.useState(false)

    const startDateContext = useContext(StartDateContext)
    const {startDate} = startDateContext
    const endDateContext = useContext(EndDateContext)
    const {endDate} = endDateContext

    const handleFilename = (rows) => {
        let dataSources = [...new Set(rows.map(index => {return (files[index]["dataSource"])}))]
        dataSources = dataSources.toString().replace(/,/g,'_')

        return dataSources + "_" + startDate + "_" + endDate + ".zip"
    }

    const handleClick = async (rowsSelected) => {
        handleDownload(rowsSelected, files, handleFilename(rowsSelected), cancelTokenSource, setLoading, setProgress);
    }

    const handleCancel = () => {
        console.log("Cancel file download")
        cancelTokenSource.cancel("Cancelled Weather Download")
        setCancel(true)
        setLoading(false)
    }

    const table = tableLoading ? <LinearProgress/> : <FDTable files={files} handleClick={handleClick}/>

    return (
        <Grid>
            {clicked && table}
            {loading && <LoadDialog load={loading} progress={progress} handleClose={handleCancel}/>}
        </Grid>
    )
}

export default WeatherTable