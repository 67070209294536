import React, {useContext} from 'react'
import AuditUserDetailsContext from "../../../Contexts/AuditUserDetails/auditUserDetailsContext";
import AuditUserSummaryContext from "../../../Contexts/AuditUserSummary/auditUserSummaryContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TotalUsers from "./TotalUsers";
import {handleProfileDetails} from "../../Utils/AuditUtils";
import PieChartInteractive from "../../Charts/PieChartInteractive";
import ProfileContext from "../../../Contexts/Profile/profileContext";

const UserDetails = () => {
    const auditUserDetailsContext = useContext(AuditUserDetailsContext)
    const {chartLoading, axes, chartFiles} = auditUserDetailsContext
    const {chartTitle,chartHAxisTitle,chartVAxisTitle} = auditUserDetailsContext
    const setUserDetailsChartLoading = auditUserDetailsContext.setChartLoading
    const setUserDetailsChartFiles = auditUserDetailsContext.setChartFiles

    const auditUserSummaryContext = useContext(AuditUserSummaryContext)
    const {files} = auditUserSummaryContext

    const profileContext = useContext(ProfileContext)
    const setProfile = profileContext.setProfile

    const handleUserDetails = () => {
        handleProfileDetails(files, setUserDetailsChartFiles)
    }

    React.useEffect(() => {
        setUserDetailsChartLoading(true)
        handleUserDetails()
    }, [files])

    React.useEffect(() => {
        setUserDetailsChartLoading(false)
    }, [chartFiles])

    return (
        <Paper square elevation={0}>
            {chartLoading ? <LinearProgress/> :
                <Grid container direction="column">
                    <Grid item>
                        <TotalUsers/>
                    </Grid>
                    <Grid item>
                        <PieChartInteractive chartFiles={chartFiles} columns={axes} title={chartTitle} haxis={chartHAxisTitle} vaxis={chartVAxisTitle} handleChartClick={setProfile}/>
                    </Grid>
                </Grid>
            }
        </Paper>
    )
}

export default UserDetails