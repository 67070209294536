import {surfaceDataFacilities} from "./facilityData";

const airportReportData = surfaceDataFacilities.map((name, id) => {return (
    {
        id: id,
        name: name,
        checked: false,
        value: (name + ",").replace('+','%2B')
    }
)})

export default airportReportData