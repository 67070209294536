import React, {useReducer} from 'react'
import FuserReducer from "./fuserReducer";
import FuserContext from "./fuserContext";
import {
    SET_CANCEL_TOKEN_SOURCE,
    SET_CLICKED,
    SET_DATA_PRODUCTS,
    SET_FILES,
    SET_PROGRESS,
    SET_TABLE_LOADING
} from "../types";
import axios from 'axios';

const FuserState = props => {
    const initialState = {
        dataProducts: {
            matmFlight: {
                name: "matmFlight",
                label: "MATM Flight",
                checked: false,
                value: "MATM_FLIGHT"
            },
            matmFlightAll: {
                name: "matmFlightAll",
                label: "MATM Flight All",
                checked: false,
                value: "MATM_FLIGHT_ALL"
            },
            matmFlightSummary: {
                name: "matmFlightSummary",
                label: "MATM Flight Summary",
                checked: false,
                value: "MATM_FLIGHT_SUMMARY"
            },
            matmPositionAll: {
                name: "matmPositionAll",
                label: "MATM Position All",
                checked: false,
                value: "MATM_POSITION_ALL"
            }
        },
        clicked: false,
        tableLoading: false,
        files: [],
        cancelTokenSource: axios.CancelToken.source()
    }

    const [state, dispatch] = useReducer(FuserReducer, initialState);

    const setDataProducts = (dataProducts) => {
        dispatch({
            type: SET_DATA_PRODUCTS,
            dataProducts: dataProducts
        })
    }

    const setClicked = () => {
        dispatch({
            type: SET_CLICKED
        })
    }

    const setTableLoading = (tableLoading) => {
        dispatch({
            type: SET_TABLE_LOADING,
            tableLoading: tableLoading
        })
    }

    const setFiles = (files) => {
        dispatch({
            type: SET_FILES,
            files: files
        })
    }

    const setProgress = (progress) => {
        dispatch({
            type: SET_PROGRESS,
            progress: progress
        })
    }

    const resetProgress = () => {
        dispatch({
            type: SET_PROGRESS,
            progress: 0
        })
    }

    const resetCancelToken = () => {
        dispatch({
            type: SET_CANCEL_TOKEN_SOURCE,
            cancelTokenSource: axios.CancelToken.source()
        })
    }

    return <FuserContext.Provider
        value = {{
            dataProducts: state.dataProducts,
            clicked: state.clicked,
            tableLoading: state.tableLoading,
            files: state.files,
            progress: state.progress,
            cancelTokenSource: state.cancelTokenSource,
            setDataProducts,
            setClicked,
            setTableLoading,
            setFiles,
            setProgress,
            resetProgress,
            resetCancelToken
        }}>
        {props.children}
    </FuserContext.Provider>
}

export default FuserState