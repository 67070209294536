import React, {useContext} from 'react'
import FuserContext from "../../../Contexts/Fuser/fuserContext";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import DataProductCheckbox from "../../Selections/DataProductCheckbox";
import {handleDataProductCheckboxes} from "../../Utils/PanelUtils";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    panel: {
        backgroundColor: theme.palette.common.lighterGray
    }
}))

const FuserPanel = () => {
    const classes = useStyles()
    const fuserContext = useContext(FuserContext)
    const {dataProducts} = fuserContext
    const setDataProducts = fuserContext.setDataProducts

    const handleChange = (event) => {
        const {name, checked} = event.target
        handleDataProductCheckboxes(name, checked, dataProducts, setDataProducts)
    }

    return (
        <Grid justify="flex-start" direction="column">
            <Paper className={classes.panel} style={{marginBottom:20,padding:15}}>
                <DataProductCheckbox name={dataProducts.matmFlight.name} label={dataProducts.matmFlight.label} checked={dataProducts.matmFlight.checked} handleChange={handleChange}/>
                <DataProductCheckbox name={dataProducts.matmFlightAll.name} label={dataProducts.matmFlightAll.label} checked={dataProducts.matmFlightAll.checked} handleChange={handleChange}/>
                <DataProductCheckbox name={dataProducts.matmFlightSummary.name} label={dataProducts.matmFlightSummary.label} checked={dataProducts.matmFlightSummary.checked} handleChange={handleChange}/>
                <DataProductCheckbox name={dataProducts.matmPositionAll.name} label={dataProducts.matmPositionAll.label} checked={dataProducts.matmPositionAll.checked} handleChange={handleChange}/>
            </Paper>
        </Grid>
    )
}

export default FuserPanel