import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import generalTheme from './Themes/GeneralTheme'
import Header from './components/Header'
import TopNav from './components/NavBars/TopNav'
import LeftNav from './components/NavBars/LeftNav'
import Home from './components/PagesHomepage/Home'
import HomeState from './Contexts/Home/HomeState'
import SideNavState from './Contexts/SideNav/SideNavState'
import TopNavState from './Contexts/TopNav/TopNavState'
import About from './components/PagesHeader/About'
import DownloadHome from './components/PagesDownload/DownloadHome'
import DownloadATACIntegrated from './components/PagesDownload/ATACUSA/DownloadATACUSA'
import DownloadATACFac from './components/PagesDownload/ATACFacilities/DownloadATACFac'
import DownloadATACSurf from './components/PagesDownload/ATACFacilities/DownloadATACSurf'
import DownloadATACCenter from './components/PagesDownload/ATACFacilities/DownloadATACCenter'
import DownloadATACTracon from './components/PagesDownload/ATACFacilities/DownloadATACTracon'
import Footer from './components/Footer'
import StartDateState from "./Contexts/StartDate/StartDateState";
import EndDateState from "./Contexts/EndDate/EndDateState";
import ATACUSAState from "./Contexts/ATACUSA/ATACUSAState";
import ATACSurfaceState from "./Contexts/ATACSurface/ATACSurfaceState";
import ATACTraconState from "./Contexts/ATACTracon/ATACTraconState";
import ATACCenterState from "./Contexts/ATACCenter/ATACCenterState";
import Contact from "./components/PagesHeader/Contact";
import SemiPieChartState from "./Contexts/SemiPieChart/SemiPieChartState";
import DownloadFacRep from "./components/PagesDownload/ATACFacilityReports/DownloadFacRep";
import ATACFacilityReportsState from "./Contexts/ATACFacilityReports/ATACFacilityReportsState";
import TraconReportState from "./Contexts/TraconReport/TraconReportState";
import CenterReportState from "./Contexts/CenterReport/CenterReportState";
import AirportReportState from "./Contexts/AirportReport/AirportReportState";
import HomeContentState from "./Contexts/HomeContent/HomeContentState";
import {basename} from "./components/Data/api";
import UserState from "./Contexts/User/UserState";
import AuditState from "./Contexts/Audit/AuditState";
import AuditUserSummaryState from "./Contexts/AuditUserSummary/AuditUserSummaryState";
import AuditUserTrafficState from "./Contexts/AuditUserTraffic/AuditUserTrafficState";
import AuditUserDetailsState from "./Contexts/AuditUserDetails/AuditUserDetailsState";
import Audit from "./components/PagesAudit/Audit";
import AuditUserActivitySummaryState from "./Contexts/AuditUserActivitySummary/AuditUserActivitySummaryState";
import AuditUserActivityState from "./Contexts/AuditUserActivity/AuditUserActivityState";
import AuditProductInterestDetailsState from "./Contexts/AuditProductInterestDetails/AuditProductInterestDetailsState";
import AuditProductInterestState from "./Contexts/AuditProductInterest/AuditProductInterestState";
import AuditProductSecuredDetailsState from "./Contexts/AuditProductSecuredDetails/AuditProductSecuredDetailsState";
import AuditProductSecuredState from "./Contexts/AuditProductSecured/AuditProductSecuredState";
import ProfileState from "./Contexts/Profile/ProfileState";
import ProfileGuestActivitySummaryState from "./Contexts/ProfileGuestActivitySummary/ProfileGuestActivitySummaryState";
import ProfileGuestActivityState from "./Contexts/ProfileGuestActivity/ProfileGuestActivityState";
import ProfileGuestProductInterestDetailsState
    from "./Contexts/ProfileGuestProductInterestDetails/ProfileGuestProductInterestDetailsState";
import ProfileGuestProductInterestState from "./Contexts/ProfileGuestProductInterest/ProfileGuestProductInterestState";
import ProfileGuestProductSecuredDetailsState
    from "./Contexts/ProfileGuestProductSecuredDetails/ProfileGuestProductSecuredDetailsState";
import ProfileGuestProductSecuredState from "./Contexts/ProfileGuestProductSecured/ProfileGuestProductSecuredState";
import Profile from "./components/PagesProfile/Profile";
import DownloadWeather from "./components/PagesDownload/Weather/DownloadWeather";
import WeatherState from "./Contexts/Weather/WeatherState";
import DownloadFuser from "./components/PagesDownload/Fuser/DownloadFuser";
import FuserState from "./Contexts/Fuser/FuserState";
import SupportState from "./Contexts/Support/SupportState";
import FeedbackState from "./Contexts/Feedback/FeedbackState";
import SupportFeedback from "./components/PagesSupport/Feedback/SupportFeedback";
import ProductAvailabilityState from "./Contexts/ProductAvailability/ProductAvailabilityState";

function App() {
    // const basename = "/sherlock_open"
    // const url = ""

  return (
    <HomeState>
    <HomeContentState>
    <SemiPieChartState>
    <SideNavState>
    <TopNavState>
    <StartDateState>
    <EndDateState>
    <UserState>
    <AuditState>
    <AuditUserSummaryState>
    <AuditUserDetailsState>
    <AuditUserTrafficState>
    <AuditUserActivitySummaryState>
    <AuditUserActivityState>
    <AuditProductInterestDetailsState>
    <AuditProductInterestState>
    <AuditProductSecuredDetailsState>
    <AuditProductSecuredState>
    <ProfileState>
    <ProfileGuestActivitySummaryState>
    <ProfileGuestActivityState>
    <ProfileGuestProductInterestDetailsState>
    <ProfileGuestProductInterestState>
    <ProfileGuestProductSecuredDetailsState>
    <ProfileGuestProductSecuredState>
    <SupportState>
    <FeedbackState>
    <ProductAvailabilityState>
    <WeatherState>
    <ATACUSAState>
    <ATACSurfaceState>
    <ATACTraconState>
    <ATACCenterState>
    <ATACFacilityReportsState>
    <TraconReportState>
    <CenterReportState>
    <AirportReportState>
    <FuserState>
      <Router basename={basename}>
        <ThemeProvider theme={generalTheme}>   
          {/*<Header/>*/}
          {/*<TopNav/>*/}
          {/*<LeftNav/>*/}
          <Switch>
            {/*<Route exact path='/' component={Home} />*/}
            <Route exact path='/' render={props =>
                <React.Fragment>
                  <Header/>
                  {/*<TopNav/>*/}
                  <LeftNav/>
                  <Home/>
                </React.Fragment>
            }/>
              <Route exact path='/sherlock_open' render={props =>
                  <React.Fragment>
                      <Header/>
                      {/*<TopNav/>*/}
                      <LeftNav/>
                      <Home/>
                  </React.Fragment>
              }/>
              <Route exact path='/Home' render={props =>
                  <React.Fragment>
                      <Header/>
                      {/*<TopNav/>*/}
                      <LeftNav/>
                      <Home/>
                  </React.Fragment>
              }/>
              <Route exact path={'/About'} render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <About/>
                  </React.Fragment>
              }/>
              <Route exact path='/Contact' render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <Contact/>
                  </React.Fragment>
              }/>
              <Route exact path='/Audit' render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <Audit/>
                  </React.Fragment>
              }/>
              <Route exact path='/Profile' render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <Profile/>
                  </React.Fragment>
              }/>
              <Route exact path='/Feedback' render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <SupportFeedback/>
                  </React.Fragment>
              }/>
              <Route exact path='/DownloadHome' render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <LeftNav/>
                      <DownloadHome/>
                  </React.Fragment>
              }/>
              <Route exact path='/DownloadWeather' render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <LeftNav/>
                      <DownloadWeather/>
                  </React.Fragment>
              } />
            <Route exact path='/DownloadUSA' render={props =>
                <React.Fragment>
                  <Header/>
                  <TopNav/>
                  <LeftNav/>
                  <DownloadATACIntegrated/>
                </React.Fragment>
            } />
              <Route exact path='/DownloadFac' render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <LeftNav/>
                      <DownloadATACFac/>
                  </React.Fragment>
              } />
              <Route exact path='/DownloadATACSurf' render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <LeftNav/>
                      <DownloadATACSurf/>
                  </React.Fragment>
              } />
              <Route exact path='/DownloadATACCenter' render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <LeftNav/>
                      <DownloadATACCenter/>
                  </React.Fragment>
              } />
              <Route exact path='/DownloadATACTracon' render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <LeftNav/>
                      <DownloadATACTracon/>
                  </React.Fragment>
              } />
              <Route exact path='/DownloadFacRep' render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <LeftNav/>
                      <DownloadFacRep/>
                  </React.Fragment>
              } />
              <Route exact path='/DownloadFuser' render={props =>
                  <React.Fragment>
                      <Header/>
                      <TopNav/>
                      <LeftNav/>
                      <DownloadFuser/>
                  </React.Fragment>
              } />
          </Switch>
            <Footer/>
        </ThemeProvider>
      </Router>
    </FuserState>
    </AirportReportState>
    </CenterReportState>
    </TraconReportState>
    </ATACFacilityReportsState>
    </ATACCenterState>
    </ATACTraconState>
    </ATACSurfaceState>
    </ATACUSAState>
    </WeatherState>
    </ProductAvailabilityState>
    </FeedbackState>
    </SupportState>
    </ProfileGuestProductSecuredState>
    </ProfileGuestProductSecuredDetailsState>
    </ProfileGuestProductInterestState>
    </ProfileGuestProductInterestDetailsState>
    </ProfileGuestActivityState>
    </ProfileGuestActivitySummaryState>
    </ProfileState>
    </AuditProductSecuredState>
    </AuditProductSecuredDetailsState>
    </AuditProductInterestState>
    </AuditProductInterestDetailsState>
    </AuditUserActivityState>
    </AuditUserActivitySummaryState>
    </AuditUserTrafficState>
    </AuditUserDetailsState>
    </AuditUserSummaryState>
    </AuditState>
    </UserState>
    </EndDateState>
    </StartDateState>
    </TopNavState>
    </SideNavState>
    </SemiPieChartState>
    </HomeContentState>
    </HomeState>
  );
}

export default App;