import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import axios from "axios";
import TitleSubtitle from "../../Titles/TitleSubtitle";
import SearchButton from "../../Buttons/SearchButton";
import StartDateContext from "../../../Contexts/StartDate/startDateContext";
import EndDateContext from "../../../Contexts/EndDate/endDateContext";
import LoadDialog from "../../Modals/LoadDialog";
import ATACUSAContext from "../../../Contexts/ATACUSA/atacUSAContext";
import Banner from "../../../images/banner17.PNG";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DataProductCheckbox from "../../Selections/DataProductCheckbox";
import FDFacilityTable from "../../Tables/FDFacilityTable";
import {fileDowloadUrl, hostname} from "../../Data/api";
import Link from "@material-ui/core/Link";

const drawerWidth = 245;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(5),
    marginBottom: `${drawerWidth}px`,
    [theme.breakpoints.up('md')]: {
      marginLeft: `${drawerWidth}px`,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(1),
      // marginLeft: theme.spacing(1),
      paddingTop: theme.spacing(10),
      width: '100%',
    },
  },
  image: {
    width: '100%',
    marginTop: -65,
    paddingBottom: 30,
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 50,
      height: 60,
    }
  },
  grid: {
    paddingLeft: 30
  },
  panel: {
    backgroundColor: theme.palette.common.lighterGray
  },
  toolbar: theme.mixins.toolbar,
}));

function DownloadATACUSA() {
  const classes = useStyles();

  const title = "Integrated Facilities USA Flight Data"
  const subtitle = "NAS-Wide, Analysis-Ready, Merged Track/ Flight Plan, Event, and Metadata"

  const [selectedFiles, setSelectedFiles] = React.useState([])

  const atacUSAContext = useContext(ATACUSAContext)
  const {dataProducts, clicked, tableLoading, files, progress, cancelTokenSource} = atacUSAContext
  const setDataProducts = atacUSAContext.setDataProducts
  const setClicked = atacUSAContext.setClicked
  const setTableLoading = atacUSAContext.setTableLoading
  const setFiles = atacUSAContext.setFiles
  const setProgress = atacUSAContext.setProgress
  const resetCancelToken = atacUSAContext.resetCancelToken

  const [loading, setLoading] = React.useState(false)
  const [cancel, setCancel] = React.useState(false)

  const startDateContext = useContext(StartDateContext)
  const {startDate, startTime} = startDateContext
  const endDateContext = useContext(EndDateContext)
  const {endDate, endTime} = endDateContext
  // console.log("ATAC USA ", startDate, startTime, endDate, endTime)

  const activateSearch = dataProducts.trackPoints.checked || dataProducts.flightEvents.checked || dataProducts.flightSummary.checked

  const handleChange = (event) => {
    const {name, checked} = event.target
    let updatedDataProducts2
    let dataProduct = Object.assign({}, dataProducts[name], {checked: checked})
    updatedDataProducts2 = Object.assign(
        {}, dataProducts, {[name]: dataProduct})
    setDataProducts(updatedDataProducts2)
  }

  const handleUserSelection = () => {
    setClicked(true)
    setTableLoading(true)
    const start = startDate.toString().replace(/\//g,'')
    const end = endDate.toString().replace(/\//g,'')
    let query = ""

    Object.entries(dataProducts).forEach(([key, value]) => {
      value.checked && (query += value.value)
    })

    // console.log("Query", query)

    // "http://localhost:8080/filedownload-0.0.1-SNAPSHOT/search/file/20190202/20190203?dataProducts=RD,EV”
    axios.get(hostname + fileDowloadUrl + "search/file/"+start+"/"+end+"?dataProducts="+query)
        .then(response => {
          // console.log("Real API ", response.data.data)
          setFiles(response.data.data)
          setTableLoading(false)
        })
        .catch(err => {
          console.log(err)
          setTableLoading(false)
        })
  }

  const handleFilename = (rows) => {
    let dataSources = [...new Set(rows.map(index => {return (files[index]["dataSource"])}))]
    dataSources = dataSources.toString().replace(/,/g,'_')
    // console.log("Data Sources", dataSources)

    return dataSources + "_" + startDate + "_" + endDate + ".zip"
  }

  const handleClick = async (rowsSelected) => {
    const updatedSelectedFiles = rowsSelected.map(index => {return (files[index]["path"])})
    setSelectedFiles(updatedSelectedFiles)

    let queryFile = ""
    updatedSelectedFiles.map(file => queryFile += file + ",")

    const total = rowsSelected.map(index => {
      return files[index]["size"]
    })

    const querySize = total.reduce((result,number)=> result+number)

    setLoading(true)
    axios.get(hostname + fileDowloadUrl + "download/file?path="+queryFile,
        {
          responseType: "blob",
          onDownloadProgress: progressEvent => {
            let progress = Math.round((progressEvent.loaded * 100)/querySize)
            setProgress(progress)
          },
          cancelToken: cancelTokenSource.token
        })
        .then(response => {
          setLoading(false)
          const file = new Blob([response.data], {type:"file/zip"})
          const link = document.createElement("a")
          link.href = URL.createObjectURL(file)
          link.download = handleFilename(rowsSelected)
          document.body.appendChild(link)
          link.click()
        })
        .catch(err => {
          console.log(err)
        })
  }

  const table = tableLoading ? <LinearProgress/> : <FDFacilityTable files={files} handleClick={handleClick}/>

  const handleCancel = () => {
    console.log("Cancel file download")
    cancelTokenSource.cancel("Cancelled ATAC USA Download")
    resetCancelToken()
    setCancel(true)
    setLoading(false)
  }

  // React.useEffect(() => {
  //   console.log("Reload")
  //   cancel && window.location.reload()
  // }, [cancel])

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <img className={classes.image} src={Banner}/>
        <TitleSubtitle title={title} subtitle={subtitle}/>
        <Grid container justify="flex-start" direction="column" spacing={0} className={classes.grid}>
          <Paper className={classes.panel} style={{marginBottom:20,marginRight:30,paddingTop:15,paddingBottom:15,paddingLeft:15}}>
            <Grid item>
              {/*<Grid container direction="row">*/}
              <DataProductCheckbox name={dataProducts.trackPoints.name} label={dataProducts.trackPoints.label} checked={dataProducts.trackPoints.checked} handleChange={handleChange}/>
              <DataProductCheckbox name={dataProducts.flightEvents.name} label={dataProducts.flightEvents.label} checked={dataProducts.flightEvents.checked} handleChange={handleChange}/>
              <DataProductCheckbox name={dataProducts.flightSummary.name} label={dataProducts.flightSummary.label} checked={dataProducts.flightSummary.checked} handleChange={handleChange}/>
              {/*</Grid>*/}
            </Grid>
          </Paper>
          <Grid container alignItems="center" spacing={4}>
            <Grid item>
              <SearchButton activateSearch={activateSearch} handleUserSelection={handleUserSelection}/>
            </Grid>
            <Grid item>
              <Link href={process.env.PUBLIC_URL+"/sdk/IFFParser.zip"} style={{textDecoration:'none'}} download>Click to download IFF parser/filter</Link>
            </Grid>
          </Grid>
          {clicked && table}
          {loading && <LoadDialog load={loading} progress={progress} handleClose={handleCancel}/>}
        </Grid>
    </main>
  )
}

export default DownloadATACUSA
