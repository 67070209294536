import React, {useContext} from 'react'
import AuditContext from "../../../Contexts/Audit/auditContext";
import AuditUserActivitySummaryContext from "../../../Contexts/AuditUserActivitySummary/auditUserActivitySummaryContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import AuditTable from "../../Tables/AuditTable";
import {auditUserActivitySummaryColumnNames, auditUserActivitySummaryColumns} from "../../Data/cgTableData";

const UserActivitySummaryPanel = () => {
    const auditContext = useContext(AuditContext)
    const {clicked} = auditContext

    const auditUserActivitySummaryContext = useContext(AuditUserActivitySummaryContext)
    const {tableLoading, files} = auditUserActivitySummaryContext

    const table = tableLoading ? <LinearProgress/> : <AuditTable files={files} columns={auditUserActivitySummaryColumns} columnNames={auditUserActivitySummaryColumnNames}/>

    return (
        clicked && table
    )

}

export default UserActivitySummaryPanel