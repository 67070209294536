import React from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Placeholder from "./Placeholder";

am4core.useTheme(am4themes_animated);

const PieChart = (props) => {
    const {chartFiles,columns,title,haxis,vaxis} = props

    const chart = React.useRef(null);

    React.useLayoutEffect(() => {
        let x = am4core.create("piechartdiv", am4charts.PieChart);

        let chartTitle = x.titles.create();
        chartTitle.text = title
        chartTitle.fontSize = 20

        x.marginRight = 400;

        x.data = chartFiles

        let pieSeries = x.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = columns[1];
        pieSeries.dataFields.category = columns[0];

        x.innerRadius = am4core.percent(30);

        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeOpacity = 1;

        pieSeries.slices.template.cursorOverStyle = [
            {
                "property": "cursor",
                "value": "pointer"
            }
        ];

        pieSeries.alignLabels = false;
        pieSeries.labels.template.bent = true;
        pieSeries.labels.template.radius = 3;
        pieSeries.labels.template.padding(0,0,0,0);

        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        x.legend = new am4charts.Legend();
        x.legend.position = "right"
        x.legend.scrollable = true;

        chart.current = x

        return () => {
            x.dispose();
        };
    }, []);

    return (
        chartFiles.length > 0 ? <div id="piechartdiv" style={{width: "100%", height: "450px"}}/> : <Placeholder/>
    )
}

export default PieChart