import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Banner from "../../../images/banner31.jpg";
import Title from "../../Titles/Title";
import Grid from "@material-ui/core/Grid";
import WeatherPanel from "./WeatherPanel";
import WeatherSearch from "./WeatherSearch";
import WeatherTable from "./WeatherTable";

const drawerWidth = 245;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(5),
    marginBottom: `${drawerWidth}px`,
    [theme.breakpoints.up('md')]: {
      marginLeft: `${drawerWidth}px`,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(1),
      paddingTop: theme.spacing(10),
      width: '100%',
    },
  },
  image: {
    width: '100%',
    marginTop: -127,
    paddingBottom: 30,
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 50,
      marginTop: -50
    },
    [theme.breakpoints.between('sm','md')]: {
      marginTop: -90
    }
  },
  margin: {
    marginLeft: 30,
    marginRight: 30
  },
  toolbar: theme.mixins.toolbar,
}));

function DownloadWeather() {
  const classes = useStyles();
  const title = "Weather Data Products"

  return (
    <main className={classes.content}>
        <div className={classes.toolbar} />
        <img className={classes.image} src={Banner}/>
        <Title title={title}/>
        <Grid className={classes.margin}>
          <WeatherPanel/>
          <WeatherSearch/>
          <WeatherTable/>
        </Grid>
    </main>
  )
}

export default DownloadWeather;
