import React from 'react'
import {SET_CURRENT_DATE, SET_DATE_TIME} from "../types";

export default (state, action) => {
    // console.log("End Date Reducer ", state, action.endDate, action.endTime)
    switch(action.type) {
        case SET_DATE_TIME:
            return {
                ...state,
                endDate: action.endDate,
                endTime: action.endTime
            }
        case SET_CURRENT_DATE:
            return {
                ...state,
                currentDate: action.currentDate
            }
        default: return state
    }
}