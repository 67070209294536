import React from 'react'
import {
    SET_AVG_SEARCH_TIME,
    SET_CLICKED,
    SET_FILES,
    SET_GUEST,
    SET_MAX_ACTIVE_TIME,
    SET_OPTIONS,
    SET_PROFILE,
    SET_TAB_VALUE,
    SET_TABLE_LOADING,
    SET_TOTAL_PRODUCT_INTEREST,
    SET_TOTAL_PRODUCT_SECURED,
    SET_TOTAL_VISITS
} from "../types";

export default (state, action) => {
    console.log("Profile Reducer ", state, action.guest)
    switch (action.type) {
        case SET_OPTIONS:
            return {
                ...state,
                profile: action.profile
            }
        case SET_PROFILE:
            return {
                ...state,
                profile: action.profile
            }
        case SET_CLICKED:
            return {
                ...state,
                clicked: true
            }
        case SET_TABLE_LOADING:
            return {
                ...state,
                tableLoading: action.tableLoading
            }
        case SET_FILES:
            return {
                ...state,
                files: action.files
            }
        case SET_GUEST:
            return {
                ...state,
                guest: action.guest
            }
        case SET_TOTAL_VISITS:
            return {
                ...state,
                totalVisits: action.totalVisits
            }
        case SET_TOTAL_PRODUCT_INTEREST:
            return {
                ...state,
                totalProductInterest: action.totalProductInterest
            }
        case SET_TOTAL_PRODUCT_SECURED:
            return {
                ...state,
                totalProductSecured: action.totalProductSecured
            }
        case SET_AVG_SEARCH_TIME:
            return {
                ...state,
                avgSearchTime: action.avgSearchTime
            }
        case SET_MAX_ACTIVE_TIME:
            return {
                ...state,
                maxActiveTime: action.maxActiveTime
            }
        case SET_TAB_VALUE:
            return {
                ...state,
                tabValue: action.tabValue
            }
        default:
            return state

    }
}