import React, {useContext} from 'react'
import ProfileGuestProductInterestContext
    from "../../../Contexts/ProfileGuestProductInterest/profileGuestProductInterestContext";
import ProfileGuestActivitySummaryContext
    from "../../../Contexts/ProfileGuestActivitySummary/profileGuestActivitySummaryContext";
import StackedChart from "../../Charts/StackedChart";
import LinearProgress from "@material-ui/core/LinearProgress";
import {handleProductAction} from "../../Utils/AuditUtils";

const GuestProductInterest = () => {
    const profileGuestProductInterestContext = useContext(ProfileGuestProductInterestContext)
    const {chartLoading, axes, chartFiles} = profileGuestProductInterestContext
    const {chartTitle,chartHAxisTitle,chartVAxisTitle} = profileGuestProductInterestContext
    const setGuestProductInterestChartLoading = profileGuestProductInterestContext.setChartLoading
    const setGuestProductInterestChartFiles = profileGuestProductInterestContext.setChartFiles
    const setGuestProductInterestChartColumns = profileGuestProductInterestContext.setChartColumns

    const profileGuestActivitySummaryContext = useContext(ProfileGuestActivitySummaryContext)
    const {files} = profileGuestActivitySummaryContext

    const handleProductInterest = () => {
        handleProductAction(files, "search", "product", setGuestProductInterestChartColumns, setGuestProductInterestChartFiles)
        // console.log("Handle Guest Product Interest", files)
        // let dates = handleUniqueValues(files, "timestamp")
        // let products = handleUniqueValues(files, "product")
        // console.log(dates, products)
        // setGuestProductInterestChartColumns(["date", ...products])
        //
        // const data = dates.map(date => { return handleProduct(date, products)})
        //
        // setGuestProductInterestChartFiles(data)
    }

    const handleProduct = (time, products) => {
        let responseData = files.filter(item => item['timestamp'].split(' ')[0] === time)
        let sumReduced = responseData.reduce((k,v) => {k[v.product] = (k[v.product] || 0) + 1;
            return k}, {})
        console.log("Handle product reduced", responseData, sumReduced)
        let productSearch = {date: time}
        products.forEach(product => { productSearch[product] = sumReduced[product]})
        console.log("Product search", productSearch)
        return productSearch
    }

    React.useEffect(() => {
        setGuestProductInterestChartLoading(true)
        handleProductInterest()
    }, [files])

    React.useEffect(() => {
        setGuestProductInterestChartLoading(false)
    }, [chartFiles])

    return (
        chartLoading ? <LinearProgress/> : <StackedChart chartFiles={chartFiles} columns={axes} title={chartTitle} haxis={chartHAxisTitle} vaxis={chartVAxisTitle}/>
    )
}

export default GuestProductInterest