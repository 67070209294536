import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    panel: {
        marginLeft: 25,
    },
    tabBar: {
        marginLeft: 25,
        marginTop: 15,
        width: '75%'
    },
    tabs: {
        backgroundColor: '#5b93bf',
        color: '#fff',
        marginRight: 20,
    },
    box: {
        marginRight: '2%',
        [theme.breakpoints.down('md')]: {
            marginRight: '3%',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '0%',
        },
        [theme.breakpoints.down('xs')]:{
            marginRight: '0%',
        }
    },
}))

const Panel = (props) => {
    const classes = useStyles()


    return (
        <div>
            <div className={classes.panel}>
                <Box className={classes.box} borderTop={5} borderColor={props.activateTabs ? '#01579b' : '#7ba8cb'} data-testid="panel">
                    {Object.entries(props.panelists).map(([name, ]) => {return (
                            (props.tabValue === name) && (
                                props.panelists[props.tabValue].value
                            )
                    )})}
                </Box>
            </div>
        </div>
    )
}

export default Panel