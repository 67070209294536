import React from 'react'
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.common.lightGray,
        height: 'auto',
        padding: 30
    },
    fontBold: {
        color: theme.palette.common.blue,
        fontSize: 24,
        fontWeight: 700,
        marginLeft: 30,
    },
    font: {
        color: theme.palette.common.black,
        fontSize: 20,
        marginLeft: 30,
    }
}))

const ContentFooter = () => {
    const classes = useStyles()

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.fontBold}>About the Data</Typography>
            <Typography className={classes.font}>Analysis-ready track, flight plan, and metadata for 76 individual facilities, including USA merged data, available for access. Performance report available for individual facilities.</Typography>
            <Typography className={classes.font}>Users have access to forecasts, warnings and analyses of hazardous weather for aviation within the entire National Airspace System. Forecast reports available for expected meteorological conditions providing either a 24 hour or 30 hour forecast for major airports.</Typography>
            {/*<br/>*/}
            {/*<Typography className={classes.fontBold}>Why ATAC Data?</Typography>*/}
            {/*<Typography className={classes.font}>Since 2015, ATAC has been brought on board to provide flight track data in a more easily analyzed format, one that is consistent regardless of origin. ATAC's processing also provides many types of daily reports. The daily reports use the flight track data to provide higher-level information about what happened in the NAS, from runway usage to sector loading.</Typography>*/}
        </Paper>
    )
}

export default ContentFooter