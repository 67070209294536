import React, {useReducer} from 'react'
import AuditUserDetailsReducer from "./auditUserDetailsReducer";
import AuditUserDetailsContext from "./auditUserDetailsContext";
import {SET_CHART, SET_CHART_LOADING} from "../types";
import {userDetailsAxes} from "../../components/Data/chartData";

const AuditUserDetailsState = props => {
    const initialState = {
        axes: userDetailsAxes,
        chartLoading: false,
        chartFiles: [],
        chartTitle: 'User Details',
        chartHAxisTitle: 'Date',
        chartVAxisTitle: 'Visitors'
    }

    const [state, dispatch] = useReducer(AuditUserDetailsReducer, initialState);

    const setChartLoading = (chartLoading) => {
        dispatch({
            type: SET_CHART_LOADING,
            chartLoading: chartLoading
        })
    }

    const setChartFiles = (files) => {
        dispatch({
            type: SET_CHART,
            chartFiles: files
        })
    }

    return <AuditUserDetailsContext.Provider
        value = {{
            axes: state.axes,
            chartLoading: state.chartLoading,
            chartFiles: state.chartFiles,
            chartTitle: state.chartTitle,
            chartHAxisTitle: state.chartHAxisTitle,
            chartVAxisTitle: state.chartVAxisTitle,
            cancelTokenSource: state.cancelTokenSource,
            setChartLoading,
            setChartFiles
        }}>
        {props.children}
    </AuditUserDetailsContext.Provider>
}

export default AuditUserDetailsState