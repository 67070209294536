import React, {useContext} from 'react'
import Grid from "@material-ui/core/Grid";
import DataProductCheckbox from "../../Selections/DataProductCheckbox";
import TraconReportContext from "../../../Contexts/TraconReport/traconReportContext";
import FacilitiesLabelPanel from "../../Panels/FacilitiesLabelPanel";
import SearchButton from "../../Buttons/SearchButton";
import LoadDialog from "../../Modals/LoadDialog";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import StartDateContext from "../../../Contexts/StartDate/startDateContext";
import EndDateContext from "../../../Contexts/EndDate/endDateContext";
import Paper from "@material-ui/core/Paper";
import FDFacilityTable from "../../Tables/FDFacilityTable";
import {fileDowloadUrl, hostname} from "../../Data/api";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    panel: {
        backgroundColor: theme.palette.common.lighterGray
    }
}))

const TraconReportPanel = () => {
    const classes = useStyles()

    const traconReportContext = useContext(TraconReportContext)
    const {dataProducts, facilities, clicked, tableLoading, files, progress, cancelTokenSource} = traconReportContext
    const setDataProducts = traconReportContext.setDataProducts
    const setFacilities = traconReportContext.setFacilities
    const setClicked = traconReportContext.setClicked
    const setTableLoading = traconReportContext.setTableLoading
    const setFiles = traconReportContext.setFiles
    const setProgress = traconReportContext.setProgress
    const resetCancelToken = traconReportContext.resetCancelToken

    const [loading, setLoading] = React.useState(false)
    const [cancel, setCancel] = React.useState(false)

    const startDateContext = useContext(StartDateContext)
    const {startDate, startTime} = startDateContext
    const endDateContext = useContext(EndDateContext)
    const {endDate, endTime} = endDateContext
    // console.log("ATAC Facility Report Tracon ", startDate, startTime, endDate, endTime)

    const activateTabs = dataProducts.goArounds.checked || dataProducts.turnsToFinal.checked || dataProducts.fixPassing.checked || dataProducts.runwayUsage.checked

    const activateSearch = dataProducts.goArounds.checked || dataProducts.turnsToFinal.checked || dataProducts.fixPassing.checked || dataProducts.runwayUsage.checked

    const handleChange = (event) => {
        const {name, checked} = event.target


        let updatedDataProducts2
        let dataProduct = Object.assign({}, dataProducts[name], {checked: checked})
        updatedDataProducts2 = Object.assign(
            {}, dataProducts, {[name]: dataProduct})


        setDataProducts(updatedDataProducts2)
    }

    const handleFacility = (event) => {
        const {name, checked} = event.target

        const updatedFacility = facilities.value.map(facility => {
            if (facility.name === name) {
                facility.checked = !facility.checked
            }
            return facility
        })

        let updatedFacilities = Object.assign(
            {}, facilities, {value: updatedFacility})

        setFacilities(updatedFacilities)
    }

    const handleUserSelection = (event) => {
        setClicked(true)
        setTableLoading(true)
        // const startDate = "20190202"
        // const endDate = "20190203"

        const start = startDate.toString().replace(/\//g,'')
        const end = endDate.toString().replace(/\//g,'')

        // console.log("Cleaned dates", start, end)
        //
        // console.log("User selected facilities")
        // console.log(facilities)

        let query = ""
        let queryFacilityGA = ""
        let queryFacilityTTF = ""
        let queryFacilityFP = ""
        let queryFacilityRU = ""

        Object.entries(dataProducts).forEach(([key, value]) => {
            value.checked && (query += value.value)
        })

        dataProducts.goArounds.checked && (
            Object.entries(facilities.value).forEach(([index,facility]) => {
                facility.checked && (queryFacilityGA += dataProducts.goArounds.value + " " + facility.value)
            })
        )
        dataProducts.turnsToFinal.checked && (
            Object.entries(facilities.value).forEach(([index,facility]) => {
                facility.checked && (queryFacilityTTF += dataProducts.turnsToFinal.value + " " + facility.value)
            })
        )
        dataProducts.fixPassing.checked && (
            Object.entries(facilities.value).forEach(([index,facility]) => {
                facility.checked && (queryFacilityFP += dataProducts.fixPassing.value + " " + facility.value)
            })
        )
        dataProducts.runwayUsage.checked && (
            Object.entries(facilities.value).forEach(([index,facility]) => {
                facility.checked && (queryFacilityRU += dataProducts.runwayUsage.value + " " + facility.value)
            })
        )

        // console.log("Query ", query)
        // console.log("Query Facility GA", queryFacilityGA)
        // console.log("Query Facility TTF", queryFacilityTTF)
        //
        // console.log("API", "http://localhost:8080/filedownload-0.0.1-SNAPSHOT/search/file/"+start+"/"+end+"?dataProducts="+queryFacilityGA+queryFacilityTTF)

        // GOAROUNDS
        // Runway Usage
        // TAXI Time
        // Turns To Final
        // Instantaneous Counts
        // Sector Stats
        // Sector Activity
        // Sectorization Events-SRE
        // Sectorization Records-SRZ

        // TURNTOFINAL results in empty object
        // http://localhost:8080/filedownload-0.0.1-SNAPSHOT/search/file/20190202/20190203?dataProducts=GOAROUNDS%20CLT
        axios.get(hostname + fileDowloadUrl + "search/file/"+start+"/"+end+"?dataProducts="+queryFacilityGA+queryFacilityTTF+queryFacilityFP+queryFacilityRU)
            .then(response => {
                let filteredFiles = response.data.data.filter(entry => {
                    return Object.keys(entry).length > 0
                })
                setFiles(filteredFiles)
                setTableLoading(false)
            })
            .catch(err => {
                console.log(err)
                setTableLoading(false)
            })
    }

    const handleFilename = (rows) => {
        let dataSources = [...new Set(rows.map(index => {return (files[index]["dataSource"])}))]
        dataSources = dataSources.toString().replace(/,/g,'_')
        // console.log("Data Sources", dataSources)

        return dataSources + "_" + startDate + "_" + endDate + ".zip"
    }

    const handleClick = (rowsSelected) => {
        // const data = files
        // console.log("Click", rowsSelected)
        // console.log("Files ", files)

        // const updatedSelectedFiles = rowsSelected.map(index => {return (files[index]["path"].replace('+','%2B'))})
        const queryFile = rowsSelected.map(index => {
            return (files[index]["path"].replace('+','%2B') + "%20" +
                files[index]["dataSource"]
            )
        }).join(",")

        // console.log("Query File ", queryFile)

        const total = rowsSelected.map(index => {
            return files[index]["size"]
        })

        const querySize = total.reduce((result,number)=> result+number)

        // http://localhost:8080/filedownload-0.0.1-SNAPSHOT/download/file?path=/home/data/atac/SVDataLocal/RD/atl+asdex/2019/201902/20190202
        setLoading(true)
        axios.get(hostname + fileDowloadUrl + "download/file?path="+queryFile,
            {
                responseType: "blob",
                onDownloadProgress: progressEvent => {
                    let progress = Math.round((progressEvent.loaded * 100)/querySize)
                    setProgress(progress)
                },
                cancelToken: cancelTokenSource.token
            })
            .then(response => {
                // console.log("Download from ", response.headers)
                setLoading(false)
                const file = new Blob([response.data], {type:"file/zip"})
                const link = document.createElement("a")
                link.href = URL.createObjectURL(file)
                link.download = handleFilename(rowsSelected)
                document.body.appendChild(link)
                link.click()
            })
            .catch(err => {
                console.log(err)
            })
    }

    const table = tableLoading ? <LinearProgress/> : <FDFacilityTable files={files} handleClick={handleClick}/>

    const handleCancel = () => {
        console.log("Cancel file download")
        cancelTokenSource.cancel("Cancelled Tracon Report Download")
        resetCancelToken()
        setCancel(true)
        setLoading(false)
    }

    const handleActivateSearch = () => {
        let search = false
        facilities.value.forEach(facility => {
            facility.checked && (search = true)
        })
        return search
    }

    let activateFacilitySearch = handleActivateSearch()

    return (
        <Grid container justify="flex-start" direction="column">
            <Paper className={classes.panel} style={{marginBottom:20,paddingLeft:15}}>
                <Grid item>
                    <Grid container direction="row">
                        <DataProductCheckbox name={dataProducts.goArounds.name} label={dataProducts.goArounds.label} checked={dataProducts.goArounds.checked} handleChange={handleChange}/>
                        <DataProductCheckbox name={dataProducts.turnsToFinal.name} label={dataProducts.turnsToFinal.label} checked={dataProducts.turnsToFinal.checked} handleChange={handleChange}/>
                        <DataProductCheckbox name={dataProducts.fixPassing.name} label={dataProducts.fixPassing.label} checked={dataProducts.fixPassing.checked} handleChange={handleChange}/>
                        <DataProductCheckbox name={dataProducts.runwayUsage.name} label={dataProducts.runwayUsage.label} checked={dataProducts.runwayUsage.checked} handleChange={handleChange}/>
                    </Grid>
                </Grid>
                <FacilitiesLabelPanel facilities={facilities} handleFacility={handleFacility} activateTabs={activateTabs}/>
            </Paper>
            <SearchButton activateSearch={activateSearch&&activateFacilitySearch} handleUserSelection={handleUserSelection}/>
            {clicked && table}
            {loading && <LoadDialog load={loading} progress={progress} handleClose={handleCancel}/>}
        </Grid>
    )
}

export default TraconReportPanel