import {HIGHLIGHT_CHARTS, HIGHLIGHT_DOWNLOADS, HIGHLIGHT_MAPS, UNHIGHLIGHT_ALL,} from '../types'

export default (state, action) => {
  switch(action.type){
    case HIGHLIGHT_DOWNLOADS:
      return {
        ...state,
        dcVariantProp:      'text',
        dcColorProp:        'primary',
        dcTypoVarProp:      'subtitle2',
        dcCursor:           'pointer',
        dmVariantProp:      'text',
        dmColorProp:        'primary',
        dmTypoVarProp:      'subtitle2',
        dmCursor:           'pointer',
        downloadVariantProp:'text',
        downloadColorProp:  'primary',
        downloadTypoVarProp:'subtitle2',
        downloadCursor:     'pointer',
        chartsVariantProp:  'text',
        chartsColorProp:    'default',
        chartsTypoVarProp:  'subtitle1',
        chartsCursor:       'auto',
        mapVariantProp:     'text',
        mapColorProp:       'default',
        mapTypoVarProp:     'subtitle1',
        mapCursor:          'auto',
        dataCategory:       'download',
        mergedExpFont:      'subtitle2',
        individualExpFont:  'subtitle2',
      }
    case HIGHLIGHT_CHARTS:
      return {
        ...state,
        dcVariantProp:      'text',
        dcColorProp:        'primary',
        dcTypoVarProp:      'subtitle2',
        dcCursor:           'pointer',
        dmVariantProp:      'text',
        dmColorProp:        'default',
        dmTypoVarProp:      'subtitle1',
        dmCursor:           'auto',
        downloadVariantProp:'text',
        downloadColorProp:  'secondary',
        downloadTypoVarProp:'subtitle1',
        downloadCursor:     'auto',
        chartsVariantProp:  'text',
        chartsColorProp:    'primary',
        chartsTypoVarProp:  'subtitle2',
        chartsCursor:       'pointer',
        mapVariantProp:     'text',
        mapColorProp:       'default',
        mapTypoVarProp:     'subtitle1',
        mapCursor:          'auto',
        dataCategory:       'charts',
        mergedExpFont:      'subtitle2',
        individualExpFont:  'subtitle1',
      }
    case HIGHLIGHT_MAPS:
      return {
        ...state,
        dcVariantProp:        'text',
        dcColorProp:          'default',
        dcTypoVarProp:        'subtitle1',
        dcCursor:             'auto',
        dmVariantProp:        'text',
        dmColorProp:          'primary',
        dmTypoVarProp:        'subtitle2',
        dmCursor:             'pointer',
        downloadVariantProp:  'text',
        downloadColorProp:    'default',
        downloadTypoVarProp:  'subtitle1',
        downloadCursor:       'auto',
        chartsVariantProp:    'text',
        chartsColorProp:      'default',
        chartsTypoVarProp:    'subtitle1',
        chartsCursor:         'auto',
        mapVariantProp:       'text',
        mapColorProp:         'primary',
        mapTypoVarProp:       'subtitle2',
        mapCursor:            'pointer',
        dataCategory:         'map',
      }
    case UNHIGHLIGHT_ALL:
      console.log('unhighlight_all was called')
      return {
        ...state,
        dcVariantProp:        'text',
        dcColorProp:          'default',
        dcTypoVarProp:        'subtitle1',
        dcCursor:             'auto',
        dmVariantProp:        'text',
        dmColorProp:          'default',
        dmTypoVarProp:        'subtitle1',
        dmCursor:             'auto',
        downloadVariantProp:  'text',
        downloadColorProp:    'default',
        downloadTypoVarProp:  'subtitle1',
        downloadCursor:       'auto',
        chartsVariantProp:    'text',
        chartsColorProp:      'default',
        chartsTypoVarProp:    'subtitle1',
        chartsCursor:         'auto',
        mapVariantProp:       'text',
        mapColorProp:         'default',
        mapTypoVarProp:       'subtitle1',
        mapCursor:            'auto',
        dataCategory:         '',
        mergedExpFont:      'subtitle1',
        individualExpFont:  'subtitle1',
      }
    default: return state
  }
}
