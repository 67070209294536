import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
    checkboxLabel: {
        // marginRight: 32,
    },
    checkbox: {
        marginRight: theme.spacing(-1)
    }
}))

const DataProductCheckbox = (props) => {
    const classes = useStyles()
    const {name,label,checked} = props

    return (
        <FormControlLabel className={classes.checkboxLabel} data-testid="checkbox"
            control={
                <Checkbox
                    color="primary"
                    name={name}
                    onChange={props.handleChange}
                    checked={checked}
                    size='small'
                    className={classes.checkbox}
                />
            }
          label={label}
        />
    )
}

export default DataProductCheckbox