import React, {useContext} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import UserContext from "../../Contexts/User/userContext";
import HeaderTitle from "../Titles/HeaderTitle";
import ProfilePanel from "./ProfilePanel";
import GuestInfoPanel from "./GuestInfo/GuestInfoPanel";
import ProfileTabPanel from "./ProfileTabPanel";
import Unauthorized from "../PagesAudit/Unauthorized";

const drawerWidth = 245;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        marginBottom: `${drawerWidth}px`,
        marginLeft: 5
    },
    panel: {
        backgroundColor: theme.palette.common.lighterGray
    },
    // toolbar: theme.mixins.toolbar,
}));

const Profile = () => {
    const classes = useStyles();
    const title = "User Profile"
    const userContext = useContext(UserContext)
    const {user} = userContext

    return (
        user.roles.includes("admin") ?
            <main className={classes.content}>
                <HeaderTitle title={title}/>
                <ProfilePanel/>
                <GuestInfoPanel/>
                <ProfileTabPanel/>
            </main>
            :
            <main className={classes.content}>
                <Unauthorized/>
            </main>
    )
}

export default Profile