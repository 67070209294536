import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';

export default function HeaderButton(props)  {
  const theme = useTheme()
  let fontSize
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  {matches ? fontSize='body1' : fontSize='subtitle1'}
  
  return (
    <Button color='secondary'>
      <Typography variant={fontSize}>
        {props.label}
      </Typography>
    </Button>
  )
}
