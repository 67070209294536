import React, {useContext, useState} from 'react';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import LeftNavMap from './LeftNavMap'
import LeftNavCharts from './LeftNavCharts';
import LeftNavDownload from './LeftNavDownload';
import SideNavContext from '../../Contexts/SideNav/sideNavContext'
import List from '@material-ui/core/List'
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import StartDateTime from "./StartDateTime";
import EndDateTime from "./EndDateTime"
import SemiPieChart from "../Charts/SemiPieChart";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import SemiPieChartContext from "../../Contexts/SemiPieChart/semiPieChartContext";
import SherlockLogo from '../../images/sherlock_logo.svg'
import TopNavContext from "../../Contexts/TopNav/topNavContext";
import Grid from "@material-ui/core/Grid";

const drawerWidth = 245;
 
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    background: '#eceff1',
  },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      zIndex: 1,
      background: '#eceff1',
      maxHeight: '95vh'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    removeUnderline: {
      textDecoration: 'none',
    },
    chartFont: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginTop: '-27%'
    },
    image: {
      width: '100%',
      marginTop: 15
    },
  horizontalDivider: {
    marginTop: 32,
    backgroundColor: theme.palette.primary.main,
    padding: 1
  }
}));

function LeftNav(props) {
  const { window1 } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [b1FontSize, setb1FontSize] = useState('subtitle1');
  const [b2FontSize, setb2FontSize] = useState('subtitle1');
  const sideNavContext = useContext(SideNavContext)
  const {dateWidget} = sideNavContext
  const mobileOpen = sideNavContext.mobileOpen
  const toggleSideNav = sideNavContext.toggleSideNav
  const leftMenu = sideNavContext.leftMenu
  const setDownloadMenu = sideNavContext.setDownloadMenu
  const setChartsMenu = sideNavContext.setChartsMenu
  const setMapMenu = sideNavContext.setMapMenu
  const resetLeftMenu = sideNavContext.resetLeftMenu
  const setDateWidget = sideNavContext.setDateWidget
  const resetDateWidget = sideNavContext.resetDateWidget

  const semiPieChartContext = useContext(SemiPieChartContext)
  const {link} = semiPieChartContext
  const topNavContext = useContext(TopNavContext)
  const highlightTopNavDownloadFont = topNavContext.highlightTopNavDownloadFont
  const highlightTopNavChartsFont = topNavContext.highlightTopNavChartsFont
  // const highlightTopNavMapFont = topNavContext.highlightTopNavMapFont
  const highlightUSA = sideNavContext.highlightUSA
  const highlightSurface = sideNavContext.highlightSurface
  const highlightTracon = sideNavContext.highlightTracon
  const highlightCenter = sideNavContext.highlightCenter
  const highlightFacRep = sideNavContext.highlightFacRep
  const highlightAtacFlight = sideNavContext.highlightAtacFlight

React.useEffect(() => {
  if(window.location.pathname.includes('Download') || window.location.pathname.includes('Charts'))
    (async () => await setDateWidget())()
  else
    (async () => await resetDateWidget())()
}, [])

  React.useEffect(() => {
    if(window.location.pathname.includes('Download'))
      (async () => await setb1FontSize('subtitle2'))();
  }, [b1FontSize]);
  React.useEffect(() => {
    if(window.location.pathname.includes('Charts'))
      (async () => await setb2FontSize('subtitle2'))();
  }, [b2FontSize]);
  React.useEffect(() => {
    if(window.location.pathname.includes('Download'))
      (async () => await setb1FontSize('subtitle2'))();
  }, [b1FontSize]);
  React.useEffect(() => {
    if(window.location.pathname.includes('Charts'))
      (async () => await setChartsMenu(<LeftNavCharts/>))();
  }, []);
  React.useEffect(() => {
    if(window.location.pathname.includes('Download'))
      (async () => await setDownloadMenu(<LeftNavDownload/>))();
  }, []);
  React.useEffect(() => {
     if(window.location.pathname.includes('Map'))
      (async () => await setMapMenu(<LeftNavMap/>))();
  }, []);
  React.useEffect(() => {
    if(window.location.pathname.includes('About') || window.location.pathname.includes('Contact'))
      (async () => await resetLeftMenu())();
  }, []);

  async function chartClick() {
    if(link.includes('Download'))
      await Promise.all([ highlightTopNavDownloadFont(), setDownloadMenu()])
    else
      await Promise.all([ highlightTopNavChartsFont(), setChartsMenu()])
    return 'Done!';}

  React.useEffect(() => {
    if(link.includes('USA'))
      (async () => await highlightUSA())();
    if(link.includes('Surf'))
      (async () => await highlightSurface())();
    if(link.includes('Tracon'))
      (async () => await highlightTracon())();
    if(link.includes('Center'))
      (async () => await highlightCenter())();
    if(link.includes('FacRep'))
      (async () => await highlightFacRep())();
    if(link.includes('Flight'))
      (async () => await highlightAtacFlight())();
  }, [link]);

  const drawer = (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.toolbar}>
          <img src={SherlockLogo} alt='Sherlock Logo' style={{paddingTop:'16px', paddingLeft: '30px'}} />
        </div>
        <div className={classes.drawer}>
          <Hidden smDown>
            <Grid container justify="center" style={{marginTop: -20, marginBottom: -24}}>
              <Link to={link} onClick={chartClick}>
                <SemiPieChart/>
              </Link>
              <Typography className={classes.chartFont}>Sherlock</Typography>
            </Grid>
          </Hidden>
        <List>
          { dateWidget && (
              <Grid container>
                <StartDateTime/>
                <EndDateTime/>
              </Grid>
            )
          }
          <Divider className={classes.horizontalDivider} />
          {leftMenu}
          <Divider />
        </List>
        </div>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );

  const container = window1 !== undefined ? () => window1().document.body : undefined;

  return (
    <div className={classes.root} >
      <nav className={classes.drawer} overflow='auto'>
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={toggleSideNav}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{keepMounted: true,}} // Better open performance on mobile.
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{paper: classes.drawerPaper}}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default LeftNav;
