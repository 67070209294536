import React, {useContext} from 'react'
import AuditProductInterestContext from "../../../Contexts/AuditProductInterest/auditProductInterestContext";
import AuditUserActivitySummaryContext
    from "../../../Contexts/AuditUserActivitySummary/auditUserActivitySummaryContext";
import StackedChart from "../../Charts/StackedChart";
import LinearProgress from "@material-ui/core/LinearProgress";
import {handleProductAction} from "../../Utils/AuditUtils";

const ProductInterest = () => {
    const auditProductInterestContext = useContext(AuditProductInterestContext)
    const {chartLoading, axes, chartFiles} = auditProductInterestContext
    const {chartTitle,chartHAxisTitle,chartVAxisTitle} = auditProductInterestContext
    const setProductInterestChartLoading = auditProductInterestContext.setChartLoading
    const setProductInterestChartFiles = auditProductInterestContext.setChartFiles
    const setProductInterestChartColumns = auditProductInterestContext.setChartColumns

    const auditUserActivitySummaryContext = useContext(AuditUserActivitySummaryContext)
    const {files} = auditUserActivitySummaryContext

    const handleProductInterest = () => {
        handleProductAction(files, "search", "product", setProductInterestChartColumns, setProductInterestChartFiles)
    }

    React.useEffect(() => {
        setProductInterestChartLoading(true)
        handleProductInterest()
    }, [files])

    React.useEffect(() => {
        setProductInterestChartLoading(false)
    }, [chartFiles])

    return (
        chartLoading ? <LinearProgress/> : <StackedChart chartFiles={chartFiles} columns={axes} title={chartTitle} haxis={chartHAxisTitle} vaxis={chartVAxisTitle}/>
    )
}

export default ProductInterest