import React, {useContext} from 'react'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import AuditContext from "../../../Contexts/Audit/auditContext";

const useStyles = makeStyles((theme) => ({
    fontHighlight: {
        color: theme.palette.primary.main,
        fontWeight: 550
    },
    font: {
        color: theme.palette.common.darkGray,
        fontWeight: 700
    },
    horizontalLine: {
        borderColor: theme.palette.secondary.main,
    }
}))

const TotalUsers = () => {
    const classes = useStyles()
    const auditContext = useContext(AuditContext)
    const {totalVisitors} = auditContext

    return (
        <Grid container alignItems="center" justify="space-between">
            <Grid direction="column">
                <Grid item>
                    <hr className={classes.horizontalLine}/>
                </Grid>
                <Grid container>
                    <Typography variant="subtitle1" style={{paddingRight:10}}>Total Visitors</Typography>
                    <Typography variant="subtitle1" className={classes.fontHighlight}>{totalVisitors}</Typography>
                </Grid>
                <Grid item>
                    <hr className={classes.horizontalLine}/>
                </Grid>
            </Grid>
            <Grid>
                <Typography className={classes.font}>Click the legend to filter user visibility.</Typography>
            </Grid>
        </Grid>
    )
}

export default TotalUsers