import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import FooterBanner from "../../../images/banner30.jpg";

const useStyles = makeStyles((theme) => ({
    footerImage: {
        marginTop: 50,
        width: '100%',
        height: 175,
        objectFit: 'cover',
        [theme.breakpoints.down('md')]: {
            marginTop: 10
        }
    }
}))

const ContentBottomFooter = () => {
    const classes = useStyles()

    return (
        <img className={classes.footerImage} src={FooterBanner}/>
    )
}

export default ContentBottomFooter