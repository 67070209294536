import React from 'react'
import {SET_LINK} from "../types";

export default (state, action) => {
    // console.log("Semi Pie Chart Reducer ", state, action.link)
    switch(action.type) {
        case SET_LINK:
            return {
                ...state,
                link: action.link
            }
        default: return state
    }
}