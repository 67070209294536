import React, {useReducer} from 'react'
import AuditUserActivityReducer from "./auditUserActivityReducer";
import AuditUserActivityContext from "./auditUserActivityContext";
import {SET_CHART, SET_CHART_LOADING} from "../types";
import {dailyUserActivityAxes} from "../../components/Data/chartData";

const AuditUserActivityState = props => {
    const initialState = {
        axes: dailyUserActivityAxes,
        chartLoading: false,
        chartFiles: [],
        chartTitle: 'User Activity',
        chartHAxisTitle: 'Date',
        chartVAxisTitle: 'Count'
    }

    const [state, dispatch] = useReducer(AuditUserActivityReducer, initialState);

    const setChartLoading = (chartLoading) => {
        dispatch({
            type: SET_CHART_LOADING,
            chartLoading: chartLoading
        })
    }

    const setChartFiles = (files) => {
        dispatch({
            type: SET_CHART,
            chartFiles: files
        })
    }

    return <AuditUserActivityContext.Provider
        value = {{
            axes: state.axes,
            chartLoading: state.chartLoading,
            chartFiles: state.chartFiles,
            chartTitle: state.chartTitle,
            chartHAxisTitle: state.chartHAxisTitle,
            chartVAxisTitle: state.chartVAxisTitle,
            setChartLoading,
            setChartFiles
        }}>
        {props.children}
    </AuditUserActivityContext.Provider>
}

export default AuditUserActivityState