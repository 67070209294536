import React from 'react'
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    margin: {
        paddingTop: 15,
        paddingBottom: 15,
        textAlign: "center"
    },
    fontBold: {
        color: theme.palette.common.black,
        fontSize: 24,
        fontWeight: 700
    },
    font: {
        color: theme.palette.common.black,
        fontSize: 20
    }
}))

const ContentHeader = () => {
    const classes = useStyles()

    return (
        <Grid className={classes.margin}>
            <Typography className={classes.fontBold}>About Sherlock Data Warehouse</Typography>
            <Typography className={classes.font}>Sherlock has access to many Air Traffic Management (ATM) and Weather data sources. Publicly available version of Sherlock analysis-ready flight and weather data is accessible using this portal.</Typography>
        </Grid>
    )
}

export default ContentHeader