import React, {useReducer} from 'react'
import CenterReportReducer from "../CenterReport/centerReportReducer";
import CenterReportContext from "../CenterReport/centerReportContext";
import {
    SET_CANCEL_TOKEN_SOURCE,
    SET_CLICKED,
    SET_DATA_PRODUCTS,
    SET_FACILITIES,
    SET_FILES,
    SET_TABLE_LOADING
} from "../types";
import centerReportData from "../../components/Data/centerReportData";
import axios from "axios";

const CenterReportState = props => {
    const initialState = {
        dataProducts: {
            instantaneousCountsStatic: {
                name: "instantaneousCountsStatic",
                label: "Instantaneous Counts Static",
                checked: false,
                value: "INSTANTANEOUSCOUNTS_SCT"
            },
            instantaneousCountsDynamic: {
                name: "instantaneousCountsDynamic",
                label: "Instantaneous Counts Dynamic",
                checked: false,
                value: "INSTANTANEOUSCOUNTS_DSCT"
            },
            sectorStatsStatic: {
                name: "sectorStatsStatic",
                label: "Sector Stats Static",
                checked: false,
                value: "SECTORSTATS_SCT"
            },
            sectorStatsDynamic: {
                name: "sectorStatsDynamic",
                label: "Sector Stats Dynamic",
                checked: false,
                value: "SECTORSTATS_DSCT"
            },
            sectorActivity: {
                name: "sectorActivity",
                label: "Sector Activity",
                checked: false,
                value: "SECTORACTIVITY"
            },
            // sectorizationEvents: {
            //     name: "sectorizationEvents",
            //     label: "Sectorization Events-SRE",
            //     checked: false,
            //     value: "SRE"
            // },
            // sectorizationRecords: {
            //     name: "sectorizationRecords",
            //     label: "Sectorization Records-SRZ",
            //     checked: false,
            //     value: "SRZ"
            // }
        },
        facilities: {
            // centers: {
                name: "Centers",
                value: centerReportData
            // }
        },
        clicked: false,
        tableLoading: false,
        files: [],
        cancelTokenSource: axios.CancelToken.source()
    }

    const [state, dispatch] = useReducer(CenterReportReducer, initialState);

    const setDataProducts = (dataProducts) => {
        dispatch({
            type: SET_DATA_PRODUCTS,
            dataProducts: dataProducts
        })
    }

    const setFacilities = (facilities) => {
        dispatch({
            type: SET_FACILITIES,
            facilities: facilities
        })
    }

    const setClicked = () => {
        dispatch({
            type: SET_CLICKED
        })
    }

    const setTableLoading = (tableLoading) => {
        dispatch({
            type: SET_TABLE_LOADING,
            tableLoading: tableLoading
        })
    }

    const setFiles = (files) => {
        dispatch({
            type: SET_FILES,
            files: files
        })
    }

    const resetCancelToken = () => {
        dispatch({
            type: SET_CANCEL_TOKEN_SOURCE,
            cancelTokenSource: axios.CancelToken.source()
        })
    }

    return <CenterReportContext.Provider
        value = {{
            dataProducts: state.dataProducts,
            facilities: state.facilities,
            clicked: state.clicked,
            tableLoading: state.tableLoading,
            files: state.files,
            cancelTokenSource: state.cancelTokenSource,
            setDataProducts,
            setFacilities,
            setClicked,
            setTableLoading,
            setFiles,
            resetCancelToken
        }}>
        {props.children}
    </CenterReportContext.Provider>
}

export default CenterReportState