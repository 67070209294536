import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FeedbackSelection from "./FeedbackSelection";
import FeedbackPrompt from "./FeedbackPrompt";
import FeedbackSend from "./FeedbackSend";
import FeedbackDialog from "./FeedbackDialog";

const drawerWidth = 245;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        width: `calc(100% - ${drawerWidth}px)`,
        marginBottom: `${drawerWidth}px`,
        [theme.breakpoints.up('md')]: {
            marginLeft: `${drawerWidth}px`,
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: theme.spacing(15),
            marginRight: theme.spacing(1),
            paddingTop: 0,
            width: '100%',
        },
    },
    paper: {
        backgroundColor: theme.palette.common.lighterGray,
        marginRight: 300,
        padding: 50
    },
    toolbar: theme.mixins.toolbar,
}));

const SupportFeedback = () => {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid justifyContent="center">
                <Paper className={classes.paper} elevation={0}>
                    <FeedbackPrompt/>
                    <FeedbackSelection/>
                    <Grid container justify="center">
                        <FeedbackSend/>
                    </Grid>
                    <FeedbackDialog/>
                </Paper>
            </Grid>
        </main>
    )
}

export default SupportFeedback