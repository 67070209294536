import React from 'react'
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    highlightFont: {
        fontWeight: 550
    }
}))

const InfoCard = (props) => {
    const classes = useStyles()
    const {label, icon, value} = props

    return (
        <Paper elevation={5} style={{padding: 10}}>
            <Grid container direction="column" alignItems="center">
                <Grid item>
                    <Typography>
                        {label}
                    </Typography>
                </Grid>
                <Grid item>
                    {icon}
                </Grid>
                <Grid item>
                    <Typography className={classes.highlightFont}>
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )

}

export default InfoCard