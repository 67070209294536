import React from 'react'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    horizontalLine: {
        borderColor: theme.palette.secondary.main,
        marginTop: 28,
        marginBottom: 28
    },
    fontBold: {
        fontWeight: 700
    },
    font: {
        fontWeight: 500
    },
    listTitle: {
        fontWeight: 700,
        marginBottom: -20
    }
}));

const Prompt = (props) => {
    const classes = useStyles()
    const {pageHeader} = props
    const listTitle = "*Please do the following before proceeding"

    return (
        <Grid>
            <Typography variant="h6" className={classes.fontBold}>{pageHeader}</Typography>
            <hr className={classes.horizontalLine}/>
            <Typography variant="body1" className={classes.listTitle}>{listTitle}</Typography>
            <ol>
                <li className={classes.font}>Select start and end dates.</li>
                <li className={classes.font}>Choose a data product.</li>
            </ol>
            <hr className={classes.horizontalLine}/>
        </Grid>
    )
}

export default Prompt