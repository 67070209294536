import React, {useContext} from 'react'
import ProfileGuestProductSecuredContext
    from "../../../Contexts/ProfileGuestProductSecured/profileGuestProductSecuredContext";
import ProfileGuestActivitySummaryContext
    from "../../../Contexts/ProfileGuestActivitySummary/profileGuestActivitySummaryContext";
import StackedChart from "../../Charts/StackedChart";
import LinearProgress from "@material-ui/core/LinearProgress";
import {handleProductAction} from "../../Utils/AuditUtils";

const GuestProductSecured = () => {
    const profileGuestProductSecuredContext = useContext(ProfileGuestProductSecuredContext)
    const {chartLoading, axes, chartFiles} = profileGuestProductSecuredContext
    const {chartTitle,chartHAxisTitle,chartVAxisTitle} = profileGuestProductSecuredContext
    const setGuestProductSecuredChartLoading = profileGuestProductSecuredContext.setChartLoading
    const setGuestProductSecuredChartFiles = profileGuestProductSecuredContext.setChartFiles
    const setGuestProductSecuredChartColumns = profileGuestProductSecuredContext.setChartColumns

    const profileGuestActivitySummaryContext = useContext(ProfileGuestActivitySummaryContext)
    const {files} = profileGuestActivitySummaryContext

    const handleProductSecured = () => {
        handleProductAction(files, "download", "product", setGuestProductSecuredChartColumns, setGuestProductSecuredChartFiles)
    }

    React.useEffect(() => {
        setGuestProductSecuredChartLoading(true)
        handleProductSecured()
    }, [files])

    React.useEffect(() => {
        setGuestProductSecuredChartLoading(false)
    }, [chartFiles])

    return (
        chartLoading ? <LinearProgress/> : <StackedChart chartFiles={chartFiles} columns={axes} title={chartTitle} haxis={chartHAxisTitle} vaxis={chartVAxisTitle}/>
    )
}

export default GuestProductSecured