import React, {useReducer} from 'react'
import ATACFacilityReportsReducer from "../ATACFacilityReports/atacFacilityReportsReducer";
import ATACFacilityReportsContext from "../ATACFacilityReports/atacFacilityReportsContext";
import {SET_TAB_VALUE} from "../types";

const ATACFacilityReportsState = props => {
    const initialState = {
        tabValue: 'tracon'
    }

    const [state, dispatch] = useReducer(ATACFacilityReportsReducer, initialState);

    const setTabValue = (value) => {
        dispatch({
            type: SET_TAB_VALUE,
            tabValue: value
        })
    }

    const generateQuery = (rowsSelected, files) => {
        return rowsSelected.map(index => { 
            return (files[index]["path"].replace('+','%2B') + "%20" +
                files[index]["dataSource"]
            )
        }).join(",")
    }

    return <ATACFacilityReportsContext.Provider
        value = {{
            tabValue: state.tabValue,
            setTabValue,
            generateQuery
        }}>
        {props.children}
    </ATACFacilityReportsContext.Provider>

}

export default ATACFacilityReportsState