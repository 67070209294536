import React, {useContext} from 'react'
import WeatherContext from "../../../Contexts/Weather/weatherContext";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import DataProductCheckbox from "../../Selections/DataProductCheckbox";
import {handleDataProductCheckboxes} from "../../Utils/PanelUtils";

const useStyles = makeStyles((theme) => ({
    panel: {
        backgroundColor: theme.palette.common.lighterGray
    }
}))

const WeatherPanel = () => {
    const classes = useStyles()
    const weatherContext = useContext(WeatherContext)
    const {dataProducts} = weatherContext
    const setDataProducts = weatherContext.setDataProducts

    const handleChange = (event) => {
        const {name, checked} = event.target
        handleDataProductCheckboxes(name, checked, dataProducts, setDataProducts)
    }

    return (
        <Grid justify="flex-start" direction="column">
            <Paper className={classes.panel} style={{marginBottom:20,padding:15}}>
                {/*<DataProductCheckbox name={dataProducts.airep.name} label={dataProducts.airep.label} checked={dataProducts.airep.checked} handleChange={handleChange}/>*/}
                <DataProductCheckbox name={dataProducts.asm.name} label={dataProducts.asm.label} checked={dataProducts.asm.checked} handleChange={handleChange}/>
                <DataProductCheckbox name={dataProducts.metar.name} label={dataProducts.metar.label} checked={dataProducts.metar.checked} handleChange={handleChange}/>
                <DataProductCheckbox name={dataProducts.taf.name} label={dataProducts.taf.label} checked={dataProducts.taf.checked} handleChange={handleChange}/>
            </Paper>
        </Grid>
    )
}

export default WeatherPanel