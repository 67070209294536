import React, {useContext} from 'react'
import ProfileGuestContext from "../../../Contexts/Profile/profileContext";
import ProfileGuestActivitySummaryContext
    from "../../../Contexts/ProfileGuestActivitySummary/profileGuestActivitySummaryContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import AuditTable from "../../Tables/AuditTable";
import {auditUserActivitySummaryColumnNames, auditUserActivitySummaryColumns} from "../../Data/cgTableData";

const GuestActivitySummaryPanel = () => {
    const profileGuestContext = useContext(ProfileGuestContext)
    const {clicked} = profileGuestContext

    const profileGuestActivitySummaryContext = useContext(ProfileGuestActivitySummaryContext)
    const {tableLoading, files} = profileGuestActivitySummaryContext

    const table = tableLoading ? <LinearProgress/> : <AuditTable files={files} columns={auditUserActivitySummaryColumns} columnNames={auditUserActivitySummaryColumnNames}/>

    return (
        clicked && table
    )

}

export default GuestActivitySummaryPanel