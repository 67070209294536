import React, {useContext} from 'react'
import ProfileContext from "../../Contexts/Profile/profileContext";
import ProfileGuestProductInterestDetailsContext
    from "../../Contexts/ProfileGuestProductInterestDetails/profileGuestProductInterestDetailsContext";
import ProfileGuestProductInterestContext
    from "../../Contexts/ProfileGuestProductInterest/profileGuestProductInterestContext";
import ProfileGuestProductSecuredDetailsContext
    from "../../Contexts/ProfileGuestProductSecuredDetails/profileGuestProductSecuredDetailsContext";
import ProfileGuestProductSecuredContext
    from "../../Contexts/ProfileGuestProductSecured/profileGuestProductSecuredContext";
import ProfileGuestActivityContext from "../../Contexts/ProfileGuestActivity/profileGuestActivityContext";
import GuestProductInterestDetailsPanel from "./GuestProductInterestDetails/GuestProductInterestDetailsPanel";
import GuestProductSecuredDetailsPanel from "./GuestProductSecuredDetails/GuestProductSecuredDetailsPanel";
import GuestActivityPanel from "./GuestActivity/GuestActivityPanel";
import GuestActivitySummaryPanel from "./GuestActivitySummary/GuestActivitySummaryPanel";
import Grid from "@material-ui/core/Grid";
import TabPanel from "../Panels/TabPanel";
import Panel from "../Panels/Panel";

const ProfileTabPanel = () => {
    const panelists = {
        productInterestDetails: {
            name: "Product Search Details",
            value: <GuestProductInterestDetailsPanel/>
        },
        productSecuredDetails: {
            name: "Product Download Details",
            value: <GuestProductSecuredDetailsPanel/>
        },
        userActivity: {
            name: "User Activity",
            value: <GuestActivityPanel/>
        },
        userActivitySummary: {
            name: "User Activity Summary",
            value: <GuestActivitySummaryPanel/>
        }
    }

    const profileContext = useContext(ProfileContext)
    const {tabValue} = profileContext
    const setTabValue = profileContext.setTabValue

    const profileGuestProductInterestDetailsContext = useContext(ProfileGuestProductInterestDetailsContext)
    const setGuestProductInterestDetailsChartLoading = profileGuestProductInterestDetailsContext.setChartLoading

    const profileGuestProductInterestContext = useContext(ProfileGuestProductInterestContext)
    const setGuestProductInterestChartLoading = profileGuestProductInterestContext.setChartLoading

    const profileGuestProductSecuredDetailsContext = useContext(ProfileGuestProductSecuredDetailsContext)
    const setGuestProductSecuredDetailsChartLoading = profileGuestProductSecuredDetailsContext.setChartLoading

    const profileGuestProductSecuredContext = useContext(ProfileGuestProductSecuredContext)
    const setGuestProductSecuredChartLoading = profileGuestProductSecuredContext.setChartLoading

    const profileGuestActivityContext = useContext(ProfileGuestActivityContext)
    const setGuestActivityChartLoading = profileGuestActivityContext.setChartLoading

    const activateTabs = true

    const handleTabs = (event, newValue) => {
        console.log("New tab value ", newValue)
        setTabValue(newValue)
    }

    const handleGuestProductInterest = () => {
        setGuestProductInterestDetailsChartLoading(true)
        setGuestProductInterestChartLoading(true)
    }

    const handleGuestProductSecured = () => {
        setGuestProductSecuredDetailsChartLoading(true)
        setGuestProductSecuredChartLoading(true)
    }

    React.useEffect(() => {
        switch (tabValue) {
            case "productInterestDetails": handleGuestProductInterest()
                break
            case "productSecuredDetails": handleGuestProductSecured()
                break
            case "userActivity": setGuestActivityChartLoading(true)
                break
            default:
                handleGuestProductInterest()
        }
    }, [tabValue])

    return (
        <Grid>
            <TabPanel activateTabs={activateTabs} panelists={panelists} tabValue={tabValue} handleTabs={handleTabs}/>
            <Panel activateTabs={activateTabs} panelists={panelists} tabValue={tabValue} handleTabs={handleTabs}/>
        </Grid>
    )
}

export default ProfileTabPanel