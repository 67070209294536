import React, {useContext} from 'react'
import {Help} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import SupportDialog from "../Modals/SupportDialog";
import SupportContext from "../../Contexts/Support/supportContext";

const useStyles = makeStyles((theme) => ({
    helpIcon: {
        color: theme.palette.common.white
    }
}));

const HeaderSupport = () => {
    const classes = useStyles();
    const supportContext = useContext(SupportContext)
    const {open, setOpen} = supportContext

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid item xs={1}>
            <Button
                startIcon={<Tooltip title="Support"><Help className={classes.helpIcon} style={{fontSize: 26}}/></Tooltip>}
                onClick={handleOpen}
            />
            <SupportDialog open={open} handleClose={handleClose}/>
        </Grid>
    )
}

export default HeaderSupport