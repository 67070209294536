import React, {useContext} from 'react'
import AuditContext from "../../Contexts/Audit/auditContext";
import AuditUserDetailsContext from "../../Contexts/AuditUserDetails/auditUserDetailsContext";
import AuditUserTrafficContext from "../../Contexts/AuditUserTraffic/auditUserTrafficContext";
import AuditProductInterestDetailsContext
    from "../../Contexts/AuditProductInterestDetails/auditProductInterestDetailsContext";
import AuditProductInterestContext from "../../Contexts/AuditProductInterest/auditProductInterestContext";
import AuditProductSecuredDetailsContext
    from "../../Contexts/AuditProductSecuredDetails/auditProductSecuredDetailsContext";
import AuditProductSecuredContext from "../../Contexts/AuditProductSecured/auditProductSecuredContext";
import AuditUserActivityContext from "../../Contexts/AuditUserActivity/auditUserActivityContext";
import UserDetailsPanel from "./UserDetails/UserDetailsPanel";
import UserSummaryPanel from "./UserSummary/UserSummaryPanel";
import ProductInterestDetailsPanel from "./ProductInterestDetails/ProductInterestDetailsPanel";
import ProductSecuredDetailsPanel from "./ProductSecuredDetails/ProductSecuredDetailsPanel";
import UserActivityPanel from "./UserActivity/UserActivityPanel";
import UserActivitySummaryPanel from "./UserActivitySummary/UserActivitySummaryPanel";
import Grid from "@material-ui/core/Grid";
import TabPanel from "../Panels/TabPanel";
import Panel from "../Panels/Panel";

const AuditTabPanel = () => {
    const panelists = {
        userDetails: {
            name: "User Details",
            value: <UserDetailsPanel/>
        },
        userSummary: {
            name: "User Summary",
            value: <UserSummaryPanel/>
        },
        productInterestDetails: {
            name: "Product Search Details",
            value: <ProductInterestDetailsPanel/>
        },
        productSecuredDetails: {
            name: "Product Download Details",
            value: <ProductSecuredDetailsPanel/>
        },
        userActivity: {
            name: "User Activity",
            value: <UserActivityPanel/>
        },
        userActivitySummary: {
            name: "User Activity Summary",
            value: <UserActivitySummaryPanel/>
        }
    }

    const auditContext = useContext(AuditContext)
    const {tabValue} = auditContext
    const setTabValue = auditContext.setTabValue

    const auditUserDetailsContext = useContext(AuditUserDetailsContext)
    const setUserDetailsChartLoading = auditUserDetailsContext.setChartLoading

    const auditUserTrafficContext = useContext(AuditUserTrafficContext)
    const setUserTrafficChartLoading = auditUserTrafficContext.setChartLoading

    const auditProductInterestDetailsContext = useContext(AuditProductInterestDetailsContext)
    const setProductInterestDetailsChartLoading = auditProductInterestDetailsContext.setChartLoading

    const auditProductInterestContext = useContext(AuditProductInterestContext)
    const setProductInterestChartLoading = auditProductInterestContext.setChartLoading

    const auditProductSecuredDetailsContext = useContext(AuditProductSecuredDetailsContext)
    const setProductSecuredDetailsChartLoading = auditProductSecuredDetailsContext.setChartLoading

    const auditProductSecuredContext = useContext(AuditProductSecuredContext)
    const setProductSecuredChartLoading = auditProductSecuredContext.setChartLoading

    const auditUserActivityContext = useContext(AuditUserActivityContext)
    const setUserActivityChartLoading = auditUserActivityContext.setChartLoading

    const activateTabs = true

    const handleTabs = (event, newValue) => {
        console.log("New tab value ", newValue)
        setTabValue(newValue)
    }

    const handleUserDetails = () => {
        setUserDetailsChartLoading(true)
        setUserTrafficChartLoading(true)
    }

    const handleProductInterest = () => {
        setProductInterestDetailsChartLoading(true)
        setProductInterestChartLoading(true)
    }

    const handleProductSecured = () => {
        setProductSecuredDetailsChartLoading(true)
        setProductSecuredChartLoading(true)
    }

    React.useEffect(() => {
        switch (tabValue) {
            case "userDetails": handleUserDetails()
                break
            case "productInterestDetails": handleProductInterest()
                break
            case "productSecuredDetails": handleProductSecured()
                break
            case "userActivity": setUserActivityChartLoading(true)
                break
            default:
                handleUserDetails()
        }
    }, [tabValue])

    return (
        <Grid>
            <TabPanel activateTabs={activateTabs} panelists={panelists} tabValue={tabValue} handleTabs={handleTabs}/>
            <Panel activateTabs={activateTabs} panelists={panelists} tabValue={tabValue} handleTabs={handleTabs}/>
        </Grid>
    )
}

export default AuditTabPanel