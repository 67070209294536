import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: 10
    }
}))

const CustomUserCell = (props) => {
    const classes = useStyles()
    const {value} = props

    return (
        <Grid container direction="row" alignItems="center">
            <Avatar className={classes.avatar}>{value[0].toUpperCase()}</Avatar>
            {value}
        </Grid>
    )
}

export default CustomUserCell