import React from 'react'
import {
    SET_AVG_SEARCH_TIME,
    SET_CLICKED,
    SET_MAX_ACTIVE_TIME,
    SET_TAB_VALUE,
    SET_TOTAL_PRODUCT_INTEREST,
    SET_TOTAL_PRODUCT_SECURED,
    SET_TOTAL_VISITORS, SET_TYPES
} from "../types";

export default (state, action) => {
    console.log("Audit Reducer ", state, action.tabValue)
    switch (action.type) {
        case SET_TYPES:
            return {
                ...state,
                userTypes: action.userTypes
            }
        case SET_CLICKED:
            return {
                ...state,
                clicked: true
            }
        case SET_TOTAL_VISITORS:
            return {
                ...state,
                totalVisitors: action.totalVisitors
            }
        case SET_TOTAL_PRODUCT_INTEREST:
            return {
                ...state,
                totalProductInterest: action.totalProductInterest
            }
        case SET_TOTAL_PRODUCT_SECURED:
            return {
                ...state,
                totalProductSecured: action.totalProductSecured
            }
        case SET_AVG_SEARCH_TIME:
            return {
                ...state,
                avgSearchTime: action.avgSearchTime
            }
        case SET_MAX_ACTIVE_TIME:
            return {
                ...state,
                maxActiveTime: action.maxActiveTime
            }
        case SET_TAB_VALUE:
            return {
                ...state,
                tabValue: action.tabValue
            }
        default:
            return state

    }
}