import React from 'react';
import Modal from "@material-ui/core/Modal";
import {makeStyles} from "@material-ui/core/styles";
import {Warning} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute',
        width: 350,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        textAlign: 'left'
    },
    warningIcon: {
        color: '#ff1744',
        fontSize: 65
    },
    button: {
        backgroundColor: '#ff1744',
        color: '#fff',
        fontSize: 16,
        marginLeft: -15,
        // marginRight: -50,
        marginBottom: -28,
        width: '109%',
    },
    heading: {
        marginLeft: 15
    },
    horizontalLine: {
        marginTop: -10
    },
}))

const getRandomNumber = (min, max) => {
    let c = window.crypto.getRandomValues(new Uint32Array(1))[0]/2**32|0

    return min+(max-min+1)*c
}

const getModalStyle = () => {
    const random = getRandomNumber(-10, 10)
    const top = 50 + random;
    const left = 50 + random;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const LimitDialog = (props) => {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle);

    return (
        <Modal open={props.open}>
            <div className={classes.modal} style={modalStyle} data-testid="limitModal">
                <Grid container direction="row">
                    <Warning className={classes.warningIcon}/>
                    <h2 className={classes.heading}>Limit Exceeded</h2>
                </Grid>
                <hr className={classes.horizontalLine}/>
                {/*<h4>File size limit has been exceeded.</h4>*/}
                <p>Please do not download more than 12 GB files at once to avoid the long wait.</p>
                <Button className={classes.button} fullWidth onClick={props.handleClose}>GO BACK</Button>
            </div>
        </Modal>
    )
}

export default LimitDialog