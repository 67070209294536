import React from 'react';
import Modal from "@material-ui/core/Modal";
import {makeStyles} from "@material-ui/core/styles";
import {ErrorOutline} from "@material-ui/icons";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute',
        width: 250,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center'
    },
    errorIcon: {
        color: '#ffb74d',
        fontSize: 75
    },
    text: {
      fontWeight: 500
    },
    button: {
        backgroundColor: '#ffb74d',
        color: '#fff',
        fontSize: 16,
        marginLeft: -32,
        // marginRight: -50,
        marginBottom: -28,
        width: '126%',
    }
}))

const getRandomNumber = (min, max) => {
    let c = window.crypto.getRandomValues(new Uint32Array(1))[0]/2**32|0

    return min+(max-min+1)*c
}

const getModalStyle = () => {
    const random = getRandomNumber(-10, 10)
    const top = 50 + random;
    const left = 50 + random;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const ModalDialog = (props) => {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle);

    return (
        <Modal
            open={props.open}
            // onClose={props.handleClose}
        >
            <div className={classes.modal} style={modalStyle}>
                <ErrorOutline className={classes.errorIcon}/>
                <h3>Invalid Search</h3>
                <h5 className={classes.text}>Please make sure to select at least one value</h5>
                <Button className={classes.button} fullWidth onClick={props.handleClose} data-testid="modalOk">OK, GOT IT</Button>
            </div>
        </Modal>
    )
}

export default ModalDialog