import {surfaceDataFacilities} from './facilityData'


const surfaceData = surfaceDataFacilities.map((name, id) => {return (
    {
        id: id,
        name: name,
        checked: false,
        value: ((name).toLowerCase() + ",").replace('+','%2B')
    }
)})


export default surfaceData