import React from 'react';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import ModalDialog from "../Modals/ModalDialog";

const useStyles = makeStyles(() => ({
    button: {
        // marginLeft: 30,
        marginTop: 4
    }
}))
// onClick for modal is handleOpen
const SearchButton = (props) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        // console.log("Modal ", props.activateSearch)
        !props.activateSearch ? setOpen(true) : props.handleUserSelection()
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button className={classes.button} variant="contained" color="primary" onClick={handleOpen} data-testid="search">Search</Button>
            <ModalDialog open={open} handleClose={handleClose}/>
        </div>
    )
}

export default SearchButton