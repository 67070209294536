import React from 'react'
import TextField from "@material-ui/core/TextField";

const SubjectField = (props) => {
    const {value, handleInput} = props

    return (
        <TextField fullWidth label="Subject" value={value} onChange={handleInput}/>
    )
}

export default SubjectField