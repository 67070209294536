import React from 'react'
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import StartDateTime from "../NavBars/StartDateTime";
import EndDateTime from "../NavBars/EndDateTime";
import Grid from "@material-ui/core/Grid";

const HorizontalDateWidget = () => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container>
                <Grid item>
                    <StartDateTime/>
                </Grid>
                <Grid item>
                    <EndDateTime/>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default HorizontalDateWidget