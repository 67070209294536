import React, {useContext} from 'react'
import UserContext from "../../../Contexts/User/userContext";
import FeedbackContext from "../../../Contexts/Feedback/feedbackContext";
import SendButton from "../../Buttons/SendButton";
import axios from "axios";
import {hostname, supportFeaturesUrl} from "../../Data/api";

const FeedbackSend = () => {
    const userContext = useContext(UserContext)
    const {name, email} = userContext.user
    const feedbackContext = useContext(FeedbackContext)
    const {category, subject, message, loading, sent, setClicked, setLoading, setSent, setAvailable} = feedbackContext

    const handleUserInput = () => {
        let url = hostname + supportFeaturesUrl + "api/email?"+"source="+hostname+"name="+name+"&sender="+email+"&category="+category+"&subject="+subject+"&message="+message

        axios.post(url)
            .then(response => {
                setLoading(false)
                setSent(true)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setAvailable(false)
            })
    }

    React.useEffect(() => {
        if (loading) {
            handleUserInput()
            setClicked(false)
        }
    }, [loading])

    return (
        <SendButton loading={loading} sent={sent} handleSend={setClicked}/>
    )
}

export default FeedbackSend