import React from 'react'
import TextField from "@material-ui/core/TextField";
import {characterLimit} from "../Data/api";

const MessageField = (props) => {
    const {value, handleInput} = props

    return (
        <TextField
            fullWidth
            label="Message"
            placeholder="Provide feedback on"
            multiline
            variant="outlined"
            rows={10}
            inputProps={{
                maxLength: characterLimit,
                color: "#000"
            }}
            helperText={`${value.length}/${characterLimit}`}
            value={value}
            onChange={handleInput}
        />
    )
}

export default MessageField