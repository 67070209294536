import React, {useReducer} from 'react'
import ATACUSAReducer from "../ATACUSA/atacUSAReducer";
import ATACUSAContext from "../ATACUSA/atacUSAContext";
import {
    SET_CANCEL_TOKEN_SOURCE,
    SET_CLICKED,
    SET_DATA_PRODUCTS,
    SET_FILES,
    SET_PROGRESS,
    SET_TABLE_LOADING
} from "../types";
import axios from 'axios';

const ATACUSAState = props => {
    const initialState = {
        dataProducts: {
            trackPoints: {
                name: "trackPoints",
                label: "Track Points (IFF)",
                checked: false,
                value: "IFF USA,"
            },
            flightEvents: {
                name: "flightEvents",
                label: "Flight Events (EV)",
                checked: false,
                value: "EV USA,"
            },
            flightSummary: {
                name: "flightSummary",
                label: "Flight Summary (RD)",
                checked: false,
                value: "RD USA,"
            }
        },
        clicked: false,
        tableLoading: false,
        files: [],
        progress: 0,
        cancelTokenSource: axios.CancelToken.source()
    }

    const [state, dispatch] = useReducer(ATACUSAReducer, initialState);

    const setDataProducts = (dataProducts) => {
        dispatch({
            type: SET_DATA_PRODUCTS,
            dataProducts: dataProducts
        })
    }

    const setClicked = () => {
        dispatch({
            type: SET_CLICKED
        })
    }

    const setTableLoading = (tableLoading) => {
        dispatch({
            type: SET_TABLE_LOADING,
            tableLoading: tableLoading
        })
    }

    const setFiles = (files) => {
        dispatch({
            type: SET_FILES,
            files: files
        })
    }

    const setProgress = (progress) => {
        dispatch({
            type: SET_PROGRESS,
            progress: progress
        })
    }

    const resetProgress = () => {
        dispatch({
            type: SET_PROGRESS,
            progress: 0
        })
    }

    const resetCancelToken = () => {
        dispatch({
            type: SET_CANCEL_TOKEN_SOURCE,
            cancelTokenSource: axios.CancelToken.source()
        })
    }

    return <ATACUSAContext.Provider
        value = {{
            dataProducts: state.dataProducts,
            clicked: state.clicked,
            tableLoading: state.tableLoading,
            files: state.files,
            progress: state.progress,
            cancelTokenSource: state.cancelTokenSource,
            setDataProducts,
            setClicked,
            setTableLoading,
            setFiles,
            setProgress,
            resetProgress,
            resetCancelToken
        }}>
        {props.children}
    </ATACUSAContext.Provider>
}

export default ATACUSAState