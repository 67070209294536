import React from 'react'
import Grid from "@material-ui/core/Grid";
import ProductInterestDetails from "./ProductInterestDetails";
import ProductInterest from "./ProductInterest";

const ProductInterestDetailsPanel = () => {
    return (
        <Grid container>
            <Grid item sm>
                <ProductInterestDetails/>
            </Grid>
            <Grid item md>
                <ProductInterest/>
            </Grid>
        </Grid>
    )
}

export default ProductInterestDetailsPanel