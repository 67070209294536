import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@material-ui/icons/Info';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {apiDocsUrl, hostname} from "../Data/api";
import ProductAvailabilityLabel from "./ProductAvailabilityLabel";

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: 550,
        marginLeft: 30,
        marginTop: -40,
        marginBottom: -10
    },
    subtitle: {
        color: '#78909c',
        // fontWeight: 550,
        marginLeft: 30,
        marginTop: 5,
        marginBottom: -10,
        fontSize: '1rem'
    },
    horizontalLine: {
        borderColor: theme.palette.primary.main,
        marginBottom: 5
    },
    icon: {
        color: theme.palette.primary.main,
    },
    typography: {
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    link: {
        fontSize: 12
    }
}))

const TitleSubtitle = (props) => {
    const classes = useStyles()
    const [, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <header>
            <Grid container justify="space-between" alignItems="flex-end">
                <Grid item xs>
                    <Typography gutterBottom variant='subtitle2' className={classes.title}>
                        {props.title}
                    </Typography>
                    <Typography gutterBottom variant='body1' className={classes.subtitle} data-testid="subtitle">
                        {props.subtitle}
                    </Typography>
                </Grid>
                <Grid item>
                    <ProductAvailabilityLabel/>
                </Grid>
                <Grid item>
                    <Link href={hostname+apiDocsUrl} target='_blank' style={{textDecoration:'none'}}>
                    <Button
                        startIcon={<Tooltip title="Info"><InfoIcon className={classes.icon} style={{fontSize: 25}}/></Tooltip>}
                        onClick={handleClick}
                    >
                    </Button>
                    </Link>
                </Grid>
            </Grid>
            <hr className={classes.horizontalLine}/>
        </header>
    )
}

export default TitleSubtitle