import React, {useContext} from 'react'
import Grid from "@material-ui/core/Grid";
import InfoCard from "../Cards/InfoCard";
import {Group, DataUsage, GetApp, Timelapse, AccessTime} from "@material-ui/icons";
import AuditContext from "../../Contexts/Audit/auditContext";

const AuditQuickInfoPanel = () => {
    const auditContext = useContext(AuditContext)
    const {totalVisitors, totalProductInterest, totalProductSecured, avgSearchTime, maxActiveTime} = auditContext
    const searchTime = avgSearchTime + "s"
    const activeTime = maxActiveTime + "s"
    console.log("Audit Quick Info", totalVisitors, totalProductInterest, totalProductSecured, searchTime, activeTime)

    return (
        <Grid container justify="center" spacing={2}>
            <Grid item>
                <InfoCard label={"Total Visitors"} icon={<Group/>} value={totalVisitors}/>
            </Grid>
            <Grid item>
                <InfoCard label={"Product Searches"} icon={<DataUsage/>} value={totalProductInterest}/>
            </Grid>
            <Grid item>
                <InfoCard label={"Product Downloads"} icon={<GetApp/>} value={totalProductSecured}/>
            </Grid>
            <Grid item>
                <InfoCard label={"Avg Search Time"} icon={<Timelapse/>} value={searchTime}/>
            </Grid>
            <Grid item>
                <InfoCard label={"Max Active Time"} icon={<AccessTime/>} value={activeTime}/>
            </Grid>
        </Grid>
    )
}

export default AuditQuickInfoPanel