import {fileDowloadUrl, hostname} from "../Data/api";
import axios from "axios";

export const handleDate = (fileDate) => {
    let [year, month, day] = fileDate.toString().split("-")

    return year + "/" + month + "/" + day
}

export const handleDownload = (rowsSelected, files, 
    downloadFileName, cancelTokenSource, setLoading, setProgress) => {

    const filteredFiles = files.filter(entry => {
        return Object.keys(entry).length > 0
    })

    const updatedSelectedFiles = rowsSelected.map(index => {
        let ret = filteredFiles[index]["path"] + " " 
            + filteredFiles[index]["dataSource"]
        ret = ret.replace("+", "%2B")
        return ret
    })

    const queryFile = updatedSelectedFiles.join(",")

    const total = rowsSelected.map(index => {
        return filteredFiles[index]["size"]
    })

    const querySize = total.reduce((result,number)=> result+number)

    handleFileDownload(queryFile,querySize,downloadFileName,setLoading,setProgress,cancelTokenSource)
}

export const handleFileDownload = (queryFile, querySize, filename, setLoading, setProgress, cancelTokenSource) => {
    const url = hostname + fileDowloadUrl + "download/file?path=" + queryFile

    setLoading(true)
    axios.get(url, {responseType: "blob",
        onDownloadProgress: progressEvent => {
            let progress = Math.round((progressEvent.loaded * 100)/querySize)
            setProgress(progress)
        },
        cancelToken: cancelTokenSource.token})
        .then(response => {
            setLoading(false)
            const file = new Blob([response.data], {type: "file/zip"})
            const link = document.createElement("a")
            link.href = URL.createObjectURL(file)
            link.download = filename
            document.body.appendChild(link)
            link.click()
        })
        .catch(err => {
            console.log(err)
        })
}