import React, {useContext} from 'react'
import ProfileGuestProductSecuredDetailsContext
    from "../../../Contexts/ProfileGuestProductSecuredDetails/profileGuestProductSecuredDetailsContext";
import ProfileGuestActivitySummaryContext
    from "../../../Contexts/ProfileGuestActivitySummary/profileGuestActivitySummaryContext";
import PieChart from "../../Charts/PieChart";
import LinearProgress from "@material-ui/core/LinearProgress";
import {handleDetails} from "../../Utils/AuditUtils";

const GuestProductSecuredDetails = () => {
    const profileGuestProductSecuredDetailsContext = useContext(ProfileGuestProductSecuredDetailsContext)
    const {chartLoading, axes, chartFiles} = profileGuestProductSecuredDetailsContext
    const {chartTitle,chartHAxisTitle,chartVAxisTitle} = profileGuestProductSecuredDetailsContext
    const setGuestProductSecuredDetailsChartLoading = profileGuestProductSecuredDetailsContext.setChartLoading
    const setGuestProductSecuredDetailsChartFiles = profileGuestProductSecuredDetailsContext.setChartFiles

    const profileGuestActivitySummaryContext = useContext(ProfileGuestActivitySummaryContext)
    const {files} = profileGuestActivitySummaryContext

    const handleProductSecuredDetails = () => {
        handleDetails(files, "download", "product", setGuestProductSecuredDetailsChartFiles)
    }

    React.useEffect(() => {
        setGuestProductSecuredDetailsChartLoading(true)
        handleProductSecuredDetails()
    }, [files])

    React.useEffect(() => {
        setGuestProductSecuredDetailsChartLoading(false)
    }, [chartFiles])

    return (
        chartLoading ? <LinearProgress/> : <PieChart chartFiles={chartFiles} columns={axes} title={chartTitle} haxis={chartHAxisTitle} vaxis={chartVAxisTitle}/>
    )
}

export default GuestProductSecuredDetails