import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Title from "../../Titles/Title";
import TabPanel from "../../Panels/TabPanel";
import Panel from "../../Panels/Panel";
import TraconReportPanel from "./TraconReportPanel";
import CenterReportPanel from "./CenterReportPanel";
import AirportReportPanel from "./AirportReportPanel";
import ATACFacilityReportsContext from "../../../Contexts/ATACFacilityReports/atacFacilityReportsContext";
import Banner from "../../../images/banner29.png";

const drawerWidth = 245;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(5),
    marginBottom: `${drawerWidth}px`,
    [theme.breakpoints.up('md')]: {
      marginLeft: `${drawerWidth}px`,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(1),
      paddingTop: theme.spacing(10),
      width: '100%',
    },
  },
  image: {
    width: '100%',
    marginTop: -128,
    paddingBottom: 30,
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 50,
      marginTop: -50
    },
    [theme.breakpoints.between('sm','md')]: {
      marginTop: -90
    }

  },
  grid: {
    paddingLeft: 30
  },
  panel: {
    backgroundColor: theme.palette.common.lighterGray
  },
  toolbar: theme.mixins.toolbar,
}));

function DownloadFacRep() {

  const classes = useStyles();

  const title = "Facility Reports (Excel)"

  const panelists = {
    tracon: {
      name: "TRACON Reports",
      value: <TraconReportPanel/>
    },
    center: {
      name: "Center Reports",
      value: <CenterReportPanel/>
    },
    surface: {
      name: "Airport (ASDEX) Reports",
      value: <AirportReportPanel/>
    }
  }

  const atacFacilityReportsContext = useContext(ATACFacilityReportsContext)
  const {tabValue} = atacFacilityReportsContext
  const setTabValue = atacFacilityReportsContext.setTabValue

  const activateTabs = true

  const handleTabs = (event, newValue) => {
    // console.log("New tab value ", newValue)
    setTabValue(newValue)
  }

  return (
    <main className={classes.content}>          
      <div className={classes.toolbar} />
      <img className={classes.image} src={Banner}/>
        <Title title={title}/>
        <TabPanel activateTabs={activateTabs} panelists={panelists} tabValue={tabValue} handleTabs={handleTabs}/>
        <Panel activateTabs={activateTabs} panelists={panelists} tabValue={tabValue} handleTabs={handleTabs}/>
    </main>
  )
}

export default DownloadFacRep;
