import React, {useContext} from "react";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import StartDateContext from "../../Contexts/StartDate/startDateContext";
import Tooltip from "@material-ui/core/Tooltip";
import ProductAvailabilityContext from "../../Contexts/ProductAvailability/productAvailabilityContext";

const nasaBlue = '#0d47a1'
// const nasaBlue = '#01579b'
const nasaGray = '#eceff1'

const muiTheme = createMuiTheme({
    palette: {
        common: {
            blue: `${nasaBlue}`,
            lightGray: `${nasaGray}`,
            mediumGray: '#b0bec5',
            darkGray: '#90a4ae',
            white: '#fff',
            black: '#000'
        },
        primary: {
            main: `${nasaBlue}`
        },
        secondary: {
            main: '#fff'
        },
        text: {
            secondary: '#0277bd'
        },
        action: {
            hoverOpacity: 0
        }
    },
    typography: {
        fontSize: 15,
        button: {
            textTransform: 'capitalize'
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: '1.5rem',
        },
        h3: {
            fontWeight: 400,
            fontSize: '1.5rem',
        },
        h4: {
            fontWeight: 400,
            fontSize: '1.5rem',
        },
    }})

const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.common.blue,
        fontSize: '1.3em',
        paddingTop: theme.spacing(.75)
    },
    arrow: {
        color: theme.palette.primary.main
    },
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        maxWidth: 200,
        top: 20
    },
    popper: {
        zIndex: 100
    }
}));

function StartDateTime() {
    const classes = useStyles();
    const startDateContext = useContext(StartDateContext)
    const {currentDate, minDate, tooltip, tooltipMessage} = startDateContext
    const setStartDate = startDateContext.setStartDate
    const setCurrentDate = startDateContext.setCurrentDate
    const setMinDate = startDateContext.setMinDate
    const resetTooltip = startDateContext.resetTooltip
    const setTooltipMessage = startDateContext.setTooltipMessage
    const productAvailabilityContext = useContext(ProductAvailabilityContext)
    const {productAvailability} = productAvailabilityContext
    const getProductAvailability = productAvailabilityContext.getProductAvailability

    const handleTooltip = () => {
        let [minDate, maxDate] = getProductAvailability("ALL")
        console.log("Min date", minDate)
        if (minDate !== "" && maxDate !== "") {
            let availability = ["Select Start and End dates. Data Only Available from", minDate, "to", maxDate].join(" ")
            setTooltipMessage(availability)
            setMinDate(minDate)
        }
    }

    const handleClose = () => {
        resetTooltip()
    }

    const handleDate = (event, value) => {
        // console.log("Selected date", value)
        setCurrentDate(value)
        setStartDate(value)
        // handleClose()
    }

    React.useEffect(() => {
        handleTooltip()
    }, [productAvailability])

    return (
        <ThemeProvider theme={muiTheme}>
            <Tooltip title={tooltipMessage}
                     placement="top"
                     arrow
                     open={tooltip}
                     onClose={handleClose}
                     disableHoverListener
                     disableTouchListener
                     PopperProps={{
                         // style:{zIndex:100},
                         disablePortal: true,
                         popperOptions:{
                             modifiers: {
                                 offset: {
                                     enabled: true,
                                     offset: '100px '
                                 },
                             },
                             // positionFixed: true,
                         }
                     }}
                     classes={{arrow: classes.arrow, tooltip: classes.tooltip}}
            >
            <div style={{marginLeft:'16px'}} >
                <KeyboardDateTimePicker ampm={false}
                                        autoOk
                                        disableFuture
                                        value={currentDate}
                                        onChange={handleDate}
                                        // minuteStep={5}
                                        animateYearScrolling={true}
                                        disabledFuture
                                        label={'Start Date'}
                                        format="yyyy/MM/DD HH:mm"
                                        minDate={minDate}
                                        leftArrowButtonProps={{ "aria-label": "Prev month" }}
                                        rightArrowButtonProps={{ "aria-label": "Next month" }}
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: { root: classes.label }
                                        }}
                />

            </div>
            </Tooltip>
        </ThemeProvider>
    );
}

export default StartDateTime