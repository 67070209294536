import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CardDataCatalog from "./CardDataCatalog";
import CardFlightDataDownload from "./CardFlightDataDownload";
import CardDocumentation from "./CardDocumentation";
import CardDataAPI from "./CardDataAPI";
import ContentFooter from "./ContentFooter";
import Paper from "@material-ui/core/Paper";
import CardWeatherDataDownload from "./CardWeatherDataDownload";
import CardSDK from "./CardSDK";
import CardFuserDataDownload from "./CardFuserDataDownload";

const useStyles = makeStyles((theme) => ({
    leftPaper: {
        backgroundColor: "#f4f6f7",
        padding: 30
    }
}))

const Content = () => {
    const classes = useStyles()

    return (
        <Grid container alignItems="center" direction="column" spacing={5}>
            <Grid item>
                <Paper className={classes.leftPaper}>
                    <Grid container justify="center" spacing={3}>
                        <Grid item>
                            <CardFlightDataDownload/>
                        </Grid>
                        <Grid item>
                            <CardWeatherDataDownload/>
                        </Grid>
                        <Grid item>
                            <CardFuserDataDownload/>
                        </Grid>
                        <Grid item>
                            <CardDataCatalog/>
                        </Grid>
                        <Grid item>
                            <CardDocumentation/>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <CardDataAPI/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <CardFlightDataDownload/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={6}>*/}
                        {/*    <CardDataQuery/>*/}
                        {/*</Grid>*/}
                        <Grid item>
                            <CardSDK/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item>
                <ContentFooter/>
            </Grid>
        </Grid>
    )
}

export default Content