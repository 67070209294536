import React, {useReducer} from 'react'
import SideNavContext from './sideNavContext'
import SideNavReducer from './sideNavReducer'
import {
  CLOSE_ATAC_FLIGHT_MENU,
  CLOSE_FACILITY_REPORTS_MENU,
  CLOSE_WEATHER_MENU,
  HIGHLIGHT_AAL,
  HIGHLIGHT_ADDITIONAL_MENU,
  HIGHLIGHT_AIRCRAFT_REP,
  HIGHLIGHT_ASDI,
  HIGHLIGHT_ASM,
  HIGHLIGHT_ATAC_FLIGHT,
  HIGHLIGHT_ATAC_MENU,
  HIGHLIGHT_ATCSCC,
  HIGHLIGHT_ATIS,
  HIGHLIGHT_CENTER,
  HIGHLIGHT_EXELIS,
  HIGHLIGHT_FAC_EQUIP,
  HIGHLIGHT_FAC_REP,
  HIGHLIGHT_FAC_REP_MENU,
  HIGHLIGHT_FLIGHT, HIGHLIGHT_FUSER,
  HIGHLIGHT_METAR,
  HIGHLIGHT_OPSNET,
  HIGHLIGHT_OPTMA,
  HIGHLIGHT_SURFACE,
  HIGHLIGHT_SWIM,
  HIGHLIGHT_TAF,
  HIGHLIGHT_TBFM,
  HIGHLIGHT_TRACON,
  HIGHLIGHT_TRAFFIC,
  HIGHLIGHT_TRAFFIC_MENU,
  HIGHLIGHT_USA,
  HIGHLIGHT_WEATHER,
  HIGHLIGHT_WEATHER_MENU,
  HIGHLIGHT_WITI,
  HOVER_HIGHLIGHT_AAL_OPS,
  HOVER_HIGHLIGHT_AIR_REP,
  HOVER_HIGHLIGHT_ASDI,
  HOVER_HIGHLIGHT_ASM,
  HOVER_HIGHLIGHT_ATAC_FLIGHT,
  HOVER_HIGHLIGHT_ATCSCC,
  HOVER_HIGHLIGHT_ATIS,
  HOVER_HIGHLIGHT_CENTER,
  HOVER_HIGHLIGHT_FAC_EQUIP,
  HOVER_HIGHLIGHT_FAC_REP, HOVER_HIGHLIGHT_FUSER,
  HOVER_HIGHLIGHT_METAR,
  HOVER_HIGHLIGHT_OPSNET,
  HOVER_HIGHLIGHT_OPTMA,
  HOVER_HIGHLIGHT_SURFACE,
  HOVER_HIGHLIGHT_TAF,
  HOVER_HIGHLIGHT_TBFM,
  HOVER_HIGHLIGHT_TRACON,
  HOVER_HIGHLIGHT_USA, HOVER_HIGHLIGHT_WEATHER,
  HOVER_HIGHLIGHT_WITI,
  OPEN_ADDITIONAL_MENU,
  OPEN_ATAC_FLIGHT_MENU,
  OPEN_FACILITY_REPORTS_MENU,
  OPEN_TRAFFIC_MGMT_MENU,
  OPEN_WEATHER_MENU,
  RESET_ADDITIONAL,
  RESET_ATAC_DOWNLOAD,
  RESET_DATE_WIDGET,
  RESET_DOWNLOAD_MENU,
  RESET_FACILITY_REPORTS_MENU,
  RESET_LEFT_MENU,
  RESET_TRAFFIC_MGMT_MENU,
  RESET_WEATHER_MENU,
  SET_CHARTS_MENU,
  SET_DATE_WIDGET,
  SET_DOWNLOAD_MENU,
  SET_MAP_MENU,
  TOGGLE_ADDITIONAL_MENU,
  TOGGLE_ATAC_FLIGHT_MENU,
  TOGGLE_FACILITY_REPORTS_MENU,
  TOGGLE_SIDENAV,
  TOGGLE_TRAFFIC_MGMT_MENU,
  TOGGLE_WEATHER_MENU,
  UNHIGHLIGHT_AAL_OPS,
  UNHIGHLIGHT_ADDITIONAL_MENU,
  UNHIGHLIGHT_AIR_REP,
  UNHIGHLIGHT_ASDI,
  UNHIGHLIGHT_ASM,
  UNHIGHLIGHT_ATAC_EXELIS,
  UNHIGHLIGHT_ATAC_FLIGHT,
  UNHIGHLIGHT_ATAC_MENU,
  UNHIGHLIGHT_ATCSCC,
  UNHIGHLIGHT_ATIS,
  UNHIGHLIGHT_CENTER,
  UNHIGHLIGHT_FAC_EQUIP,
  UNHIGHLIGHT_FAC_REP,
  UNHIGHLIGHT_FAC_REP_MENU, UNHIGHLIGHT_FUSER,
  UNHIGHLIGHT_METAR,
  UNHIGHLIGHT_OPSNET,
  UNHIGHLIGHT_OPTMA,
  UNHIGHLIGHT_SURFACE,
  UNHIGHLIGHT_TAF,
  UNHIGHLIGHT_TBFM,
  UNHIGHLIGHT_TRACON,
  UNHIGHLIGHT_TRAFFIC_MENU,
  UNHIGHLIGHT_USA, UNHIGHLIGHT_WEATHER,
  UNHIGHLIGHT_WEATHER_MENU,
  UNHIGHLIGHT_WITI
} from '../types'


const SideNavState = props => {
  const initialState = {
    mobileOpen: false,
    leftMenu: '',
    dateWidget: false,
    additionalMenuState:      false,
    atacFlightMenuState:      false,
    facilityReportsMenuState: false,
    trafficMgmtMenuState:     false,
    weatherMenuState:         false,
    aalFont:            'caption',
    additionalMenuFont: 'body1',
    aircraftRepFont:    'caption',
    asdiFont:           'caption',
    asmFont:            'caption',
    atacFlightFont:     'body1',
    atacMenuFont:       'body1',
    atcsccFont:         'caption',
    atisFont:           'caption',
    centerFont:         'caption',
    color:              'primary',
    exelisFont:         'body1',
    facEquipFont:       'caption',
    facRepFont:         'body1',
    facRepMenuFont:     'body1',
    flightFont:         'body1',
    menuItemFont:       'body1',
    metarFont:          'caption',
    opsnetFont:         'caption',
    opTmaFont:          'caption',
    surfaceFont:        'caption',
    swimFont:           'body1',
    tafFont:            'caption',
    tbfmFont:           'caption',
    traconFont:         'caption',
    trafficMenuFont:    'body1',
    usaFont:            'caption',
    weatherFont:        'body1',
    weatherMenuFont:    'body1',
    witiFont:           'caption',
    fuserFont:          'body1',
  }

  const [state, dispatch] = useReducer(SideNavReducer, initialState);

  const setDateWidget = () => (
      dispatch({
        type: SET_DATE_WIDGET
      })
  )

  const resetDateWidget = () => (
      dispatch({
        type: RESET_DATE_WIDGET
      })
  )
  
  const closeFacilityReportsMenu = () => (
    dispatch({
      type: CLOSE_FACILITY_REPORTS_MENU
    })
  )

  const closeAtacFlightMenu = () => (
    dispatch({
      type: CLOSE_ATAC_FLIGHT_MENU
    })
  )

  const closeWeatherMenu = () => (
    dispatch({
      type: CLOSE_WEATHER_MENU
    })
  )

  const highlightAdditionalMenu = () => (
    dispatch({
      type: HIGHLIGHT_ADDITIONAL_MENU
    })
  )

  const highlightAtacMenu = () => (
    dispatch({
      type: HIGHLIGHT_ATAC_MENU
    })
  )

  const highlightUSA = () => (
    dispatch({
      type: HIGHLIGHT_USA
    })
  )

  const highlightSurface = () => (
    dispatch({
      type: HIGHLIGHT_SURFACE
    })
  )

  const highlightAtis = () => (
    dispatch({
      type: HIGHLIGHT_ATIS
    })
  )

  const highlightAsdi = () => (
    dispatch({
      type: HIGHLIGHT_ASDI
    })
  )

  const highlightCenter = () => (
    dispatch({
      type: HIGHLIGHT_CENTER
    })
  )

  const highlightFacEquip = () => (
    dispatch({
      type: HIGHLIGHT_FAC_EQUIP
    })
  )
  const highlightTracon = () => (
    dispatch({
      type: HIGHLIGHT_TRACON
    })
  )

  const highlightTbfm = () => (
    dispatch({
      type: HIGHLIGHT_TBFM
    })
  )
  const highlightOpsNet = () => (
    dispatch({
      type: HIGHLIGHT_OPSNET
    })
  )

  const highlightAal = () => {
    dispatch({
      type: HIGHLIGHT_AAL
    })
  }

  const highlightMetar = () => {
    dispatch({
      type: HIGHLIGHT_METAR
    })
  }

  const highlightTaf = () => {
    dispatch({
      type: HIGHLIGHT_TAF
    })
  }

  const highlightWiti = () => {
    dispatch({
      type: HIGHLIGHT_WITI
    })
  }

  const highlightAircraftRep = () => {
    dispatch({
      type: HIGHLIGHT_AIRCRAFT_REP
    })
  }

  const highlightAsm = () => {
    dispatch({
      type: HIGHLIGHT_ASM
    })
  }

  const highlightAtacFlight = () => {
    dispatch({
      type: HIGHLIGHT_ATAC_FLIGHT
    })
  }

  const highlightAtcscc = () => {
    dispatch({
      type: HIGHLIGHT_ATCSCC
    })
  }

  const highlightOpTma = () => {
    dispatch({
      type: HIGHLIGHT_OPTMA
    })
  }

  const highlightExelis = () => {
    dispatch({
      type: HIGHLIGHT_EXELIS
    })
  }
  const highlightWeather = ()  => (
    dispatch({
      type: HIGHLIGHT_WEATHER
    })
  )

  const highlightFlight = ()  => (
    dispatch({
      type: HIGHLIGHT_FLIGHT
    })
  )

  const highlightFacRep = ()  => (
    dispatch({
      type: HIGHLIGHT_FAC_REP
    })
  )
  
  const highlightTraffic = ()  => (
    dispatch({
      type: HIGHLIGHT_TRAFFIC
    })
  )

  const highlightTrafficMenu = ()  => (
    dispatch({
      type: HIGHLIGHT_TRAFFIC_MENU
    })
  )

  const highlightSwim = ()  => (
    dispatch({
      type: HIGHLIGHT_SWIM
    })
  )

  const highlightWeatherMenu = () => {
    dispatch({
      type: HIGHLIGHT_WEATHER_MENU
    })
  }

  const highlightFacRepMenu = () => (
    dispatch({
      type: HIGHLIGHT_FAC_REP_MENU
    })
  )

  const hoverHighlightAirRep = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_AIR_REP
    })
  }

  const hoverHighlightAsm = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_ASM
    })
  }

  const hoverHighlightMetar = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_METAR
    })
  }

  const hoverHighlightTaf = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_TAF
    })
  }

  const hoverHighlightWeather = ()  => (
      dispatch({
        type: HOVER_HIGHLIGHT_WEATHER
      })
  )

  const hoverHighlightWiti = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_WITI
    })
  }

  const hoverHighlightAalOps = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_AAL_OPS
    })
  }

  const hoverHighlightOpsnet = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_OPSNET
    })
  }

  const hoverHighlightAtcscc = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_ATCSCC
    })
  }

  const hoverHighlightTbfm = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_TBFM
    })
  }

  const hoverHighlightOpTma = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_OPTMA
    })
  }

  const hoverHighlightFacEquip = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_FAC_EQUIP
    })
  }

  const hoverHighlightFacRep = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_FAC_REP
    })
  }

  const hoverHighlightAsdi = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_ASDI
    })
  }

  const hoverHighlightAtis = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_ATIS
    })
  }

  const hoverHighlightUSA = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_USA
    })
  }

  const hoverHighlightSurface = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_SURFACE
    })
  }

  const hoverHighlightTracon = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_TRACON
    })
  }

  const hoverHighlightCenter = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_CENTER
    })
  }

  const hoverHighlightAtacFlight = () => {
    dispatch({
      type: HOVER_HIGHLIGHT_ATAC_FLIGHT
    })
  }

  const openFacilityReportsMenu = () => (
    dispatch({
      type: OPEN_FACILITY_REPORTS_MENU
    })
  )
  
  const openAdditionalMenu = () => {
    dispatch({
      type: OPEN_ADDITIONAL_MENU
    })
  }

  const openAtacFlightMenu = () => (
    dispatch({
      type: OPEN_ATAC_FLIGHT_MENU
    })
  )

  const openTrafficMgmtMenu = ()  => (
    dispatch({
      type: OPEN_TRAFFIC_MGMT_MENU
    })
  )

  const openWeatherMenu = () => (
    dispatch({
      type: OPEN_WEATHER_MENU
    })
  )

  const resetLeftMenu = () => (
      dispatch({
        type: RESET_LEFT_MENU
      })
  )

  const resetTrafficMgmtMenu = ()  => (
    dispatch({
      type: RESET_TRAFFIC_MGMT_MENU
    })
  ) 

  const resetAdditional = () => (
    dispatch({
      type: RESET_ADDITIONAL
    })
  )

  const resetAtacDownload = () => (
    dispatch({
      type: RESET_ATAC_DOWNLOAD
    })
  )

  const resetFacilityReportsMenu = () => {
    dispatch({
      type: RESET_FACILITY_REPORTS_MENU
    })
  }

  const resetWeatherMenu = () => (
    dispatch({
      type: RESET_WEATHER_MENU
    })
  )
  
  const resetDownloadMenu = () => (
    dispatch({
      type: RESET_DOWNLOAD_MENU
    })
  )

  const setDownloadMenu = () => (
    dispatch({
      type: SET_DOWNLOAD_MENU
    })
  )

  const setChartsMenu = () => (
    dispatch({
      type: SET_CHARTS_MENU
    })
  )

  const setMapMenu = () => (
    dispatch({
      type: SET_MAP_MENU
    })
  )

  const toggleAtacFlightMenu = () => (
    dispatch({
      type: TOGGLE_ATAC_FLIGHT_MENU
    })
  )

  const toggleSideNav = () => (
    dispatch({
      type: TOGGLE_SIDENAV
    })
  )

  const toggleWeatherMenu = () => (
    dispatch({
      type: TOGGLE_WEATHER_MENU
    })
  )

  const toggleTrafficMgmtMenu = () => (
    dispatch({
      type: TOGGLE_TRAFFIC_MGMT_MENU
    })
  )

  const toggleFacilityReportsMenu = () => (
    dispatch({
      type: TOGGLE_FACILITY_REPORTS_MENU
    })
  )

  const toggleAdditionalMenu = () => (
    dispatch({
      type: TOGGLE_ADDITIONAL_MENU
    })
  )
  
  const unhighlightAdditionalMenu = () => (
    dispatch({
      type: UNHIGHLIGHT_ADDITIONAL_MENU
    })
  )

  const unhighlightAirRep = () => (
    dispatch({
      type: UNHIGHLIGHT_AIR_REP
    })
  )

  const unhighlightAsm = () => (
    dispatch({
      type: UNHIGHLIGHT_ASM
    })
  )

  const unhighlightAtacMenu = () => (
    dispatch({
      type: UNHIGHLIGHT_ATAC_MENU
    })
  ) 

  const unhighlightAtacExelis = () => {
    dispatch({
      type: UNHIGHLIGHT_ATAC_EXELIS
    })
  }

  const unhighlightFacRep = () => {
    dispatch({
      type: UNHIGHLIGHT_FAC_REP
    })
  }

  const unhighlightFacRepMenu = () => {
    dispatch({ 
      type: UNHIGHLIGHT_FAC_REP_MENU
    })
  }

  const unhighlightMetar = () => (
    dispatch({
      type: UNHIGHLIGHT_METAR
    })
  )

  const unhighlightTaf = () => (
    dispatch({
      type: UNHIGHLIGHT_TAF
    })
  )

  const unhighlightTrafficMenu = () => {
    dispatch({
      type: UNHIGHLIGHT_TRAFFIC_MENU
    })
  }

  const unhighlightWeather = ()  => (
      dispatch({
        type: UNHIGHLIGHT_WEATHER
      })
  )

  const unhighlightWeatherMenu = () => {
    dispatch({
      type: UNHIGHLIGHT_WEATHER_MENU
    })
  }

  const unhighlightWiti = () => (
    dispatch({
      type: UNHIGHLIGHT_WITI
    })
  )

  const unhighlightAalOps = () => (
    dispatch({
      type: UNHIGHLIGHT_AAL_OPS
    })
  )

  const unhighlightOpsnet = () => (
    dispatch({
      type: UNHIGHLIGHT_OPSNET
    })
  )

  const unhighlightAtcscc = () => (
    dispatch({
      type: UNHIGHLIGHT_ATCSCC
    })
  )

  const unhighlightTbfm = () => (
    dispatch({
      type: UNHIGHLIGHT_TBFM
    })
  )

  const unhighlightOpTma = () => (
    dispatch({
      type: UNHIGHLIGHT_OPTMA
    })
  )

  const unhighlightFacEquip = () => (
    dispatch({
      type: UNHIGHLIGHT_FAC_EQUIP
    })
  )

  const unhighlightAsdi = () => (
    dispatch({
      type: UNHIGHLIGHT_ASDI
    })
  )

  const unhighlightAtis = () => (
    dispatch({
      type: UNHIGHLIGHT_ATIS
    })
  )

  const unhighlightUSA = () => (
    dispatch({
      type: UNHIGHLIGHT_USA
    })
  )

  const unhighlightSurface = () => (
    dispatch({
      type: UNHIGHLIGHT_SURFACE
    })
  )

  const unhighlightTracon = () => (
    dispatch({
      type: UNHIGHLIGHT_TRACON
    })
  )

  const unhighlightCenter = () => (
    dispatch({
      type: UNHIGHLIGHT_CENTER
    })
  )

  const unhighlightAtacFlight = () => (
      dispatch({
        type: UNHIGHLIGHT_ATAC_FLIGHT
      })
  )

  const highlightFuser = () => (
      dispatch({
        type: HIGHLIGHT_FUSER
      })
  )

  const hoverHighlightFuser = () => (
      dispatch({
        type: HOVER_HIGHLIGHT_FUSER
      })
  )

  const unhighlightFuser = () => (
      dispatch({
        type: UNHIGHLIGHT_FUSER
      })
  )

  return <SideNavContext.Provider 
    value = {{
      dateWidget:           state.dateWidget,
      aalFont:              state.aalFont,
      additionalFont:       state.additionalFont,
      additionalMenuFont:   state.additionalMenuFont,
      additionalMenuState:  state.additionalMenuState,
      asdiFont:             state.asdiFont,
      atacMenuFont:         state.atacMenuFont,
      atacFlightMenuState:  state.atacFlightMenuState,
      atcsccFont:           state.atcsccFont,
      atisFont:             state.atisFont,
      aircraftRepFont:      state.aircraftRepFont,
      asmFont:              state.asmFont,
      atacFlightFont:       state.atacFlightFont,
      centerFont:           state.centerFont,
      color:                'primary',
      exelisFont:           state.exelisFont,
      facRepFont:           state.facRepFont,
      facRepMenuFont:       state.facRepMenuFont,
      facilityReportsMenuState:  state.facilityReportsMenuState,
      facEquipFont:         state.facEquipFont,
      flightFont:           state.flightFont,
      leftMenu:             state.leftMenu,
      metarFont:            state.metarFont,
      mobileOpen:           state.mobileOpen,
      opsnetFont:           state.opsnetFont,
      opTmaFont:            state.opTmaFont,
      swimFont:             state.swimFont,
      surfaceFont:          state.surfaceFont,
      trafficMgmtMenuState: state.trafficMgmtMenuState,
      trafficFont:          state.trafficFont,
      trafficMenuFont:      state.trafficMenuFont,
      traconFont:           state.traconFont,
      tafFont:              state.tafFont,
      tbfmFont:             state.tbfmFont,
      usaFont:              state.usaFont,
      weatherFont:          state.weatherFont,
      weatherMenuState:     state.weatherMenuState,
      weatherMenuFont:      state.weatherMenuFont,
      witiFont:             state.witiFont,
      fuserFont:            state.fuserFont,
      setDateWidget,
      resetDateWidget,
      // AAL OPS ANALYSIS
      highlightAal,
      // Additional Data Menu
      toggleAdditionalMenu,
      openAdditionalMenu,
      highlightAdditionalMenu,
      unhighlightAdditionalMenu,
      hoverHighlightFacEquip,
      hoverHighlightAsdi,
      hoverHighlightAtis,
      // Aircraft Reports
      highlightAircraftRep,
      // ATAC
      highlightAtacFlight,
      highlightCenter,
      highlightSurface,
      highlightTracon,
      highlightUSA,
      unhighlightUSA,
      unhighlightSurface,
      unhighlightTracon,
      unhighlightCenter,
      unhighlightAtacFlight,
      toggleAtacFlightMenu,
      openAtacFlightMenu,
      closeAtacFlightMenu,
      resetAtacDownload,
      highlightAtacMenu,
      unhighlightAtacMenu,
      hoverHighlightUSA,
      hoverHighlightSurface,
      hoverHighlightTracon,
      hoverHighlightCenter,
      hoverHighlightAtacFlight,
      // Charts Menu
      setChartsMenu,
      // Download Menu
      highlightSwim,
      resetDownloadMenu,
      setDownloadMenu,
      // Exelis
      highlightExelis,
      unhighlightAtacExelis,
      setMapMenu,
      // Facility Reports
      toggleFacilityReportsMenu,
      openFacilityReportsMenu,
      closeFacilityReportsMenu,
      resetFacilityReportsMenu,
      highlightFacRep,
      highlightFacRepMenu,
      hoverHighlightFacRep,
      unhighlightFacRep,
      unhighlightFacRepMenu,
      unhighlightAalOps,
      unhighlightOpsnet,
      highlightFlight,
      toggleSideNav,
      hoverHighlightAalOps,
      hoverHighlightOpsnet,
      // Traffic Management
      highlightTraffic,
      highlightTbfm,
      toggleTrafficMgmtMenu,
      openTrafficMgmtMenu,
      resetTrafficMgmtMenu,
      highlightTrafficMenu,
      unhighlightTrafficMenu,
      unhighlightAtcscc,
      unhighlightTbfm,
      unhighlightOpTma,
      highlightAtcscc,
      highlightOpTma,
      hoverHighlightAtcscc,
      hoverHighlightTbfm,
      hoverHighlightOpTma,
      // Weather
      highlightAsm,
      highlightMetar,
      highlightOpsNet,
      highlightTaf,
      highlightWeather,
      highlightWiti,
      hoverHighlightAsm,
      hoverHighlightAirRep,
      hoverHighlightMetar,
      hoverHighlightTaf,
      hoverHighlightWeather,
      hoverHighlightWiti,
      toggleWeatherMenu,
      openWeatherMenu,
      closeWeatherMenu,
      resetWeatherMenu,
      unhighlightWeatherMenu,
      highlightWeatherMenu,
      unhighlightAsm,
      unhighlightAirRep,
      unhighlightMetar,
      unhighlightTaf,
      unhighlightWeather,
      unhighlightWiti,
      //Additional Menu
      highlightAsdi,
      highlightAtis,
      highlightFacEquip,
      unhighlightFacEquip,
      unhighlightAsdi,
      unhighlightAtis,
      resetAdditional,
      resetLeftMenu,
      highlightFuser,
      hoverHighlightFuser,
      unhighlightFuser
  }}>
  {props.children}
  </SideNavContext.Provider>
}

export default SideNavState