import React, {useContext} from 'react'
import UserContext from "../../../Contexts/User/userContext";
import FeedbackContext from "../../../Contexts/Feedback/feedbackContext";
import Grid from "@material-ui/core/Grid";
import SessionDialog from "../../Modals/SessionDialog";
import InputDialog from "../../Modals/InputDialog";
import ServiceDialog from "../../Modals/ServiceDialog";
import SendDialog from "../../Modals/SendDialog";

const FeedbackDialog = () => {
    const userContext = useContext(UserContext)
    const {user} = userContext
    const feedbackContext = useContext(FeedbackContext)
    const {category, subject, message, clicked, sent, available, setClicked, setLoading, setAvailable} = feedbackContext
    let loggedIn = user.auid !== undefined
    let selection = category !== null
    let input = subject !== "" && message !== ""
    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {
        if (clicked)
            if (selection && input)
                setLoading(true)
            else
                setOpen(true)
    }, [clicked, category, subject, message])

    React.useEffect(() => {
        if (!open)
            setClicked(false)
    }, [open])


    return (
        clicked ?
            <Grid>
                <SessionDialog/>
                {loggedIn && <InputDialog open={open} setOpen={setOpen}/>}
                <ServiceDialog available={!available} setAvailable={setAvailable}/>
            </Grid>
            :
            <SendDialog sent={sent}/>
    )
}

export default FeedbackDialog