import React, {useReducer} from 'react'
import ATACTraconReducer from "../ATACTracon/atacTraconReducer";
import ATACTraconContext from "../ATACTracon/atacTraconContext";
import {
    SET_CANCEL_TOKEN_SOURCE,
    SET_CLICKED,
    SET_DATA_PRODUCTS,
    SET_FACILITIES,
    SET_FILES, SET_PROGRESS,
    SET_TABLE_LOADING
} from "../types";
import traconData from "../../components/Data/traconData";
import axios from 'axios';

const ATACTraconState = props => {
    const initialState = {
        dataProducts: {
            trackPoints: {
                name: "trackPoints",
                label: "Track Points (IFF)",
                checked: false,
                value: "IFF"
            },
            flightEvents: {
                name: "flightEvents",
                label: "Flight Events (EV)",
                checked: false,
                value: "EV"
            },
            flightSummary: {
                name: "flightSummary",
                label: "Flight Summary (RD)",
                checked: false,
                value: "RD"
            }
        },
        facilities: {
            name: "TRACON",
            value: traconData
        },
        clicked: false,
        tableLoading: false,
        files: [],
        progress: 0,
        cancelTokenSource: axios.CancelToken.source()
    }

    const [state, dispatch] = useReducer(ATACTraconReducer, initialState);

    const setDataProducts = (dataProducts) => {
        dispatch({
            type: SET_DATA_PRODUCTS,
            dataProducts: dataProducts
        })
    }

    const setFacilities = (facilities) => {
        dispatch({
            type: SET_FACILITIES,
            facilities: facilities
        })
    }

    const setClicked = () => {
        dispatch({
            type: SET_CLICKED
        })
    }

    const setTableLoading = (tableLoading) => {
        dispatch({
            type: SET_TABLE_LOADING,
            tableLoading: tableLoading
        })
    }

    const setFiles = (files) => {
        dispatch({
            type: SET_FILES,
            files: files
        })
    }

    const setProgress = (progress) => {
        dispatch({
            type: SET_PROGRESS,
            progress: progress
        })
    }

    const resetProgress = () => {
        dispatch({
            type: SET_PROGRESS,
            progress: 0
        })
    }

    const resetCancelToken = () => {
        dispatch({
            type: SET_CANCEL_TOKEN_SOURCE,
            cancelTokenSource: axios.CancelToken.source()
        })
    }

    return <ATACTraconContext.Provider
        value = {{
            dataProducts: state.dataProducts,
            facilities: state.facilities,
            clicked: state.clicked,
            tableLoading: state.tableLoading,
            files: state.files,
            progress: state.progress,
            cancelTokenSource: state.cancelTokenSource,
            setDataProducts,
            setFacilities,
            setClicked,
            setTableLoading,
            setFiles,
            setProgress,
            resetProgress,
            resetCancelToken
        }}>
        {props.children}
    </ATACTraconContext.Provider>
}

export default ATACTraconState