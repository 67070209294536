import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Prompt from "../Titles/Prompt";

const drawerWidth = 245;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.up('md')]: {
      marginLeft: `${drawerWidth}px`,
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(10),
      marginRight: theme.spacing(1),
      padding: 0,
      width: '100%',
    },
  },
  paper: {
    backgroundColor: "#fff",
    padding: 50,
    [theme.breakpoints.up('md')]: {
      marginRight: '25%',
    },
  },
  font: {
    fontWeight: 500
  },
  fontHighlight: {
    color: theme.palette.primary.main
  },
  toolbar: theme.mixins.toolbar,
}));

function DownloadHome() {

  const classes = useStyles();
  const pageHeader = "File Download"

  const highlightFont = (font) => (
      <subtitle className={classes.fontHighlight}>
      {font}
      </subtitle>
  )

  const fileDownloadPage = (
      <p className={classes.font} align="justify">
        File Download page displays dates when files are available as well as unavailable.
        Users can search reports to determine the quality (e.g. Complete vs. Incomplete) of the data sources on the dates that they inquire and download available files on the same page directly.
      </p>
  )

  const fileSearch = (
      <p className={classes.font} align="justify">
        Users could look for complete USA data by selecting {highlightFont("Integrated Facilities")} or refine their search for facility specific sources by selecting any one of the {highlightFont("Individual Facilities")} links to the left.
      </p>
  )

  const integratedFacilities = (
      <p className={classes.font} align="justify">
        Sherlock provides integrated track and flight plan data starting from 2020-01-01.
        The integrated USA product includes all 20 Centers, 26 TRACONS, and Surface data for 30 airports to deliver an end-to-end view of the data.
      </p>
  )

  const individualFacilities = (
      <p className={classes.font} align="justify">
        Sherlock has also collected individual facility data for Centers, TRACONS, and surface ASDE-X data that is available in binary format on the file system.
        The individual facilities have end-to-end trajectories, flight plans, metadata, and performance reports that are produced daily.
      </p>
  )

  const weather = (
      <p className={classes.font} align="justify">
        Sherlock houses weather data that provide users access to local weather for aviation use ({highlightFont("METAR")}), advises of weather, other than convective activity, that is potentially hazardous to all aircraft ({highlightFont("ASM")}), and {highlightFont("TAF")} reports that are critical for flight planning and for aircraft movement within the National Airspace System (NAS).
      </p>
  )

  return (
    <main className={classes.content}>          
      <div className={classes.toolbar} />
        <Paper className={classes.paper} elevation={0}>
          <Prompt pageHeader={pageHeader}/>
          {/*<p className={classes.font} align="justify">The data stored in Sherlock's file archive, either in real time or one-two days later, after the data has been collected. The /home/data directory is visible throughout the ATM Linux network. The first level of subdirectories is the type of data. Below each subdirectory, the data are paritioned by year, month, and date. Then within the date directory are the data files themselves. The date directory to which the files are assigned is the GMT time that the file was started. That is, if a file is started at 7 pm Pacific time, it will be in the date directory for the next day, since 7 pm is past midnight GMT.</p>*/}
          {/*<p className={classes.font} align="justify">At the file level, a set of file naming rules is applied to the files. The terms include the time stamp of the file, the type of data, and metadata parameters such as duration, source, adaptation, or cycle, as applicable. This information allows the file to have a context independent of the directory structure and provides basic metadata for the database-driven portion of the warehouse.</p>*/}
          {fileDownloadPage}
          {weather}
          {fileSearch}
          {integratedFacilities}
          {individualFacilities}
        </Paper>
      </main>
  )
}

export default DownloadHome
