import React, {useState} from 'react';
import MUIDataTable from 'mui-datatables';
import {makeStyles, ThemeProvider} from "@material-ui/core/styles";
import CustomUserCell from "./CustomUserCell";
import {AuditTableTheme} from "../../Themes/AuditTableTheme";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    stackedCommonEdit: {
        zIndex: '1',
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block',
            height: 'auto',
            width: 'calc(100%)',
            boxSizing: 'border-box',
        }
    },
    grid: {
        marginLeft: 60,
        whiteSpace: 'nowrap'
    },
    heading: {
        fontWeight: 700,
        padding: 10
    }
}))

const AuditTable = (props) => {
    const classes = useStyles()
    const {files, columns, columnNames} = props

    const auditColumnNames = [
        {name: "User ID", options: {customBodyRender: value => <CustomUserCell value={value}/>}},
        {name: "Timestamp"},
        ...columnNames
    ]

    const [rowsSelected, setRowsSelected] = useState([])

    const data = files
        .filter(item => item && Object.keys(item).length > 0)
        .map(item =>{ return (columns.map(col => item[col]))})

    const options = {
        filterType: "dropdown",
        selectableRows: "none",
        rowsSelected: rowsSelected,
        onRowsSelect: (rowsSelected, allRows) => {
            console.log("All rows", allRows)
            setRowsSelected(allRows.map(row => row.dataIndex))
        },
        search: false,
        download: false,
        print: false,
        filter: false,
        viewColumns: false,
        responsive: "scrollMaxHeight"
    }

    return (
        <ThemeProvider theme={AuditTableTheme}>
            <Grid container>
                <MUIDataTable
                    className={classes.stackedCommonEdit}
                    data={data}
                    columns={auditColumnNames}
                    options={options}
                />
            </Grid>
        </ThemeProvider>
    )
}
export default AuditTable