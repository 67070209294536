import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import GetAppSharp from '@material-ui/icons/GetAppSharp';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SideNavContext from '../../Contexts/SideNav/sideNavContext'
import TopNavContext from '../../Contexts/TopNav/topNavContext'
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {basename} from "../Data/api";

const theme = createMuiTheme({
  overrides: {
    MuiToolbar: {
      dense: {
        minHeight: 30,
      },
    },
  },
  typography: {
    fontSize: 16,
    subtitle1: {
      fontWeight: 500,
      fontSize: '1.2rem',
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '1.2rem'
    },
  },
  palette: {
    common: {
      blue: '#0d47a1',
      lightGray: '#cfd8dc',
      mediumGray: '#b0bec5',
      darkGray: '#90a4ae',
      white: '#fff',
      black: '#000'
    },
    primary: {
      main: '#0d47a1'
    },
    secondary: {
      main: '#fff'
    },
    text: {
      secondary: '#0277bd'
    }
  },
  MuiButton: {
    root: {
      padding: '16px'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: { 
      zIndex: 1199,
      boxShadow: 'none',
      marginRight: '0%',
      marginTop: '3.8em',
      padding: 7,
      width: '100%',
      background: 'linear-gradient(180deg, #eceff1 25%, #b0bec5 90%)'
      // background: 'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)',
    },
    [theme.breakpoints.only('xs')]: { 
      zIndex: 1199,
      boxShadow: 'none',
      marginRight: '0%',
      marginTop: '9em',
      width: '100%',
      background: 'linear-gradient(180deg, #eceff1 25%, #b0bec5 90%)'
    },
  },
  NavLink: {
    marginTop: theme.spacing(-1.25),
    paddingBottom: theme.spacing(1.2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: "capitalize",
    // backgroundColor: "#5b93bf",
    color: "#fff"
  },
  topNavButton: {   
    marginRight: theme.spacing(-3), 
    paddingTop: '0px',
    paddingBottom: '0px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.only('xs')]: {
      // display: 'none',
      color: 'primary',
      paddingTop: 0,
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      marginLeft: theme.spacing(0),
      paddingRight: theme.spacing(3),
    }
  },
  menuButton: {
    marginLeft: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  topNavIcon: {
    [theme.breakpoints.only('sm')]: {
    fontSize: '35px' ,
    marginBottom: theme.spacing(-1),
    marginRight: theme.spacing(0),
    color: '#0d47a1'
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: 0,
      marginBottom: 0,
      fontSize: '25px',
      color: '#0d47a1'
    }
  },
  topNavIconLabels: {
    fontSize: 12,
    marginRight: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      fontSize: '16px',
      lineHeight: '100%',
    }
  },
  removeUnderline: {
    textDecoration: 'none',
    height: '25px',
  },
  extraSpace: {
    // This is the line that pushs down the main content
    // so the nav bar doesn't cover it.
    margin: '40px'
  },
  verticalLine: {
    backgroundColor: '#fff',
    marginLeft: 1.4,
    marginRight: 1.4
  }
}));

function TopNav() {
  const classes = useStyles() 
  const sideNavContext = useContext(SideNavContext)
  const toggleSideNav = sideNavContext.toggleSideNav
  const setDownloadMenu = sideNavContext.setDownloadMenu
  // const setChartsMenu = sideNavContext.setChartsMenu

  const topNavContext = useContext(TopNavContext)

  const menuIcon = topNavContext.menuIcon
  const setMenuIconOn = topNavContext.setMenuIconOn
  // const setMenuIconOff = topNavContext.setMenuIconOff
  const {downloadFontColor} = topNavContext
  const highlightTopNavDownloadFont = topNavContext.highlightTopNavDownloadFont
  const unhighlightTopNavDownloadFont = topNavContext.unhighlightTopNavDownloadFont

  const setDateWidget = sideNavContext.setDateWidget
  // const resetDateWidget = sideNavContext.resetDateWidget


  let downloadHover
  let downloadBorder
  

  if(window.location.pathname.includes('Download')) {
    downloadBorder = true
    downloadHover = highlightTopNavDownloadFont
  } else {
    downloadBorder = false
    downloadHover = unhighlightTopNavDownloadFont
  }


  const handleDownloadMenu = () => {
    setDownloadMenu()
    setDateWidget()
  }


  React.useEffect(() => {
    if(window.location.pathname.includes('Download'))
      (async () => await highlightTopNavDownloadFont())();
  }, []);


  async function downloadIconClickMethod() {
    await Promise.all([ highlightTopNavDownloadFont(), setDownloadMenu()])
    return 'Done!';}

  return (
    <ThemeProvider theme={theme}>
    <AppBar position="fixed" className={classes.appBar}>
      <Grid container alignItems='center'>
        <Grid item lg={2} md={1} sm={1} xs={2}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSideNav}
            className={classes.menuButton}
            >
            {menuIcon}
          </IconButton>
        </Grid>
        <Grid item lg={10} md={11} sm={11} xs={10} >
          <Toolbar variant={'dense'} disableGutters>
            <Hidden xsDown>
              <Link to={'/DownloadHome'} className={classes.removeUnderline} >
                <Button style={{color:downloadFontColor}}
                        className={classes.NavLink}
                        // onMouseOver={hoverHighlightTopNavDownloadButton}
                        onMouseLeave={downloadHover}
                        onClick={handleDownloadMenu}>
                  <Typography onClick={highlightTopNavDownloadFont}>
                    File Download
                  </Typography>
                </Button>
                <div style={{borderBottomStyle: 'solid', borderWidth: downloadBorder ? '5px' : '0px', borderColor: downloadBorder && downloadFontColor,marginTop: downloadBorder ? -4 : 0}}/>
              </Link>
              {/*<Divider className={classes.verticalLine} orientation="vertical" flexItem />*/}
              {/*<Link to='/ChartsHome' className={classes.removeUnderline}>*/}
              {/*  <Button  style={{color:chartsFontColor}}*/}
              {/*           className={classes.NavLink}*/}
              {/*           // onMouseOver={hoverHighlightTopNavChartsButton}*/}
              {/*           onMouseLeave={chartsHover}*/}
              {/*           onClick={handleChartsMenu} >*/}
              {/*    <Typography onClick={highlightTopNavChartsFont}>*/}
              {/*      Data Warehouse*/}
              {/*    </Typography>*/}
              {/*  </Button>*/}
              {/*  <div style={{borderBottomStyle: 'solid', borderWidth: chartsBorder ? '5px' : '0px', borderColor: chartsBorder && chartsFontColor,marginTop: chartsBorder ? -4 : 0}}/>*/}
              {/*</Link>*/}
              {/* <Link to='/MapHome' className={classes.removeUnderline}>
                <Button  color='primary' className={classes.NavLink} onClick={setMapMenu}>
                  <Typography variant={mapLinkFont} onClick={boldTopNavMapFont}>
                    WorldWind
                  </Typography>
                </Button>
              </Link> */}
            </Hidden>
            <Hidden smUp>
              <IconButton className={classes.topNavButton} onClick={downloadIconClickMethod}>
                <Link to={'/DownloadHome'}
                      onClick={setMenuIconOn}
                      className={classes.removeUnderline}
                      style={{marginBottom: '3px',}}>
                  <GetAppSharp className={classes.topNavIcon} />
                </Link>
                <Link to={'/DownloadHome'} onClick={highlightTopNavDownloadFont} className={classes.removeUnderline}>
                  <Typography align='left' className={classes.topNavIconLabels} style={{color:downloadFontColor}}>
                      File Download
                  </Typography>
                </Link>
              </IconButton>
              {/*<IconButton className={classes.topNavButton} onClick={chartsIconClickMethod}>*/}
              {/*  <Link to='/ChartsHome' onClick={setMenuIconOn} >*/}
              {/*    <GridOnIcon className={classes.topNavIcon} />*/}
              {/*  </Link>*/}
              {/*  <Link to='/ChartsHome' onClick={highlightTopNavChartsFont} className={classes.removeUnderline}>*/}
              {/*    <Typography align='left' className={classes.topNavIconLabels} style={{color:chartsFontColor}}>*/}
              {/*      Data Warehouse*/}
              {/*    </Typography>*/}
              {/*  </Link>*/}
              {/*</IconButton>*/}
              {/* <IconButton  className={classes.topNavButton} onClick={`map`IconClickMethod}>
                <Link to='/ChartsHome' onClick={setMenuIconOn}  className={classes.removeUnderline}>
                  <Map className={classes.topNavIcon} />
                </Link>
                <Link to='/MapHome' onClick={setMenuIconOn}  className={classes.removeUnderline}>
                  <Typography align='left' className={classes.topNavIconLabels}>
                    WorldWind<br/>Map
                  </Typography>
                </Link>
              </IconButton> */}
            </Hidden>
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
  <div className={classes.extraSpace}/>
  </ThemeProvider>
  )
}

export default TopNav;