import React from 'react'
import {
    SET_CANCEL_TOKEN_SOURCE,
    SET_CLICKED,
    SET_DATA_PRODUCTS,
    SET_FACILITIES,
    SET_FILES, SET_PROGRESS,
    SET_TABLE_LOADING
} from "../types";

export default (state, action) => {
    console.log("Airport Report Reducer ", state, action.facilities)
    switch (action.type) {
        case SET_DATA_PRODUCTS:
            return {
                ...state,
                dataProducts: action.dataProducts
            }
        case SET_FACILITIES:
            return {
                ...state,
                facilities: action.facilities
            }
        case SET_CLICKED:
            return {
                ...state,
                clicked: true
            }
        case SET_TABLE_LOADING:
            return {
                ...state,
                tableLoading: action.tableLoading
            }
        case SET_FILES:
            return {
                ...state,
                files: action.files
            }
        case SET_PROGRESS:
            return {
                ...state,
                progress: action.progress
            }
        case SET_CANCEL_TOKEN_SOURCE:
            return {
                ...state,
                cancelTokenSource: action.cancelTokenSource
            }
        default:
            return state
    }
}