import React from 'react'
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {AddBox, Comment, BugReport, Help, WarningRounded} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    header: {
        fontWeight: 700,
        textAlign: "center"
    },
    helpIcon: {
        color: "#2196f3"
    },
    addIcon: {
        color: "#4caf50"
    },
    warningIcon: {
        color: "#ff9800"
    },
    bugIcon: {
        color: "#ff7043"
    },
    commentIcon: {
        color: "#000"
    }
}));

const SelectionIconWidget = (props) => {
    const classes = useStyles()
    const {value, handleSelection} = props

    return (
        <Grid container alignContent="center" direction="column">
            <Typography className={classes.header}>
                Choose the Primary Feedback Reason
            </Typography>
            <ToggleButtonGroup
                value={value}
                exclusive
                onChange={handleSelection}
                style={{background: "#fff"}}
            >
                <ToggleButton value="General Question">
                    <Grid>
                        <Help className={classes.helpIcon}/>
                        <Typography className={classes.helpIcon}>General Question</Typography>
                    </Grid>
                </ToggleButton>
                <ToggleButton value="Feature Request">
                    <Grid>
                        <AddBox className={classes.addIcon}/>
                        <Typography className={classes.addIcon}>Feature Request</Typography>
                    </Grid>
                </ToggleButton>
                <ToggleButton value="Missing Data">
                    <Grid>
                        <WarningRounded className={classes.warningIcon}/>
                        <Typography className={classes.warningIcon}>Missing Data</Typography>
                    </Grid>
                </ToggleButton>
                <ToggleButton value="Potential Bug">
                    <Grid>
                        <BugReport className={classes.bugIcon}/>
                        <Typography className={classes.bugIcon}>Potential Bug</Typography>
                    </Grid>
                </ToggleButton>
                <ToggleButton value="Comment">
                    <Grid>
                        <Comment className={classes.commentIcon}/>
                        <Typography className={classes.commentIcon}>Comment</Typography>
                    </Grid>
                </ToggleButton>
            </ToggleButtonGroup>
        </Grid>
    )
}

export default SelectionIconWidget