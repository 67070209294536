import React from 'react'
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: 250,
        marginBottom: -5
    }
}))

const TypesLabelPanel = (props) => {
    const classes = useStyles()

    const {types, handleType} = props

    const handleClick = (event) => {
        let value = event.target.getAttribute("name")
        console.log("Types Label Panel ", value)
        handleType(types.name, value)
    }

    return (
        <Grid>
            <Typography gutterBottom variant='body1' className={classes.title}>
                {types.name}
            </Typography>
            <Box borderTop={5} borderColor={'#01579b'}/>
            <Grid container justify="flex-start" direction="row">
                {Object.entries(types.value).map(([index, type]) => {return (
                    <Grid item>
                        <FormControlLabel
                            style={{whiteSpace:"nowrap"}}
                            control={
                                <Checkbox
                                    color="primary"
                                    name={type.name}
                                    onChange={handleClick}
                                    checked={type.checked}
                                    size="small"
                                />
                            }
                            label={type.name}
                        />
                    </Grid>
                )})}
            </Grid>
        </Grid>
    )
}

export default TypesLabelPanel