import React, {useContext} from 'react'
import AuditContext from "../../../Contexts/Audit/auditContext";
import AuditUserSummaryContext from "../../../Contexts/AuditUserSummary/auditUserSummaryContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import AuditTable from "../../Tables/AuditTable";
import {auditUserSummaryColumnNames, auditUserSummaryColumns} from "../../Data/cgTableData";

const UserSummaryPanel = () => {
    const auditContext = useContext(AuditContext)
    const {clicked} = auditContext

    const auditUserSummaryContext = useContext(AuditUserSummaryContext)
    const {tableLoading, files} = auditUserSummaryContext

    const table = tableLoading ? <LinearProgress/> : <AuditTable files={files} columns={auditUserSummaryColumns} columnNames={auditUserSummaryColumnNames}/>

    return (
        clicked && table
    )
}

export default UserSummaryPanel