import React from 'react'

export const handleActivateSearchDataProducts = (dataProducts) => {
    let search = false
    Object.entries(dataProducts).forEach(([key, value]) => {
        value.checked && (search = true)
    })
    return search
}

export const handleSelectedDataProducts = (dataProducts) => {
    let checkedDataProducts = []

    Object.entries(dataProducts).forEach(([key, value]) => {
        value.checked && (checkedDataProducts.push(value.value))
    })

    return checkedDataProducts.join(",")
}