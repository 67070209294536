import React, {useContext} from 'react'
import AuditUserTrafficContext from "../../../Contexts/AuditUserTraffic/auditUserTrafficContext";
import AuditUserSummaryContext from "../../../Contexts/AuditUserSummary/auditUserSummaryContext";
import StackedChart from "../../Charts/StackedChart";
import LinearProgress from "@material-ui/core/LinearProgress";
import {handleTraffic} from "../../Utils/AuditUtils";

const UserTraffic = () => {
    const auditUserTrafficContext = useContext(AuditUserTrafficContext)
    const {chartLoading, axes, chartFiles} = auditUserTrafficContext
    const {chartTitle,chartHAxisTitle,chartVAxisTitle} = auditUserTrafficContext
    const setUserTrafficChartLoading = auditUserTrafficContext.setChartLoading
    const setUserTrafficChartFiles = auditUserTrafficContext.setChartFiles

    const auditUserSummaryContext = useContext(AuditUserSummaryContext)
    const {files} = auditUserSummaryContext

    const handleUserTraffic = () => {
        handleTraffic(files, setUserTrafficChartFiles)
    }

    React.useEffect(() => {
        setUserTrafficChartLoading(true)
        handleUserTraffic()
    }, [files])

    React.useEffect(() => {
        setUserTrafficChartLoading(false)
    }, [chartFiles])

    return (
        chartLoading ? <LinearProgress/> : <StackedChart chartFiles={chartFiles} columns={axes} title={chartTitle} haxis={chartHAxisTitle} vaxis={chartVAxisTitle}/>
    )
}

export default UserTraffic