import React from 'react'
import Grid from "@material-ui/core/Grid";
import GuestProductSecuredDetails from "./GuestProductSecuredDetails";
import GuestProductSecured from "./GuestProductSecured";

const GuestProductSecuredDetailsPanel = () => {
    return (
        <Grid container>
            <Grid item sm>
                <GuestProductSecuredDetails/>
            </Grid>
            <Grid item md>
                <GuestProductSecured/>
            </Grid>
        </Grid>
    )
}

export default GuestProductSecuredDetailsPanel