import React, {useReducer} from 'react'
import WeatherReducer from "./weatherReducer";
import WeatherContext from "./weatherContext";
import {
    SET_CANCEL_TOKEN_SOURCE,
    SET_CLICKED,
    SET_DATA_PRODUCTS,
    SET_FILES,
    SET_PROGRESS,
    SET_TABLE_LOADING
} from "../types";
import axios from 'axios';

const WeatherState = props => {
    const initialState = {
        dataProducts: {
            // airep: {
            //     name: "airep",
            //     label: "AIREP",
            //     checked: false,
            //     value: "airep"
            // },
            asm: {
                name: "asm",
                label: "ASM",
                checked: false,
                value: "asm"
            },
            metar: {
                name: "metar",
                label: "METAR",
                checked: false,
                value: "metar"
            },
            taf: {
                name: "taf",
                label: "TAF",
                checked: false,
                value: "taf"
            }
        },
        clicked: false,
        tableLoading: false,
        files: [],
        progress: 0,
        cancelTokenSource: axios.CancelToken.source()
    }

    const [state, dispatch] = useReducer(WeatherReducer, initialState);

    const setDataProducts = (dataProducts) => {
        dispatch({
            type: SET_DATA_PRODUCTS,
            dataProducts: dataProducts
        })
    }

    const setClicked = () => {
        dispatch({
            type: SET_CLICKED
        })
    }

    const setTableLoading = (tableLoading) => {
        dispatch({
            type: SET_TABLE_LOADING,
            tableLoading: tableLoading
        })
    }

    const setFiles = (files) => {
        dispatch({
            type: SET_FILES,
            files: files
        })
    }

    const setProgress = (progress) => {
        dispatch({
            type: SET_PROGRESS,
            progress: progress
        })
    }

    const resetProgress = () => {
        dispatch({
            type: SET_PROGRESS,
            progress: 0
        })
    }

    const resetCancelToken = () => {
        dispatch({
            type: SET_CANCEL_TOKEN_SOURCE,
            cancelTokenSource: axios.CancelToken.source()
        })
    }

    return <WeatherContext.Provider
        value = {{
            dataProducts: state.dataProducts,
            clicked: state.clicked,
            tableLoading: state.tableLoading,
            files: state.files,
            progress: state.progress,
            cancelTokenSource: state.cancelTokenSource,
            setDataProducts,
            setClicked,
            setTableLoading,
            setFiles,
            setProgress,
            resetProgress,
            resetCancelToken
        }}>
        {props.children}
    </WeatherContext.Provider>
}

export default WeatherState