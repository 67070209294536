import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import CopyrightIcon from '@material-ui/icons/Copyright';
import Link from "@material-ui/core/Link";

const useStyles = makeStyles({
  footer: {
    bottom: 0,
    position: 'fixed',
    width: '100%',
    background: 'linear-gradient(180deg, #90caf9 5%, #0d47a1 90%)',
    zIndex: '1100',
  },
  fontBold: {
    fontWeight: 700,
    fontSize: '0.9rem'
  },
  copyrightIcon: {
    fontWeight: 700,
    fontSize: '1.1rem',
    color: '#fff'
  },
  link: {
    fontWeight: 500,
    fontSize: '0.8rem'
  },
  toolbar: {
    background: 'linear-gradient(180deg, #90caf9 5%, #0d47a1 90%)',
    zIndex: '1100',
    fontSize: '1rem',
    lineHeight: 1,
    paddingLeft: '3px',
    paddingRight: '3px',
  }
});

export default function Footer () {
  const classes = useStyles();
  const color = 'secondary';
  let year = new Date().getFullYear()

  return (
      <footer className={classes.footer}>
          <Toolbar disableGutters className={classes.toolbar}>
            <Grid container justify="center">
              <Grid container spacing={1} alignItems="flex-end" justify="center">
                <Grid item>
                  <Typography className={classes.fontBold} color={color}>
                    Copyright
                  </Typography>
                </Grid>
                <Grid item>
                  <CopyrightIcon className={classes.copyrightIcon}/>
                </Grid>
                <Grid item>
                  <Typography className={classes.fontBold} color={color}>
                    {year} Sherlock Data Warehouse. All rights reserved.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Link className={classes.link} color={color} href="https://nasa.gov/accessibility">
                  Accessibility
                </Link>
              </Grid>
            </Grid>
          </Toolbar>
      </footer>
  );
}