import React, {useContext} from 'react'
import Grid from "@material-ui/core/Grid";
import DataProductCheckbox from "../../Selections/DataProductCheckbox";
import AirportReportContext from "../../../Contexts/AirportReport/airportReportContext";
import FacilitiesLabelPanel from "../../Panels/FacilitiesLabelPanel";
import SearchButton from "../../Buttons/SearchButton";
import LoadDialog from "../../Modals/LoadDialog";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import StartDateContext from "../../../Contexts/StartDate/startDateContext";
import EndDateContext from "../../../Contexts/EndDate/endDateContext";
import Paper from "@material-ui/core/Paper";
import FDFacilityTable from "../../Tables/FDFacilityTable";
import {fileDowloadUrl, hostname} from "../../Data/api";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    panel: {
        backgroundColor: theme.palette.common.lighterGray
    }
}))

const AirportReportPanel = () => {
    const classes = useStyles()

    const airportReportContext = useContext(AirportReportContext)
    const {dataProducts, facilities, clicked, tableLoading, files, progress, cancelTokenSource} = airportReportContext
    const setDataProducts = airportReportContext.setDataProducts
    const setFacilities = airportReportContext.setFacilities
    const setClicked = airportReportContext.setClicked
    const setTableLoading = airportReportContext.setTableLoading
    const setFiles = airportReportContext.setFiles
    const setProgress = airportReportContext.setProgress
    const resetCancelToken = airportReportContext.resetCancelToken

    // const [tableLoading, setTableLoading] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [cancel, setCancel] = React.useState(false)

    const startDateContext = useContext(StartDateContext)
    const {startDate, startTime} = startDateContext
    const endDateContext = useContext(EndDateContext)
    const {endDate, endTime} = endDateContext
    // console.log("ATAC Facility Report Airport ", startDate, startTime, endDate, endTime)

    const activateTabs = dataProducts.runwayUsage.checked || dataProducts.taxiTime.checked
    const activateSearch = dataProducts.runwayUsage.checked || dataProducts.taxiTime.checked

    const handleChange = (event) => {
        const {name, checked} = event.target

        let updatedDataProducts2
        let dataProduct = Object.assign({}, dataProducts[name], {checked: checked})
        updatedDataProducts2 = Object.assign(
            {}, dataProducts, {[name]: dataProduct})

        setDataProducts(updatedDataProducts2)
    }

    const handleFacility = (event) => {
        const {name, checked} = event.target

        const updatedFacility = facilities.value.map(facility => {
            if (facility.name === name) {
                facility.checked = !facility.checked
            }
            return facility
        })


        let updatedFacilities = Object.assign(
            {}, facilities, {value: updatedFacility})


        // console.log("Facility Data Update ", updatedFacilityData)
        //
        // let updatedFacilities = Object.assign(
        //     {}, facilities, {[value]: updatedFacilityData})

        setFacilities(updatedFacilities)
    }

    const handleUserSelection = (event) => {
        setClicked(true)
        setTableLoading(true)
        // const startDate = "20190202"
        // const endDate = "20190203"

        const start = startDate.toString().replace(/\//g,'')
        const end = endDate.toString().replace(/\//g,'')

        // console.log("Cleaned dates", start, end)
        //
        // console.log("User selected facilities")
        // console.log(facilities)

        let query = ""
        let queryFacilityRU = ""
        let queryFacilityTT = ""

        Object.entries(dataProducts).forEach(([key, value]) => {
            value.checked && (query += value.value)
        })

        dataProducts.runwayUsage.checked && (
            Object.entries(facilities.value).forEach(([index,facility]) => {
                facility.checked && (queryFacilityRU += dataProducts.runwayUsage.value + " " + facility.value)
            })
        )
        dataProducts.taxiTime.checked && (
            Object.entries(facilities.value).forEach(([index,facility]) => {
                facility.checked && (queryFacilityTT += dataProducts.taxiTime.value + " " + facility.value)
            })
        )

        // console.log("Query ", query)
        // console.log("Query Facility RU", queryFacilityRU)
        // console.log("Query Facility TT", queryFacilityTT)
        //
        // console.log("API", "http://localhost:8080/filedownload-0.0.1-SNAPSHOT/search/file/"+start+"/"+end+"?dataProducts="+queryFacilityRU+queryFacilityTT)

        // Runway Usage
        // TAXI Time

        // http://localhost:8080/filedownload-0.0.1-SNAPSHOT/search/file/20190202/20190203?dataProducts=GOAROUNDS%20CLT
        axios.get(hostname + fileDowloadUrl + "search/file/"+start+"/"+end+"?dataProducts="+queryFacilityRU+queryFacilityTT)
            .then(response => {
                let filteredFiles = response.data.data.filter(entry => {
                    return Object.keys(entry).length > 0
                })
                setFiles(filteredFiles)
                setTableLoading(false)
            })
            .catch(err => {
                console.log(err)
                setTableLoading(false)
            })
    }

    const handleFilename = (rows) => {
        let dataSources = [...new Set(rows.map(index => {return (files[index]["dataSource"])}))]
        dataSources = dataSources.toString().replace(/,/g,'_')
        // console.log("Data Sources", dataSources)

        return dataSources + "_" + startDate + "_" + endDate + ".zip"
    }

    const handleClick = (rowsSelected) => {
        // const data = files
        // console.log("Click", rowsSelected)
        // console.log("Files ", files)

        // const updatedSelectedFiles = rowsSelected.map(index => {return (files[index]["path"].replace('+','%2B'))})
        const queryFile = rowsSelected.map(index => {
            return (files[index]["path"].replace('+','%2B') + "%20" +
                files[index]["dataSource"]
            )
        }).join(",")

        const total = rowsSelected.map(index => {
            return files[index]["size"]
        })

        const querySize = total.reduce((result,number)=> result+number)

        // console.log("Query File ", queryFile)

        // http://localhost:8080/filedownload-0.0.1-SNAPSHOT/download/file?path=/home/data/atac/SVDataLocal/RD/atl+asdex/2019/201902/20190202
        setLoading(true)
        axios.get(hostname + fileDowloadUrl + "download/file?path="+queryFile,
            {
                responseType: "blob",
                onDownloadProgress: progressEvent => {
                    let progress = Math.round((progressEvent.loaded * 100)/querySize)
                    setProgress(progress)
                },
                cancelToken: cancelTokenSource.token
            })
            .then(response => {
                // console.log("Download from ", response.headers)
                setLoading(false)
                const file = new Blob([response.data], {type:"file/zip"})
                const link = document.createElement("a")
                link.href = URL.createObjectURL(file)
                link.download = handleFilename(rowsSelected)
                document.body.appendChild(link)
                link.click()
            })
            .catch(err => {
                console.log(err)
            })
    }

    const table = tableLoading ? <LinearProgress/> : <FDFacilityTable files={files} handleClick={handleClick}/>

    const handleCancel = () => {
        console.log("Cancel file download")
        cancelTokenSource.cancel("Cancelled Airport Report Download")
        resetCancelToken()
        setCancel(true)
        setLoading(false)
    }

    const handleActivateSearch = () => {
        let search = false
        facilities.value.forEach(facility => {
            facility.checked && (search = true)
        })
        return search
    }

    let activateFacilitySearch = handleActivateSearch()

    return (
        <Grid container justify="flex-start" direction="column">
            <Paper className={classes.panel} style={{marginBottom:20,paddingLeft:15}}>
                <Grid item>
                    <Grid container direction="row">
                        <DataProductCheckbox name={dataProducts.runwayUsage.name} label={dataProducts.runwayUsage.label} checked={dataProducts.runwayUsage.checked} handleChange={handleChange}/>
                        <DataProductCheckbox name={dataProducts.taxiTime.name} label={dataProducts.taxiTime.label} checked={dataProducts.taxiTime.checked} handleChange={handleChange}/>
                    </Grid>
                </Grid>
                <FacilitiesLabelPanel facilities={facilities} handleFacility={handleFacility} activateTabs={activateTabs}/>
            </Paper>
            <SearchButton activateSearch={activateSearch&&activateFacilitySearch} handleUserSelection={handleUserSelection}/>
            {clicked && table}
            {loading && <LoadDialog load={loading} progress={progress} handleClose={handleCancel}/>}
        </Grid>
    )
}

export default AirportReportPanel