import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      padding: 0,
      width: '100%',
    },
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    marginTop: '25px',
    marginBottom: theme.spacing(10)
  },
  // toolbar: theme.mixins.toolbar,
}));

function DownloadATACFac() {

  const classes = useStyles();

  return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Paper >This is the ATAC Facilities flight data page.</Paper>
      </main>
  )
}

export default DownloadATACFac;
