import React from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Placeholder from "./Placeholder";

am4core.useTheme(am4themes_animated);

const StackedChart = (props) => {
    const {chartFiles,columns,title,haxis,vaxis} = props

    const chart = React.useRef(null);

    const createStackRow = (x, y) => {
        let series = new am4charts.ColumnSeries();
        series.dataFields.valueY = y;
        series.dataFields.categoryX = x;
        series.name = y;
        series.tooltipText = "{name}: [bold]{valueY.value}[/]";
        series.stacked = true

        return series
    }

    React.useLayoutEffect(() => {
        let x = am4core.create("stackedchartdiv", am4charts.XYChart);

        let chartTitle = x.titles.create();
        chartTitle.text = title
        chartTitle.fontSize = 20

        x.marginRight = 400;
        x.leftAxesContainer.layout = "vertical";

        x.data = chartFiles

        let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = columns[0];
        categoryAxis.title.text = haxis;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 200

        let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = vaxis
        valueAxis.min = 0;

        valueAxis.renderer.gridContainer.background.fill = am4core.color("#fff");

        // stack all rows at once
        let series = columns.slice(1,columns.length).map(col => {return x.series.push(createStackRow(columns[0], col))})

        x.cursor = new am4charts.XYCursor();

        chart.current = x

        return () => {
            x.dispose();
        };
    }, []);

    return (
        chartFiles.length > 0 ? <div id="stackedchartdiv" style={{ width: "100%", height: "500px" }}/> : <Placeholder/>
    )
}

export default StackedChart