import {
    RESET_SELECTION,
    SET_AVAILABLE,
    SET_CATEGORY,
    SET_CLICKED,
    SET_LOADING,
    SET_MESSAGE,
    SET_SENT,
    SET_SUBJECT
} from "../types";

export default (state, action) => {
    switch (action.type) {
        case SET_CATEGORY:
            return {
                ...state,
                category: action.category
            }
        case SET_SUBJECT:
            return {
                ...state,
                subject: action.subject
            }
        case SET_MESSAGE:
            return {
                ...state,
                message: action.message
            }
        case SET_CLICKED:
            return {
                ...state,
                clicked: action.clicked
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        case SET_SENT:
            return {
                ...state,
                sent: action.sent
            }
        case SET_AVAILABLE:
            return {
                ...state,
                available: action.available
            }
        case RESET_SELECTION:
            return {
                ...state,
                category: "General Question",
                subject: "",
                message: "",
                clicked: false,
                sent: false
            }
        default:
            return state
    }
}