import React, {useContext} from 'react'
import ProfileGuestProductInterestDetailsContext
    from "../../../Contexts/ProfileGuestProductInterestDetails/profileGuestProductInterestDetailsContext";
import ProfileGuestActivitySummaryContext
    from "../../../Contexts/ProfileGuestActivitySummary/profileGuestActivitySummaryContext";
import PieChart from "../../Charts/PieChart";
import LinearProgress from "@material-ui/core/LinearProgress";
import {handleDetails} from "../../Utils/AuditUtils";

const GuestProductInterestDetails = () => {
    const profileGuestProductInterestDetailsContext = useContext(ProfileGuestProductInterestDetailsContext)
    const {chartLoading, axes, chartFiles} = profileGuestProductInterestDetailsContext
    const {chartTitle,chartHAxisTitle,chartVAxisTitle} = profileGuestProductInterestDetailsContext
    const setGuestProductInterestDetailsChartLoading = profileGuestProductInterestDetailsContext.setChartLoading
    const setGuestProductInterestDetailsChartFiles = profileGuestProductInterestDetailsContext.setChartFiles

    const profileGuestActivitySummaryContext = useContext(ProfileGuestActivitySummaryContext)
    const {files} = profileGuestActivitySummaryContext

    const handleProductInterestDetails = () => {
        handleDetails(files, "search", "product", setGuestProductInterestDetailsChartFiles)
        // let products = handleUniqueValues(files, "product")
        // let productsReduced = handleValueSum(files, "product")
        // // let productsReduced = files.reduce((k,v) => {k[v.product] = (k[v.product] || 0) + 1;
        // //     return k}, {})
        // const data = products.map(product => { return {product: product, sum: productsReduced[product]}})
        //
        // setGuestProductDetailsChartFiles(data)
    }

    React.useEffect(() => {
        setGuestProductInterestDetailsChartLoading(true)
        handleProductInterestDetails()
    }, [files])

    React.useEffect(() => {
        setGuestProductInterestDetailsChartLoading(false)
    }, [chartFiles])

    return (
        chartLoading ? <LinearProgress/> : <PieChart chartFiles={chartFiles} columns={axes} title={chartTitle} haxis={chartHAxisTitle} vaxis={chartVAxisTitle}/>
    )
}

export default GuestProductInterestDetails