import React from 'react'
import {SET_TAB_VALUE} from "../types";

export default (state, action) => {
    // console.log("ATAC Facility Reports Reducer ", state, action.tabValue)
    switch (action.type) {
        case SET_TAB_VALUE:
            return {
                ...state,
                tabValue: action.tabValue
            }
        default:
            return state

    }
}