import React, {useReducer} from 'react'
import AuditReducer from "./auditReducer";
import AuditContext from "./auditContext";
import {
    SET_AVG_SEARCH_TIME,
    SET_CLICKED,
    SET_MAX_ACTIVE_TIME,
    SET_TAB_VALUE,
    SET_TOTAL_PRODUCT_INTEREST,
    SET_TOTAL_PRODUCT_SECURED,
    SET_TOTAL_VISITORS, SET_TYPES
} from "../types";
import userTypeData from "../../components/Data/userTypeData";

const AuditState = props => {
    const initialState = {
        userTypes: {
            name: "User Types",
            value: userTypeData
        },
        clicked: false,
        totalVisitors: 0,
        totalProductInterest: 0,
        totalProductSecured: 0,
        avgSearchTime: 0,
        maxActiveTime: 0,
        tabValue: 'userDetails'
    }

    const [state, dispatch] = useReducer(AuditReducer, initialState);

    const setUserType = (types) => {
        dispatch({
            type: SET_TYPES,
            userTypes: types
        })
    }

    const setClicked = () => {
        dispatch({
            type: SET_CLICKED
        })
    }

    const setTotalVisitors = (totalVisitors) => {
        dispatch({
            type: SET_TOTAL_VISITORS,
            totalVisitors: totalVisitors
        })
    }

    const setTotalProductInterest = (totalProductInterest) => {
        dispatch({
            type: SET_TOTAL_PRODUCT_INTEREST,
            totalProductInterest: totalProductInterest
        })
    }

    const setTotalProductSecured = (totalProductSecured) => {
        dispatch({
            type: SET_TOTAL_PRODUCT_SECURED,
            totalProductSecured: totalProductSecured
        })
    }

    const setAvgSearchTime = (avgSearchTime) => {
        dispatch({
            type: SET_AVG_SEARCH_TIME,
            avgSearchTime: avgSearchTime
        })
    }

    const setMaxActiveTime = (maxActiveTime) => {
        dispatch({
            type: SET_MAX_ACTIVE_TIME,
            maxActiveTime: maxActiveTime
        })
    }

    const setTabValue = (value) => {
        dispatch({
            type: SET_TAB_VALUE,
            tabValue: value
        })
    }

    return <AuditContext.Provider
        value = {{
            userTypes: state.userTypes,
            clicked: state.clicked,
            totalVisitors: state.totalVisitors,
            totalProductInterest: state.totalProductInterest,
            totalProductSecured: state.totalProductSecured,
            avgSearchTime: state.avgSearchTime,
            maxActiveTime: state.maxActiveTime,
            tabValue: state.tabValue,
            setUserType,
            setClicked,
            setTotalVisitors,
            setTotalProductInterest,
            setTotalProductSecured,
            setAvgSearchTime,
            setMaxActiveTime,
            setTabValue
        }}>
        {props.children}
    </AuditContext.Provider>

}

export default AuditState