import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

const drawerWidth = 245;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        marginBottom: `${drawerWidth}px`,
        [theme.breakpoints.up('md')]: {
            paddingLeft: `${drawerWidth}px`,
        }
    },
    grid: {
        paddingLeft: 30
    },
    panel: {
        backgroundColor: theme.palette.common.lighterGray
    },
    heading: {
        marginTop: 120,
        marginLeft: -200,
        fontWeight: 550
    },
    subheading: {
        marginLeft: -200,
    }
    // toolbar: theme.mixins.toolbar,
}));

const Unauthorized = () => {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
                <Typography gutterBottom variant='subtitle2' className={classes.heading}>Access denied.</Typography>
                <Typography gutterBottom variant='body1' className={classes.subheading}>You are not authorized to access this page.</Typography>
        </main>
    )
}

export default Unauthorized