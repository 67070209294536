import React, {useReducer} from 'react'
import SemiPieChartReducer from "./semiPieChartReducer";
import SemiPieChartContext from "./semiPieChartContext";
import {SET_LINK} from "../types";

const SemiPieChartState = props => {
    const initialState = {
        link: ""
    }

    const [state, dispatch] = useReducer(SemiPieChartReducer, initialState);

    const setLink = (link) => {
        dispatch({
            type: SET_LINK,
            link: link
        })
    }

    return <SemiPieChartContext.Provider
        value = {{
            link: state.link,
            setLink
        }}>
        {props.children}
    </SemiPieChartContext.Provider>

}

export default SemiPieChartState