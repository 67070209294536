import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import sideNavContext from '../../Contexts/SideNav/sideNavContext'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 290,
    background: 'linear-gradient(90deg, #cfd8dc 60%, #78909c 120%)',
    //This is the padding between the end date and the menu.
    paddingTop: theme.spacing(1),
  },
  nested1: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '8px',
    height: '15px',
    width: '270px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  nested2: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '5px',
    height: '15px',
    width: '270px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  removeUnderline: {
    textDecoration: 'none',
    paddingBottom: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  removePadding: {
    marginBottom: theme.spacing(-1),
    disableGutters: true,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  underline: {
    borderBottomStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#01579b',
    marginLeft: '15px',
    marginRight: '23px',
    paddingLeft: '0px',
  }
}));

function LeftNavChartsFacRep(props) {
  const classes = useStyles();
  const SideNavContext = useContext(sideNavContext)
  // const openFacilityReportsMenu = SideNavContext.openFacilityReportsMenu
  const toggleFacRepMenu = SideNavContext.toggleFacilityReportsMenu
  const facilityReportsMenuState = SideNavContext.facilityReportsMenuState
  const facRepMenuFont = SideNavContext.facRepMenuFont
  const opsnetFont = SideNavContext.opsnetFont
  const aalFont = SideNavContext.aalFont
  const opsnetFontStyle = {variant: opsnetFont, color: 'primary'}
  const aalFontStyle = {variant: aalFont, color: 'primary'}
  const facRepStyle = { variant: facRepMenuFont, color: 'primary'}
  const highlightFacRepMenu = SideNavContext.highlightFacRepMenu
  const highlightOpsNet = SideNavContext.highlightOpsNet
  const highlightAal = SideNavContext.highlightAal
  const hoverHighlightAalOps = SideNavContext.hoverHighlightAalOps
  const hoverHighlightOpsnet = SideNavContext.hoverHighlightOpsnet
  const unhighlightAalOps = SideNavContext.unhighlightAalOps
  const unhighlightOpsnet = SideNavContext.unhighlightOpsnet
  const unhighlightFacRepMenu = SideNavContext.unhighlightFacRepMenu
  let facRepClickMethod 
  let aalOpsHover
  let opsnetHover

  if((aalFont === 'overline' ||
     opsnetFont === 'overline') &&
     facRepMenuFont === 'body1') {
       facRepClickMethod = async function () {
         Promise.all([ highlightFacRepMenu(), toggleFacRepMenu() ])
       }
  } else if  (( aalFont === 'overline' ||
                opsnetFont === 'overline') &&
                facRepMenuFont === 'body2') {
                  facRepClickMethod = async function () {
                    Promise.all([ unhighlightFacRepMenu(), toggleFacRepMenu() ])
                  }
  } else {
    facRepClickMethod = function () {
      toggleFacRepMenu()
    }
  }

  if(window.location.pathname.includes('ChartsAAL')) {
    aalOpsHover = hoverHighlightAalOps
  } else {
    aalOpsHover = unhighlightAalOps
  }

  if(window.location.pathname.includes('ChartsOpsNet')) {
    opsnetHover = hoverHighlightOpsnet
  } else {
    opsnetHover = unhighlightOpsnet
  }

  return (
    <React.Fragment>
    <div className={classes.underline}>
      <ListItem button 
                disableRipple
                onClick={facRepClickMethod} 
                disableGutters 
                className={classes.removePadding}>
        <ListItemText primary='Facility Reports' 
                      primaryTypographyProps={facRepStyle}/>
        {facilityReportsMenuState ? <ExpandLess color='primary'/> : <ExpandMore color='primary'/>}
      </ListItem>
    </div>
    <Collapse in={facilityReportsMenuState} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to='/ChartsAAL' className={classes.removeUnderline}>
          <ListItem button 
                    disableRipple 
                    className={classes.nested1} 
                    onClick={highlightAal}>
            <ListItemText primary="AAL Ops Analysis" 
                            onMouseOver={hoverHighlightAalOps}
                            onMouseDown = {highlightAal}
                            onMouseLeave={aalOpsHover}
                            primaryTypographyProps={aalFontStyle}/>
            </ListItem>
          </Link>
          <Link to='/ChartsOpsNet' className={classes.removeUnderline}>
            <ListItem button 
                      disableRipple 
                      className={classes.nested2} 
                      onClick={highlightOpsNet}>
              <ListItemText primary="OpsNet Delays" 
                            onMouseOver={hoverHighlightOpsnet}
                            onMouseDown={highlightOpsNet}
                            onMouseLeave={opsnetHover}
                            primaryTypographyProps={opsnetFontStyle}/>
            </ListItem>
          </Link>
        </List>
      </Collapse>  
    </React.Fragment>
  )
}

export default LeftNavChartsFacRep;
