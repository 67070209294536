export const BOLD_TOP_NAV_DOWNLOAD_FONT = 'BOLD_TOP_NAV_DOWNLOAD_FONT'
export const BOLD_TOP_NAV_CHARTS_FONT = 'BOLD_TOP_NAV_CHARTS_FONT'
export const BOLD_TOP_NAV_MAP_FONT = 'BOLD_TOP_NAV_MAP_FONT'
export const HIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON = 'HIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON'
export const HIGHLIGHT_TOP_NAV_CHARTS_BUTTON = 'HIGHLIGHT_TOP_NAV_CHARTS_BUTTON'
export const HIGHLIGHT_TOP_NAV_MAP_BUTTON = 'HIGHLIGHT_TOP_NAV_MAP_BUTTON'
export const UNHIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON = 'UNHIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON'
export const UNHIGHLIGHT_TOP_NAV_CHARTS_BUTTON = 'UNHIGHLIGHT_TOP_NAV_CHARTS_BUTTON'
export const UNHIGHLIGHT_TOP_NAV_MAP_BUTTON = 'UNHIGHLIGHT_TOP_NAV_MAP_BUTTON'
export const HIGHLIGHT_TOP_NAV_DOWNLOAD_FONT = 'HIGHLIGHT_TOP_NAV_DOWNLOAD_FONT'
export const HIGHLIGHT_TOP_NAV_CHARTS_FONT = 'HIGHLIGHT_TOP_NAV_CHARTS_FONT'
export const HIGHLIGHT_TOP_NAV_MAP_FONT = 'HIGHLIGHT_TOP_NAV_MAP_FONT'
export const UNHIGHLIGHT_TOP_NAV_DOWNLOAD_FONT = 'UNHIGHLIGHT_TOP_NAV_DOWNLOAD_FONT'
export const UNHIGHLIGHT_TOP_NAV_CHARTS_FONT = 'UNHIGHLIGHT_TOP_NAV_CHARTS_FONT'
export const UNHIGHLIGHT_TOP_NAV_MAP_FONT = 'UNHIGHLIGHT_TOP_NAV_MAP_FONT'
export const BOLD_TOP_SM_NAV_DOWNLOAD_FONT = 'BOLD_TOP_SM_NAV_DOWNLOAD_FONT'
export const SET_DATE_WIDGET = 'SET_DATE_WIDGET'
export const RESET_DATE_WIDGET = 'RESET_DATE_WIDGET'
export const CLOSE_WEATHER_MENU = 'CLOSE_WEATHER_MENU'
export const CLOSE_ATAC_FLIGHT_MENU = 'CLOSE_ATAC_FLIGHT_MENU'
export const CLOSE_FACILITY_REPORTS_MENU = 'CLOSE_FACILITY_REPORTS_MENU'
export const HIGHLIGHT_AAL = 'HIGHLIGHT_AAL'
export const HIGHLIGHT_ADDITIONAL_MENU = 'HIGHLIGHT_ADDITIONAL_MENU'
export const HIGHLIGHT_AIRCRAFT_REP = 'HIGHLIGHT_AIRCRAFT_REP'
export const HIGHLIGHT_ASDI = 'HIGHLIGHT_ASDI'
export const HIGHLIGHT_ASM = 'HIGHLIGHT_ASM'
export const HIGHLIGHT_ATAC_FLIGHT = 'HIGHLIGHT_ATAC_FLIGHT'
export const HIGHLIGHT_ATAC_MENU = 'HIGHLIGHT_ATAC_MENU'
export const HIGHLIGHT_ATCSCC = 'HIGHLIGHT_ATCSCC'
export const HIGHLIGHT_ATIS = 'HIGHLIGHT_ATIS'
export const HIGHLIGHT_CENTER = 'HIGHLIGHT_CENTER_DOWNLOAD'
export const HIGHLIGHT_CHARTS = 'HIGHLIGHT_CHARTS'
export const HIGHLIGHT_DOWNLOADS = 'HIGHLIGHT_DOWNLOADS'
export const HIGHLIGHT_EXELIS = 'HIGHLIGHT_EXELIS'
export const HIGHLIGHT_FAC_EQUIP = 'HIGHLIGHT_FAC_EQUIP'
export const HIGHLIGHT_FAC_REP = 'HIGHLIGHT_FAC_REP'
export const HIGHLIGHT_FAC_REP_MENU = 'HIGHLIGHT_FAC_REP_MENU'
export const HIGHLIGHT_FLIGHT = 'HIGHLIGHT_FLIGHT'
export const HIGHLIGHT_MAPS = 'HIGHLIGHT_MAPS'
export const HIGHLIGHT_METAR = 'HIGHLIGHT_METAR'
export const HIGHLIGHT_OPSNET = 'HIGHLIGHT_OPSNET'
export const HIGHLIGHT_OPTMA = 'HIGHLIGHT_OPTMA'
export const HIGHLIGHT_SURFACE = 'HIGHLIGHT_SURFACE_DOWNLOAD'
export const HIGHLIGHT_SWIM = 'HIGHLIGHT_SWIM'
export const HIGHLIGHT_TAF = 'HIGHLIGHT_TAF'
export const HIGHLIGHT_TBFM = 'HIGHLIGHT_TBFM'
export const HIGHLIGHT_TRAFFIC = 'HIGHLIGHT_TRAFFIC'
export const HIGHLIGHT_TRAFFIC_MENU = 'HIGHLIGHT_TRAFFIC_MENU'
export const HIGHLIGHT_TRACON = 'HIGHLIGHT_TRACON_DOWNLOAD'
export const HIGHLIGHT_USA = 'HIGHLIGHT_USA_DOWNLOAD'
export const HIGHLIGHT_WEATHER = 'HIGHLIGHT_WEATHER'
export const HIGHLIGHT_WEATHER_MENU = 'HIGHLIGHT_WEATHER_MENU'
export const HIGHLIGHT_WITI = 'HIGHIGHT_WITI'
export const HOVER_HIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON = 'HOVER_HIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON'
export const HOVER_HIGHLIGHT_TOP_NAV_CHARTS_BUTTON = 'HOVER_HIGHLIGHT_TOP_NAV_CHARTS_BUTTON'
export const HOVER_HIGHLIGHT_TOP_NAV_MAP_BUTTON = 'HOVER_HIGHLIGHT_TOP_NAV_MAP_BUTTON'
export const HOVER_HIGHLIGHT_AAL_OPS = 'HOVER_HIGHLIGHT_AAL_OPS'
export const HOVER_HIGHLIGHT_AIR_REP = 'HOVER_HIGHLIGHT_AIR_REP'
export const HOVER_HIGHLIGHT_ASDI = 'HOVER_HIGHLIGHT_ASDI'
export const HOVER_HIGHLIGHT_ASM = 'HOVER_HIGHLIGHT_ASM'
export const HOVER_HIGHLIGHT_ATAC_FLIGHT = 'HOVER_HIGHLIGHT_ATAC_FLIGHT'
export const HOVER_HIGHLIGHT_ATCSCC = 'HOVER_HIGHLIGHT_ATCSCC'
export const HOVER_HIGHLIGHT_ATIS = 'HOVER_HIGHLIGHT_ATIS'
export const HOVER_HIGHLIGHT_CENTER = 'HOVER_HIGHLIGHT_CENTER'
export const HOVER_HIGHLIGHT_FAC_EQUIP = 'HOVER_HIGHLIGHT_FAC_EQUIP'
export const HOVER_HIGHLIGHT_FAC_REP = 'HOVER_HIGHLIGHT_FAC_REP'
export const HOVER_HIGHLIGHT_METAR = 'HOVER_HIGHLIGHT_METAR'
export const HOVER_HIGHLIGHT_OPSNET = 'HOVER_HIGHLIGHT_OPSNET'
export const HOVER_HIGHLIGHT_OPTMA = 'HOVER_HIGHLIGHT_OPTMA'
export const HOVER_HIGHLIGHT_SURFACE = 'HOVER_HIGHLIGHT_SURFACE'
export const HOVER_HIGHLIGHT_TBFM = 'HOVER_HIGHLIGHT_TBFM'
export const HOVER_HIGHLIGHT_TRACON = 'HOVER_HIGHLIGHT_TRACON'
export const HOVER_HIGHLIGHT_TAF = 'HOVER_HIGHLIGHT_TAF'
export const HOVER_HIGHLIGHT_USA = 'HOVER_HIGHLIGHT_USA'
export const HOVER_HIGHLIGHT_WEATHER = 'HOVER_HIGHLIGHT_WEATHER'
export const HOVER_HIGHLIGHT_WITI = 'HOVER_HIGHLIGHT_WITI'
export const OPEN_ADDITIONAL_MENU = 'OPEN_ADDITIONAL_MENU'
export const OPEN_FACILITY_REPORTS_MENU = 'OPEN_FACILITY_REPORTS_MENU'
export const OPEN_ATAC_FLIGHT_MENU = 'OPEN_ATAC_FLIGHT_MENU'
export const OPEN_WEATHER_MENU = 'OPEN_WEATHER_MENU'
export const OPEN_TRAFFIC_MGMT_MENU = 'OPEN_TRAFFIC_MGMT_MENU'
export const RESET_LEFT_MENU = 'RESET_LEFT_MENU'
export const RESET_WEATHER_MENU = 'RESET_WEATHER_MENU'
export const RESET_ADDITIONAL = 'RESET_ADDITIONAL'
export const RESET_ATAC_DOWNLOAD = 'RESET_ATAC_DOWNLOAD'
export const RESET_DOWNLOAD_MENU = 'RESET_DOWNLOAD_MENU'
export const RESET_TOP_NAV_ALL = 'RESET_TOP_NAV_ALL'
export const RESET_FACILITY_REPORTS_MENU = 'RESET_FACILITY_REPORTS_MENU'
export const RESET_TRAFFIC_MGMT_MENU = 'RESET_TRAFFIC_MGMT_MENU'
export const SET_DOWNLOAD_MENU = 'SET_DOWNLOAD_MENU'
export const SET_CHARTS_MENU = 'SET_CHARTS_MENU'
export const SET_MAP_MENU = 'SET_MAP_MENU'
export const SET_MENU_ICON_ON = 'SET_MENU_ICON_ON'
export const TOGGLE_SIDENAV = 'TOGGLE_SIDENAV'
export const TOGGLE_ATAC_FLIGHT_MENU = 'TOGGLE_ATAC_FLIGHT_MENU'
export const TOGGLE_WEATHER_MENU = 'TOGGLE_WEATHER_MENU'
export const TOGGLE_FACILITY_REPORTS_MENU = 'TOGGLE_FACILITY_REPORTS_MENU'
export const TOGGLE_TRAFFIC_MGMT_MENU = 'TOGGLE_TRAFFIC_MGMT_MENU'
export const TOGGLE_ADDITIONAL_MENU = 'TOGGLE_ADDITIONAL_MENU'
export const UNHIGHLIGHT_AAL_OPS = 'UNHIGHLIGHT_AAL_OPS'
export const UNHIGHLIGHT_ADDITIONAL_MENU = 'UNHIGHLIGHT_ADDITIONAL_MENU'
export const UNHIGHLIGHT_AIR_REP = 'UNHIGHLIGHT_AIR_REP'
export const UNHIGHLIGHT_ALL = 'UNHIGHLIGHT_ALL'
export const UNHIGHLIGHT_ASDI = 'UNHIGHLIGHT_ASDI'
export const UNHIGHLIGHT_ASM = 'UNHIGHLIGHT_ASM'
export const UNHIGHLIGHT_ATAC_FLIGHT = 'UNHIGHLIGHT_ATAC_FLIGHT'
export const UNHIGHLIGHT_ATAC_MENU = 'UNHIGHLIGHT_ATAC_MENU'
export const UNHIGHLIGHT_ATAC_EXELIS = 'UNHIGHLIGHT_ATAC_EXELIS'
export const UNHIGHLIGHT_ATCSCC = 'UNHIGHLIGHT_ATCSCC'
export const UNHIGHLIGHT_ATIS = 'UNHIGHLIGHT_ATIS'
export const UNHIGHLIGHT_CENTER = 'UNHIGHLIGHT_CENTER'
export const UNHIGHLIGHT_FAC_REP = 'UNHIGHLIGHT_FAC_REP'
export const UNHIGHLIGHT_FAC_REP_MENU = 'UNHIGHLIGHT_FAC_REP_MENU'
export const UNHIGHLIGHT_FAC_EQUIP = 'UNHIGHLIGHT_FAC_EQUIP'
export const UNHIGHLIGHT_TRAFFIC_MENU = 'UNHIGHLIGHT_TRAFFIC_MENU'
export const UNHIGHLIGHT_METAR = 'UNHIGHLIGHT_METAR'
export const UNHIGHLIGHT_OPSNET = 'UNHIGHLIGHT_OPSNET'
export const UNHIGHLIGHT_OPTMA = 'UNHIGHLIGHT_OPTMA'
export const UNHIGHLIGHT_TAF = 'UNHIGHLIGHT_TAF'
export const UNHIGHLIGHT_TBFM = 'UNHIGHLIGHT_TBFM'
export const UNHIGHLIGHT_TRACON = 'UNHIGHLIGHT_TRACON'
export const UNHIGHLIGHT_SURFACE = 'UNHIGHLIGHT_SURFACE'
export const UNHIGHLIGHT_USA = 'UNHIGHLIGHT_USA'
export const UNHIGHLIGHT_WEATHER = 'UNHIGHLIGHT_WEATHER'
export const UNHIGHLIGHT_WEATHER_MENU = 'UNHIGHLIGHT_WEATHER_MENU'
export const UNHIGHLIGHT_WITI = 'UNHIGHLIGHT_WITI'
export const SET_DATE_TIME = 'SET_DATE_TIME'
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE'
export const SET_MIN_DATE = 'SET_MIN_DATE'
export const SET_START_TYPE = 'SET_START_TYPE'
export const SET_END_TYPE = 'SET_END_TYPE'
export const SET_DATA_PRODUCTS = 'SET_DATA_PRODUCTS'
export const SET_FACILITIES = 'SET_FACILITIES'
export const SET_AIRPORTS = 'SET_AIRPORTS'
export const SET_EV_TYPES = 'SET_EV_TYPES'
export const SET_CALL_SIGN = 'SET_CALL_SIGN'
export const SET_ERROR = 'SET_ERROR'
export const SET_CURRENT = 'SET_CURRENT'
export const SET_SHUTTLE_BOX = 'SET_SHUTTLE_BOX'
export const SET_WEATHER_MENU = 'SET_WEATHER_MENU'
export const SET_CLICKED = 'SET_CLICKED'
export const SET_LOADING = 'SET_LOADING'
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING'
export const SET_PROGRESS = 'SET_PROGRESS'
export const SET_TAB_VALUE = 'SET_TAB_VALUE'
export const SET_COLUMNS = 'SET_COLUMNS'
export const SET_FILES = 'SET_FILES'
export const SET_DATA = 'SET_DATA'
export const SET_TIME_ZONE = 'SET_TIME_ZONE'
export const SET_LOCAL = 'SET_LOCAL'
export const SET_UTC = 'SET_UTC'
export const SET_HOURS_BETWEEN = 'SET_HOURS_BETWEEN'
export const SET_UTC_OFFSET = 'SET_UTC_OFFSET'
export const SET_TRACK_THRESHOLD = 'SET_TRACK_THRESHOLD'
export const SET_CHART_OUTER = 'SET_CHART_OUTER'
export const SET_CHART_INNER = 'SET_CHART_INNER'
export const SET_HIGH = 'SET_HIGH'
export const SET_LOW = 'SET_LOW'
export const SET_TIME_SERIES = 'SET_TIME_SERIES'
export const SET_LINK = 'SET_LINK'
export const SET_TOOLTIP = 'SET_TOOLTIP'
export const RESET_TOOLTIP = 'RESET_TOOLTIP'
export const SET_TOOLTIP_MESSAGE = 'SET_TOOLTIP_MESSAGE'
export const SET_EXPANDED = 'SET_EXPANDED'
export const SET_CANCEL_TOKEN_SOURCE = 'SET_CANCEL_TOKEN_SOURCE'
export const SET_CHART = 'SET_CHART'
export const SET_CHART_LOADING = 'SET_CHART_LOADING'
export const SET_CHART_COLUMNS = 'SET_CHART_COLUMNS'
export const SET_OPTIONS = 'SET_OPTIONS'
export const SET_TOTAL_VISITORS = 'SET_TOTAL_VISITORS'
export const SET_TOTAL_PRODUCT_INTEREST = 'SET_TOTAL_PRODUCT_INTEREST'
export const SET_TOTAL_PRODUCT_SECURED = 'SET_TOTAL_PRODUCT_SECURED'
export const SET_AVG_SEARCH_TIME = 'SET_AVG_SEARCH_TIME'
export const SET_MAX_ACTIVE_TIME = 'SET_MAX_ACTIVE_TIME'
export const SET_TOTAL_VISITS = 'SET_TOTAL_VISITS'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_GUEST = 'SET_GUEST'
export const SET_TYPES = 'SET_TYPES'
export const SET_OPEN = 'SET_OPEN'
export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_SUBJECT = 'SET_SUBJECT'
export const SET_MESSAGE = 'SET_MESSAGE'
export const SET_SENT = 'SET_SENT'
export const SET_AVAILABLE = 'SET_AVAILABLE'
export const RESET_SELECTION = 'RESET_SELECTION'
export const HIGHLIGHT_FUSER = 'HIGHLIGHT_FUSER'
export const HOVER_HIGHLIGHT_FUSER = 'HOVER_HIGHLIGHT_FUSER'
export const UNHIGHLIGHT_FUSER = 'UNHIGHLIGHT_FUSER'
