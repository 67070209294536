import React, {useReducer} from 'react'
import AuditUserSummaryReducer from "./auditUserSummaryReducer";
import AuditUserSummaryContext from "./auditUserSummaryContext";
import {SET_FILES, SET_TABLE_LOADING} from "../types";

const AuditUserSummaryState = props => {
    const initialState = {
        tableLoading: false,
        files: []
    }

    const [state, dispatch] = useReducer(AuditUserSummaryReducer, initialState);

    const setTableLoading = (tableLoading) => {
        dispatch({
            type: SET_TABLE_LOADING,
            tableLoading: tableLoading
        })
    }

    const setFiles = (files) => {
        dispatch({
            type: SET_FILES,
            files: files
        })
    }

    return <AuditUserSummaryContext.Provider
        value = {{
            tableLoading: state.tableLoading,
            files: state.files,
            setTableLoading,
            setFiles
        }}>
        {props.children}
    </AuditUserSummaryContext.Provider>
}

export default AuditUserSummaryState