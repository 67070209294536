import React from 'react';
import Button from "@material-ui/core/Button";
import {basename, hostname} from "../Data/api";

const LoginButton = () => {
    const login = () => {
        window.location.href = hostname.slice(0,-1) + basename + "/Home"
    }

    return (
        <Button variant="contained" color="primary" onClick={login}>NASA Guest Account</Button>
    )
}

export default LoginButton