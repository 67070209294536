import React, { useContext } from 'react'
import sideNavContext from '../../Contexts/SideNav/sideNavContext'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 290,
    background: 'linear-gradient(90deg, #cfd8dc 60%, #78909c 120%)',
    //This is the padding between the end date and the menu.
    paddingTop: theme.spacing(1),
  },
  nested1: {
    paddingTop: 0,
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '10px',
    height: '15px',
    width: '270px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },nested2: {
    paddingTop: 0,
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '5px',
    height: '15px',
    width: '270px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  removeUnderline: {
    textDecoration: 'none',
    paddingBottom: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  removePadding: {
    marginBottom: theme.spacing(-1),
    disableGutters: true,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  underline: {
    borderBottomStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#01579b',
    marginLeft: '15px',
    marginRight: '23px',
    paddingLeft: '0px',
  },
}));

function LeftNavChartsTraffic(props) {
  const classes = useStyles();
  const SideNavContext = useContext(sideNavContext)
  const opTmaFont = SideNavContext.opTmaFont
  const atcsccFont = SideNavContext.atcsccFont
  const tbfmFont = SideNavContext.tbfmFont
  const trafficMenuFont = SideNavContext.trafficMenuFont
  const highlightTrafficMenu = SideNavContext.highlightTrafficMenu
  const unhighlightTrafficMenu = SideNavContext.unhighlightTrafficMenu
  const opTmaStyle = { variant: opTmaFont, color: 'primary' }
  const atcsccStyle = { variant: atcsccFont, color: 'primary'}
  const trafficMgmtStyle = { variant: trafficMenuFont, color: 'primary' }
  const tbfmStyle = { variant: tbfmFont, color: 'primary' }
  const highlightOpTma = SideNavContext.highlightOpTma
  const highlightAtcscc = SideNavContext.highlightAtcscc
  const highlightTbfm = SideNavContext.highlightTbfm
  const unhighlightOpTma = SideNavContext.unhighlightOpTma
  const unhighlightAtcscc = SideNavContext.unhighlightAtcscc
  const unhighlightTbfm = SideNavContext.unhighlightTbfm
  const hoverHighlightOpTma = SideNavContext.hoverHighlightOpTma
  const hoverHighlightAtcscc = SideNavContext.hoverHighlightAtcscc
  const hoverHighlightTbfm = SideNavContext.hoverHighlightTbfm
  const toggleTrafficMgmtMenu = SideNavContext.toggleTrafficMgmtMenu
  const trafficMgmtMenuState = SideNavContext.trafficMgmtMenuState
  let trafficClickMethod
  let atcsccHover
  let tbfmHover
  let opTmaHover

  if (( atcsccFont === 'overline' ||
        tbfmFont === 'overline' ||
        opTmaFont === 'overline' ) &&
        trafficMenuFont === 'body1' ) {
          trafficClickMethod = async function  () {
             await Promise.all ([ toggleTrafficMgmtMenu(), highlightTrafficMenu() ])
          }
        }
  else if (( atcsccFont === 'overline' ||
            tbfmFont === 'overline' ||
            opTmaFont === 'overline' ) &&
            trafficMenuFont === 'body2' ) {
              trafficClickMethod = async function  () {
                await Promise.all ([ toggleTrafficMgmtMenu(), unhighlightTrafficMenu() ])
              }
            }
  else trafficClickMethod = toggleTrafficMgmtMenu


  if(window.location.pathname.includes('ChartsATCSCC')) {
    atcsccHover = hoverHighlightAtcscc
  } else {
    atcsccHover = unhighlightAtcscc
  }

  if(window.location.pathname.includes('ChartsTBFM')) {
    tbfmHover = hoverHighlightTbfm
  } else {
    tbfmHover = unhighlightTbfm
  }

  if(window.location.pathname.includes('ChartsOTMA')) {
    opTmaHover = hoverHighlightOpTma
  } else {
    opTmaHover = unhighlightOpTma
  }


  return (
    <React.Fragment>
      <div className={classes.underline}>
        <ListItem button 
                  disableRipple
                  onClick={trafficClickMethod} 
                  disableGutters 
                  className={classes.removePadding}>
          <ListItemText primary='Traffic Management' primaryTypographyProps={trafficMgmtStyle}/>
          {trafficMgmtMenuState ? <ExpandLess color='primary'/> : <ExpandMore color='primary'/>}
        </ListItem>
      </div>
    <Collapse in={trafficMgmtMenuState} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to='/ChartsATCSCC' className={classes.removeUnderline}>
          <ListItem button
                    disableRipple
                    onMouseOver={hoverHighlightAtcscc}
                    onMouseDown ={highlightAtcscc}
                    onMouseLeave={atcsccHover}
                    onClick={highlightAtcscc} 
                    className={classes.nested1}>
            
              <ListItemText primary="ATCSCC Advisories" 
                            primaryTypographyProps={atcsccStyle}/>
            </ListItem>
          </Link>
          <Link to='/ChartsTBFM' className={classes.removeUnderline}>
          <ListItem button 
                    disableRipple
                    onMouseOver={hoverHighlightTbfm}
                    onMouseDown ={highlightTbfm}
                    onMouseLeave={tbfmHover}
                    onClick={highlightTbfm} 
                    className={classes.nested2}>
              <ListItemText primary="TBFM" 
                            primaryTypographyProps={tbfmStyle}/>
            </ListItem>
          </Link>
          <Link to='/ChartsOTMA' className={classes.removeUnderline}>
          <ListItem button
                    disableRipple
                    onClick={highlightOpTma} 
                    onMouseOver={hoverHighlightOpTma}
                    onMouseDown ={highlightOpTma}
                    onMouseLeave={opTmaHover}
                    className={classes.nested2} >
            
              <ListItemText primary="Operational TMA" 
                            primaryTypographyProps={opTmaStyle}/>
            </ListItem>  
          </Link>
        </List>
      </Collapse>  
    </React.Fragment>
  )
}

export default LeftNavChartsTraffic;