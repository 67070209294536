import React, {useReducer} from 'react'
import AuditProductInterestReducer from "./auditProductInterestReducer";
import AuditProductInterestContext from "./auditProductInterestContext";
import {SET_CHART, SET_CHART_COLUMNS, SET_CHART_LOADING} from "../types";

const AuditProductInterestState = props => {
    const initialState = {
        axes: [],
        chartLoading: false,
        chartFiles: [],
        chartTitle: 'Daily Product Search',
        chartHAxisTitle: 'Date',
        chartVAxisTitle: 'Count'
    }

    const [state, dispatch] = useReducer(AuditProductInterestReducer, initialState);

    const setChartLoading = (chartLoading) => {
        dispatch({
            type: SET_CHART_LOADING,
            chartLoading: chartLoading
        })
    }

    const setChartFiles = (files) => {
        dispatch({
            type: SET_CHART,
            chartFiles: files
        })
    }

    const setChartColumns = (axes) => {
        dispatch({
            type: SET_CHART_COLUMNS,
            axes: axes
        })
    }

    return <AuditProductInterestContext.Provider
        value = {{
            axes: state.axes,
            chartLoading: state.chartLoading,
            chartFiles: state.chartFiles,
            chartTitle: state.chartTitle,
            chartHAxisTitle: state.chartHAxisTitle,
            chartVAxisTitle: state.chartVAxisTitle,
            cancelTokenSource: state.cancelTokenSource,
            setChartLoading,
            setChartFiles,
            setChartColumns
        }}>
        {props.children}
    </AuditProductInterestContext.Provider>
}

export default AuditProductInterestState