import React, {useContext} from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {LabelImportant} from '@material-ui/icons';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import ProductAvailabilityContext from "../../Contexts/ProductAvailability/productAvailabilityContext";

const useStyles = makeStyles(() => ({
    label: {
        backgroundColor: "#e8f5e9",
        paddingLeft: 10,
        paddingRight: 10
    },
    labelIcon: {
        color: "#4caf50"
    },
    font: {
        fontWeight: 550
    }
}));

const ProductAvailabilityLabel = () => {
    const classes = useStyles()
    let [minDate, maxDate] = ["", ""]
    const productAvailabilityContext = useContext(ProductAvailabilityContext)
    const getProductAvailability = productAvailabilityContext.getProductAvailability

    if(window.location.pathname.includes('Weather'))
        [minDate, maxDate] = getProductAvailability("WEATHER");
    if((window.location.pathname.includes('USA')) || (window.location.pathname.includes('Surf')) || (window.location.pathname.includes('Tracon')) || (window.location.pathname.includes('Center')))
        [minDate, maxDate] = getProductAvailability("FLIGHT");
    if(window.location.pathname.includes('FacRep'))
        [minDate, maxDate] = getProductAvailability("FACILITY REPORTS");
    if(window.location.pathname.includes('Fuser'))
        [minDate, maxDate] = getProductAvailability("FUSER");

    return (
        <Paper elevation={0} className={classes.label}>
            <Grid container spacing={1}>
                <Grid item>
                    <LabelImportant className={classes.labelIcon}/>
                </Grid>
                <Grid item alignContent="center">
                    {minDate === "" ?
                        <Typography variant="body1" className={classes.font}>Latest Data Available</Typography>
                        :
                        <Typography variant="body1" className={classes.font}>Latest Data {minDate} to {maxDate}</Typography>
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}

export default ProductAvailabilityLabel