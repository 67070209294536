import React, {useContext} from 'react';
import Modal from "@material-ui/core/Modal";
import {makeStyles} from "@material-ui/core/styles";
import {LockOutlined} from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import UserContext from "../../Contexts/User/userContext";
import Grid from "@material-ui/core/Grid";
import LoginButton from "../Buttons/LoginButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute',
        width: 700,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center'
    },
    horizontalLine: {
        borderColor: theme.palette.primary.main,
        marginTop: -10
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: 50
    },
    heading: {
        marginLeft: 15
    }
}))

const getRandomNumber = (min, max) => {
    let c = window.crypto.getRandomValues(new Uint32Array(1))[0]/2**32|0

    return min+(max-min+1)*c
}

const getModalStyle = () => {
    const random = getRandomNumber(-10, 10)
    const top = 50 + random;
    const left = 50 + random;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const LoginDialog = () => {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle);
    const onboardingGuide = process.env.PUBLIC_URL + "/reference/Onboarding_Guide.pdf"

    const {user, loading} = useContext(UserContext)
    let open = user.auid === undefined || user.auid == null

    return (
        <Modal
            open={open}
        >
            <div className={classes.modal} style={modalStyle}>
                <Grid container direction="row" alignItems="center">
                    <LockOutlined className={classes.icon}/>
                    <h2 className={classes.heading}>Login to Open Data Portal</h2>
                </Grid>
                <hr className={classes.horizontalLine}/>
                {loading && window.location.pathname.includes('Home') ?
                    <Grid>
                        <h4>Verifying user credentials ...</h4>
                        <CircularProgress/>
                    </Grid>
                    :
                    <Grid>
                        <h4>If you already have an account, please click the login button.</h4>
                        <LoginButton/>
                        <h5>Otherwise, please see the <Link href={onboardingGuide} target='_blank'>Onboarding Guide</Link> to create NASA Guest account to login to Open Data Portal.</h5>
                    </Grid>
                }
            </div>
        </Modal>
    )
}

export default LoginDialog