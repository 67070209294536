import React from 'react';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {makeStyles} from "@material-ui/core/styles";
import {chromeTabsStylesHook} from '@mui-treasury/styles/tabs';

const useStyles = makeStyles(() => ({
    tabBar: {
        marginLeft: 25,
        // marginRight: "50%",
        marginTop: 15,
        width: '80%'
    },
    tabs: {
        backgroundColor: '#5b93bf',
        color: '#fff',
        marginRight: 2,
    }
}))

const TabPanel = (props) => {
    const classes = useStyles()
    const tabsStyles = chromeTabsStylesHook .useTabs();
    const tabItemStyles = chromeTabsStylesHook .useTabItem();


    return (
        <div className={classes.tabBar}>
            <Tabs classes={tabsStyles}
                  value={props.tabValue}
                  onChange={props.handleTabs}
                  variant="scrollable"
            > {Object.entries(props.panelists).map(([name, label]) => {return (
                <Tab className={classes.tabs} classes={tabItemStyles} value={name} label={label.name} disabled={props.activateTabs ? false : true}/>)})}
            </Tabs>
        </div>
    )
}

export default TabPanel