import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: 550,
        marginLeft: 30,
        marginTop: 75,
        marginBottom: -10
    },
    horizontalLine: {
        borderColor: theme.palette.primary.main,
        marginBottom: -16
    }
}))

const HeaderTitle = (props) => {
    const classes = useStyles()

    return (
        <header>
            <Grid container>
                <Grid item>
                    <Typography gutterBottom variant='subtitle2' className={classes.title}>
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
            <hr className={classes.horizontalLine}/>
        </header>
    )
}

export default HeaderTitle