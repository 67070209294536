import React, {useContext} from 'react'
import Grid from "@material-ui/core/Grid";
import DataProductCheckbox from "../../Selections/DataProductCheckbox";
import CenterReportContext from "../../../Contexts/CenterReport/centerReportContext";
import axios from "axios";
import FacilitiesLabelPanel from "../../Panels/FacilitiesLabelPanel";
import SearchButton from "../../Buttons/SearchButton";
import LoadDialog from "../../Modals/LoadDialog";
import LinearProgress from '@material-ui/core/LinearProgress';
import StartDateContext from "../../../Contexts/StartDate/startDateContext";
import EndDateContext from "../../../Contexts/EndDate/endDateContext";
import Paper from "@material-ui/core/Paper";
import FDFacilityTable from "../../Tables/FDFacilityTable";
import {fileDowloadUrl, hostname} from "../../Data/api";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    panel: {
        backgroundColor: theme.palette.common.lighterGray
    }
}))

const CenterReportPanel = () => {
    const classes = useStyles()

    const centerReportContext = useContext(CenterReportContext)
    const {dataProducts, facilities, clicked, tableLoading, files, cancelTokenSource} = centerReportContext
    const setDataProducts = centerReportContext.setDataProducts
    const setFacilities = centerReportContext.setFacilities
    const setClicked = centerReportContext.setClicked
    const setTableLoading = centerReportContext.setTableLoading
    const setFiles = centerReportContext.setFiles
    const resetCancelToken = centerReportContext.resetCancelToken

    const [loading, setLoading] = React.useState(false)
    const [cancel, setCancel] = React.useState(false)

    const startDateContext = useContext(StartDateContext)
    const {startDate, startTime} = startDateContext
    const endDateContext = useContext(EndDateContext)
    const {endDate, endTime} = endDateContext
    // console.log("ATAC Facility Report Center ", startDate, startTime, endDate, endTime)

    const activateTabs = dataProducts.instantaneousCountsStatic.checked || dataProducts.instantaneousCountsDynamic.checked || dataProducts.sectorActivity.checked || dataProducts.sectorStatsStatic.checked || dataProducts.sectorStatsDynamic.checked

    const activateSearch = dataProducts.instantaneousCountsStatic.checked || dataProducts.instantaneousCountsDynamic.checked ||dataProducts.sectorActivity.checked || dataProducts.sectorStatsStatic.checked || dataProducts.sectorStatsDynamic.checked

    const handleChange = (event) => {
        const {name, checked} = event.target

        let updatedDataProducts2
        let dataProduct = Object.assign({}, dataProducts[name], {checked: checked})
        updatedDataProducts2 = Object.assign(
            {}, dataProducts, {[name]: dataProduct})

        setDataProducts(updatedDataProducts2)
    }

    const handleFacility = (event) => {
        const {name, checked} = event.target

        const updatedFacility = facilities.value.map(facility => {
            if (facility.name === name) {
                facility.checked = !facility.checked
            }
            return facility
        })


        let updatedFacilities = Object.assign(
            {}, facilities, {value: updatedFacility})

        setFacilities(updatedFacilities)
    }

    const handleUserSelection = (event) => {
        setClicked(true)
        setTableLoading(true)
        // const startDate = "20190202"
        // const endDate = "20190203"

        const start = startDate.toString().replace(/\//g,'')
        const end = endDate.toString().replace(/\//g,'')

        // console.log("Cleaned dates", start, end)
        //
        // console.log("User selected facilities")
        // console.log(facilities)

        let query = ""
        let queryFacilityICS = ""
        let queryFacilityICD = ""
        let queryFacilitySA = ""
        let queryFacilitySSS = ""
        let queryFacilitySSD = ""
        // let queryFacilitySE = ""
        // let queryFacilitySR = ""

        Object.entries(dataProducts).forEach(([key, value]) => {
            value.checked && (query += value.value)
        })


        dataProducts.instantaneousCountsStatic.checked && (
            Object.entries(facilities.value).forEach(([index,facility]) => {
                facility.checked && (queryFacilityICS += dataProducts.instantaneousCountsStatic.value + " " + facility.value)
            })
        )
        dataProducts.instantaneousCountsDynamic.checked && (
            Object.entries(facilities.value).forEach(([index,facility]) => {
                facility.checked && (queryFacilityICD += dataProducts.instantaneousCountsDynamic.value + " " + facility.value)
            })
        )
        dataProducts.sectorActivity.checked && (
            Object.entries(facilities.value).forEach(([index,facility]) => {
                facility.checked && (queryFacilitySA += dataProducts.sectorActivity.value + " " + facility.value)
            })
        )
        dataProducts.sectorStatsStatic.checked && (
            Object.entries(facilities.value).forEach(([index,facility]) => {
                facility.checked && (queryFacilitySSS += dataProducts.sectorStatsStatic.value + " " + facility.value)
            })
        )
        dataProducts.sectorStatsDynamic.checked && (
            Object.entries(facilities.value).forEach(([index,facility]) => {
                facility.checked && (queryFacilitySSD += dataProducts.sectorStatsDynamic.value + " " + facility.value)
            })
        )
        // dataProducts.sectorizationEvents.checked && (
        //     Object.entries(facilities.value).forEach(([index,facility]) => {
        //         facility.checked && (queryFacilitySE += dataProducts.sectorizationEvents.value + " " + facility.value)
        //     })
        // )
        // dataProducts.sectorizationRecords.checked && (
        //     Object.entries(facilities.value).forEach(([index,facility]) => {
        //         facility.checked && (queryFacilitySR += dataProducts.sectorizationRecords.value + " " + facility.value)
        //     })
        // )

        // console.log("Query ", query)
        // console.log("Query Facility ICS", queryFacilityICS)
        // console.log("Query Facility ICD", queryFacilityICD)
        // console.log("Query Facility SA", queryFacilitySA)
        // console.log("Query Facility SSS", queryFacilitySSS)
        // console.log("Query Facility SSD", queryFacilitySSD)
        // console.log("Query Facility SE", queryFacilitySE)
        // console.log("Query Facility SR", queryFacilitySR)
        //
        // console.log("API", "http://localhost:8080/filedownload-0.0.1-SNAPSHOT/search/file/"+start+"/"+end+"?dataProducts="+queryFacilityICS+queryFacilityICD+queryFacilitySA+queryFacilitySSS+queryFacilitySSD+queryFacilitySE+queryFacilitySR)

        // Instantaneous Counts
        // Sector Stats
        // Sector Activity
        // Sectorization Events-SRE
        // Sectorization Records-SRZ

        // TURNTOFINAL results in empty object
        // http://localhost:8080/filedownload-0.0.1-SNAPSHOT/search/file/20190202/20190203?dataProducts=GOAROUNDS%20CLT
        axios.get(hostname + fileDowloadUrl + "search/file/"+start+"/"+end+"?dataProducts="+queryFacilityICS+queryFacilityICD+queryFacilitySA+queryFacilitySSS+queryFacilitySSD)
            .then(response => {
                let filteredFiles = response.data.data.filter(entry => {
                    return Object.keys(entry).length > 0
                })
                setFiles(filteredFiles)
                setTableLoading(false)
            })
            .catch(err => {
                console.log(err)
                setTableLoading(false)
            })
    }

    const handleFilename = (rows) => {
        let dataSources = [...new Set(rows.map(index => {return (files[index]["dataSource"])}))]
        dataSources = dataSources.toString().replace(/,/g,'_')
        // console.log("Data Sources", dataSources)

        return dataSources + "_" + startDate + "_" + endDate + ".zip"
    }

    const handleClick = (rowsSelected) => {
        // const data = files
        // console.log("Click", rowsSelected)
        // console.log("Files ", files)

        // const updatedSelectedFiles = rowsSelected.map(index => {return (files[index]["path"].replace('+','%2B'))})
        const queryFile = rowsSelected.map(index => {
            return (files[index]["path"].replace('+','%2B') + "%20" +
                files[index]["dataSource"]
            )
        }).join(",")

        // console.log("Query File ", queryFile)

        // http://localhost:8080/filedownload-0.0.1-SNAPSHOT/download/file?path=/home/data/atac/SVDataLocal/RD/atl+asdex/2019/201902/20190202
        setLoading(true)
        axios.get(hostname + fileDowloadUrl + "download/file?path="+queryFile, {responseType: "blob", cancelToken: cancelTokenSource.token})
            .then(response => {
                // console.log("Download from ", response.headers)
                setLoading(false)
                const file = new Blob([response.data], {type:"file/zip"})
                const link = document.createElement("a")
                link.href = URL.createObjectURL(file)
                link.download = handleFilename(rowsSelected)
                document.body.appendChild(link)
                link.click()
            })
            .catch(err => {
                console.log(err)
            })
    }

    const table = tableLoading ? <LinearProgress/> : <FDFacilityTable files={files} handleClick={handleClick}/>

    const handleCancel = () => {
        console.log("Cancel file download")
        cancelTokenSource.cancel("Cancelled Center Report Download")
        resetCancelToken()
        setCancel(true)
        setLoading(false)
    }

    const handleActivateSearch = () => {
        let search = false
        facilities.value.forEach(facility => {
            facility.checked && (search = true)
        })
        return search
    }

    let activateFacilitySearch = handleActivateSearch()

    return (
        <Grid container justify="flex-start" direction="column">
            <Paper className={classes.panel} style={{marginBottom:20,paddingLeft:15}}>
                <Grid item>
                    <Grid container direction="row">
                        <DataProductCheckbox name={dataProducts.instantaneousCountsStatic.name} label={dataProducts.instantaneousCountsStatic.label} checked={dataProducts.instantaneousCountsStatic.checked} handleChange={handleChange}/>
                        <DataProductCheckbox name={dataProducts.sectorStatsStatic.name} label={dataProducts.sectorStatsStatic.label} checked={dataProducts.sectorStatsStatic.checked} handleChange={handleChange}/>
                        <DataProductCheckbox name={dataProducts.sectorActivity.name} label={dataProducts.sectorActivity.label} checked={dataProducts.sectorActivity.checked} handleChange={handleChange}/>
                        {/*<DataProductCheckbox name={dataProducts.sectorizationEvents.name} label={dataProducts.sectorizationEvents.label} checked={dataProducts.sectorizationEvents.checked} handleChange={handleChange}/>*/}
                        {/*<DataProductCheckbox name={dataProducts.sectorizationRecords.name} label={dataProducts.sectorizationRecords.label} checked={dataProducts.sectorizationRecords.checked} handleChange={handleChange}/>*/}
                    </Grid>
                    <Grid container direction="row">
                        <DataProductCheckbox name={dataProducts.instantaneousCountsDynamic.name} label={dataProducts.instantaneousCountsDynamic.label} checked={dataProducts.instantaneousCountsDynamic.checked} handleChange={handleChange}/>
                        <DataProductCheckbox name={dataProducts.sectorStatsDynamic.name} label={dataProducts.sectorStatsDynamic.label} checked={dataProducts.sectorStatsDynamic.checked} handleChange={handleChange}/>
                    </Grid>
                </Grid>
                <FacilitiesLabelPanel facilities={facilities} handleFacility={handleFacility} activateTabs={activateTabs}/>
            </Paper>
            <SearchButton activateSearch={activateSearch&&activateFacilitySearch} handleUserSelection={handleUserSelection}/>
            {clicked && table}
            {loading && <LoadDialog load={loading} handleClose={handleCancel}/>}
        </Grid>
    )
}

export default CenterReportPanel