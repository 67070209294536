import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import LeftNavGeneric from '../Buttons/LeftNavGeneric';
import sideNavContext from '../../Contexts/SideNav/sideNavContext'
import LeftNavDownloadATACFlight from "../Buttons/LeftNavDownloadATACFlight";
import LeftNavTooltip from "../Buttons/LeftNavTooltip";
import HorizontalLine from "./HorizontalLine";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 245,
    background: '#eceff1',
    //This is the padding between the end date and the menu.
    paddingTop: theme.spacing(2),
  },
  removeUnderline: {
    textDecoration: 'none',
    paddingBottom: theme.spacing(0),
  },
  removePadding: {
    marginBottom: theme.spacing(-1),
  },
}));

export default function LeftNavDownload() {
  const classes = useStyles();
  const SideNavContext = useContext(sideNavContext)
  const {weatherFont, usaFont, surfaceFont, traconFont, centerFont, facRepFont, fuserFont} = SideNavContext

  const highlightWeather = SideNavContext.highlightWeather
  const hoverHighlightWeather = SideNavContext.hoverHighlightWeather
  const unhighlightWeather = SideNavContext.unhighlightWeather

  const highlightFacRep = SideNavContext.highlightFacRep
  const hoverHighlightFacRep = SideNavContext.hoverHighlightFacRep
  const unhighlightFacRep = SideNavContext.unhighlightFacRep

  const highlightFuser = SideNavContext.highlightFuser
  const hoverHighlightFuser = SideNavContext.hoverHighlightFuser
  const unhighlightFuser = SideNavContext.unhighlightFuser

  const fontStyleWeather = {variant: weatherFont, color: 'primary', }
  const fontStyleFacRep = {variant: facRepFont, color: 'primary', }
  const fontStyleFuser = {variant: fuserFont, color: 'primary', }

  let weatherHover
  let facRepHover
  let fuserHover

  React.useEffect(() => {
    if(window.location.pathname.includes('Weather'))
      (async () => await highlightWeather())();
    if(window.location.pathname.includes('FacRep'))
      (async () => await highlightFacRep())();
    if(window.location.pathname.includes('Fuser'))
      (async () => await highlightFuser())();
  }, []);

  weatherHover = window.location.pathname.includes('Weather') ? hoverHighlightWeather : unhighlightWeather
  facRepHover = window.location.pathname.includes('FacRep') ? hoverHighlightFacRep : unhighlightFacRep
  fuserHover = window.location.pathname.includes('Fuser') ? hoverHighlightFuser : unhighlightFuser

  return (
    <List
      component="nav"
      dense
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <LeftNavGeneric link={'/DownloadWeather'}
                      label={'Weather'}
                      primaryTypographyProps={fontStyleWeather}
                      onMouseOver={hoverHighlightWeather}
                      onMouseLeave={weatherHover}
                      onClick={highlightWeather}/>
      <LeftNavDownloadATACFlight/>
      <LeftNavGeneric link={'/DownloadFacRep'}
                      label={'Facility Reports'}
                      onMouseOver={hoverHighlightFacRep}
                      onMouseLeave={facRepHover}
                      primaryTypographyProps={fontStyleFacRep}
                      onClick={highlightFacRep}/>
      <HorizontalLine/>
      <LeftNavTooltip link={'/DownloadFuser'}
                      label={'Fuser Data'}
                      availabilityMessage={'Available from 2022-08-31 to 2022-12-03'}
                      onMouseOver={hoverHighlightFuser}
                      onMouseLeave={fuserHover}
                      primaryTypographyProps={fontStyleFuser}
                      onClick={highlightFuser}/>
    </List>
  );
}
