import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import {Link} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 270,
    background: 'linear-gradient(90deg, #cfd8dc 60%, #78909c 120%)',
    //This is the padding between the end date and the menu.
    paddingTop: theme.spacing(1),
  },
  removeUnderline: {
    textDecoration: 'none',
    paddingBottom: theme.spacing(0),
  },
  removePadding: {
    marginBottom: theme.spacing(-1),
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  underline: {
    borderBottomStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#01579b',
    marginLeft: '15px',
    marginRight: '23px',
    paddingLeft: '0px',
  }
}));

function LeftNavGeneric(props) {
  const classes = useStyles();

  return (
    <div className={classes.underline}>
      <Link to={props.link} 
            onClick={props.onClick} 
            className={classes.removeUnderline}>
        <ListItem button
                  disableRipple
                  onMouseOver={props.onMouseOver}
                  onMouseLeave={props.onMouseLeave}
                  disableGutters className={classes.removePadding} >
        
          <ListItemText primary={props.label} 
                        primaryTypographyProps={props.primaryTypographyProps}/>
        </ListItem>
      </Link>
    </div>
  )
}

export default LeftNavGeneric;