import React from 'react'

export const handleDataProductCheckboxes = (name, checked, dataProducts, setDataProducts) => {
    Object.entries(dataProducts).forEach(([key, dataProduct]) => {
        name === "all" ?
            dataProduct.name === name ? dataProduct.checked = true : dataProduct.checked = false
            :
            dataProduct.name === "all" ? dataProduct.checked = false : dataProduct.name === name && (dataProduct.checked = !dataProduct.checked)

        let updatedDataProducts = Object.assign({}, dataProducts, {[key]: dataProduct})
        setDataProducts(updatedDataProducts)
    })
}

const handleUpdatedType = (name, types) => {
    const type = Object.entries(types).map(([key, type]) => {
        name === "All" ?
            type.name === name ? type.checked = true : type.checked = false
            :
            type.name === "All" ? type.checked = false : type.name === name && (type.checked = !type.checked)
        return type
    })

    return type
}

const handleAllType = (types) => {
    let name = "All"
    let checkedSize = 0
    Object.entries(types).forEach(([key, value]) => {
        value.checked && (checkedSize += 1)
    })
    console.log("Values checked", checkedSize, types.length - 1)
    let updatedAllTypes = checkedSize === types.length - 1 ? handleUpdatedType(name, types) : types

    return updatedAllTypes
}

export const handleTypesLabelPanel = (name, types, setTypes) => {
    let updatedType = handleUpdatedType(name, types.value)
    console.log("Updated type", updatedType)

    updatedType = name === "All" ? updatedType : handleAllType(updatedType)

    let updatedTypes = Object.assign({}, types, {value: updatedType})

    setTypes(updatedTypes)
}