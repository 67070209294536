import {traconReportFacilities} from "./facilityData";

const traconReportData = traconReportFacilities.map((name, id) => {return (
    {
        id: id,
        name: name,
        checked: false,
        value: name + ","
    }
)})

export default traconReportData