import React, {useState} from 'react';
import MUIDataTable from 'mui-datatables';
import {makeStyles, ThemeProvider, useTheme} from "@material-ui/core/styles";
import CustomFooter from "./CustomFooter";
import {FDTableTheme} from "../../Themes/FDTableTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
    table: {
        overflow: 'auto',
        marginRight: '30%',
        marginTop: 16,
        [theme.breakpoints.down('md')]:{
            marginRight: '30%',
        },
        [theme.breakpoints.down('sm')]:{
            marginRight: '5%',
        },
        [theme.breakpoints.down('xs')]:{
            marginRight: '5%',
        }
    },
    stackedCommonEdit: {
        zIndex: '1',
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block',
            height: 'auto',
            width: 'calc(100%)',
            boxSizing: 'border-box',
        }
    },
}))

const FDFacilityTable = (props) => {
    const classes = useStyles()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const columns = ["date", "dataSource", "count", "size", "status"]

    const columnNames = matches ? [
            {name: "File Date"},
            {name: "Name"},
            {name: "Count", options: {setCellProps: value => {
                        return {style: {color: `${value === "Complete" ? "green" : "red"}`}}}}},
            {name: "File Size"},
    ] :
    [
        {name: "File Date"},
        {name: "Name"},
        {name: "Count"},
        {name: "File Size"},
        {name: "Status", options: {setCellProps: value => {
                    return {style: {color: `${value === "Complete" ? "green" : "red"}`}}}}},
    ]

    const [rowsSelected, setRowsSelected] = useState([])
    const [selectionSize, setSelectionSize] = useState([])

    const data = props.files
        .filter(item => item && Object.keys(item).length > 0)
        .map(item =>{ return (columns.map(col =>
                col === 'dataSource' && item[col] !== undefined ? item[col] + '_ ' + item['facility'].toUpperCase() : item[col]
            ))}
        )

    const options = {
        filterType: "checkbox",
        rowsSelected: rowsSelected,
        onRowsSelect: (rowsSelected, allRows) => {
            // console.log("All rows", allRows)
            setRowsSelected(allRows.map(row => row.dataIndex))
            setSelectionSize(allRows.map(row => props.files[row.dataIndex]["size"]))
        },
        search: false,
        download: false,
        print: false,
        filter: false,
        viewColumns: false,
        responsive: "scrollMaxHeight",
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) =>
            <CustomFooter
                dataLength={data.length}
                rowsSelected={rowsSelected}
                selectionSize={selectionSize}
                handleClick={props.handleClick}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                changeRowsPerPage={changeRowsPerPage}
                changePage={changePage}
                textLabels={textLabels}
            />
    }

    return (
        <ThemeProvider theme={FDTableTheme}>
            <div className={classes.table}>
                <MUIDataTable
                    className={classes.stackedCommonEdit}
                    data={data}
                    columns={columnNames}
                    options={options}
                />
            </div>
        </ThemeProvider>
    )
}
export default FDFacilityTable