import React, {useReducer} from 'react'
import SupportContext from "./supportContext";
import SupportReducer from "./supportReducer";
import {SET_OPEN} from "../types";
import {basename} from "../../components/Data/api";

const SupportState = props => {
    const initialState = {
        open: window.location.pathname === basename + "/Home"
    }

    const [state, dispatch] = useReducer(SupportReducer, initialState);

    const setOpen = (open) => {
        dispatch({
            type: SET_OPEN,
            open: open
        })
    }

    return <SupportContext.Provider
        value = {{
            open: state.open,
            setOpen
        }}>
        {props.children}
    </SupportContext.Provider>
}

export default SupportState