import React from 'react'
import {SET_CHART, SET_CHART_LOADING} from "../types";

export default (state, action) => {
    console.log("Audit Product Interest Details Reducer ", state, action.chartFiles)
    switch (action.type) {
        case SET_CHART_LOADING:
            return {
                ...state,
                chartLoading: action.chartLoading
            }
        case SET_CHART:
            return {
                ...state,
                chartFiles: action.chartFiles
            }
        default:
            return state
    }
}