import React from 'react';
import Modal from "@material-ui/core/Modal";
import {makeStyles} from "@material-ui/core/styles";
import {Send} from "@material-ui/icons";
import {basename, hostname} from "../Data/api";

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute',
        width: 250,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center'
    },
    sendIcon: {
        color: '#00e676',
        fontSize: 75
    },
    text: {
        fontWeight: 500
    }
}))

const getRandomNumber = (min, max) => {
    let c = window.crypto.getRandomValues(new Uint32Array(1))[0]/2**32|0

    return min+(max-min+1)*c
}

const getModalStyle = () => {
    const random = getRandomNumber(-10, 10)
    const top = 50 + random;
    const left = 50 + random;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const SendDialog = (props) => {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle);
    const {sent} = props

    React.useEffect(() => {
        if (sent)
            setTimeout( () => {
                window.location.href = hostname.slice(0,-1) + basename + "/Home"
            }, 1000)
    }, [sent])

    return (
        <Modal
            open={sent}
        >
            <div className={classes.modal} style={modalStyle}>
                <Send className={classes.sendIcon}/>
                <h3>Feedback Sent</h3>
                <h5 className={classes.text}>Thank you for your feedback</h5>
                <h5 className={classes.text}>You will be redirected in a few seconds</h5>
            </div>
        </Modal>
    )
}

export default SendDialog