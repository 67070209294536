import React, { useContext } from 'react'
import sideNavContext from '../../Contexts/SideNav/sideNavContext'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 290,
    background: 'linear-gradient(90deg, #cfd8dc 60%, #78909c 120%)',
    //This is the padding between the end date and the menu.
    paddingTop: theme.spacing(1),
  },
  nested1: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '8px',
    height: '15px',
    width: '270px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  nested2: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '5px',
    height: '15px',
    width: '270px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  removeUnderline: {
    textDecoration: 'none',
    paddingBottom: theme.spacing(0),
  },
  removePadding: {
    marginBottom: theme.spacing(-1),
    paddingTop: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  underline: {
    borderBottomStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#01579b',
    marginLeft: '15px',
    marginRight: '23px',
    paddingLeft: '0px',
  },
}));

function LeftNavChartsAdditional(props) {
  const classes = useStyles();
  const SideNavContext = useContext(sideNavContext)
  const facEquipFont = SideNavContext.facEquipFont
  const atisFont = SideNavContext.atisFont
  const asdiFont = SideNavContext.asdiFont
  const additionalMenuFont = SideNavContext.additionalMenuFont
  const toggleAdditionalMenu = SideNavContext.toggleAdditionalMenu
  const highlightAdditionalMenu = SideNavContext.highlightAdditionalMenu
  const highlightFacEquip = SideNavContext.highlightFacEquip
  const highlightAsdi = SideNavContext.highlightAsdi
  const highlightAtis = SideNavContext.highlightAtis
  const unhighlightAdditionalMenu = SideNavContext.unhighlightAdditionalMenu
  const unhighlightFacEquip = SideNavContext.unhighlightFacEquip
  const unhighlightAsdi = SideNavContext.unhighlightAsdi
  const unhighlightAtis = SideNavContext.unhighlightAtis
  const hoverHighlightFacEquip = SideNavContext.hoverHighlightFacEquip
  const hoverHighlightAsdi = SideNavContext.hoverHighlightAsdi
  const hoverHighlightAtis = SideNavContext.hoverHighlightAtis
  const additionalMenuState = SideNavContext.additionalMenuState
  const additionalStyle = { variant: additionalMenuFont, color: 'primary' }
  const facEquipStyle = { variant: facEquipFont, color: 'primary' }
  const atisStyle = { variant: atisFont, color: 'primary' }
  const asdiStyle = { variant: asdiFont, color: 'primary' }
  let addtionalClickMethod
  let facEquipHover
  let asdiHover
  let atisHover

  if (( facEquipFont === 'overline' ||
        asdiFont === 'overline' ||
        atisFont === 'overline' ) &&
        additionalMenuFont === 'body1' ) {
        addtionalClickMethod = async function  () {
          await Promise.all ([ toggleAdditionalMenu(), highlightAdditionalMenu() ])
    }
  }
  else if (( facEquipFont === 'overline' ||
              asdiFont === 'overline' ||
              atisFont === 'overline' ) &&
              additionalMenuFont === 'body2' ) {
              addtionalClickMethod = async function  () {
                await Promise.all ([ toggleAdditionalMenu(), unhighlightAdditionalMenu() ])
        }
      }
  else addtionalClickMethod = toggleAdditionalMenu

  if(window.location.pathname.includes('ChartsFacEquip')) {
    facEquipHover = hoverHighlightFacEquip
  } else {
    facEquipHover = unhighlightFacEquip
  }

  if(window.location.pathname.includes('ChartsATIS')) {
    atisHover = hoverHighlightAtis
  } else {
    atisHover = unhighlightAtis
  }

  if(window.location.pathname.includes('ChartsASDI')) {
    asdiHover = hoverHighlightAsdi
  } else {
    asdiHover = unhighlightAsdi
  }

  return (
    <React.Fragment>
      <div className={classes.underline}>
        <ListItem button 
                  disableRipple
                  onClick={addtionalClickMethod} 
                  disableGutters 
                  className={classes.removePadding}>
          <ListItemText primary='Additional Data'   
                        primaryTypographyProps={additionalStyle}/>
          {additionalMenuState ? <ExpandLess color='primary'/> : <ExpandMore color='primary'/>}
        </ListItem>
      </div>
      <Collapse in={additionalMenuState} timeout="auto" unmountOnExit>
        <List component="div" disablePadding >
          <Link to='/ChartsFacEquip' 
                    className={classes.removeUnderline}>
            <ListItem button 
                      disableRipple
                      onMouseOver={hoverHighlightFacEquip}
                      onMouseDown ={highlightFacEquip}
                      onMouseLeave={facEquipHover}
                      className={classes.nested1}
                      onClick={highlightFacEquip} >
                <ListItemText primary="Facilities & Equipment" 
                              primaryTypographyProps={facEquipStyle}/>
              </ListItem>
          </Link>
          <Link to='/ChartsASDI'
                  className={classes.removeUnderline}>          
            <ListItem button 
                      disableRipple
                      onMouseOver={hoverHighlightAsdi}
                      onMouseDown ={highlightAsdi}
                      onMouseLeave={asdiHover}
                      className={classes.nested2}
                      onClick={highlightAsdi}>
                <ListItemText primary="ASDI Track Analysis" 
                              primaryTypographyProps={asdiStyle}/>
            </ListItem>
          </Link>
          <Link to='/ChartsATIS' 
                className={classes.removeUnderline}>
            <ListItem button 
                      disableRipple
                      onMouseOver={hoverHighlightAtis}
                      onMouseDown ={highlightAtis}
                      onMouseLeave={atisHover}
                      className={classes.nested2}
                      onClick={highlightAtis}>
              <ListItemText primary="ATIS (Authorization Req)" 
                            primaryTypographyProps={atisStyle}/>
            </ListItem>
          </Link>
        </List>
      </Collapse>  
    </React.Fragment>
  )
}

export default LeftNavChartsAdditional;
