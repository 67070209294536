import React, {useReducer} from 'react'
import TopNavContext from './topNavContext'
import TopNavReducer from './topNavReducer'
import {
    BOLD_TOP_NAV_CHARTS_FONT,
    BOLD_TOP_NAV_DOWNLOAD_FONT,
    BOLD_TOP_NAV_MAP_FONT,
    BOLD_TOP_SM_NAV_DOWNLOAD_FONT,
    HIGHLIGHT_TOP_NAV_CHARTS_BUTTON,
    HIGHLIGHT_TOP_NAV_CHARTS_FONT,
    HIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON,
    HIGHLIGHT_TOP_NAV_DOWNLOAD_FONT,
    HIGHLIGHT_TOP_NAV_MAP_BUTTON,
    HIGHLIGHT_TOP_NAV_MAP_FONT,
    HOVER_HIGHLIGHT_TOP_NAV_CHARTS_BUTTON,
    HOVER_HIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON,
    HOVER_HIGHLIGHT_TOP_NAV_MAP_BUTTON,
    RESET_TOP_NAV_ALL,
    SET_MENU_ICON_ON,
    UNHIGHLIGHT_TOP_NAV_CHARTS_BUTTON,
    UNHIGHLIGHT_TOP_NAV_CHARTS_FONT,
    UNHIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON,
    UNHIGHLIGHT_TOP_NAV_DOWNLOAD_FONT,
    UNHIGHLIGHT_TOP_NAV_MAP_BUTTON,
    UNHIGHLIGHT_TOP_NAV_MAP_FONT
} from '../types'

const TopNavState = props => {
    const initialState = {
        downloadLinkFont:   'subtitle1',
        chartsLinkFont:     'subtitle1',
        mapLinkFont:        'subtitle1',
        smDownloadLinkFont: 'caption',
        menuIcon:           '',
        downloadButtonColor: 'primary',
        chartsButtonColor: 'primary',
        mapButtonColor: 'primary',
        downloadFontColor: '#232323',
        chartsFontColor: '#232323',
        mapFontColor: '#232323'
  }

  const [state, dispatch] = useReducer(TopNavReducer, initialState);

  //Highlight the clicked/active link.
  const boldTopNavDownloadFont = () => (
    dispatch({
      type: BOLD_TOP_NAV_DOWNLOAD_FONT
    })
  )

  const boldTopNavChartsFont = () => (
    dispatch({
      type: BOLD_TOP_NAV_CHARTS_FONT
    })
  )

  const boldTopNavMapFont = () => (
    dispatch({
      type: BOLD_TOP_NAV_MAP_FONT
    })
  )

    const highlightTopNavDownloadButton = () => (
        dispatch({
            type: HIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON
        })
    )

    const highlightTopNavChartsButton = () => (
        dispatch({
            type: HIGHLIGHT_TOP_NAV_CHARTS_BUTTON
        })
    )

    const highlightTopNavMapButton = () => (
        dispatch({
            type: HIGHLIGHT_TOP_NAV_MAP_BUTTON
        })
    )

    const hoverHighlightTopNavDownloadButton = () => (
        dispatch({
            type: HOVER_HIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON
        })
    )

    const hoverHighlightTopNavChartsButton = () => (
        dispatch({
            type: HOVER_HIGHLIGHT_TOP_NAV_CHARTS_BUTTON
        })
    )

    const hoverHighlightTopNavMapButton = () => (
        dispatch({
            type: HOVER_HIGHLIGHT_TOP_NAV_MAP_BUTTON
        })
    )

    const unhighlightTopNavDownloadButton = () => (
        dispatch({
            type: UNHIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON
        })
    )

    const unhighlightTopNavChartsButton = () => (
        dispatch({
            type: UNHIGHLIGHT_TOP_NAV_CHARTS_BUTTON
        })
    )

    const unhighlightTopNavMapButton = () => (
        dispatch({
            type: UNHIGHLIGHT_TOP_NAV_MAP_BUTTON
        })
    )

    const highlightTopNavDownloadFont = () => (
        dispatch({
            type: HIGHLIGHT_TOP_NAV_DOWNLOAD_FONT
        })
    )

    const highlightTopNavChartsFont = () => (
        dispatch({
            type: HIGHLIGHT_TOP_NAV_CHARTS_FONT
        })
    )

    const highlightTopNavMapFont = () => (
        dispatch({
            type: HIGHLIGHT_TOP_NAV_MAP_FONT
        })
    )

    const unhighlightTopNavDownloadFont = () => (
        dispatch({
            type: UNHIGHLIGHT_TOP_NAV_DOWNLOAD_FONT
        })
    )

    const unhighlightTopNavChartsFont = () => (
        dispatch({
            type: UNHIGHLIGHT_TOP_NAV_CHARTS_FONT
        })
    )

    const unhighlightTopNavMapFont = () => (
        dispatch({
            type: UNHIGHLIGHT_TOP_NAV_MAP_FONT
        })
    )
  
  //If the user clicks "Home," all 3 links reset
  const resetTopNavAll = () => (
    dispatch({
      type: RESET_TOP_NAV_ALL
    })
  )
  
  const boldSmTopNavDownloadFont = () => (
    dispatch({
      type: BOLD_TOP_SM_NAV_DOWNLOAD_FONT
    })
  )

  const setMenuIconOn = ()  => (
    dispatch({
      type: SET_MENU_ICON_ON
    })
  )

  return <TopNavContext.Provider 
    value = {{
      downloadLinkFont:   state.downloadLinkFont,
      chartsLinkFont:     state.chartsLinkFont,
      mapLinkFont:        state.mapLinkFont,
      smDownloadLinkFont: state.smDownloadLinkFont,
      menuIcon:           state.menuIcon,
        downloadButtonColor: state.downloadButtonColor,
        chartsButtonColor: state.chartsButtonColor,
        mapButtonColor: state.mapButtonColor,
        downloadFontColor: state.downloadFontColor,
        chartsFontColor: state.chartsFontColor,
        mapFontColor: state.mapFontColor,
      boldTopNavDownloadFont,
      boldTopNavChartsFont,
      boldTopNavMapFont,
        highlightTopNavDownloadButton,
        highlightTopNavChartsButton,
        highlightTopNavMapButton,
        hoverHighlightTopNavDownloadButton,
        hoverHighlightTopNavChartsButton,
        hoverHighlightTopNavMapButton,
        unhighlightTopNavDownloadButton,
        unhighlightTopNavChartsButton,
        unhighlightTopNavMapButton,
        highlightTopNavDownloadFont,
        highlightTopNavChartsFont,
        highlightTopNavMapFont,
        unhighlightTopNavDownloadFont,
        unhighlightTopNavChartsFont,
        unhighlightTopNavMapFont,
      resetTopNavAll,
      boldSmTopNavDownloadFont,
      setMenuIconOn,
    }}>
  {props.children}
  </TopNavContext.Provider>
}

export default TopNavState