import React, {useReducer} from 'react'
import ProfileGuestActivitySummaryReducer from "./profileGuestActivitySummaryReducer";
import ProfileGuestActivitySummaryContext from "./profileGuestActivitySummaryContext";
import {SET_FILES, SET_TABLE_LOADING} from "../types";

const ProfileGuestActivitySummaryState = props => {
    const initialState = {
        tableLoading: false,
        files: []
    }

    const [state, dispatch] = useReducer(ProfileGuestActivitySummaryReducer, initialState);

    const setTableLoading = (tableLoading) => {
        dispatch({
            type: SET_TABLE_LOADING,
            tableLoading: tableLoading
        })
    }

    const setFiles = (files) => {
        dispatch({
            type: SET_FILES,
            files: files
        })
    }

    return <ProfileGuestActivitySummaryContext.Provider
        value = {{
            tableLoading: state.tableLoading,
            files: state.files,
            setTableLoading,
            setFiles
        }}>
        {props.children}
    </ProfileGuestActivitySummaryContext.Provider>
}

export default ProfileGuestActivitySummaryState