import React from 'react'
import {SET_EXPANDED} from "../types";

export default (state, action) => {
    // console.log("Home Content Reducer ", state, action.expanded)
    switch (action.type) {
        case SET_EXPANDED:
            return {
                ...state,
                expanded: action.expanded
            }
            default:
                return state
    }
}