import React, {useContext} from 'react'
import StartDateContext from "../../Contexts/StartDate/startDateContext";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import HorizontalDateWidget from "../Selections/HorizontalDateWidget";
import {makeStyles} from "@material-ui/core/styles";
import AuditContext from "../../Contexts/Audit/auditContext";
import {handleTypesLabelPanel} from "../Utils/PanelUtils";
import TypesLabelPanel from "../Panels/TypesLabelPanel";
import AuditSearch from "./AuditSearch";

const useStyles = makeStyles((theme) => ({
    panel: {
        backgroundColor: theme.palette.common.lighterGray
    }
}));

const AuditPanel = () => {
    const classes = useStyles();
    const startDateContext = useContext(StartDateContext)
    const resetTooltip = startDateContext.resetTooltip

    const auditContext = useContext(AuditContext)
    const {userTypes} = auditContext
    const setUserType = auditContext.setUserType

    const handleType = (id, value) => {
        handleTypesLabelPanel(value, userTypes, setUserType)
    }

    React.useEffect(() => {
        resetTooltip()
    }, [])

    return (
        <Paper className={classes.panel} style={{marginBottom:20,paddingLeft:15}}>
            <Grid container style={{marginTop: 30}} alignItems="center" spacing={4}>
                <Grid item>
                    <HorizontalDateWidget/>
                </Grid>
                <Grid item>
                    <TypesLabelPanel types={userTypes} handleType={handleType}/>
                </Grid>
                <Grid item>
                    <AuditSearch/>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default AuditPanel