import React from 'react';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    button: {
        // marginLeft: 30,
        marginTop: 4
    }
}))

const AuditLogButton = (props) => {
    const classes = useStyles()

    return (
        <Button className={classes.button} variant="contained" color="primary" onClick={props.handleUserSelection}>Audit Log</Button>
    )
}

export default AuditLogButton