import React from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Placeholder from "./Placeholder";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";

am4core.useTheme(am4themes_animated);

const PieChartInteractive = (props) => {
    const {chartFiles,columns,title,haxis,vaxis} = props

    const chart = React.useRef(null);

    const createInteractiveSlice = (series) => {
        series.slices.template.events.on("hit", (ev) => {
            let name = ev.target.dataItem.category
            console.log("Event clicked", name, ev.target)
            props.handleChartClick(ev.target, name)
        })
    }

    React.useLayoutEffect(() => {
        let x = am4core.create("piechartinteractivediv", am4charts.PieChart);

        let chartTitle = x.titles.create();
        chartTitle.text = title
        chartTitle.fontSize = 20

        x.marginRight = 400;

        x.data = chartFiles

        let pieSeries = x.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = columns[1];
        pieSeries.dataFields.category = columns[0];

        x.innerRadius = am4core.percent(30);

        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeOpacity = 1;

        pieSeries.slices.template.cursorOverStyle = [
            {
                "property": "cursor",
                "value": "pointer"
            }
        ];

        pieSeries.alignLabels = false;
        pieSeries.labels.template.bent = true;
        pieSeries.labels.template.radius = 3;
        pieSeries.labels.template.padding(0,0,0,0);

        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        createInteractiveSlice(pieSeries)

        x.legend = new am4charts.Legend();
        x.legend.parent = am4core.create("legenddiv", am4core.Container);
        x.legend.parent.logo.disabled = true
        x.legend.position = "right"
        x.legend.scrollable = true;

        chart.current = x

    }, []);

    return (
        chartFiles.length > 0 ?
            <Grid container>
                <Grid item md>
                    <Link to="/Profile">
                        <div id="piechartinteractivediv" style={{width: "100%", height: "450px"}}/>
                    </Link>
                </Grid>
                <Grid item>
                    <div id="legenddiv" style={{width: "100%", height: "100%"}}/>
                </Grid>
            </Grid>
            :
            <Placeholder/>
    )
}

export default PieChartInteractive