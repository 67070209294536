import React, {useContext} from 'react'
import ProfileContext from "../../Contexts/Profile/profileContext";
import ProfileGuestActivitySummaryContext
    from "../../Contexts/ProfileGuestActivitySummary/profileGuestActivitySummaryContext";
import axios from "axios";
import {auditUrl, hostname} from "../Data/api";
import StartDateContext from "../../Contexts/StartDate/startDateContext";
import EndDateContext from "../../Contexts/EndDate/endDateContext";
import AuditLogButton from "../Buttons/AuditLogButton";
import {
    handleAverageTime,
    handleFilterFiles,
    handleFilterProfileFiles,
    handleMaximumTime,
    handleTotalProducts
} from "../Utils/AuditUtils";
import UserContext from "../../Contexts/User/userContext";

const ProfileSearch = () => {
    const profileContext = useContext(ProfileContext)
    const {profile} = profileContext
    const setClicked = profileContext.setClicked
    const profileFiles = profileContext.files
    const setGuest = profileContext.setGuest
    const setTotalVisits = profileContext.setTotalVisits
    const setTotalProductInterest = profileContext.setTotalProductInterest
    const setTotalProductSecured = profileContext.setTotalProductSecured
    const setAvgSearchTime = profileContext.setAvgSearchTime
    const setMaxActiveTime = profileContext.setMaxActiveTime

    const profileGuestActivitySummaryContext = useContext(ProfileGuestActivitySummaryContext)
    const {files} = profileGuestActivitySummaryContext
    const setTableLoading = profileGuestActivitySummaryContext.setTableLoading
    const setFiles = profileGuestActivitySummaryContext.setFiles

    const userContext = useContext(UserContext)
    const {user} = userContext

    const startDateContext = useContext(StartDateContext)
    const {startDate, startTime} = startDateContext
    const endDateContext = useContext(EndDateContext)
    const {endDate, endTime} = endDateContext

    const handleUserSelection = () => {
        setClicked(true)
        setTableLoading(true)
        const start = startDate.toString().replace(/\//g, '-')
        const end = endDate.toString().replace(/\//g, '-')

        const userId = profile.value.split("(").pop().split(")")[0]
        console.log("Query ", userId)

        axios.get(hostname + auditUrl + "audit/products?starttime=" + start + " " + startTime + "&stoptime=" + end + " " + endTime)
            .then(response => {
                console.log("Real API ", response.data)

                const data = response.data.filter(item => item["userId"] === userId)
                console.log("User API", data)

                setFiles(data)
                setTableLoading(false)
            })
            .catch(err => {
                console.log(err)
                setTableLoading(false)
            })
    }

    const handleGuestInfo = () => {
        const guestFiles = handleFilterProfileFiles(profileFiles, profile.value.split("(").pop().split(")")[0])
        if (profile.value !== "" && guestFiles.length > 0) {
            const {firstName, lastName, userId, email} = guestFiles[0]
            setGuest([firstName, lastName].join(" "), userId, email)
        }
        else
            setGuest(user.name, user.auid, user.email)
        setTotalVisits(guestFiles.reduce((k, v) => k + v.count, 0))
    }

    const handleGuestActivityQuickInfo = () => {
        let guestSearchData = handleFilterFiles(files, "search")
        handleAverageTime(guestSearchData, setAvgSearchTime)
        handleMaximumTime(guestSearchData, setMaxActiveTime)
        handleTotalProducts(files, "search", "product", setTotalProductInterest)
        handleTotalProducts(files, "download", "product", setTotalProductSecured)
    }

    React.useEffect(() => {
        handleUserSelection()
    }, [])

    React.useEffect(() => {
        handleGuestInfo()
        handleGuestActivityQuickInfo()
    }, [profileFiles, files])

    return (
        <AuditLogButton handleUserSelection={handleUserSelection}/>
    )
}

export default ProfileSearch