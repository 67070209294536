import React, {useContext} from 'react'
import AuditProductSecuredDetailsContext
    from "../../../Contexts/AuditProductSecuredDetails/auditProductSecuredDetailsContext";
import AuditUserActivitySummaryContext
    from "../../../Contexts/AuditUserActivitySummary/auditUserActivitySummaryContext";
import PieChart from "../../Charts/PieChart";
import LinearProgress from "@material-ui/core/LinearProgress";
import {handleDetails} from "../../Utils/AuditUtils";

const ProductSecuredDetails = () => {
    const auditProductSecuredDetailsContext = useContext(AuditProductSecuredDetailsContext)
    const {chartLoading, axes, chartFiles} = auditProductSecuredDetailsContext
    const {chartTitle,chartHAxisTitle,chartVAxisTitle} = auditProductSecuredDetailsContext
    const setProductSecuredDetailsChartLoading = auditProductSecuredDetailsContext.setChartLoading
    const setProductSecuredDetailsChartFiles = auditProductSecuredDetailsContext.setChartFiles

    const auditUserActivitySummaryContext = useContext(AuditUserActivitySummaryContext)
    const {files} = auditUserActivitySummaryContext

    const handleProductSecuredDetails = () => {
        handleDetails(files, "download", "product", setProductSecuredDetailsChartFiles)
    }

    React.useEffect(() => {
        setProductSecuredDetailsChartLoading(true)
        handleProductSecuredDetails()
    }, [files])

    React.useEffect(() => {
        setProductSecuredDetailsChartLoading(false)
    }, [chartFiles])

    return (
        chartLoading ? <LinearProgress/> : <PieChart chartFiles={chartFiles} columns={axes} title={chartTitle} haxis={chartHAxisTitle} vaxis={chartVAxisTitle}/>
    )
}

export default ProductSecuredDetails