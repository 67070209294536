import {centerFacilities} from "./facilityData";

const centerReportData = centerFacilities.map((name, id) => {return (
    {
        id: id,
        name: name,
        checked: false,
        value: name + ","
    }
)})

export default centerReportData