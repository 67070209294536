import React, {useContext} from 'react'
import AuditProductSecuredContext from "../../../Contexts/AuditProductSecured/auditProductSecuredContext";
import AuditUserActivitySummaryContext
    from "../../../Contexts/AuditUserActivitySummary/auditUserActivitySummaryContext";
import StackedChart from "../../Charts/StackedChart";
import LinearProgress from "@material-ui/core/LinearProgress";
import {handleProductAction} from "../../Utils/AuditUtils";

const ProductSecured = () => {
    const auditProductSecuredContext = useContext(AuditProductSecuredContext)
    const {chartLoading, axes, chartFiles} = auditProductSecuredContext
    const {chartTitle,chartHAxisTitle,chartVAxisTitle} = auditProductSecuredContext
    const setProductSecuredChartLoading = auditProductSecuredContext.setChartLoading
    const setProductSecuredChartFiles = auditProductSecuredContext.setChartFiles
    const setProductSecuredChartColumns = auditProductSecuredContext.setChartColumns

    const auditUserActivitySummaryContext = useContext(AuditUserActivitySummaryContext)
    const {files} = auditUserActivitySummaryContext

    const handleProductSecured = () => {
        handleProductAction(files, "download", "product", setProductSecuredChartColumns, setProductSecuredChartFiles)
    }

    React.useEffect(() => {
        setProductSecuredChartLoading(true)
        handleProductSecured()
    }, [files])

    React.useEffect(() => {
        setProductSecuredChartLoading(false)
    }, [chartFiles])

    return (
        chartLoading ? <LinearProgress/> : <StackedChart chartFiles={chartFiles} columns={axes} title={chartTitle} haxis={chartHAxisTitle} vaxis={chartVAxisTitle}/>
    )
}

export default ProductSecured