import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";

const drawerWidth = 245;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        width: `calc(100% - ${drawerWidth}px)`,
        marginBottom: `${drawerWidth}px`,
        [theme.breakpoints.up('md')]: {
            marginLeft: `${drawerWidth}px`,
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: theme.spacing(15),
            marginRight: theme.spacing(1),
            paddingTop: 0,
            width: '100%',
        },
    },
    paper: {
        backgroundColor: "#1976d2",
        padding: 80,
        marginRight: '10%'
    },
    solidBanner: {
        backgroundColor: "#303f9f",
        alignItems: "center"
    },
    horizontalLine: {
        borderColor: theme.palette.secondary.main,
        marginTop: 25,
        marginBottom: 25
    },
    fontBold: {
        fontWeight: 700,
        color: "#fff",
        textAlign: "center"
    },
    font: {
        fontSize: 20,
        fontWeight: 500,
        color: theme.palette.common.darkGray,
        textAlign: "center"
    },
    fontWhite: {
        fontSize: 20,
        fontWeight: 500,
        color: theme.palette.common.white,
        textAlign: "center"
    },
    fontCompact: {
        fontWeight: 500,
        color: theme.palette.common.white,
        textAlign: "center",
        margin: 0
    },
    link: {
        color: theme.palette.common.white,
        textDecoration: 'underline'
    },
    toolbar: theme.mixins.toolbar,
}));

function Contact() {

    const classes = useStyles();

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Paper className={classes.paper} elevation={0}>
                <Typography variant="h4" className={classes.fontBold}>Contact Us</Typography>
                <p className={classes.fontWhite}>For questions and support, please send an email to <Link className={classes.link} href="mailto:sherlock-support@lists.nasa.gov">sherlock-support@lists.nasa.gov</Link></p>
                <br/>
                <p className={classes.fontCompact}>
                    NASA Ames Research Center<br/>
                    Sherlock<br/>
                    N210:262<br/>
                    Moffett Field, CA 94035-1000
                </p>
            </Paper>
        </main>
    )
}

export default Contact