import { createMuiTheme } from '@material-ui/core/styles';

const nasaBlue = '#0d47a1'
// const nasaBlue = '#01579b'
const nasaGray = '#eceff1'

export default createMuiTheme({
  palette: {
    common: {
      blue: `${nasaBlue}`,
      lighterGray: '#fafafa',
      lightGray: `${nasaGray}`,
      mediumGray: '#b0bec5',
      darkGray: '#90a4ae',
      white: '#fff',
      black: '#000'
    },
    primary: {
      main: `${nasaBlue}`
    },
    secondary: {
      main: '#fff'
    },
    text: {
      secondary: '#0277bd'
    },
    action: {
      hoverOpacity: 0
    }
  },
  typography: {
    fontSize: 17,
    button: {
      textTransform: "capitalize"
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1.2rem',
      textTransform: "capitalize",
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '1.2rem'
    },
    body1: {
      fontWeight: 500,
      fontSize: '1rem',
    },
    body2: {
      fontWeight: 700,
      fontSize: '1rem',
    },
    caption: {
      fontWeight: 400,
      fontSize: '.9rem',
      lineHeight: 1,
    },
    overline: {
      fontWeight: 700,
      fontSize: '.9rem',
      lineHeight: 1,
    },
  },
  overrides: {
    // to get -10 marginTop need to move the table down
    MuiTab: {
      root: {
        '&$selected': {
          flexGrow: 1,
          fontWeight: 700,
          backgroundColor: '#0d47a1',
          color: '#fff',
          marginTop: 0
        },
        marginTop: 10,
      },
    },
    MuiButton: {
      root: {
        fontSize: 16,
        textTransform: 'capitalize'
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 16
      }
    },
    MuiPopover: {
      paper: {
        // width: 300
      }
    },
    MuiDrawer: {
      paper: {
        overflow: 'hidden'
      }
    },
    MuiInputBase: {
      input: {
        fontSize: 16
      }
    },
  }
});

export const FDTableTheme = createMuiTheme({
  palette: {
    primary: {
      main: `${nasaBlue}`
    },
    // keeps row color white on select
    secondary: {
      main: '#fff'
    },
    text: {
      secondary: '#0277bd'
    },
  },
  typography: {
    fontSize: 17,
  },
  overrides: {
    MUIDataTable: {
      paper: {
        boxShadow: "none",
      },
    },
    MUIDataTableToolbar: {
      root: {
        // backgroundColor: '#fafafa',
        display: 'none'
      }
    },
    MUIDataTableToolbarSelect: {
      root: {
        display: 'none'
      }
    },
    MUIDataTableHead: {
      root: {
        backgroundColor: '#000'
      }
    },
    MUIDataTableHeadCell: {
      // changed fixedHeaderCommon to fixedHeader
      fixedHeader: {
        backgroundColor: '#eeeeee',
        color: `${nasaBlue}`,
        fontWeight: 700,
        // fontFamily: '"Calibri"',
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: '#eeeeee',
      },
    },
    MUIDataTableHeadRow: {
      root: {
        backgroundColor: '#000'
      }
    },
    // change row color on select or create a TableTheme
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(even)': {
          backgroundColor: '#fafafa'
        },
      },
      responsiveStacked: {
        selected: {
          backgroundColor: '#000'
        }
      },
      selected: {
        backgroundColor: '#000'
      }
    },
    MUIDataTableCell: {
      root: {
        paddingRight: '0px',
      }
    }
  }
})
export const CGTableTheme = createMuiTheme({
  palette: {
    primary: {
      main: `${nasaBlue}`
    }
  },
  typography: {
    fontSize: 16,
  },
  overrides: {
    MUIDataTable: {
      paper: {
        boxShadow: "none",
      },
    },
    MUIDataTableToolbar: {
      root: {
        backgroundColor: '#fafafa',
        marginTop: -15,
        // display: 'none'
        // overflowX: "auto"
      },
      icon: {
        color: `${nasaBlue}`
      }
    },
    MUIDataTableToolbarSelect: {
      root: {
        display: 'none'
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: `${nasaBlue}`,
        color: '#fff',
        fontWeight: 700,
        // fontFamily: '"Calibri"',
        whiteSpace: 'nowrap',
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: '#eeeeee',
      }
    },
    // change row color on select or create a TableTheme
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(even)': {
          backgroundColor: '#fafafa'
        },
      }
    },
    MUIDataTableCell: {
      root: {
        paddingRight: '0px',
      },
    },
  }
})