import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import WeatherMenu from '../Buttons/LeftNavChartsWeather';
import FacReports from '../Buttons/LeftNavChartsFacRep';
import LeftNavGeneric from '../Buttons/LeftNavGeneric';
import LeftNavChartsTraffic from '../Buttons/LeftNavChartsTraffic';
import LeftNavChartsMisc from '../Buttons/LeftNavChartsAdditional';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 290,
    background: 'linear-gradient(90deg, #cfd8dc 60%, #78909c 120%)',
    //This is the padding between the end date and the menu.
    paddingTop: theme.spacing(1),
  },
}));

export default function LeftNavMap() {
  const classes = useStyles();

  return (
    <List
      component="nav"
      dense
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <WeatherMenu />
      <LeftNavGeneric link='/ChartsATAC' label='ATAC Flight Data'/>
      <LeftNavGeneric link='/ChartsExelis' label='EXELIS' />
      <FacReports />
      <LeftNavChartsTraffic/>
      <LeftNavChartsMisc />
    </List>
  );
}
