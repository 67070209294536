import React from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import EqualizerIcon from '@material-ui/icons/Equalizer';

const Placeholder = () => {
    return (
        <Grid container style={{padding:15}} justify="center" alignItems="center">
            <Grid item>
                <EqualizerIcon/>
            </Grid>
            <Grid item>
                <Typography>Sorry, no data found.</Typography>
            </Grid>
        </Grid>
    )
}

export default Placeholder