import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import makeStyles from '@material-ui/styles/makeStyles'
import Content from "./HomeContent/Content";
import ContentTopHeader from "./HomeContent/ContentTopHeader";
import ContentHeader from "./HomeContent/ContentHeader";
import ContentBottomFooter from "./HomeContent/ContentBottomFooter";
import Grid from "@material-ui/core/Grid";
import LoginDialog from "../Modals/LoginDialog";

const useStyles = makeStyles((theme) => ({
  zIndex: {
    [theme.breakpoints.up('md')]: {
      zIndex: 1001,
      height: '100vh',
      background: 'white',
      paddingTop: '102px',
      overflowY: 'auto',
      overflowX: 'hidden'
    },
    [theme.breakpoints.down('sm')]: {
        zIndex: 1001,
        height: '93vh',
        background: 'white',
        paddingTop: '118px',
        overflowY: 'auto',
        overflowX: 'hidden'
      },
  },
    margin: {
        paddingLeft: 100,
        paddingRight: 100
    }
}))

export default function Home() {
  const classes = useStyles()
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position='fixed' className={classes.zIndex}>
          <LoginDialog/>
          <ContentTopHeader/>
          <Grid className={classes.margin}>
            <ContentHeader/>
            <Content/>
          </Grid>
          <ContentBottomFooter/>
       </AppBar>
    </React.Fragment>
  );
}