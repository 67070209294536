import React from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    fontBold: {
        fontWeight: 700,
        textAlign: "center"
    },
    font: {
        fontWeight: 500,
        textAlign: "center"
    },
    horizontalLine: {
        borderColor: theme.palette.secondary.main,
        marginTop: 25,
        marginBottom: 25
    }
}));

const FeedbackPrompt = () => {
    const classes = useStyles();

    return (
        <Grid>
            <Grid container alignContent="center" spacing={2} direction="column">
                <Grid item>
                    <Typography variant="h5" className={classes.fontBold}>Send Us Feedback</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.font}>We would love to hear your questions, requests or comments to help us improve your experience.</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.font}>We will get in contact with you through email.</Typography>
                </Grid>
            </Grid>
            <hr className={classes.horizontalLine}/>
        </Grid>
    )
}

export default FeedbackPrompt