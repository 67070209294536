import React from 'react';
import Modal from "@material-ui/core/Modal";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgressLabeled from "./CircularProgressLabeled";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    modal: {
        position: 'absolute',
        height: 180,
        width: 350,
        backgroundColor: '#000',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        textAlign: 'center'
    },
    heading: {
        color: '#039be5',
        marginLeft: 15
    },
    horizontalLine: {
        borderColor: theme.palette.primary.main,
        marginTop: -10
    },
    loadIcon: {
        color: '#039be5'
    },
    text: {
        color: theme.palette.common.white,
        fontWeight: 500
    },
}));

const getRandomNumber = (min, max) => {
    let c = window.crypto.getRandomValues(new Uint32Array(1))[0]/2**32|0

    return min+(max-min+1)*c
}

const getModalStyle = () => {
    const random = getRandomNumber(-10, 10)
    const top = 50 + random;
    const left = 50 + random;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const LoadDialog = (props) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    return (
        <Modal open={props.load}>
            <div className={classes.modal} style={modalStyle}>
                <h2 className={classes.heading} style={{textAlign: 'left'}}>Please Wait</h2>
                <hr className={classes.horizontalLine}/>
                <Grid container alignItems="center">
                    <Grid item xs={3}>
                        <CircularProgressLabeled className={classes.loadIcon} value={props.progress}/>
                    </Grid>
                    <Grid item xs={9}>
                        <h4 className={classes.text}>Preparing to download ...</h4>
                    </Grid>
                </Grid>
                <Button style={{color:'#039be5', marginLeft:'70%'}} onClick={props.handleClose}>Cancel</Button>
            </div>
        </Modal>
    )
}

export default LoadDialog