import React, {useReducer} from 'react'
import TraconReportReducer from "../TraconReport/traconReportReducer";
import TraconReportContext from "../TraconReport/traconReportContext";
import {
    SET_CANCEL_TOKEN_SOURCE,
    SET_CLICKED,
    SET_DATA_PRODUCTS,
    SET_FACILITIES,
    SET_FILES, SET_PROGRESS,
    SET_TABLE_LOADING
} from "../types";
import traconReportData from "../../components/Data/traconReportData";
import axios from "axios";

const TraconReportState = props => {
    const initialState = {
        dataProducts: {
            goArounds: {
                name: "goArounds",
                label: "Go Arounds",
                checked: false,
                value: "GOAROUNDS"
            },
            turnsToFinal: {
                name: "turnsToFinal",
                label: "Turns To Final",
                checked: false,
                value: "TURNTOFINAL"
            },
            fixPassing: {
                name: "fixPassing",
                label: "Fix Passing",
                checked: false,
                value: "FIXPASSING"
            },
            runwayUsage: {
                name: "runwayUsage",
                label: "Runway Usage",
                checked: false,
                value: "RUNWAYUSAGE"
            }
        },
        facilities: {
            // tracons: {
                name: "TRACONS",
                value: traconReportData
            // }
        },
        clicked: false,
        tableLoading: false,
        files: [],
        progress: 0,
        cancelTokenSource: axios.CancelToken.source()
    }

    const [state, dispatch] = useReducer(TraconReportReducer, initialState);

    const setDataProducts = (dataProducts) => {
        dispatch({
            type: SET_DATA_PRODUCTS,
            dataProducts: dataProducts
        })
    }

    const setFacilities = (facilities) => {
        dispatch({
            type: SET_FACILITIES,
            facilities: facilities
        })
    }

    const setClicked = () => {
        dispatch({
            type: SET_CLICKED
        })
    }

    const setTableLoading = (tableLoading) => {
        dispatch({
            type: SET_TABLE_LOADING,
            tableLoading: tableLoading
        })
    }

    const setFiles = (files) => {
        dispatch({
            type: SET_FILES,
            files: files
        })
    }

    const setProgress = (progress) => {
        dispatch({
            type: SET_PROGRESS,
            progress: progress
        })
    }

    const resetProgress = () => {
        dispatch({
            type: SET_PROGRESS,
            progress: 0
        })
    }

    const resetCancelToken = () => {
        dispatch({
            type: SET_CANCEL_TOKEN_SOURCE,
            cancelTokenSource: axios.CancelToken.source()
        })
    }

    return <TraconReportContext.Provider
        value = {{
            dataProducts: state.dataProducts,
            facilities: state.facilities,
            clicked: state.clicked,
            tableLoading: state.tableLoading,
            files: state.files,
            progress: state.progress,
            cancelTokenSource: state.cancelTokenSource,
            setDataProducts,
            setFacilities,
            setClicked,
            setTableLoading,
            setFiles,
            setProgress,
            resetProgress,
            resetCancelToken
        }}>
        {props.children}
    </TraconReportContext.Provider>
}

export default TraconReportState