import React from 'react'
import Banner from '../../../images/banner20.png'
import {Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles/'
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    image: {
        marginTop: '-5%',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            marginTop: 25
        }
    },
    heading: {
        color: theme.palette.common.white,
        fontSize: 56,
        fontWeight: 700,
        textAlign: 'center',
        marginTop: '-12%',
        [theme.breakpoints.down('xs')]: {
            fontSize: 28
        }
    }
}));

const ContentTopHeader = () => {
    const classes = useStyles()
    const heading = "Sherlock Open Data Portal"

    return (
        <Grid container justify="center">
            <img className={classes.image} src={Banner}/>
            <Typography className={classes.heading}>{heading}</Typography>
        </Grid>
    )
}

export default ContentTopHeader