import React, {useReducer} from 'react'
import FeedbackReducer from "./feedbackReducer";
import FeedbackContext from "./feedbackContext";
import {
    RESET_SELECTION,
    SET_AVAILABLE,
    SET_CATEGORY,
    SET_CLICKED,
    SET_LOADING,
    SET_MESSAGE,
    SET_SENT,
    SET_SUBJECT
} from "../types";

const FeedbackState = props => {
    const initialState = {
        category: "General Question",
        subject: "",
        message: "",
        clicked: false,
        loading: false,
        sent: false,
        available: true
    }

    const [state, dispatch] = useReducer(FeedbackReducer, initialState);

    const setCategory = (event, category) => {
        dispatch({
            type: SET_CATEGORY,
            category: category
        })
    }

    const setSubject = (event) => {
        let {value} = event.target
        dispatch({
            type: SET_SUBJECT,
            subject: value
        })
    }

    const setMessage = (event) => {
        let {value} = event.target
        dispatch({
            type: SET_MESSAGE,
            message: value
        })
    }

    const setClicked = (clicked) => {
        dispatch({
            type: SET_CLICKED,
            clicked: clicked
        })
    }

    const setLoading = (loading) => {
        dispatch({
            type: SET_LOADING,
            loading: loading
        })
    }

    const setSent = (sent) => {
        dispatch({
            type: SET_SENT,
            sent: sent
        })
    }

    const setAvailable = (available) => {
        dispatch({
            type: SET_AVAILABLE,
            available: available
        })
    }

    const resetSelection = () => {
        dispatch({
            type: RESET_SELECTION
        })
    }

    return <FeedbackContext.Provider
        value = {{
            category: state.category,
            subject: state.subject,
            message: state.message,
            clicked: state.clicked,
            loading: state.loading,
            sent: state.sent,
            available: state.available,
            setCategory,
            setSubject,
            setMessage,
            setClicked,
            setLoading,
            setSent,
            setAvailable,
            resetSelection
        }}>
        {props.children}
    </FeedbackContext.Provider>
}

export default FeedbackState