import React, {useReducer} from 'react'
import HomeContentReducer from "../HomeContent/homeContentReducer";
import HomeContentContext from "../HomeContent/homeContentContext";
import {SET_EXPANDED} from "../types";

const HomeContentState = props => {
    const initialState = {
        expanded: false
    }

    const [state, dispatch] = useReducer(HomeContentReducer, initialState);

    const setExpanded = (expanded) => {
        dispatch({
            type: SET_EXPANDED,
            expanded: expanded

        })
    }

    return <HomeContentContext.Provider
        value = {{
            expanded: state.expanded,
            setExpanded
        }}>
        {props.children}
    </HomeContentContext.Provider>
}

export default HomeContentState