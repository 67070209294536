import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import cx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useBlogTextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/blog';
import {useOverShadowStyles} from '@mui-treasury/styles/shadow/over';
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(({ breakpoints, spacing, transitions }) => ({
    root: {
        margin: 'auto',
        borderRadius: spacing(2), // 16px
        transition: '0.3s',
        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
        position: 'relative',
        maxHeight: 180,
        maxWidth: 500,
        marginLeft: 'auto',
        overflow: 'initial',
        background: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: spacing(2),
        [breakpoints.up('md')]: {
            flexDirection: 'row',
            paddingTop: spacing(2),
        },
    },
    media: {
        width: '88%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: spacing(-3),
        height: 0,
        paddingBottom: '48%',
        borderRadius: spacing(2),
        backgroundColor: '#fff',
        position: 'relative',
        [breakpoints.up('md')]: {
            width: '100%',
            marginLeft: spacing(-3),
            marginTop: 0,
            transform: 'translateX(-8px)',
        },
        '&:after': {
            content: '" "',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(147deg, #fe8a39 0%, #fd3838 74%)',
            borderRadius: spacing(2), // 16
            opacity: 0.5,
        },
    },
    content: {
        padding: 24,
    },
    cta: {
        marginTop: 24,
        textTransform: 'initial',
    },
    button: {
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
        color: 'white',
        height: 38,
        padding: '0 30px',
        margin: 8
    },
    link: {
        [breakpoints.down('sm')]: {
            fontSize: 12,
        },
        fontWeight: 700,
        color: '#1976d2',
        textDecoration: 'none'
    },
    icon: {
        [breakpoints.down('sm')]: {
            fontSize: 18,
        },
        fontSize: 22,
        marginBottom: -5,
        marginLeft: 5
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: transitions.create('transform', {
            duration: transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    removeUnderline: {
        textDecoration: 'none'
    }
}))

const CardWeatherDataDownload = () => {
    const styles = useStyles()
    const {
        button: buttonStyles,
        ...contentStyles
    } = useBlogTextInfoContentStyles()
    const shadowStyles = useOverShadowStyles()

    return (
        <Card className={cx(styles.root, shadowStyles.root)}>
            <CardMedia/>
            <CardContent>
                <TextInfoContent
                    classes={contentStyles}
                    heading={'Weather Data'}
                    body={
                        'User Interface for downloading weather data flat files. Select time filters and data sources to download filtered data.'
                    }
                />
                <Grid container justify="space-evenly">
                    <Grid item>
                        <Link to={'/DownloadWeather'} className={styles.link}>ASM</Link>
                    </Grid>
                    <Grid item>
                        <Link to={'/DownloadWeather'} className={styles.link}>METAR</Link>
                    </Grid>
                    <Grid item>
                        <Link to={'/DownloadWeather'} className={styles.link}>TAF</Link>
                    </Grid>
                </Grid>
                <Link to={'/DownloadHome'} className={styles.removeUnderline}>
                    <Button className={styles.button}>Download >></Button>
                </Link>
            </CardContent>
        </Card>
    )
}

export default CardWeatherDataDownload
