import React, {useContext} from 'react'
import HeaderTitle from "../Titles/HeaderTitle";
import Unauthorized from "./Unauthorized";
import UserContext from "../../Contexts/User/userContext";
import {makeStyles} from "@material-ui/core/styles";
import AuditPanel from "./AuditPanel";
import AuditQuickInfoPanel from "./AuditQuickInfoPanel";
import AuditTabPanel from "./AuditTabPanel";

const drawerWidth = 245;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        marginBottom: `${drawerWidth}px`,
        marginLeft: 5
    },
    panel: {
        backgroundColor: theme.palette.common.lighterGray
    },
    // toolbar: theme.mixins.toolbar,
}));

const Audit = () => {
    const classes = useStyles();
    const title = "Audit Report"
    const userContext = useContext(UserContext)
    const {user} = userContext

    return (
        user.roles.includes("admin") ?
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <HeaderTitle title={title}/>
                <AuditPanel/>
                <AuditQuickInfoPanel/>
                <AuditTabPanel/>
            </main>
            :
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Unauthorized/>
            </main>
    )
}

export default Audit