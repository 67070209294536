import React, {useContext} from 'react'
import axios from "axios";
import FuserContext from "../../../Contexts/Fuser/fuserContext";
import StartDateContext from "../../../Contexts/StartDate/startDateContext";
import EndDateContext from "../../../Contexts/EndDate/endDateContext";
import SearchButton from "../../Buttons/SearchButton";
import {handleActivateSearchDataProducts, handleSelectedDataProducts} from "../../Utils/SearchUtils";
import {fileDowloadUrl, hostname} from "../../Data/api";

const FuserSearch = () => {
    const fuserContext = useContext(FuserContext)
    const {dataProducts} = fuserContext
    const setClicked = fuserContext.setClicked
    const setTableLoading = fuserContext.setTableLoading
    const setFiles = fuserContext.setFiles

    const startDateContext = useContext(StartDateContext)
    const {startDate} = startDateContext
    const endDateContext = useContext(EndDateContext)
    const {endDate} = endDateContext

    const activateSearch = handleActivateSearchDataProducts(dataProducts)

    const handleUserSelection = () => {
        setClicked()
        setTableLoading(true)
        const start = startDate.toString().replace(/\//g,'')
        const end = endDate.toString().replace(/\//g,'')

        let queryDataProducts = handleSelectedDataProducts(dataProducts)
        console.log("Query", queryDataProducts)

        axios.get(hostname + fileDowloadUrl + "search/file/"+start+"/"+end+"?dataProducts="+queryDataProducts)
            .then(response => {
                console.log("Real API ", response.data.data)
                let responseData = response.data.data.filter(value => Object.keys(value).length !== 0)
                setFiles(responseData)
                setTableLoading(false)
            })
            .catch(err => {
                console.log(err)
                setTableLoading(false)
            })
    }

    return (
        <SearchButton activateSearch={activateSearch} handleUserSelection={handleUserSelection}/>
    )

}

export default FuserSearch