import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import sideNavContext from '../../Contexts/SideNav/sideNavContext'

const useStyles = makeStyles((theme) => ({
  nested1: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '8px',
    height: '15px',
    width: '270px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
      font: 'overline'
    }
  },
  nested2: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '5px',
    height: '20px',
    width: '270px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  nested3: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '5px',
    height: '15px',
    width: '290px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  nested4: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '5px',
    height: '30px',
    width: '270px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
      variant: 'overline'
    },
  },
  nested5: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: '5px',
    height: '30px',
    width: '270px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  removeUnderline: {
    textDecoration: 'none',
    paddingBottom: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  removePadding: {
    margingTop: theme.spacing(0),
    marginBottom: theme.spacing(-1),
    disableGutters: true,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    }
  },
  underline: {
    borderBottomStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#01579b',
    marginLeft: '15px',
    marginRight: '23px',
    paddingLeft: '0px',
  },
  active: {
    variant: 'body2',
  }
}));

function LeftNavChartsWeather(props) {
  const classes = useStyles();
  const SideNavContext = useContext(sideNavContext)
  const toggleWeatherMenu = SideNavContext.toggleWeatherMenu
  const highlightWeatherMenu = SideNavContext.highlightWeatherMenu
  const unhighlightWeatherMenu = SideNavContext.unhighlightWeatherMenu
  const metarFont = SideNavContext.metarFont
  const tafFont = SideNavContext.tafFont
  const witiFont = SideNavContext.witiFont
  const weatherMenuFont = SideNavContext.weatherMenuFont
  const asmFont = SideNavContext.asmFont
  const aircraftRepFont = SideNavContext.aircraftRepFont
  const weatherMenuState = SideNavContext.weatherMenuState
  const weatherStyle = { variant: weatherMenuFont, color: 'primary',}
  const aircraftRepStyle = { variant:aircraftRepFont, color:'primary',}
  const highlightAircraftRep = SideNavContext.highlightAircraftRep
  const highlightAsm = SideNavContext.highlightAsm
  const highlightMetar = SideNavContext.highlightMetar
  const highlightTaf = SideNavContext.highlightTaf
  const highlightWiti = SideNavContext.highlightWiti
  const unhighlightAirRep = SideNavContext.unhighlightAirRep
  const hoverHighlightAirRep = SideNavContext.hoverHighlightAirRep
  const hoverHighlightAsm = SideNavContext.hoverHighlightAsm
  const hoverHighlightMetar = SideNavContext.hoverHighlightMetar
  const hoverHighlightTaf = SideNavContext.hoverHighlightTaf
  const hoverHighlightWiti = SideNavContext.hoverHighlightWiti
  const unhighlightAsm = SideNavContext.unhighlightAsm
  const unhighlightMetar = SideNavContext.unhighlightMetar
  const unhighlightTaf = SideNavContext.unhighlightTaf
  const unhighlightWiti = SideNavContext.unhighlightWiti
  const metarStyle = { variant: metarFont, color: 'primary' }
  const asmStyle = { variant: asmFont, color: 'primary' }
  const witiStyle = { variant: witiFont, color: 'primary' }
  const tafStyle = { variant: tafFont, color: 'primary' }
  let weatherClickMethod
  let airRepHover
  let asmHover
  let metarHover
  let tafHover
  let witiHover

  if((aircraftRepFont === 'overline' ||  
      asmFont === 'overline'||  
      metarFont === 'overline'||
      tafFont === 'overline' ||
      witiFont === 'overline') &&
      weatherMenuFont === 'body1' ) {
        weatherClickMethod = async function () {
          await Promise.all([highlightWeatherMenu(), toggleWeatherMenu()]);
        }
  } else if ((aircraftRepFont === 'overline' ||  
              asmFont === 'overline'||  
              metarFont === 'overline'||
              tafFont === 'overline' ||
              witiFont === 'overline') &&
              weatherMenuFont === 'body2' ) {
                weatherClickMethod = async function () {
                  await Promise.all([unhighlightWeatherMenu(), toggleWeatherMenu()]);
                }
  } else {
    weatherClickMethod = async function () {
      await Promise.all([ toggleWeatherMenu()]);
    }
  }

  if(window.location.pathname.includes('ChartsAircraftReports')) {
    airRepHover = hoverHighlightAirRep
  } else {
    airRepHover = unhighlightAirRep
  }

  if(window.location.pathname.includes('ChartsASM')) {
    asmHover = hoverHighlightAsm
  } else {
    asmHover = unhighlightAsm
  }

  if(window.location.pathname.includes('ChartsMetar')) {
    metarHover = hoverHighlightMetar
  } else {
    metarHover = unhighlightMetar
  }

  if(window.location.pathname.includes('ChartsTAF')) {
    tafHover = hoverHighlightTaf
  } else {
    tafHover = unhighlightTaf
  }

  if(window.location.pathname.includes('ChartsWITI')) {
    witiHover = hoverHighlightWiti
  } else {
    witiHover = unhighlightWiti
  }
    


  return (
    <React.Fragment>
      <div className={classes.underline}>
        <ListItem button disableRipple
                  onClick={weatherClickMethod} 
                  disableGutters 
                  className={classes.removePadding}>
          <ListItemText primary='Weather' 
                        primaryTypographyProps={weatherStyle}/>
          {weatherMenuState ? <ExpandLess color='primary'/> : <ExpandMore color='primary'/>}
        </ListItem>
      </div>
      <Collapse in={weatherMenuState} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to='/ChartsAircraftReports' className={classes.removeUnderline}>
          <ListItem button
                    disableRipple 
                    onMouseOver={hoverHighlightAirRep}
                    onMouseDown ={highlightAircraftRep}
                    onMouseLeave={airRepHover}
                    className={classes.nested1}
                    onClick={highlightAircraftRep}>           
              <ListItemText primary="Aircraft Reports" 
                            //inset
                            primaryTypographyProps={aircraftRepStyle}/>
          </ListItem>
        </Link>
        <Link to='/ChartsASM' className={classes.removeUnderline}>
          <ListItem button 
                    disableRipple 
                    className={classes.nested2} 
                    onClick={highlightAsm}>
            <ListItemText primary="AirSigMets (ASM)" 
                          onMouseOver={hoverHighlightAsm}
                          onMouseLeave={asmHover}
                          primaryTypographyProps={asmStyle}/>
          </ListItem>
        </Link>
          <Link to='/ChartsMetar' className={classes.removeUnderline}>
            <ListItem button 
                      disableRipple 
                      className={classes.nested3} 
                      onClick={highlightMetar} >
              <ListItemText primary="METAR" 
                            onMouseOver={hoverHighlightMetar}
                            onMouseLeave={metarHover}
                            primaryTypographyProps={metarStyle}/>
            </ListItem>
          </Link>
          <Link to='/ChartsTAF' className={classes.removeUnderline}>
            <ListItem button 
                      disableRipple 
                      className={classes.nested4} 
                      onClick={highlightTaf}>
              <ListItemText primary="Terminal Aerodrome" 
                            primaryTypographyProps={tafStyle} 
                            secondary="Forecast (TAF)"
                            secondaryTypographyProps={tafStyle}
                            onMouseOver={hoverHighlightTaf}
                            onMouseLeave={tafHover}/>
            </ListItem>
          </Link>
          <Link to='/ChartsWITI' className={classes.removeUnderline}>
            <ListItem button 
                      disableRipple 
                      className={classes.nested5} 
                      onClick={highlightWiti}>
              <ListItemText primary="Weather Impacted" 
                            primaryTypographyProps={witiStyle}
                            secondary="Traffic Index (WITI)"
                            secondaryTypographyProps={witiStyle}
                            onMouseOver={hoverHighlightWiti}
                            onMouseLeave={witiHover}/>
            </ListItem>
          </Link>
        </List>
      </Collapse>
      </React.Fragment>
  )
}

export default LeftNavChartsWeather;