import React, {useContext} from "react";
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import EndDateContext from "../../Contexts/EndDate/endDateContext";
import StartDateContext from "../../Contexts/StartDate/startDateContext";

const nasaBlue = '#0d47a1'
// const nasaBlue = '#01579b'
const nasaGray = '#eceff1'

const muiTheme = createMuiTheme({
    palette: {
        common: {
            blue: `${nasaBlue}`,
            lightGray: `${nasaGray}`,
            mediumGray: '#b0bec5',
            darkGray: '#90a4ae',
            white: '#fff',
            black: '#000'
        },
        primary: {
            main: `${nasaBlue}`
        },
        secondary: {
            main: '#fff'
        },
        text: {
            secondary: '#0277bd'
        },
        action: {
            hoverOpacity: 0
        }
    },
    typography: {
        fontSize: 15,
        button: {
            textTransform: 'capitalize'
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: '1.5rem',
        },
        h3: {
            fontWeight: 400,
            fontSize: '1.5rem',
        },
        h4: {
            fontWeight: 400,
            fontSize: '1.5rem',
        },
    }})

const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.common.blue,
        fontSize: '1.3em',
        paddingTop: theme.spacing(.75)
    }
}));

function EndDateTime() {
    const classes = useStyles();
    const {currentDate, setEndDate, setCurrentDate, maxDate} = useContext(EndDateContext)
    const {startDate} = useContext(StartDateContext)

    const handleDate = (event, value) => {
        // console.log("Selected date", value)
        setCurrentDate(value)
        setEndDate(value)
    }

    return (
        <ThemeProvider theme={muiTheme}>
        <div style={{marginLeft:'16px'}} >
            <KeyboardDateTimePicker ampm={false}
                                    autoOk
                                    disableFuture
                                    value={currentDate}
                                    onChange={handleDate}
                                    // minuteStep={5}
                                    animateYearScrolling={true}
                                    disabledFuture
                                    label={'End Date'}
                                    format="yyyy/MM/DD HH:mm"
                                    minDate={startDate}
                                    // maxDate={maxDate}
                                    leftArrowButtonProps={{ "aria-label": "Prev month" }}
                                    rightArrowButtonProps={{ "aria-label": "Next month" }}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: { root: classes.label }
                                    }}
            />
        </div>
        </ThemeProvider>
    );
}

export default EndDateTime