import React from 'react'
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

const muiTheme = createMuiTheme({
    typography: {
        fontSize: '1em'
    }
})

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: 250,
        marginBottom: -5
    },
    disabledCheckBoxLabel: {
        color: '#9e9e9e',
    },
    grid: {
        [theme.breakpoints.up('sm')]: {
            ...theme.mixins.toolbar,
            columns: 3,
            columnGap: 0
        },
        [theme.breakpoints.up('md')]: {
            ...theme.mixins.toolbar,
            columns: 5,
            columnGap: 0
        },
    },

}))

const FacilitiesLabelPanel = (props) => {
    const classes = useStyles()

    const {value} = props

    return (
        <ThemeProvider theme={muiTheme}>
            <div role="tabpanel">
                <Typography gutterBottom variant='body1' className={classes.title}>
                    {props.facilities.name}
                </Typography>
                <Grid container justify="flex-start" direction="row">
                    {/*<Paper className={classes.paper}>*/}
                        <Box borderTop={5} borderColor={props.activateTabs ? '#01579b' : '#7ba8cb'}>
                            <div className={classes.grid}>
                                {Object.entries(props.facilities.value).map(([, facility]) => {return (
                                    <Grid item xs={12}  data-testid="facilityLabel">
                                        <FormControlLabel
                                            style={{whiteSpace:"nowrap"}}
                                            className={!props.activateTabs && classes.disabledCheckBoxLabel}
                                            control={
                                                <Checkbox
                                                    style={{color: props.activateTabs && '#01579b'}}
                                                    color="primary"
                                                    name={facility.name}
                                                    onChange={props.handleFacility}
                                                    checked={facility.checked}
                                                    size="small"
                                                />
                                            }
                                            disabled={!props.activateTabs}
                                            label={facility.name}
                                        />
                                    </Grid>
                                )})}
                            </div>
                        </Box>
                    {/*</Paper>*/}
                </Grid>
            </div>
        </ThemeProvider>
    )
}

export default FacilitiesLabelPanel