import React, {useReducer} from 'react'
import StartDateContext from "./startDateContext";
import StartDateReducer from "./startDateReducer";
import {
    RESET_TOOLTIP,
    SET_CURRENT_DATE,
    SET_DATE_TIME, SET_MIN_DATE,
    SET_START_TYPE,
    SET_TOOLTIP,
    SET_TOOLTIP_MESSAGE,
} from "../types";

const StartDateState = props => {
    const date = new Date(new Date().setHours(0,0) - 1000 * 3600 * 24 * 90)
    const initialState = {
        startType: "datetime-local",
        startDate: date.toISOString().split('T')[0].replace(/-/g, '/'),
        startTime: "00:00",
        minDate: "2020/01/01",
        currentDate: date,
        tooltip: true,
        tooltipMessage: "Select Start and End dates."
    }

    const [state, dispatch] = useReducer(StartDateReducer, initialState);

    const setStartType = (startType) => {
        dispatch({
            type: SET_START_TYPE,
            startType: startType
        })
    }

    const setStartDate = (value) => {
        // console.log("Context Handle Date ", value)
        dispatch({
            type: SET_DATE_TIME,
            startDate: value.toString().split(' ')[0],
            startTime: value.toString().split(' ')[1]
        })
    }

    const setCurrentDate = (value) => {
        dispatch({
            type: SET_CURRENT_DATE,
            currentDate: value
        })
    }

    const setMinDate = (value) => {
        dispatch({
            type: SET_MIN_DATE,
            minDate: value
        })
    }

    const setTooltip = () => {
        dispatch({
            type: SET_TOOLTIP
        })
    }

    const resetTooltip = () => {
        dispatch({
            type: RESET_TOOLTIP
        })
    }

    const setTooltipMessage = (tooltipMessage) => {
        dispatch({
            type: SET_TOOLTIP_MESSAGE,
            tooltipMessage: tooltipMessage
        })
    }

    return <StartDateContext.Provider
        value = {{
            startDate: state.startDate,
            startTime: state.startTime,
            currentDate: state.currentDate,
            minDate: state.minDate,
            startType: state.startType,
            tooltip: state.tooltip,
            tooltipMessage: state.tooltipMessage,
            setStartDate,
            setCurrentDate,
            setMinDate,
            setStartType,
            setTooltip,
            resetTooltip,
            setTooltipMessage
        }}>
        {props.children}
    </StartDateContext.Provider>
}

export default StartDateState