import React, {useContext} from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import SemiPieChartContext from "../../Contexts/SemiPieChart/semiPieChartContext";

am4core.useTheme(am4themes_animated);

const SemiPieChart = () => {
    const chart = React.useRef(null);

    const links = {
        Weather: '/DownloadWeather',
        USA: '/DownloadUSA',
        Surface: '/DownloadATACSurf',
        Tracon: '/DownloadATACTracon',
        Centers: '/DownloadATACCenter',
        FacRep: '/DownloadFacRep',
        Fuser: '/DownloadFuser'
    }

    const semiPieChartContext = useContext(SemiPieChartContext)
    const setLink = semiPieChartContext.setLink


    React.useLayoutEffect(() => {
        let x = am4core.create("chartdiv", am4charts.PieChart);

        x.data = [
            {
                dataProduct: "Weather",
                value: 410
            },
            {
                dataProduct: "USA",
                value: 400
            },
            {
                dataProduct: "Surface",
                value: 300
            },
            {
                dataProduct: "Tracon",
                value: 200
            },
            {
                dataProduct: "Centers",
                value: 165
            },
            {
                dataProduct: "FacRep",
                value: 100
            },
            {
                dataProduct: "Fuser",
                value: 50
            }
        ]
        x.radius = am4core.percent(80)
        x.innerRadius = am4core.percent(50)
        x.startAngle = 180
        x.endAngle = 360

        let series = x.series.push(new am4charts.PieSeries());
        series.dataFields.value = "value";
        series.dataFields.category = "dataProduct";

        series.alignLabels = false;

        series.labels.template.disabled = true

        series.slices.template.events.on("hit", (ev) => {
            let name = ev.target.dataItem.category
            console.log("Event clicked", ev.target, name)
            setLink(links[name])
        })

        x.cursor = new am4charts.XYCursor();


        chart.current = x

        return () => {
            x.dispose();
        };
    }, []);

    return (
        <div id="chartdiv" style={{width: "100%", height: "200px"}}/>
    )
}

export default SemiPieChart