import React, {useContext} from 'react'
import Grid from "@material-ui/core/Grid";
import InfoCard from "../../Cards/InfoCard";
import {LockOpen, DataUsage, GetApp, Timelapse, AccessTime} from "@material-ui/icons";
import ProfileGuestContext from "../../../Contexts/Profile/profileContext";

const GuestQuickInfoPanel = () => {
    const profileGuestContext = useContext(ProfileGuestContext)
    const {totalVisits, totalProductInterest, totalProductSecured, avgSearchTime, maxActiveTime} = profileGuestContext
    const searchTime = avgSearchTime + "s"
    const activeTime = maxActiveTime + "s"

    return (
        <Grid container justify="center" spacing={2}>
            <Grid item>
                <InfoCard label={"Total Visits"} icon={<LockOpen/>} value={totalVisits}/>
            </Grid>
            <Grid item>
                <InfoCard label={"Product Searches"} icon={<DataUsage/>} value={totalProductInterest}/>
            </Grid>
            <Grid item>
                <InfoCard label={"Product Downloads"} icon={<GetApp/>} value={totalProductSecured}/>
            </Grid>
            <Grid item>
                <InfoCard label={"Avg Search Time"} icon={<Timelapse/>} value={searchTime}/>
            </Grid>
            <Grid item>
                <InfoCard label={"Max Active Time"} icon={<AccessTime/>} value={activeTime}/>
            </Grid>
        </Grid>
    )
}

export default GuestQuickInfoPanel