import React from 'react'
import {
  CLOSE_ATAC_FLIGHT_MENU,
  CLOSE_FACILITY_REPORTS_MENU,
  CLOSE_WEATHER_MENU,
  HIGHLIGHT_AAL,
  HIGHLIGHT_ADDITIONAL_MENU,
  HIGHLIGHT_AIRCRAFT_REP,
  HIGHLIGHT_ASDI,
  HIGHLIGHT_ASM,
  HIGHLIGHT_ATAC_FLIGHT,
  HIGHLIGHT_ATAC_MENU,
  HIGHLIGHT_ATCSCC,
  HIGHLIGHT_ATIS,
  HIGHLIGHT_CENTER,
  HIGHLIGHT_EXELIS,
  HIGHLIGHT_FAC_EQUIP,
  HIGHLIGHT_FAC_REP,
  HIGHLIGHT_FAC_REP_MENU,
  HIGHLIGHT_FLIGHT, HIGHLIGHT_FUSER,
  HIGHLIGHT_METAR,
  HIGHLIGHT_OPSNET,
  HIGHLIGHT_OPTMA,
  HIGHLIGHT_SURFACE,
  HIGHLIGHT_SWIM,
  HIGHLIGHT_TAF,
  HIGHLIGHT_TBFM,
  HIGHLIGHT_TRACON,
  HIGHLIGHT_TRAFFIC,
  HIGHLIGHT_TRAFFIC_MENU,
  HIGHLIGHT_USA,
  HIGHLIGHT_WEATHER,
  HIGHLIGHT_WEATHER_MENU,
  HIGHLIGHT_WITI,
  HOVER_HIGHLIGHT_AAL_OPS,
  HOVER_HIGHLIGHT_AIR_REP,
  HOVER_HIGHLIGHT_ASDI,
  HOVER_HIGHLIGHT_ASM,
  HOVER_HIGHLIGHT_ATAC_FLIGHT,
  HOVER_HIGHLIGHT_ATCSCC,
  HOVER_HIGHLIGHT_ATIS,
  HOVER_HIGHLIGHT_CENTER,
  HOVER_HIGHLIGHT_FAC_EQUIP,
  HOVER_HIGHLIGHT_FAC_REP, HOVER_HIGHLIGHT_FUSER,
  HOVER_HIGHLIGHT_METAR,
  HOVER_HIGHLIGHT_OPSNET,
  HOVER_HIGHLIGHT_OPTMA,
  HOVER_HIGHLIGHT_SURFACE,
  HOVER_HIGHLIGHT_TAF,
  HOVER_HIGHLIGHT_TBFM,
  HOVER_HIGHLIGHT_TRACON,
  HOVER_HIGHLIGHT_USA, HOVER_HIGHLIGHT_WEATHER,
  HOVER_HIGHLIGHT_WITI,
  OPEN_ADDITIONAL_MENU,
  OPEN_ATAC_FLIGHT_MENU,
  OPEN_FACILITY_REPORTS_MENU,
  OPEN_TRAFFIC_MGMT_MENU,
  OPEN_WEATHER_MENU,
  RESET_ADDITIONAL,
  RESET_ATAC_DOWNLOAD,
  RESET_DATE_WIDGET,
  RESET_DOWNLOAD_MENU,
  RESET_FACILITY_REPORTS_MENU,
  RESET_LEFT_MENU,
  RESET_TRAFFIC_MGMT_MENU,
  RESET_WEATHER_MENU,
  SET_CHARTS_MENU,
  SET_DATE_WIDGET,
  SET_DOWNLOAD_MENU,
  SET_MAP_MENU,
  TOGGLE_ADDITIONAL_MENU,
  TOGGLE_ATAC_FLIGHT_MENU,
  TOGGLE_FACILITY_REPORTS_MENU,
  TOGGLE_SIDENAV,
  TOGGLE_TRAFFIC_MGMT_MENU,
  TOGGLE_WEATHER_MENU,
  UNHIGHLIGHT_AAL_OPS,
  UNHIGHLIGHT_ADDITIONAL_MENU,
  UNHIGHLIGHT_AIR_REP,
  UNHIGHLIGHT_ASDI,
  UNHIGHLIGHT_ASM,
  UNHIGHLIGHT_ATAC_EXELIS,
  UNHIGHLIGHT_ATAC_FLIGHT,
  UNHIGHLIGHT_ATAC_MENU,
  UNHIGHLIGHT_ATCSCC,
  UNHIGHLIGHT_ATIS,
  UNHIGHLIGHT_CENTER,
  UNHIGHLIGHT_FAC_EQUIP,
  UNHIGHLIGHT_FAC_REP,
  UNHIGHLIGHT_FAC_REP_MENU, UNHIGHLIGHT_FUSER,
  UNHIGHLIGHT_METAR,
  UNHIGHLIGHT_OPSNET,
  UNHIGHLIGHT_OPTMA,
  UNHIGHLIGHT_SURFACE,
  UNHIGHLIGHT_TAF,
  UNHIGHLIGHT_TBFM,
  UNHIGHLIGHT_TRACON,
  UNHIGHLIGHT_TRAFFIC_MENU,
  UNHIGHLIGHT_USA, UNHIGHLIGHT_WEATHER,
  UNHIGHLIGHT_WEATHER_MENU,
  UNHIGHLIGHT_WITI,
} from '../types'
import LeftNavDownload from '../../components/NavBars/LeftNavDownload'
import LeftNavCharts from '../../components/NavBars/LeftNavCharts'
import LeftNavMap from '../../components/NavBars/LeftNavMap'

export default (state, action) => {
  switch(action.type){
    case SET_DATE_WIDGET:
      return {
        ...state,
        dateWidget: true
      }
    case RESET_DATE_WIDGET:
      return {
        ...state,
        dateWidget: false
      }
    case CLOSE_ATAC_FLIGHT_MENU:
      return {
        ...state,
        atacFlightMenuState: false
      }
    case CLOSE_FACILITY_REPORTS_MENU:
      return {
        ...state,
        facilityReportsMenuState: false,
      }
    case CLOSE_WEATHER_MENU:
      return {
        ...state,
        weatherMenuState: false,
      }
    case OPEN_ADDITIONAL_MENU:
      return {
        ...state,
        additionalMenuState: true,
      }
    case OPEN_ATAC_FLIGHT_MENU:
      return {
        ...state,
        atacFlightMenuState: true,
      }
    case OPEN_FACILITY_REPORTS_MENU:
      return {
        ...state,
        facilityReportsMenuState: true,
      }
    case OPEN_TRAFFIC_MGMT_MENU:
      return {
        ...state,
        trafficMgmtMenuState: true,
      }
    case OPEN_WEATHER_MENU:
      return {
        ...state,
        weatherMenuState: true,
      }
    case RESET_LEFT_MENU:
      return {
        ...state,
        leftMenu: '',
      }
    case RESET_ADDITIONAL:
      return {
        ...state,
        asdiFont:             'caption',
        atisFont:             'caption',
        facEquipFont:         'caption',
        additionalMenuState:  false
      }
    case RESET_ATAC_DOWNLOAD:
      return {
        ...state,
        atacMenuFont: 'body1',
        usaFont:      'body1',
        traconFont:   'body1',
        surfaceFont:  'body1',
        centerFont:   'body1',
        weatherFont:  'body1',
        facRepFont:   'body1',
        flightFont:   'body1',
        trafficFont:  'body1',
        swimFont:     'body1',
        fuserFont:    'body1',
      }
    case RESET_DOWNLOAD_MENU:
      return {
        ...state,
        atacMenuFont:   'body1',
        weatherFont:    'body1',
        facRepFont:     'body1',
        flightFont:     'body1',
        trafficFont:    'body1',
        swimFont:       'body1',
        surfaceFont:    'caption',
        centerFont:     'caption',
        traconFont:     'caption',
        usaFont:        'caption',
        fuserFont:      'body1',
      }
  case RESET_FACILITY_REPORTS_MENU:
    return {
      ...state,
      facilityReportsMenuState: false,
      opsnetFont: 'caption',
      aalFont:    'caption',
    }
    case RESET_TRAFFIC_MGMT_MENU:
      return {
        ...state,
        trafficMgmtMenuState: false,
        atcsccFont:           'caption',
        opTmaFont:            'caption',
        tbfmFont:             'caption',
      }
    case RESET_WEATHER_MENU:
      return {
        ...state,
        weatherMenuState: false,
        asmFont:          'caption',
        aircraftRepFont:  'caption',
        metarFont:        'caption',
        tafFont:          'caption',
        witiFont:         'caption',
      }
    case SET_DOWNLOAD_MENU:
      return {
        ...state,
        leftMenu: <LeftNavDownload/>,
      }
    case SET_CHARTS_MENU:
      return {
        ...state,
        leftMenu: <LeftNavCharts/>,

    }
    case SET_MAP_MENU:
      return {
        ...state,
        leftMenu: <LeftNavMap/>,
      }
    case TOGGLE_ATAC_FLIGHT_MENU:
      return {
        ...state,
        atacFlightMenuState: !state.atacFlightMenuState, 
      }
    case TOGGLE_FACILITY_REPORTS_MENU:
      return {
        ...state,
        facilityReportsMenuState: !state.facilityReportsMenuState,
      }
    case TOGGLE_SIDENAV:
      return {
        ...state,
        mobileOpen: !state.mobileOpen
      }
    case TOGGLE_TRAFFIC_MGMT_MENU:
      return {
        ...state,
        trafficMgmtMenuState: !state.trafficMgmtMenuState,
      }
    case TOGGLE_WEATHER_MENU:
      return {
        ...state,
        weatherMenuState: !state.weatherMenuState,
      }
    case TOGGLE_ADDITIONAL_MENU:
    return {
      ...state,
      additionalMenuState: !state.additionalMenuState,
    }
    case UNHIGHLIGHT_AAL_OPS:
      return {
        ...state,
        aalFont: 'caption',
      }
    case UNHIGHLIGHT_OPSNET:
      return {
        ...state,
        opsnetFont: 'caption',
      }
    case UNHIGHLIGHT_ATCSCC:
      return {
        ...state,
        atcsccFont: 'caption',
      }
    case UNHIGHLIGHT_TBFM:
      return {
        ...state,
        tbfmFont: 'caption',
      }
    case UNHIGHLIGHT_OPTMA:
      return {
        ...state,
        opTmaFont: 'caption',
      }
    case UNHIGHLIGHT_FAC_EQUIP:
      return {
        ...state,
        facEquipFont: 'caption',
      }
    case UNHIGHLIGHT_ASDI:
      return {
        ...state,
        asdiFont: 'caption',
      }
    case UNHIGHLIGHT_ATIS:
      return {
        ...state,
        atisFont: 'caption',
      }
    case UNHIGHLIGHT_WEATHER:
      return {
        ...state,
        weatherFont: 'body1',
      }
    case UNHIGHLIGHT_USA:
      return {
        ...state,
        usaFont: 'caption',
      }
    case UNHIGHLIGHT_SURFACE:
      return {
        ...state,
        surfaceFont: 'caption',
      }
    case UNHIGHLIGHT_TRACON:
      return {
        ...state,
        traconFont: 'caption',
      }
    case UNHIGHLIGHT_CENTER:
      return {
        ...state,
        centerFont: 'caption',
      }
    case UNHIGHLIGHT_ATAC_FLIGHT:
      return {
        ...state,
        atacFlightFont: 'body1',
      }
    case UNHIGHLIGHT_ATAC_EXELIS:
      return {
        ...state,
        exelisFont: 'body1',
        atacFlightFont:   'body1',
      }
    case UNHIGHLIGHT_AIR_REP:
      return {
        ...state,
        aircraftRepFont: 'caption'
      }
    case UNHIGHLIGHT_ASM:
      return {
        ...state,
        asmFont: 'caption'
      }
    case UNHIGHLIGHT_METAR:
      return {
        ...state,
        metarFont: 'caption'
      }
    case UNHIGHLIGHT_TAF:
      return {
        ...state,
        tafFont: 'caption',
      }
    case UNHIGHLIGHT_WEATHER_MENU: 
      return {
        ...state,
        weatherMenuFont: 'body1',
      }
    case UNHIGHLIGHT_WITI:
      return {
        ...state,
        witiFont: 'caption',
      }
    case UNHIGHLIGHT_ADDITIONAL_MENU:
      return {
        ...state,
        additionalMenuFont: 'body1',
      }
    case UNHIGHLIGHT_ATAC_MENU:
      return {
        ...state,
        atacMenuFont: 'body1',
      }
    case UNHIGHLIGHT_FAC_REP:
      return {
        ...state,
        facRepFont: 'body1',
      }
    case UNHIGHLIGHT_FAC_REP_MENU:
      return {
        ...state,
        facRepMenuFont: 'body1',
      }
    case UNHIGHLIGHT_TRAFFIC_MENU:
      return {
        ...state,
        trafficMenuFont: 'body1',
      }
    case HIGHLIGHT_ADDITIONAL_MENU:
      return {
        ...state,
        additionalMenuFont: 'body2',
      }
    case HIGHLIGHT_ATAC_MENU:
      return {
        ...state,
        atacMenuFont: 'body2',
      }
    case HIGHLIGHT_ASDI:
      return {
        ...state,
        aalFont:          'caption',
        additionalMenuFont: 'body1',
        aircraftRepFont:  'caption',
        asdiFont:         'overline',
        asmFont:          'caption',
        atacFlightFont:   'body1',
        atcsccFont:       'caption',
        atisFont:         'caption',
        exelisFont:       'body1',
        facEquipFont:     'caption',
        facRepMenuFont:   'body1',
        metarFont:        'caption',
        opsnetFont:       'caption',
        opTmaFont:        'caption',
        tafFont:          'caption',
        tbfmFont:         'caption',
        trafficMenuFont:  'body1',
        weatherMenuFont:  'body1',
        witiFont:         'caption',
      }
    case HIGHLIGHT_ATIS:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        aalFont:          'caption',
        aircraftRepFont:  'caption',
        asdiFont:         'caption',
        asmFont:          'caption',
        atacFlightFont:   'body1',
        atcsccFont:       'caption',
        atisFont:         'overline',
        exelisFont:       'body1',
        facEquipFont:     'caption',
        metarFont:        'caption',
        opsnetFont:       'caption',
        opTmaFont:        'caption',
        tafFont:          'caption',
        tbfmFont:         'caption',
        weatherMenuFont:  'body1',
        witiFont:         'caption',
      }
    case HIGHLIGHT_FAC_EQUIP:
      return {
        ...state,
        usaFont:      'body1',
        traconFont:   'body1',
        surfaceFont:  'body1',
        centerFont:   'body1',
        facRepFont:   'body2',
        atacMenuFont: 'body1',
        fuserFont:    'body1',
      }
    case HIGHLIGHT_FAC_REP_MENU:
      return {
        ...state,
        facRepMenuFont: 'body2',
      }
    case HIGHLIGHT_SURFACE:
      return {
        ...state,
        usaFont:      'caption',
        traconFont:   'caption',
        surfaceFont:  'overline',
        centerFont:   'caption',
        facRepFont:   'body1',
        atacMenuFont: 'body1',
        weatherFont:  'body1',
        fuserFont:    'body1',
      }
    case HIGHLIGHT_TBFM:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        aalFont:          'caption',
        aircraftRepFont:  'caption',
        asdiFont:         'caption',
        asmFont:          'caption',
        atacFlightFont:   'body1',
        atcsccFont:       'caption',
        atisFont:         'caption',
        exelisFont:       'body1',
        facEquipFont:     'caption',
        metarFont:        'caption',
        opsnetFont:       'caption',
        opTmaFont:        'caption',
        tafFont:          'caption',
        tbfmFont:         'overline',
        weatherMenuFont:  'body1',
        witiFont:         'caption',
      }
    case HIGHLIGHT_TRACON:
      return {
        ...state,
        usaFont:      'caption',
        traconFont:   'overline',
        surfaceFont:  'caption',
        centerFont:   'caption',
        facRepFont:   'body1',
        atacMenuFont: 'body1',
        weatherFont:  'body1',
        fuserFont:    'body1',
      }
    case HIGHLIGHT_CENTER:
      return {
        ...state,
        usaFont:      'caption',
        traconFont:   'caption',
        surfaceFont:  'caption',
        centerFont:   'overline',
        facRepFont:   'body1',
        atacMenuFont: 'body1',
        weatherFont:  'body1',
        fuserFont:    'body1',
      }
    case HIGHLIGHT_USA:
      return {
        ...state,
        usaFont:      'overline',
        traconFont:   'caption',
        surfaceFont:  'caption',
        centerFont:   'caption',
        facRepFont:   'body1',
        atacMenuFont: 'body1',
        weatherFont:  'body1',
        fuserFont:    'body1',
      }
    case HIGHLIGHT_WEATHER:
      return {
        ...state,
        usaFont:      'caption',
        traconFont:   'caption',
        surfaceFont:  'caption',
        centerFont:   'caption',
        facRepFont:   'body1',
        atacMenuFont: 'body1',
        weatherFont:  'body2',
        fuserFont:    'body1',
      }
    case HIGHLIGHT_FAC_REP:
      return {
        ...state,
        usaFont:      'caption',
        traconFont:   'caption',
        surfaceFont:  'caption',
        centerFont:   'caption',
        facRepFont:   'body2',
        atacMenuFont: 'body1',
        weatherFont:  'body1',
        fuserFont:    'body1',
      }
    case HIGHLIGHT_FLIGHT:
      return {
        ...state,
        atacMenuFont:   'body1',
        weatherFont:    'body1',
        facRepFont:     'body1',
        flightFont:     'body2',
        trafficFont:    'body1',
        swimFont:       'body1',
        surfaceFont:  'caption',
        centerFont:   'caption',
        traconFont:   'caption',
        usaFont:      'caption',
        fuserFont:      'body1',
      }
    case HIGHLIGHT_TRAFFIC:
      return {
        ...state,
        atacMenuFont:   'body1',
        weatherFont:    'body1',
        facRepFont:     'body1',
        flightFont:     'body1',
        trafficFont:    'body2',
        swimFont:       'body1',
        surfaceFont:  'caption',
        centerFont:   'caption',
        traconFont:   'caption',
        usaFont:      'caption',
        fuserFont:      'body1',
      }
    case HIGHLIGHT_SWIM:
      return {
        ...state,
        atacMenuFont:   'body1',
        weatherFont:    'body1',
        facRepFont:     'body1',
        flightFont:     'body1',
        trafficFont:    'body1',
        swimFont:       'body2',
        surfaceFont:  'caption',
        centerFont:   'caption',
        traconFont:   'caption',
        usaFont:      'caption',
        fuserFont:      'body1',
      }
    case HIGHLIGHT_AIRCRAFT_REP:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        aircraftRepFont:  'overline',
        asmFont:          'caption',
        metarFont:        'caption',
        tafFont:          'caption',
        tbfmFont:         'caption',
        witiFont:         'caption',
        aalFont:          'caption',
        opsnetFont:       'caption',
        atacFlightFont:   'body1',
        exelisFont:       'body1',
        opTmaFont:        'caption',
        atcsccFont:       'caption',
        facEquipFont:     'caption',
        asdiFont:         'caption',
        atisFont:         'caption',
      }
    case HIGHLIGHT_ASM:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        asmFont:          'overline',
        aircraftRepFont:  'caption',
        metarFont:        'caption',
        tafFont:          'caption',
        tbfmFont:         'caption',
        witiFont:         'caption',
        aalFont:          'caption',
        opsnetFont:       'caption',
        atacFlightFont:   'body1',
        exelisFont:       'body1',
        opTmaFont:        'caption',
        atcsccFont:       'caption',
        facEquipFont:     'caption',
        asdiFont:         'caption',
        atisFont:         'caption',
      }
    case HIGHLIGHT_METAR:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        metarFont:        'overline',
        aircraftRepFont:  'caption',
        asmFont:          'caption',
        tafFont:          'caption',
        tbfmFont:         'caption',
        witiFont:         'caption',
        aalFont:          'caption',
        opsnetFont:       'caption',
        atacFlightFont:   'body1',
        exelisFont:       'body1',
        opTmaFont:        'caption',
        atcsccFont:       'caption',
        facEquipFont:     'caption',
        asdiFont:         'caption',
        atisFont:         'caption',
      }
    case HIGHLIGHT_TAF:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        tafFont:          'overline',
        tbfmFont:         'caption',
        aircraftRepFont:  'caption',
        metarFont:        'caption',
        asmFont:          'caption',
        witiFont:         'caption',
        aalFont:          'caption',
        opsnetFont:       'caption',
        atacFlightFont:   'body1',
        exelisFont:       'body1',
        opTmaFont:        'caption',
        atcsccFont:       'caption',
        facEquipFont:     'caption',
        asdiFont:         'caption',
        atisFont:         'caption',
      }
    case HIGHLIGHT_WITI:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        witiFont:         'overline',
        aircraftRepFont:  'caption',
        metarFont:        'caption',
        asmFont:          'caption',
        tafFont:          'caption',
        tbfmFont:         'caption',
        aalFont:          'caption',
        opsnetFont:       'caption',
        atacFlightFont:   'body1',
        exelisFont:       'body1',
        opTmaFont:        'caption',
        atcsccFont:       'caption',
        facEquipFont:     'caption',
        asdiFont:         'caption',
        atisFont:         'caption',
      }
    case HIGHLIGHT_ATAC_FLIGHT:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        aalFont:          'caption',
        aircraftRepFont:  'caption',
        asdiFont:         'caption',
        asmFont:          'caption',
        atacFlightFont:   'body2',
        atcsccFont:       'caption',
        atisFont:         'caption',
        exelisFont:       'body1',
        facEquipFont:     'caption',
        metarFont:        'caption',
        opsnetFont:       'caption',
        opTmaFont:        'caption',
        tafFont:          'caption',
        tbfmFont:         'caption',
        weatherMenuFont:  'body1',
        witiFont:         'caption',
      }
    case HIGHLIGHT_EXELIS:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        aalFont:          'caption',
        aircraftRepFont:  'caption',
        asdiFont:         'caption',
        asmFont:          'caption',
        atacFlightFont:   'body1',
        atcsccFont:       'caption',
        atisFont:         'caption',
        exelisFont:       'body2',
        facEquipFont:     'caption',
        metarFont:        'caption',
        opsnetFont:       'caption',
        opTmaFont:        'caption',
        tafFont:          'caption',
        tbfmFont:         'caption',
        weatherMenuFont:  'body1',
        witiFont:         'caption',
      }
    case HIGHLIGHT_OPSNET:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        aalFont:          'caption',
        aircraftRepFont:  'caption',
        asdiFont:         'caption',
        asmFont:          'caption',
        atacFlightFont:   'body1',
        atcsccFont:       'caption',
        atisFont:         'caption',
        exelisFont:       'body1',
        facEquipFont:     'caption',
        metarFont:        'caption',
        opsnetFont:       'overline',
        opTmaFont:        'caption',
        tafFont:          'caption',
        tbfmFont:         'caption',
        weatherMenuFont:  'body1',
        witiFont:         'caption',
      }
    case HIGHLIGHT_AAL:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        aalFont:          'overline',
        aircraftRepFont:  'caption',
        asdiFont:         'caption',
        asmFont:          'caption',
        atacFlightFont:   'body1',
        atcsccFont:       'caption',
        atisFont:         'caption',
        exelisFont:       'body1',
        facEquipFont:     'caption',
        metarFont:        'caption',
        opsnetFont:       'caption',
        opTmaFont:        'caption',
        tafFont:          'caption',
        tbfmFont:         'caption',
        weatherMenuFont:  'body1',
        witiFont:         'caption',
      }
    case HIGHLIGHT_OPTMA:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        aalFont:          'caption',
        aircraftRepFont:  'caption',
        asdiFont:         'caption',
        asmFont:          'caption',
        atacFlightFont:   'body1',
        atcsccFont:       'caption',
        atisFont:         'caption',
        exelisFont:       'body1',
        facEquipFont:     'caption',
        metarFont:        'caption',
        opsnetFont:       'caption',
        opTmaFont:        'overline',
        tafFont:          'caption',
        tbfmFont:         'caption',
        weatherMenuFont:  'body1',
        witiFont:         'caption',
      }
    case HIGHLIGHT_ATCSCC:
      return {
        ...state,
        additionalMenuFont: 'body1',
        trafficMenuFont:  'body1',
        facRepMenuFont:   'body1',
        aalFont:          'caption',
        aircraftRepFont:  'caption',
        asdiFont:         'caption',
        asmFont:          'caption',
        atacFlightFont:   'body1',
        atcsccFont:       'overline',
        atisFont:         'caption',
        exelisFont:       'body1',
        facEquipFont:     'caption',
        metarFont:        'caption',
        opsnetFont:       'caption',
        opTmaFont:        'caption',
        tafFont:          'caption',
        tbfmFont:         'caption',
        weatherMenuFont:  'body1',
        witiFont:         'caption',
      }
    case HIGHLIGHT_TRAFFIC_MENU:
      return {
        ...state,
        trafficMenuFont: 'body2',
      }
    case HIGHLIGHT_WEATHER_MENU:
      return {
        ...state,
        weatherMenuFont: 'body2',
      }
    case HOVER_HIGHLIGHT_AIR_REP:
        return {
          ...state,
          aircraftRepFont: 'overline',
        }
    case HOVER_HIGHLIGHT_ASM:
      return {
        ...state,
        asmFont: 'overline',
      }
    case HOVER_HIGHLIGHT_METAR:
      return {
        ...state,
        metarFont: 'overline',
      }
    case HOVER_HIGHLIGHT_TAF:
      return {
        ...state,
        tafFont: 'overline',
      }
    case HOVER_HIGHLIGHT_WITI:
      return {
        ...state,
        witiFont: 'overline',
      }
    case HOVER_HIGHLIGHT_AAL_OPS:
      return {
        ...state,
        aalFont: 'overline',
      }
    case HOVER_HIGHLIGHT_OPSNET:
      return {
        ...state,
        opsnetFont: 'overline',
      }
    case HOVER_HIGHLIGHT_ATCSCC:
      return {
        ...state,
        atcsccFont: 'overline',
      }
    case HOVER_HIGHLIGHT_TBFM:
      return {
        ...state,
        tbfmFont: 'overline',
      }
    case HOVER_HIGHLIGHT_OPTMA:
      return {
        ...state,
        opTmaFont: 'overline',
      }
    case HOVER_HIGHLIGHT_FAC_EQUIP:
      return {
        ...state,
        facEquipFont: 'overline',
      }
    case HOVER_HIGHLIGHT_FAC_REP:
      return {
        ...state,
        facRepFont: 'body2',
      }
    case HOVER_HIGHLIGHT_ASDI:
      return {
        ...state,
        asdiFont: 'overline',
      }
    case HOVER_HIGHLIGHT_ATIS:
      return {
        ...state,
        atisFont: 'overline',
      }
    case HOVER_HIGHLIGHT_WEATHER:
      return {
        ...state,
        weatherFont: 'body2',
      }
    case HOVER_HIGHLIGHT_USA:
      return {
        ...state,
        usaFont: 'overline',
      }
    case HOVER_HIGHLIGHT_SURFACE:
      return {
        ...state,
        surfaceFont: 'overline',
      }
    case HOVER_HIGHLIGHT_TRACON:
      return {
        ...state,
        traconFont: 'overline',
      }
    case HOVER_HIGHLIGHT_CENTER:
      return {
        ...state,
        centerFont: 'overline',
      }
    case HOVER_HIGHLIGHT_ATAC_FLIGHT:
      return {
        ...state,
        atacFlightFont: 'body2',
      }
    case HIGHLIGHT_FUSER:
      return {
        ...state,
        atacMenuFont: 'body1',
        usaFont:      'caption',
        traconFont:   'caption',
        surfaceFont:  'caption',
        centerFont:   'caption',
        facRepFont:   'body1',
        weatherFont:  'body1',
        fuserFont:    'body2',
      }
    case HOVER_HIGHLIGHT_FUSER:
      return {
        ...state,
        fuserFont:    'body2'
      }
    case UNHIGHLIGHT_FUSER:
      return {
        ...state,
        fuserFont:    'body1'
      }
    default: return state
  }
}