import React, {useReducer} from 'react'
import ProfileReducer from "./profileReducer";
import ProfileContext from "./profileContext";
import {
    SET_AVG_SEARCH_TIME,
    SET_CLICKED,
    SET_FILES,
    SET_GUEST,
    SET_MAX_ACTIVE_TIME,
    SET_OPTIONS,
    SET_PROFILE,
    SET_TAB_VALUE,
    SET_TABLE_LOADING,
    SET_TOTAL_PRODUCT_INTEREST,
    SET_TOTAL_PRODUCT_SECURED,
    SET_TOTAL_VISITS
} from "../types";

const ProfileState = props => {
    const initialState = {
        profile: {
            label: "User",
            placeholder: "",
            options: [],
            value: "",
        },
        clicked: false,
        tableLoading: false,
        files: [],
        guest: {
            name: "",
            userId: "",
            email: ""
        },
        totalVisits: 0,
        totalProductInterest: 0,
        totalProductSecured: 0,
        avgSearchTime: 0,
        maxActiveTime: 0,
        tabValue: 'productInterestDetails'
    }

    const [state, dispatch] = useReducer(ProfileReducer, initialState);

    const setOptions = (option) => {
        const profile = state.profile
        profile.options = option
        dispatch({
            type: SET_OPTIONS,
            profile: profile
        })
    }

    const setProfile = (event, value) => {
        if (value === undefined || value === null){
            value = ""
        }
        const profile = state.profile
        profile.value = value
        dispatch({
            type: SET_PROFILE,
            profile: profile
        })
    }

    const setProfileSelection = (value) => {
        if (value === undefined || value === null){
            value = ""
        }
        const profile = state.profile
        profile.value = value
        dispatch({
            type: SET_PROFILE,
            profile: profile
        })
    }

    const resetProfileSelection = () => {
        const profile = state.profile
        profile.value = ""
        dispatch({
            type: SET_PROFILE,
            profile: profile
        })
    }

    const setClicked = () => {
        dispatch({
            type: SET_CLICKED
        })
    }

    const setTableLoading = (tableLoading) => {
        dispatch({
            type: SET_TABLE_LOADING,
            tableLoading: tableLoading
        })
    }

    const setFiles = (files) => {
        dispatch({
            type: SET_FILES,
            files: files
        })
    }

    const setGuest = (name, userId, email) => {
        const guest = state.guest
        guest.name = name
        guest.userId = userId
        guest.email = email
        dispatch({
            type: SET_GUEST,
            guest: guest
        })
    }

    const setTotalVisits = (totalVisits) => {
        dispatch({
            type: SET_TOTAL_VISITS,
            totalVisits: totalVisits
        })
    }

    const setTotalProductInterest = (totalProductInterest) => {
        dispatch({
            type: SET_TOTAL_PRODUCT_INTEREST,
            totalProductInterest: totalProductInterest
        })
    }

    const setTotalProductSecured = (totalProductSecured) => {
        dispatch({
            type: SET_TOTAL_PRODUCT_SECURED,
            totalProductSecured: totalProductSecured
        })
    }

    const setAvgSearchTime = (avgSearchTime) => {
        dispatch({
            type: SET_AVG_SEARCH_TIME,
            avgSearchTime: avgSearchTime
        })
    }

    const setMaxActiveTime = (maxActiveTime) => {
        dispatch({
            type: SET_MAX_ACTIVE_TIME,
            maxActiveTime: maxActiveTime
        })
    }

    const setTabValue = (value) => {
        dispatch({
            type: SET_TAB_VALUE,
            tabValue: value
        })
    }

    return <ProfileContext.Provider
        value = {{
            profile: state.profile,
            clicked: state.clicked,
            tableLoading: state.tableLoading,
            files: state.files,
            guest: state.guest,
            totalVisits: state.totalVisits,
            totalProductInterest: state.totalProductInterest,
            totalProductSecured: state.totalProductSecured,
            avgSearchTime: state.avgSearchTime,
            maxActiveTime: state.maxActiveTime,
            tabValue: state.tabValue,
            setOptions,
            setProfile,
            setProfileSelection,
            resetProfileSelection,
            setClicked,
            setTableLoading,
            setFiles,
            setGuest,
            setTotalVisits,
            setTotalProductInterest,
            setTotalProductSecured,
            setAvgSearchTime,
            setMaxActiveTime,
            setTabValue
        }}>
        {props.children}
    </ProfileContext.Provider>

}

export default ProfileState