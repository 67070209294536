import React from 'react'
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MuiTablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import LimitDialog from "../Modals/LimitDialog";
import NoDownloadDialog from "../Modals/NoDownloadDialog";
import {fileDownloadLimit} from "../Data/api";

const useStyles = makeStyles(theme => ({
    footerStyle: {
        maxHeight: '40px',
        display:'flex',
        justifyContent: 'space-between',
        padding: '0px 24px 0px 12px'
    },
    button: {
        maxHeight: '40px'
    }
}))

const CustomFooter = (props) => {
    const classes = useStyles()
    const {dataLength, rowsSelected, count, textLabels, rowsPerPage, page} = props

    // for modal
    const [open, setOpen] = React.useState(false)
    const [noDownload, setNoDownload] = React.useState(false)

    const handleRowChange = (event) => {
        props.changeRowsPerPage(event.target.value)
    }

    const handlePageChange = (_, page) => {
        props.changePage(page)
    }

    const handleDownload = () => {
        // console.log("Rows selected check", props.selectionSize)
        // const sum = props.rowsSelected.length
        const sum =  props.selectionSize.reduce((result,number)=> result+number)
        // sum > 20000000 ? setOpen(true) : props.handleClick(rowsSelected)
        if (sum === 0)
            setNoDownload(true)
        else
            sum > fileDownloadLimit ? setOpen(true) : props.handleClick(rowsSelected)
    }

    const handleNoDownloadClose = () => {
        setNoDownload(false)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <TableFooter>
            <TableRow>
                <TableCell className={classes.footerStyle} colSpan={1000}>
                    {dataLength > 0 &&
                    <div>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={handleDownload}>
                            Download
                        </Button>
                        <NoDownloadDialog open={noDownload} handleClose={handleNoDownloadClose}/>
                        <LimitDialog open={open} handleClose={handleClose}/>
                    </div>}
                    <MuiTablePagination
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={textLabels.rowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${textLabels.displayRows} ${count}`}
                        backIconButtonProps={{
                            'aria-label': textLabels.previous,
                        }}
                        nextIconButtonProps={{
                            'aria-label': textLabels.next,
                        }}
                        rowsPerPageOptions={[10,20,100]}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowChange}
                    />
                </TableCell>
            </TableRow>
        </TableFooter>

    )
}

export default CustomFooter