import React, {useContext} from 'react'
import ProfileGuestActivityContext from "../../../Contexts/ProfileGuestActivity/profileGuestActivityContext";
import ProfileGuestActivitySummaryContext
    from "../../../Contexts/ProfileGuestActivitySummary/profileGuestActivitySummaryContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import {handleActivity} from "../../Utils/AuditUtils";
import LineChart from "../../Charts/LineChart";

const GuestActivityPanel = () => {
    const profileGuestActivityContext = useContext(ProfileGuestActivityContext)
    const {chartLoading, axes, chartFiles} = profileGuestActivityContext
    const {chartTitle,chartHAxisTitle,chartVAxisTitle} = profileGuestActivityContext
    const setGuestActivityChartLoading = profileGuestActivityContext.setChartLoading
    const setGuestActivityChartFiles = profileGuestActivityContext.setChartFiles

    const profileGuestActivitySummaryContext = useContext(ProfileGuestActivitySummaryContext)
    const {files} = profileGuestActivitySummaryContext

    const handleUserActivity = () => {
        handleActivity(files, setGuestActivityChartFiles)
        // console.log("Handle guest activity", chartLoading)
        // let action = "search"
        // let actionData = handleFilterFiles(files, action)
        //
        // let dates = handleUniqueValues(actionData, "timestamp")
        //
        // let sumReduced = actionData.reduce((k,v) => {k[v.timestamp.split(' ')[0]] = (k[v.timestamp.split(' ')[0]] || 0) + 1;
        //     return k}, {})
        // const data = dates.map(date => { return {date: date, [action]: sumReduced[date]}})
        // setGuestActivityChartFiles(data)
    }

    React.useEffect(() => {
        console.log("Chart loading", chartLoading)
        setGuestActivityChartLoading(true)
        handleUserActivity()
    }, [files])

    React.useEffect(() => {
        setGuestActivityChartLoading(false)
    }, [chartFiles])

    return (
        chartLoading ? <LinearProgress/> : <LineChart chartFiles={chartFiles} columns={axes} title={chartTitle} haxis={chartHAxisTitle} vaxis={chartVAxisTitle}/>
    )

}

export default GuestActivityPanel