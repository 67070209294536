import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import axios from "axios";
import TitleSubtitle from "../../Titles/TitleSubtitle";
import SearchButton from "../../Buttons/SearchButton";
import StartDateContext from "../../../Contexts/StartDate/startDateContext";
import EndDateContext from "../../../Contexts/EndDate/endDateContext";
import LoadDialog from "../../Modals/LoadDialog";
import ATACSurfaceContext from "../../../Contexts/ATACSurface/atacSurfaceContext";
import Banner from "../../../images/banner15.PNG";
import LinearProgress from "@material-ui/core/LinearProgress";
import {fileDowloadUrl, hostname} from "../../Data/api";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DataProductCheckbox from "../../Selections/DataProductCheckbox";
import FacilitiesLabelPanel from "../../Panels/FacilitiesLabelPanel";
import FDFacilityTable from "../../Tables/FDFacilityTable";


const drawerWidth = 245;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        marginTop: theme.spacing(5),
        marginBottom: `${drawerWidth}px`,
        [theme.breakpoints.up('md')]: {
            marginLeft: `${drawerWidth}px`,
            width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.only('xs')]: {
            marginRight: theme.spacing(1),
            // marginLeft: theme.spacing(1),
            paddingTop: theme.spacing(10),
            width: '100%',
        },
    },
    image: {
        width: '100%',
        marginTop: -134,
        paddingBottom: 30,
        [theme.breakpoints.only('xs')]: {
            paddingBottom: 50,
            marginTop: -55
        }
    },
    grid: {
        paddingLeft: 30
    },
    panel: {
        backgroundColor: theme.palette.common.lighterGray
    },
    toolbar: theme.mixins.toolbar,
}));

function DownloadATACSurf() {
    const classes = useStyles();

    const title = "Individual Facilities Surface Data"
    const subtitle = "Analysis-Ready, Surface Track, Event, and Metadata by ATCT"

    const [selectedFiles, setSelectedFiles] = React.useState([])

    const atacSurfaceContext = useContext(ATACSurfaceContext)
    const {dataProducts, facilities, clicked, tableLoading, files, progress, cancelTokenSource} = atacSurfaceContext
    const setDataProducts = atacSurfaceContext.setDataProducts
    const setFacilities = atacSurfaceContext.setFacilities
    const setClicked = atacSurfaceContext.setClicked
    const setTableLoading = atacSurfaceContext.setTableLoading
    const setFiles = atacSurfaceContext.setFiles
    const setProgress = atacSurfaceContext.setProgress
    const resetCancelToken = atacSurfaceContext.resetCancelToken

    const [loading, setLoading] = React.useState(false)
    const [cancel, setCancel] = React.useState(false)

    const startDateContext = useContext(StartDateContext)
    const {startDate, startTime} = startDateContext
    const endDateContext = useContext(EndDateContext)
    const {endDate, endTime} = endDateContext
    // console.log("ATAC Facilities ", startDate, startTime, endDate, endTime)

    const activateTabs = dataProducts.trackPoints.checked || dataProducts.flightEvents.checked || dataProducts.flightSummary.checked

    const activateSearch = dataProducts.trackPoints.checked || dataProducts.flightEvents.checked || dataProducts.flightSummary.checked

    const handleChange = (event) => {
        const {name, checked} = event.target

        let updatedDataProducts2
        let dataProduct = Object.assign({}, dataProducts[name], {checked: checked})
        updatedDataProducts2 = Object.assign(
            {}, dataProducts, {[name]: dataProduct})

        setDataProducts(updatedDataProducts2)
    }

    const handleFacility = (event) => {
        const {name, checked} = event.target

        const updatedFacility = facilities.value.map(facility => {
            if (facility.name === name) {
                facility.checked = !facility.checked
            }
            return facility
        })

        let updatedFacilities = Object.assign(
            {}, facilities, {value: updatedFacility})


        setFacilities(updatedFacilities)
    }

    const handleUserSelection = (event) => {
        setClicked(true)
        setTableLoading(true)
        // const startDate = "20190202"
        // const endDate = "20190203"
        const start = startDate.toString().replace(/\//g,'')
        const end = endDate.toString().replace(/\//g,'')

        // console.log("Cleaned dates", start, end)
        // console.log("User selected facilities")
        // console.log(facilities)

        let query = ""
        let queryFacilityIFF = ""
        let queryFacilityEV = ""
        let queryFacilityRD = ""

        Object.entries(dataProducts).forEach(([key, value]) => {
            value.checked && (query += value.value)
        })

        //Object.entries(facilities.value).map(([index,facility]) => {
        //             facility.checked && (queryFacility += facility.value)
        //         })

        dataProducts.trackPoints.checked && (
            Object.entries(facilities.value).forEach(([index, facility]) => {
                facility.checked && (queryFacilityIFF += dataProducts.trackPoints.value + " " + facility.value)
            })
        )
        dataProducts.flightEvents.checked && (
            Object.entries(facilities.value).forEach(([index, facility]) => {
                facility.checked && (queryFacilityEV += dataProducts.flightEvents.value + " " + facility.value)
            })
        )
        dataProducts.flightSummary.checked && (
            Object.entries(facilities.value).forEach(([index, facility]) => {
                facility.checked && (queryFacilityRD += dataProducts.flightSummary.value + " " + facility.value)
            })
        )

        // console.log("Query ", query)
        // console.log("Query Facility IFF", queryFacilityIFF)
        // console.log("Query Facility EV", queryFacilityEV)
        // console.log("Query Facility RD", queryFacilityRD)
        // IFF CLT,IFF CVG,EV atl%2Basdex,EV CLT does not work
        axios.get(hostname + fileDowloadUrl + "search/file/"+start+"/"+end+"?dataProducts="+queryFacilityIFF+queryFacilityEV+queryFacilityRD)
            .then(response => {
                // console.log("Real API ", response.data.data)
                // this.setState({files: response.data.data})
                setFiles(response.data.data)
                setTableLoading(false)
            })
            .catch(err => {
                console.log(err)
                setTableLoading(false)
            })
    }

    const handleFilename = (rows) => {
        let dataSources = [...new Set(rows.map(index => {return (files[index]["dataSource"])}))]
        dataSources = dataSources.toString().replace(/,/g,'_')
        // console.log("Data Sources", dataSources)

        return dataSources + "_" + startDate + "_" + endDate + ".zip"
    }

    const handleClick = (rowsSelected) => {
        const updatedSelectedFiles = rowsSelected.map(index => {return (files[index]["path"].replace('+','%2B'))})

        setSelectedFiles(updatedSelectedFiles)

        let queryFile = ""
        updatedSelectedFiles.map(file => queryFile += file + ",")
        // console.log("Query File ", queryFile)

        const total = rowsSelected.map(index => {
            return files[index]["size"]
        })

        const querySize = total.reduce((result,number)=> result+number)

        // http://localhost:8080/filedownload-0.0.1-SNAPSHOT/download/file?path=/home/data/atac/SVDataLocal/RD/atl+asdex/2019/201902/20190202
        setLoading(true)
        axios.get(hostname + fileDowloadUrl + "download/file?path="+queryFile,
            {
                responseType: "blob",
                onDownloadProgress: progressEvent => {
                    let progress = Math.round((progressEvent.loaded * 100)/querySize)
                    setProgress(progress)
                },
                cancelToken: cancelTokenSource.token
            })
            .then(response => {
                // console.log("Download from ", response.headers)
                setLoading(false)
                const file = new Blob([response.data], {type:"file/zip"})
                const link = document.createElement("a")
                link.href = URL.createObjectURL(file)
                link.download = handleFilename(rowsSelected)
                document.body.appendChild(link)
                link.click()
            })
            .catch(err => {
                console.log(err)
            })
    }

    const table = tableLoading ? <LinearProgress/> : <FDFacilityTable files={files} handleClick={handleClick}/>

    const handleCancel = () => {
        console.log("Cancel file download")
        cancelTokenSource.cancel("Cancelled ATAC Surface Download")
        resetCancelToken()
        setCancel(true)
        setLoading(false)
    }


    const handleActivateSearch = () => {
        let search = false
        facilities.value.forEach(facility => {
            facility.checked && (search = true)
        })
        return search
    }

    let activateFacilitySearch = handleActivateSearch()

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <img className={classes.image} src={Banner}/>
            <TitleSubtitle title={title} subtitle={subtitle}/>
            <Grid container justify="flex-start" direction="column" spacing={0} className={classes.grid}>
                <Paper className={classes.panel} style={{marginBottom:20,marginRight:30,paddingLeft:15}}>
                    <Grid item>
                        <Grid container direction="row">
                            <DataProductCheckbox name={dataProducts.trackPoints.name} label={dataProducts.trackPoints.label} checked={dataProducts.trackPoints.checked} handleChange={handleChange}/>
                            <DataProductCheckbox name={dataProducts.flightEvents.name} label={dataProducts.flightEvents.label} checked={dataProducts.flightEvents.checked} handleChange={handleChange}/>
                            <DataProductCheckbox name={dataProducts.flightSummary.name} label={dataProducts.flightSummary.label} checked={dataProducts.flightSummary.checked} handleChange={handleChange}/>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FacilitiesLabelPanel facilities={facilities} handleFacility={handleFacility} activateTabs={activateTabs}/>
                    </Grid>
                </Paper>
                <SearchButton activateSearch={activateSearch&&activateFacilitySearch} handleUserSelection={handleUserSelection}/>
                {clicked && table}
                {loading && <LoadDialog load={loading} progress={progress} handleClose={handleCancel}/>}
            </Grid>
        </main>
    )
}

export default DownloadATACSurf;