import React, {useContext} from 'react'
import AuditContext from "../../../Contexts/Audit/auditContext";
import UserDetails from "./UserDetails";
import UserTraffic from "./UserTraffic";
import Grid from "@material-ui/core/Grid";

const UserDetailsPanel = () => {
    const auditContext = useContext(AuditContext)
    const {clicked} = auditContext

    return (
        clicked && (
            <Grid container>
                <Grid item sm>
                    <UserDetails/>
                </Grid>
                <Grid item md>
                    <UserTraffic/>
                </Grid>
            </Grid>
        )
    )

}

export default UserDetailsPanel