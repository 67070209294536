import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import HeaderButton from './Buttons/HeaderButton'
import withWidth from '@material-ui/core/withWidth'
import TopNavContext from '../Contexts/TopNav/topNavContext'
import HomeContext from '../Contexts/Home/homeContext'
import SideNavContext from '../Contexts/SideNav/sideNavContext'
import SherlockLogo from '../images/sherlock_logo.svg'
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {ArrowDropDown} from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MUILink from "@material-ui/core/Link";
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {apiDocsUrl, hostname} from "./Data/api";
import HeaderAvatar from "./PagesHeader/HeaderAvatar";
import HeaderSupport from "./PagesHeader/HeaderSupport";

const useStyles = makeStyles((theme) => ({
  headerGradiant: {
    background: 'linear-gradient(180deg, #90caf9 5%, #0d47a1 90%)',
     zIndex: 1100
  },
  headerButtonsContainter: {
    marginLeft: 'auto'
  },
  removeUnderline: {
    textDecoration: 'none',
    paddingBottom: theme.spacing(-1),
    [theme.breakpoints.only('xs')]: {
      fontSize: '11px',
      fontWeight: 'normal',
      paddingBottom: theme.spacing(0)
    }
  },
  headerButtonDropdown: {
    color: "#fff",
    fontSize: 24
  },
  dropdownIcon: {
    color: "#fff"
  },
  link: {
    color: '#000',
    fontSize: 16,
    textDecoration: 'none'
  },
}));

function Header(props)  {

  const classes = useStyles();
  const topNavContext = useContext(TopNavContext)
  const resetTopNavAll = topNavContext.resetTopNavAll
  const homeContext = useContext(HomeContext)
  const unhighlightAll = homeContext.unhighlightAll
  const sideNavContext = useContext(SideNavContext)
  const resetAtacDownload = sideNavContext.resetAtacDownload
  const closeAtacFlightMenu = sideNavContext.closeAtacFlightMenu
  const resetDownloadMenu = sideNavContext.resetDownloadMenu
  const resetFacilityReportsMenu = sideNavContext.resetFacilityReportsMenu
  const resetTrafficMgmtMenu = sideNavContext.resetTrafficMgmtMenu
  const resetWeatherMenu = sideNavContext.resetWeatherMenu
  const unhighlightAtacExelis = sideNavContext.unhighlightAtacExelis
  const resetAdditional = sideNavContext.resetAdditional
  const unhighlightWeatherMenu = sideNavContext.unhighlightWeatherMenu
  const unhiglightFacRepMenu = sideNavContext.unhighlightFacRepMenu
  const unhighlightTrafficMenu = sideNavContext.unhighlightTrafficMenu
  const unhighlightAdditionalMenu = sideNavContext.unhighlightAdditionalMenu
  const setDownloadMenu = sideNavContext.setDownloadMenu
  const setChartsMenu = sideNavContext.setChartsMenu
  const setMapMenu = sideNavContext.setMapMenu
  const resetLeftMenu = sideNavContext.resetLeftMenu
  const setDateWidget = sideNavContext.setDateWidget
  const resetDateWidget = sideNavContext.resetDateWidget

  const highlightTopNavDownloadButton = topNavContext.highlightTopNavDownloadButton
  const highlightTopNavChartsButton = topNavContext.highlightTopNavChartsButton
  const highlightTopNavMapButton = topNavContext.highlightTopNavMapButton
  const highlightTopNavDownloadFont = topNavContext.highlightTopNavDownloadFont
  const highlightTopNavChartsFont = topNavContext.highlightTopNavChartsFont
  const highlightTopNavMapFont = topNavContext.highlightTopNavMapFont

  async function resetAll() { 
    await Promise.all([resetLeftMenu(), resetDateWidget(), resetAtacDownload(), closeAtacFlightMenu(), unhighlightAll(),
                        resetTopNavAll(), resetDownloadMenu(), resetFacilityReportsMenu(), 
                        resetWeatherMenu(), resetAdditional(), unhighlightAtacExelis(), 
                        resetTrafficMgmtMenu(), unhighlightWeatherMenu(), unhiglightFacRepMenu(),
                        unhighlightTrafficMenu(), unhighlightAdditionalMenu()])
    return 'Done!';}

  async function setDownload() {
    await Promise.all([setDownloadMenu(), setDateWidget(), highlightTopNavDownloadFont()])
    return 'Done!';}

  async function setCharts() {
    await Promise.all([setChartsMenu(), setDateWidget(), highlightTopNavChartsFont()])
    return 'Done!';}

  async function setMap() {
    await Promise.all([setMapMenu(), setDateWidget(), highlightTopNavMapFont()])
    return 'Done!';}


  const [open, setOpen] = React.useState(false);

  const theme = useTheme()
  let fontSize
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  {matches ? fontSize='body1' : fontSize='subtitle1'}

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar position='fixed' className={classes.headerGradiant}>
      <Grid container>
        <Grid item lg={2} md={1} sm={1} xs={1}>
        </Grid>
        <Grid item lg={8} md={10} sm={10} xs={10} >
          <Toolbar variant={'dense'} disableGutters>
            <Link to={'/Home'} onClick={ resetAll } style={{height:'63px'}}>
              <img src={SherlockLogo} alt="Sherlock Logo" style={{paddingTop:'1px'}} />
            </Link>
            <Grid container justify="flex-end" alignItems="center">
                <Link to={'/Home'} onClick={ resetAll } className={classes.removeUnderline}>
                  <HeaderButton label='Home' />
                </Link>
              <Button
                  className={classes.headerButtonDropdown}
                  onClick={handleMenu}
                  endIcon={<ArrowDropDown className={classes.dropdownIcon} style={{marginLeft:-10}}/>}
              ><Typography variant={fontSize}>Services</Typography>
              </Button>
              <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                  transformOrigin={{vertical: 'top', horizontal: 'center'}}
              >
                <MenuItem><Link to={'/DownloadHome'} onClick={ setDownload } className={classes.link}>Data Download</Link></MenuItem>
                {/*<MenuItem><MUILink href={hostname+apiDocsUrl+'APIhome'} target='_blank' style={{textDecoration:'none', color:'#000', fontSize:16}}>Data API</MUILink></MenuItem>*/}
                <MenuItem><MUILink href={hostname+apiDocsUrl+'catalog'} target='_blank' style={{textDecoration:'none', color:'#000', fontSize:16}}>Data Catalog</MUILink></MenuItem>
                <MenuItem><MUILink href={hostname+apiDocsUrl} target='_blank' style={{textDecoration:'none', color:'#000', fontSize:16}}>Documentation</MUILink></MenuItem>
              </Menu>
               <Link to={'/About'} onClick={ resetAll } className={classes.removeUnderline}>
                <HeaderButton label='About' />
               </Link>
              <Link to={'/Contact'} onClick={ resetAll } className={classes.removeUnderline}>
                <HeaderButton label='Contact'/>
              </Link>
              <HeaderAvatar linkClassName={classes.removeUnderline}/>
              <HeaderSupport/>
            </Grid>
          </Toolbar>
        </Grid>
        <Grid item lg={2} md={1} sm={1} />
      </Grid>        
    </AppBar>
  )
}

export default withWidth()(Header);