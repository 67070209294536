import React, {useReducer} from 'react'
import AuditProductSecuredDetailsReducer from "./auditProductSecuredDetailsReducer";
import AuditProductSecuredDetailsContext from "./auditProductSecuredDetailsContext";
import {SET_CHART, SET_CHART_LOADING} from "../types";
import {productDetailsAxes} from "../../components/Data/chartData";

const AuditProductSecuredDetailsState = props => {
    const initialState = {
        axes: productDetailsAxes,
        chartLoading: false,
        chartFiles: [],
        chartTitle: 'Product Download Details',
        chartHAxisTitle: 'Date',
        chartVAxisTitle: 'Products'
    }

    const [state, dispatch] = useReducer(AuditProductSecuredDetailsReducer, initialState);

    const setChartLoading = (chartLoading) => {
        dispatch({
            type: SET_CHART_LOADING,
            chartLoading: chartLoading
        })
    }

    const setChartFiles = (files) => {
        dispatch({
            type: SET_CHART,
            chartFiles: files
        })
    }

    return <AuditProductSecuredDetailsContext.Provider
        value = {{
            axes: state.axes,
            chartLoading: state.chartLoading,
            chartFiles: state.chartFiles,
            chartTitle: state.chartTitle,
            chartHAxisTitle: state.chartHAxisTitle,
            chartVAxisTitle: state.chartVAxisTitle,
            setChartLoading,
            setChartFiles
        }}>
        {props.children}
    </AuditProductSecuredDetailsContext.Provider>
}

export default AuditProductSecuredDetailsState