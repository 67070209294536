import React, {useContext} from 'react'
import ProfileContext from "../../../Contexts/Profile/profileContext";
import Grid from "@material-ui/core/Grid";
import UserInfoCard from "../../Cards/UserInfoCard";
import GuestQuickInfoPanel from "./GuestQuickInfoPanel";

const GuestInfoPanel = () => {
    const profileContext = useContext(ProfileContext)
    const {guest} = profileContext

    return (
        <Grid container alignItems="center" justify="space-around">
            <Grid item>
                <UserInfoCard username={guest.name} userId={guest.userId} email={guest.email}/>
            </Grid>
            <Grid item>
                <GuestQuickInfoPanel/>
            </Grid>
        </Grid>
    )
}

export default GuestInfoPanel