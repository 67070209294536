import React from 'react'
import {
  BOLD_TOP_NAV_CHARTS_FONT,
  BOLD_TOP_NAV_DOWNLOAD_FONT,
  BOLD_TOP_NAV_MAP_FONT,
  BOLD_TOP_SM_NAV_DOWNLOAD_FONT,
  HIGHLIGHT_TOP_NAV_CHARTS_BUTTON,
  HIGHLIGHT_TOP_NAV_CHARTS_FONT,
  HIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON,
  HIGHLIGHT_TOP_NAV_DOWNLOAD_FONT,
  HIGHLIGHT_TOP_NAV_MAP_BUTTON,
  HIGHLIGHT_TOP_NAV_MAP_FONT,
  HOVER_HIGHLIGHT_TOP_NAV_CHARTS_BUTTON,
  HOVER_HIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON,
  HOVER_HIGHLIGHT_TOP_NAV_MAP_BUTTON,
  RESET_TOP_NAV_ALL,
  SET_MENU_ICON_ON,
  UNHIGHLIGHT_TOP_NAV_CHARTS_BUTTON,
  UNHIGHLIGHT_TOP_NAV_CHARTS_FONT,
  UNHIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON,
  UNHIGHLIGHT_TOP_NAV_DOWNLOAD_FONT,
  UNHIGHLIGHT_TOP_NAV_MAP_BUTTON,
  UNHIGHLIGHT_TOP_NAV_MAP_FONT,
} from '../types'
import MenuIcon from '@material-ui/icons/Menu'

export default (state, action) => {
  switch(action.type){
    case BOLD_TOP_NAV_DOWNLOAD_FONT:
      return {
        ...state,
        downloadLinkFont: 'subtitle2',
        chartsLinkFont:   'subtitle1',
        mapLinkFont:      'subtitle1',
        menuIcon: <MenuIcon/>,
      }
    case BOLD_TOP_NAV_CHARTS_FONT:
      return {
        ...state,
        downloadLinkFont: 'subtitle1',
        chartsLinkFont:   'subtitle2',
        mapLinkFont:      'subtitle1',
        menuIcon: <MenuIcon/>,
      }
    case BOLD_TOP_NAV_MAP_FONT:
      return {
        ...state,
        downloadLinkFont: 'subtitle1',
        chartsLinkFont:   'subtitle1',
        mapLinkFont:      'subtitle2',
        menuIcon: <MenuIcon/>,
      }
    case HIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON:
      return {
        ...state,
        downloadButtonColor: '#5b93bf',
        chartsButtonColor:   'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)',
        mapButtonColor:      'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)',
        menuIcon: <MenuIcon/>,
      }
    case HIGHLIGHT_TOP_NAV_CHARTS_BUTTON:
      return {
        ...state,
        downloadButtonColor: 'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)',
        chartsButtonColor:   '#5b93bf',
        mapButtonColor:      'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)',
        menuIcon: <MenuIcon/>,
      }
    case HIGHLIGHT_TOP_NAV_MAP_BUTTON:
      return {
        ...state,
        downloadButtonColor: 'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)',
        chartsButtonColor:   'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)',
        mapButtonColor:      '#5b93bf',
        menuIcon: <MenuIcon/>,
      }
    case HOVER_HIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON:
      return {
        ...state,
        downloadButtonColor:   '#5b93bf'
      }
    case HOVER_HIGHLIGHT_TOP_NAV_CHARTS_BUTTON:
      return {
        ...state,
        chartsButtonColor: '#5b93bf'
      }
    case HOVER_HIGHLIGHT_TOP_NAV_MAP_BUTTON:
      return {
        ...state,
        mapButtonColor: '#5b93bf'
      }
    case UNHIGHLIGHT_TOP_NAV_DOWNLOAD_BUTTON:
      return {
        ...state,
        downloadButtonColor: 'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)'
      }
    case UNHIGHLIGHT_TOP_NAV_CHARTS_BUTTON:
      return {
        ...state,
        chartsButtonColor:   'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)'
      }
    case UNHIGHLIGHT_TOP_NAV_MAP_BUTTON:
      return {
        ...state,
        mapButtonColor:      'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)'
      }
    case HIGHLIGHT_TOP_NAV_DOWNLOAD_FONT:
      return {
        ...state,
        downloadFontColor: '#0d47a1',
        chartsFontColor:   '#232323',
        mapFontColor:      '#232323',
        menuIcon: <MenuIcon/>,
      }
    case HIGHLIGHT_TOP_NAV_CHARTS_FONT:
      return {
        ...state,
        downloadFontColor: '#232323',
        chartsFontColor:   '#0d47a1',
        mapFontColor:      '#232323',
        menuIcon: <MenuIcon/>,
      }
    case HIGHLIGHT_TOP_NAV_MAP_FONT:
      return {
        ...state,
        downloadFontColor: '#232323',
        chartsFontColor:   '#232323',
        mapFontColor:      '#0d47a1',
        menuIcon: <MenuIcon/>,
      }
    case UNHIGHLIGHT_TOP_NAV_DOWNLOAD_FONT:
      return {
        ...state,
        downloadFontColor: '#232323'
      }
    case UNHIGHLIGHT_TOP_NAV_CHARTS_FONT:
      return {
        ...state,
        chartsFontColor:   '#232323'
      }
    case UNHIGHLIGHT_TOP_NAV_MAP_FONT:
      return {
        ...state,
        mapFontColor:      '#232323'
      }
    case RESET_TOP_NAV_ALL:
      return {
        ...state,
        downloadLinkFont: 'subtitle1',
        chartsLinkFont:   'subtitle1',
        mapLinkFont:      'subtitle1',
        menuIcon:         '',
        downloadButtonColor: 'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)',
        chartsButtonColor:   'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)',
        mapButtonColor:      'linear-gradient(180deg, #b0bec5 55%, #78909c 90%)',
        downloadFontColor: '#232323',
        chartsFontColor:   '#232323',
        mapFontColor:      '#232323',
      }
    case BOLD_TOP_SM_NAV_DOWNLOAD_FONT:
      return {
        ...state,
        smDownloadLinkFont: {
          'fontSize':     16,
          'fontWeight':   '700',
          'color':        '#01579b',
          'marginRight':  '8px',
        }
      }
    case SET_MENU_ICON_ON:
      return {
        ...state,
        menuIcon: <MenuIcon/>
      }
    default: return state
  }
}