import React from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {AccountCircle} from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(theme => ({
    highlightFont: {
        fontWeight: 550
    },
    infoFont: {
        color: "#9e9e9e"
    },
    accountIcon: {
        color: theme.palette.primary.main,
        fontSize: 50
    },
}))

const UserInfoCard = (props) => {
    const classes = useStyles()
    const {username, userId, email} = props

    const preview = <Skeleton variant="text"/>

    return (
        <Paper elevation={4} style={{padding: 15}}>
            <Grid container alignItems="center" direction="column">
                <AccountCircle className={classes.accountIcon}/>
                {username !== "undefined" ? <Typography variant="h5" className={classes.highlightFont}>{username}</Typography> : preview}
                {userId !== "undefined" ? <Typography className={classes.infoFont}>{userId}</Typography> : preview}
                {email !== "undefined" ? <Typography className={classes.infoFont}>{email}</Typography> : preview}
            </Grid>
        </Paper>
    )
}

export default UserInfoCard