import React, {useReducer} from 'react'
import EndDateContext from "./endDateContext";
import EndDateReducer from "./endDateReducer";
import {SET_CURRENT_DATE, SET_DATE_TIME} from "../types";

const EndDateState = props => {
    const date = new Date(new Date().setHours(0,0) - 1000 * 3600 * 24 * 3)
    const initialState = {
        endDate: date.toISOString().split('T')[0].replace(/-/g, '/'),
        endTime: "00:00",
        maxDate: date,
        currentDate: date
    }

    const [state, dispatch] = useReducer(EndDateReducer, initialState);

    const setEndDate = (value) => {
        // console.log("Context Handle End Date ", value)
        dispatch({
            type: SET_DATE_TIME,
            endDate: value.toString().split(' ')[0],
            endTime: value.toString().split(' ')[1]
        })
    }

    const setCurrentDate = (value) => {
        dispatch({
            type: SET_CURRENT_DATE,
            currentDate: value
        })
    }

    return <EndDateContext.Provider
        value = {{
            endDate: state.endDate,
            endTime: state.endTime,
            currentDate: state.currentDate,
            maxDate: state.maxDate,
            setEndDate,
            setCurrentDate
        }}>
        {props.children}
    </EndDateContext.Provider>
}

export default EndDateState