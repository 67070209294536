import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from "@material-ui/core/Typography";

const drawerWidth = 245;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    width: `calc(100% - ${drawerWidth}px)`,
    marginBottom: `${drawerWidth}px`,
    [theme.breakpoints.up('md')]: {
      marginLeft: `${drawerWidth}px`,
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(15),
      marginRight: theme.spacing(1),
      paddingTop: 0,
      width: '100%',
    },
  },
  paper: {
    backgroundColor: "#fff",
    padding: 50,
    [theme.breakpoints.up('md')]: {
      marginRight: '25%',
    },
  },
  horizontalLine: {
    borderColor: theme.palette.secondary.main,
    marginTop: 25,
    marginBottom: 25
  },
  fontBold: {
    fontWeight: 700
  },
  font: {
    fontWeight: 500
  },
  toolbar: theme.mixins.toolbar,
}));

function About() {

  const classes = useStyles();

  return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Paper className={classes.paper} elevation={0}>
          <Typography variant="h6" className={classes.fontBold}>About</Typography>
          <hr className={classes.horizontalLine}/>
          <p className={classes.font} align="justify">Sherlock is a data warehouse storing large variety of Air Traffic Management (ATM) data collected over many years. Sherlock focuses on the NASA vision to accelerate ATM and aviation research efforts by enhancing Sherlock Data Warehouse data products to increase their readiness level for analysis and research. Many FAA System Wide Management (SWIM) products are integrated, processed and merged to develop quality data products ready to use. This integration produces analysis-ready flight data sets, including nationwide merged data, performance reports, and more.</p>
          <p className={classes.font} align="justify">NASA has contracted ATAC to stitch together data from various facilities (Centers, TRACONS, and airport surface data) to create a set of integrated data products that track surface activity, flight plans, flight events, and trajectories. The benefits of these products lie in the fact that they provide a complete picture of each flight from origination airport to destination airport. Complete trajectories can be mapped from the Track Points (IFF) files, and the events from the Flight Events (EV) files can be matched to the trajectories. The same data products are also available by individual facilities. The integrated products, sometimes also referred to as merged or USA, provide an end-to-end view of the data. The individual facility products provide a partial view.</p>
          {/*<p className={classes.font} align="justify">There  is one file per facility per day for each file type. Track Points (IFF), Flight Events (EV), and Flight Summary (RD) files for the same facility and date will have matching names, except for the IFF, EV, or RD prefix, and constitute a file set. Each set contains data on flights that ended in a 24 hour local standard time-based period found in the name of the file. For example, an IFF file with the name IFF_ZDC_20210610_050225_86208.csv contains flight track and flight plan information for flights recorded by Washington Center that ended between 00:00:00 and 23:59:59 local on 6/10/2021.  When using a UTC time reference this corresponds to flights that ended between 05:00:00 on 6/10/2021 and 04:59:59 on 6/11/2021. All internal file times are UTC unless specifically noted otherwise.</p>*/}
          {/*<p className={classes.font} align="justify">The file formats were designed to handle characteristics from multiple independent ATC automation systems and simulation outputs, so not all fields are populated at any given time for a particular application.  For example, Field10 or flight plan route only exists in Center automation data, so this field will not be populated in flight plan messages received from other automation systems.</p>*/}
          <p className={classes.font} align="justify">The File Download link in the top nav bar opens an interface that provides access to the Sherlock file archive. You can download flat files directly from the archive based on start date, end date, product, and facility filters. The Track Points files are particularly large so use caution regarding how many files you download at once.</p>
          <p className={classes.font} align="justify">For assistance, please email sherlock-support@lists.nasa.gov</p>
        </Paper>
      </main>
  )
}

export default About