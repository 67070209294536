import React, {useReducer} from 'react'
import AirportReportReducer from "../AirportReport/airportReportReducer";
import AirportReportContext from "../AirportReport/airportReportContext";
import {
    SET_CANCEL_TOKEN_SOURCE,
    SET_CLICKED,
    SET_DATA_PRODUCTS,
    SET_FACILITIES,
    SET_FILES, SET_PROGRESS,
    SET_TABLE_LOADING
} from "../types";
import airportReportData from "../../components/Data/airportReportData";
import axios from "axios";

const AirportReportState = props => {
    const initialState = {
        dataProducts: {
            runwayUsage: {
                name: "runwayUsage",
                label: "Runway Usage",
                checked: false,
                value: "RUNWAYUSAGE"
            },
            taxiTime: {
                name: "taxiTime",
                label: "Taxi Time",
                checked: false,
                value: "TAXITIME"
            }
        },
        facilities: {
            // airports: {
                name: "Airports",
                value: airportReportData
            // }
        },
        clicked: false,
        tableLoading: false,
        files: [],
        progress: 0,
        cancelTokenSource: axios.CancelToken.source()
    }

    const [state, dispatch] = useReducer(AirportReportReducer, initialState);

    const setDataProducts = (dataProducts) => {
        dispatch({
            type: SET_DATA_PRODUCTS,
            dataProducts: dataProducts
        })
    }

    const setFacilities = (facilities) => {
        dispatch({
            type: SET_FACILITIES,
            facilities: facilities
        })
    }

    const setClicked = () => {
        dispatch({
            type: SET_CLICKED
        })
    }

    const setTableLoading = (tableLoading) => {
        dispatch({
            type: SET_TABLE_LOADING,
            tableLoading: tableLoading
        })
    }

    const setFiles = (files) => {
        dispatch({
            type: SET_FILES,
            files: files
        })
    }

    const setProgress = (progress) => {
        dispatch({
            type: SET_PROGRESS,
            progress: progress
        })
    }

    const resetProgress = () => {
        dispatch({
            type: SET_PROGRESS,
            progress: 0
        })
    }

    const resetCancelToken = () => {
        dispatch({
            type: SET_CANCEL_TOKEN_SOURCE,
            cancelTokenSource: axios.CancelToken.source()
        })
    }

    return <AirportReportContext.Provider
        value = {{
            dataProducts: state.dataProducts,
            facilities: state.facilities,
            clicked: state.clicked,
            tableLoading: state.tableLoading,
            files: state.files,
            progress: state.progress,
            cancelTokenSource: state.cancelTokenSource,
            setDataProducts,
            setFacilities,
            setClicked,
            setTableLoading,
            setFiles,
            setProgress,
            resetCancelToken
        }}>
        {props.children}
    </AirportReportContext.Provider>
}

export default AirportReportState