import React, {useReducer} from 'react'
import HomeContext from './homeContext'
import HomeReducer from './homeReducer'
import {HIGHLIGHT_CHARTS, HIGHLIGHT_DOWNLOADS, HIGHLIGHT_MAPS, UNHIGHLIGHT_ALL,} from '../types'

const HomeState = props => {
  const initialState = {
    dmColorProp:          'default',
    dmVariantProp:        'text',
    dmTypoVarProp:        'subtitle1',
    dmCursor:             'auto',
    dcColorProp:          'default',
    dcVariantProp:        'text',
    dcTypoVarProp:        'subtitle1',
    dcCursor:             'auto',
    downloadColorProp:    'default',
    downloadVariantProp:  'text',
    downloadTypoVarProp:  'subtitle1',
    downloadCursor:       'auto',
    chartsColorProp:       'default',
    chartsVariantProp:     'text',
    chartsTypoVarProp:     'subtitle1',
    chartsCursor:          'auto',
    mapColorProp:         'default',
    mapVariantProp:       'text',
    mapTypoVarProp:       'subtitle1',
    mapCursor:            'auto',
    dataCategory:         '',
    mergedExpFont:        'subtitle1',
    individualExpFont:    'subtitle1',
  }

  const [state, dispatch] = useReducer(HomeReducer, initialState)

  //Highlight the data products that are available in charts and grids
  const highlightCharts = () => (
    dispatch({
      type: HIGHLIGHT_CHARTS
    })
  )
  //Highlight the data products that are available to download as flat files.
  const highlightDownloads = () => (
    dispatch({
      type: HIGHLIGHT_DOWNLOADS,
    })
  )
  //Highlight the data products that can be plotted on a map.
  const highlightMaps = () => (
    dispatch ({
      type: HIGHLIGHT_MAPS,
    })
  )
  const unhighlightAll = () => (
    dispatch ({
      type: UNHIGHLIGHT_ALL,
    })
  )

  return  <HomeContext.Provider value = {
            {
              dmColorProp:          state.dmColorProp,
              dmVariantProp:        state.dmVariantProp,
              dmTypoVarProp:        state.dmTypoVarProp,
              dmCursor:             state.dmCursor,
              dcColorProp:          state.dcColorProp,
              dcVariantProp:        state.dcVariantProp,
              dcTypoVarProp:        state.dcTypoVarProp,
              dcCursor:             state.dcCursor,
              downloadColorProp:    state.downloadColorProp,
              downloadVariantProp:  state.downloadVariantProp,
              downloadTypoVarProp:  state.downloadTypoVarProp,
              downloadCursor:       state.downloadCursor,
              chartsColorProp:       state.chartsColorProp,
              chartsVariantProp:     state.chartsariantProp,
              chartsTypoVarProp:     state.chartsTypoVarProp,
              chartsCursor:          state.chartsCursor,
              mapColorProp:         state.mapColorProp,
              mapVariantProp:       state.mapVariantProp,
              mapTypoVarProp:       state.mapTypoVarProp,
              mapCursor:            state.mapCursor,
              dataCategory:         state.dataCategory,
              mergedExpFont:        state.mergedExpFont,
              individualExpFont:    state.individualExpFont,
              highlightCharts,
              highlightDownloads,
              highlightMaps,
              unhighlightAll,
            }
          }>

        {props.children}
        </HomeContext.Provider>
}

export default HomeState