import React, {useContext} from 'react'
import AuditProductInterestDetailsContext
    from "../../../Contexts/AuditProductInterestDetails/auditProductInterestDetailsContext";
import AuditUserActivitySummaryContext
    from "../../../Contexts/AuditUserActivitySummary/auditUserActivitySummaryContext";
import PieChart from "../../Charts/PieChart";
import LinearProgress from "@material-ui/core/LinearProgress";
import {handleDetails} from "../../Utils/AuditUtils";

const ProductInterestDetails = () => {
    const auditProductInterestDetailsContext = useContext(AuditProductInterestDetailsContext)
    const {chartLoading, axes, chartFiles} = auditProductInterestDetailsContext
    const {chartTitle,chartHAxisTitle,chartVAxisTitle} = auditProductInterestDetailsContext
    const setProductInterestDetailsChartLoading = auditProductInterestDetailsContext.setChartLoading
    const setProductInterestDetailsChartFiles = auditProductInterestDetailsContext.setChartFiles

    const auditUserActivitySummaryContext = useContext(AuditUserActivitySummaryContext)
    const {files} = auditUserActivitySummaryContext

    const handleProductInterestDetails = () => {
        handleDetails(files, "search", "product", setProductInterestDetailsChartFiles)
    }

    React.useEffect(() => {
        setProductInterestDetailsChartLoading(true)
        handleProductInterestDetails()
    }, [files])

    React.useEffect(() => {
        setProductInterestDetailsChartLoading(false)
    }, [chartFiles])

    return (
        chartLoading ? <LinearProgress/> : <PieChart chartFiles={chartFiles} columns={axes} title={chartTitle} haxis={chartHAxisTitle} vaxis={chartVAxisTitle}/>
    )
}

export default ProductInterestDetails