import {traconFacilities} from './facilityData'


const traconData = traconFacilities.map((name, id) => {return (
    {
        id: id,
        name: name,
        checked: false,
        value: name.toLowerCase() + ","
    }
)})

export default traconData