import React, {useContext} from 'react'
import StartDateContext from "../../Contexts/StartDate/startDateContext";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import HorizontalDateWidget from "../Selections/HorizontalDateWidget";
import {makeStyles} from "@material-ui/core/styles";
import ProfileSearch from "./ProfileSearch";
import ProfileSelection from "./ProfileSelection";

const useStyles = makeStyles((theme) => ({
    panel: {
        backgroundColor: theme.palette.common.lighterGray
    }
}));

const ProfilePanel = () => {
    const classes = useStyles();
    const startDateContext = useContext(StartDateContext)
    const resetTooltip = startDateContext.resetTooltip

    React.useEffect(() => {
        resetTooltip()
    }, [])

    return (
        <Paper className={classes.panel} style={{marginBottom:20,paddingLeft:15}}>
            <Grid container style={{marginTop: 30}} alignItems="center" spacing={4}>
                <Grid item>
                    <HorizontalDateWidget/>
                </Grid>
                <Grid item>
                    <ProfileSelection/>
                </Grid>
                <Grid item>
                    <ProfileSearch/>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default ProfilePanel