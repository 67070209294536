import {createMuiTheme} from "@material-ui/core/styles";

const nasaBlue = '#0d47a1'
const nasaGray = '#eceff1'

export const AuditTableTheme = createMuiTheme({
    palette: {
        primary: {
            main: `${nasaBlue}`
        },
        // keeps row color white on select
        secondary: {
            main: '#fff'
        },
        text: {
            secondary: '#0277bd'
        }
    },
    typography: {
        fontSize: 14,
    },
    button: {
        textTransform: 'capitalize'
    },
    overrides: {
        MUIDataTable: {
            paper: {
                boxShadow: "none",
            },
        },
        // MUIDataTableRow: {
        //     root: {
        //         padding: '0px'
        //     }
        // },
        MUIDataTableToolbar: {
            root: {
                // backgroundColor: '#fafafa',
                display: 'none'
            }
        },
        MUIDataTableToolbarSelect: {
            root: {
                display: 'none'
            }
        },
        MUIDataTableHeadCell: {
            fixedHeader: {
                backgroundColor: '#eeeeee',
                color: `${nasaBlue}`,
                fontWeight: 700,
                paddingTop: 8,
                paddingBottom: 8,
                fontSize: '1rem'
                // fontFamily: '"Calibri"',
            },
        },
        // MUIDataTableBodyCell: {
        //     root: {
        //         paddingTop: '4px',
        //         paddingBottom: '0px',
        //         paddingLeft: '8px',
        //         paddingRight: '0px',
        //         height: '25px',
        //         whiteSpace: 'nowrap'
        //     },
        // },
        MUIDataTableSelectCell: {
            headerCell: {
                backgroundColor: '#eeeeee',
            },
        },
        // change row color on select or create a TableTheme
        MUIDataTableBodyRow: {
            root: {
                '&:nth-child(even)': {
                    backgroundColor: '#fafafa'
                },
            },
        },
        MUIDataTableCell: {
            root: {
                paddingRight: '0px',
            }
        },
        MuiTableCell: {
            footer: {
                overflow: 'hidden',
            }
        },
        MuiTablePagination: {
            root: {
                overflow: 'hidden'
            },
            toolbar: {
                overflow: 'hidden'
            }
        },
        // MuiSvgIcon: {
        //     root: {
        //         width: '.7em',
        //         height: '.7em',
        //     }
        // },
    }
})
