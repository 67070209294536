import React, {useReducer} from 'react'
import ProfileGuestProductInterestDetailsReducer from "./profileGuestProductInterestDetailsReducer";
import ProfileGuestProductInterestDetailsContext from "./profileGuestProductInterestDetailsContext";
import {SET_CHART, SET_CHART_LOADING} from "../types";
import {productDetailsAxes} from "../../components/Data/chartData";

const ProfileGuestProductInterestDetailsState = props => {
    const initialState = {
        axes: productDetailsAxes,
        chartLoading: false,
        chartFiles: [],
        chartTitle: 'Product Search Details',
        chartHAxisTitle: 'Date',
        chartVAxisTitle: 'Products'
    }

    const [state, dispatch] = useReducer(ProfileGuestProductInterestDetailsReducer, initialState);

    const setChartLoading = (chartLoading) => {
        dispatch({
            type: SET_CHART_LOADING,
            chartLoading: chartLoading
        })
    }

    const setChartFiles = (files) => {
        dispatch({
            type: SET_CHART,
            chartFiles: files
        })
    }

    return <ProfileGuestProductInterestDetailsContext.Provider
        value = {{
            axes: state.axes,
            chartLoading: state.chartLoading,
            chartFiles: state.chartFiles,
            chartTitle: state.chartTitle,
            chartHAxisTitle: state.chartHAxisTitle,
            chartVAxisTitle: state.chartVAxisTitle,
            setChartLoading,
            setChartFiles
        }}>
        {props.children}
    </ProfileGuestProductInterestDetailsContext.Provider>
}

export default ProfileGuestProductInterestDetailsState